import React, {useEffect, useState} from 'react';
import styles from './ListingAssistantModal.module.scss'
import {MoonLoader} from "react-spinners";
import {CloseModal, Lightbulb, PopoverIcon} from "../../../../assets/icons";
import {toast} from "react-toastify";
import Listings from '../../../../services/Listings'
import _ from "lodash";
import CreatableSelect from 'react-select/creatable';
import Tippy from "@tippyjs/react";
import Button, {ModifierType} from "../../../UI/Button";
import Auth from "@services/Auth";
import UserConfigs from "@services/UserConfigs";

export const ListingAssistantModal = (
 {
	 configID,
	 setShowListingAssistantModal,
	 imagesFromUploader,
	 setExpressList,
	 setFormChanged,
	 setFormChangedWhileCreating,
	 setUpdatedItems,
	 listingAssistantTone,
	 setListingAssistantTone,
	 listingAssistantContext,
	 setShowRegenerate,
	 setListingAssistantContext,
	 setRegenerateAttempsLeftCount,
	 assistantTitle,
	 setRegenerateId,
	 regenerateId,
	 regenerateAttempsLeftCount,
	 regenerateTotal,
	 setRegenerateTotal,
	 allFieldsForListingAssistant,
	 setCreateActive
 }: any) => {
	const [listingAssistantLoader, setListingAssistantLoader] = useState(false);
	const [barCodeLimits, setBarCodeLimits] = useState({
		available: 0,
		total: 0,
	});
	const [enableLaunchAssistant, setEnableLaunchAssistant] = useState(true);
	const [selectedOption, setSelectedOption] = useState<any>(listingAssistantTone ? {value: listingAssistantTone, label: listingAssistantTone} : null);
	const [options, setOptions] = useState([
		{ value: 'Slightly funny', label: 'Slightly funny' },
		{ value: 'Very funny', label: 'Very funny' },
		{ value: 'Storyteller', label: 'Storyteller' },
		{ value: 'Verbose', label: 'Verbose' },
		{ value: 'Professional', label: 'Professional' },
		{ value: 'Concise', label: 'Concise' },
		{ value: 'Just the facts', label: 'Just the facts' },
		{ value: 'Antique or Vintage', label: 'Antique or Vintage' },
		{ value: 'Sports or Athletics', label: 'Sports or Athletics' },
		{ value: 'Bookworm', label: 'Bookworm' },
		{ value: 'Persuasive', label: 'Persuasive' },
		{ value: '', label: 'Add your own' }
	]);
	const [preventFirstRender, setPreventFirstRender] = useState(false);
	const [availableLoader, setAvailableLoader] = useState(true)
	const [localAvalaibleCount, setLocalAvalaibleCount] = useState(0);
	const token: any = Auth.get_token();

	const customStyles = {
		input: (provided:any) => ({
			...provided,
			cursor: 'pointer',
		}),
		clearIndicator: (provided:any) => ({
			...provided,
			cursor: 'pointer',
		}),
		menuList: (provided:any) => ({
			...provided,
			maxHeight: '200px',
			overflow:'auto'
		}),
	};

	const handleUpdateTone = async(value :any) => {
		setEnableLaunchAssistant(false);
		try {
			const payload = {
					config: {
						['listing_assistant_tone'] : value
					},
			};
			await UserConfigs.website_update({ id: configID }, payload, token)
			setListingAssistantTone(value)
		}
		catch (e) {
			console.log(e, 'error')
		}
		finally {
			setEnableLaunchAssistant(true);
		}
	}

	const handleContextChange = (e:any) =>{
		setListingAssistantContext(e.target?.value);
	}

	const handleLaunchClicked = async()=>{
		setListingAssistantLoader(true);
		setCreateActive(false);
		try {
			if(imagesFromUploader.length){
				const changeImagesPrefix = imagesFromUploader.map((url:any) =>
				 url.includes('https://s3.amazonaws.com/app1.listperfectly') ?
				  url.replace('https://s3.amazonaws.com/app1.listperfectly', 'https://media.listperfectly.com') :
				  url
				);
				const valueOfTone = selectedOption?.value ? selectedOption?.value : "";
				const payload: any = {
					133: 'Journey Through Time: Mysteries of Ancient Civilizations',
					image_urls : changeImagesPrefix,
				}
				if(valueOfTone) {
					payload.tone = valueOfTone;
				}
				if(listingAssistantContext) {
					payload.additional_context = listingAssistantContext;
				}
				if (assistantTitle === 'regenerate') {
					payload.regeneration_id = regenerateId;
				}

				const options = {
					version: 4,
				}

				const response : any = await Listings.tools_generator_add(token,  payload, options);
                if(response) {
					setListingAssistantTone(valueOfTone)
					// await handleUpdateTone(valueOfTone)
					if (setExpressList) {
						const updatedItems: any = {};

						// Define the keys you want to include
						const keysToInclude = ['133', '209', '82', '213', '206', '505', '704', '453', '15', '205', '211', '2', '3', '795', '796', '17', '210', '43', '36' ];

						// not needed
						// const filteredKeysToInclude = keysToInclude.filter(code => backendCodes?.includes(code));

						const filteredKeysToInclude = keysToInclude.filter((code: string) =>
						 allFieldsForListingAssistant.some((field: any) => field?.keyCode === code && field?.checked)
						);

						// Define the mapping from key to name
						const keyToNameMapping: any = {
							'133': 'Title',
							'209': 'Color',
							'82': 'Material',
							'213': 'Size',
							'206': 'Brand',
							'505': 'Description',
							'704': 'Upc',
							'453': 'Keywords',
							'15': 'Condition',
							'205': 'Style',
							'211': 'Madein',
							'2': 'ShippingWeightlbkg',
							'3': 'ShippingWeightozg',
							'795': 'NewCondition',
							'796': 'PreOwnedCondition',
							'17': 'ConditionNotes',
							'210': 'PatternOrTexture',
							'43': 'Care',
							'36': 'SeasonOrWeather'
						};

						filteredKeysToInclude.forEach(key => {
							const name = `new${keyToNameMapping[key]}`;
							if (_.has(response.data, key)) {
								let value = _.get(response.data, key);
								// Check if the value is an array
							if(value !== null && value !== '' && value !== undefined) {
								if (Array.isArray(value)) {
									// Convert array to a string separated by commas
									value = value.join(', ');
								}
								if(name === 'newShippingWeightlbkg' || name === 'newShippingWeightozg'){
									value = value.toString();
								}
								updatedItems[name] = value;
							}
							}
						});

						setExpressList((prevState: any) => ({
							...prevState,
							// Update only the fields present in updatedItems
							...updatedItems,
							// Set 'changed' to true for each updated field
							...Object.keys(updatedItems).reduce((obj:any, key:any) => {
								obj[key] = { text: updatedItems[key], changed: true };
								return obj;
							}, {}),
						}));

						setUpdatedItems((prevState: any) => ({
							...prevState,
							// Update only the fields present in updatedItems
							...updatedItems,
						}));

						setFormChangedWhileCreating(true);
						setFormChanged(true);
					}
					setRegenerateAttempsLeftCount(response?.regeneration_quota?.available);
					setRegenerateTotal(response?.regeneration_quota?.total);
					if(response?.data?.regeneration_id) {
						setRegenerateId(response?.data?.regeneration_id);
					}
				}


				toast.success(`Details generated`, {
					autoClose: 2000,
					position: 'bottom-right', // You can customize the notification position
				});
				setLocalAvalaibleCount(localAvalaibleCount + 1);
				setShowListingAssistantModal(false);
				setShowRegenerate(true);

			}
			else {
				toast.warning(`Please upload some images for generate details`, {
					position: 'bottom-right', // You can customize the notification position
				});
			}
		}
		catch (e) {
			toast.error(`Request failed`, {
				position: 'bottom-right', // You can customize the notification position
			});
			setCreateActive(true);
			console.log('error', e)
		}
		finally {
			setListingAssistantLoader(false);
		}
	}
	const handleCloseAssistantModal = () => {
		setShowListingAssistantModal(false);
		setCreateActive(true);
	}
	const handleGetBarCodeTotal = async () => {
		if(assistantTitle !== 'assistant') return
		try {
			const response : any = await  Listings.tools_quotas_list(token, { name: 'generator' })

			const data = response.data[0];
			setBarCodeLimits({
				available: data.available,
				total: data.total,
			});
		} catch (e) {
			console.log(e, 'error');
		}
		finally {
			setAvailableLoader(false)
		}
	};

	const handleInputChange = async(newValue: any, actionMeta: any) => {
		try {
			if (actionMeta.action === 'clear' || newValue.value === '') {
				setSelectedOption(null);
			} else if (newValue && typeof newValue === 'object' && newValue.value) {
				setSelectedOption(newValue);
			} else if (typeof newValue === 'string') {
				const strippedValue = newValue.replace(/\s/g, ''); // Remove spaces
				if (strippedValue.length <= 20) {
					setSelectedOption({ value: newValue, label: newValue });
				}
			}
		} catch (error) {
			console.error('Error handling input change:', error);
		}
	};

	useEffect(() => {
		handleGetBarCodeTotal();
	}, []);

	useEffect(() => {
		setPreventFirstRender(true);
		 if(preventFirstRender) {
			  const valueOfTone = selectedOption?.value ? selectedOption?.value : "";
			  handleUpdateTone(valueOfTone)
		 }
	}, [selectedOption]);

	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		}
	}, []);


	return (
	 <div className={styles.listingAssistantModalContainer} style={{width: `100vw`}}>
		 <div className={styles.listingAssistantModal}>
			 <div className={styles.closeModal} onClick={handleCloseAssistantModal}>
				 <CloseModal height={20} width={20}/>
			 </div>
			 {
				 listingAssistantLoader ? <div className={styles.loadercontainerOverlay}><MoonLoader/>
					  Generating details ...</div> :
				  (
				   <>

					   <p className={styles.notice}>{assistantTitle === 'assistant' ?  <div className={styles.listingAssistantTitle}><Lightbulb /> <span>Listing Assistant</span></div> : `Regenerate all listing details`}</p>
					   <div className={styles.assistantInnerContainer}>
						   {
							   assistantTitle === 'assistant' ?
								<>
									<p className={styles.description}>
										Listing Assistant uses the first six images to generate your listing. For the best
										results, images can include:
									</p>
									<ul className={styles.suggestedList}>
										<li>Labels</li>
										<li>Brand</li>
										<li>Size</li>
									</ul>
								</>
								:
								<>
									<p className={styles.description} style={{paddingBottom:'10px'}}>
										Use if the initial details had errors or you want to try a different tone.
										Moving or changing images will have no effect on regeneration. <br/> If you need
										details generated based on adjusted photos, use Listing Assistant option instead.
									</p>
								</>
						   }
						   {
							!enableLaunchAssistant &&
							<div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px'}}><MoonLoader
							 size={20}/>Saving tone...</div>
						   }
						   <div>
							   <div className={styles.toneTitleContainer}>
								   <p className={styles.headingTitle}>Choose tone <Tippy
									interactive={true}
									arrow={false}
									zIndex={9999}
									trigger="click"
									content={
										<div className={`popover ${styles.popoverContent}`}>
											Add or choose the tone for your listing to match the style and voice you want to convey. Whether it’s professional, friendly, or playful, selecting the right tone helps engage your target audience.
										</div>
									}
								   >
									   <PopoverIcon
										className={styles.popoverIcon}
										color={'#fff'}
									   ></PopoverIcon>
								   </Tippy></p>
								   <p style={{color: '#767676', fontStyle: 'italic', paddingTop: '5px'}}>Max
									   character
									   limit is 20</p>
							   </div>
							   <CreatableSelect
								placeholder={"Select a tone or add your own"}
								styles={customStyles}
								value={selectedOption}
								options={options}
								onChange={handleInputChange}
								isClearable
								isValidNewOption={(inputValue, selectValue, selectOptions) =>
								 inputValue.trim().length > 0 && inputValue.trim().length <= 20
								}
							   />
							   <div className={styles.contextTitleContainer}>
								   <p className={styles.headingTitle}>Add details <Tippy
									interactive={true}
									arrow={false}
									zIndex={9999}
									trigger="click"
									content={
										<div className={`popover ${styles.popoverContent}`}>
											Provide additional context or details that will help tailor the listing to your specific needs. This can include special features, unique selling points, or any other relevant information to enhance your listing.
										</div>
									}
								   >
									   <PopoverIcon
										className={styles.popoverIcon}
										color={'#fff'}
									   ></PopoverIcon>
								   </Tippy></p>
								   <p style={{color: '#767676', fontStyle: 'italic', paddingTop: '5px'}}>Max
									   character
									   limit is 200 </p>
							   </div>
							   <textarea
								className={styles.assistantTextarea}
								placeholder={''}
								value={listingAssistantContext}
								maxLength={200}
								onChange={(e) => handleContextChange(e)}
							   ></textarea>
						   </div>
						   {assistantTitle === 'assistant' &&
							<p className={styles.description}>Ensure these images accurately represent your item for the
								best results! Review for accuracy. </p>}
						   {
							   assistantTitle === 'assistant' ? <div className={styles.available}> {availableLoader ?
								 <div className={styles.loaderAvailable}><MoonLoader size={18}/>
								 </div> : barCodeLimits.available - localAvalaibleCount} of {availableLoader ?
								 <div className={styles.loaderAvailable}><MoonLoader size={18}/>
								 </div> : barCodeLimits.total} available</div> :
								<div className={styles.available}>
									{`${regenerateAttempsLeftCount} of ${regenerateTotal} available`}
								</div>
						   }
					   </div>
					   {
						   assistantTitle === 'assistant' ?
							barCodeLimits.available ?
								 <Button style={{margin:'auto'}} modifier={ModifierType.Warning} disabled={!enableLaunchAssistant || !(imagesFromUploader?.length > 0)}
										 handleClick={() => {
											 if (enableLaunchAssistant) {
										 			handleLaunchClicked();
									 			}
								 }}>
									 Launch Listing Assistant
								 </Button>
							 : null
							:
							<div onClick={handleLaunchClicked}
								 className={`${styles.launchButton} ${imagesFromUploader?.length ? '' : styles.disabledLaunchButton}`}> Generate
							</div>
					   }
				   </>
				  )
			 }
		 </div>
	 </div>
	);
};