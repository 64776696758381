import React from 'react' ;
import styles from "./AddSubAccountModal.module.scss" ;

import {AddSolid} from "@assets/icons";
import Button, {ModifierType} from "../../../../../../components/UI/Button";

interface AddSubAccountModalUIProps {
	addSubAccountModalRef:any;
	formik:any;
	// handleGeneratePassword:() => void;
	sendWelcomeEmail:boolean;
	setSendWelcomeEmail:(sendWelcomeEmail:any) => void;
	isButtonDisabled:boolean;
	handleToggleAddSubAccountModal:() => void;
	generatedPassword:string
}

const AddSubAccountModalUI = (props:AddSubAccountModalUIProps) => {
	const {addSubAccountModalRef,formik,
		// handleGeneratePassword,
		sendWelcomeEmail,setSendWelcomeEmail,isButtonDisabled,handleToggleAddSubAccountModal,generatedPassword} = props ;
	return (
	 <div className={styles['modal__overlay']}>
		 <div ref={addSubAccountModalRef} className={styles['modal']}>
			 <div className={styles['modal__header']}>
				 <div className={styles['modal__label']}>
					 <AddSolid/>
					 <div>Add Sub Account</div>
				 </div>
			 </div>
			 <form onSubmit={formik.handleSubmit}>
				 <div className={styles['modal__email']}>
					 <div className={styles['modal__content']}>
						 <label htmlFor="email">Email</label>
						 <input
						  id="email"
						  className={styles['modal__input']}

						  type="email"
						  {...formik.getFieldProps('email')}
						 />
						 {formik.touched.email && formik.errors.email ? (
						  <div className={styles['modal__error']}>{formik.errors.email}</div>
						 ) : null}
					 </div>
				 </div>
				 <div className={styles['modal__name']}>
					 <div className={styles['modal__content']}>
						 <label htmlFor="first_name">First Name</label>
						 <input
						  id="first_name"
						  className={styles['modal__input']}
						  {...formik.getFieldProps('first_name')}
						 />
						 {formik.touched.first_name && formik.errors.first_name ? (
						  <div className={styles['modal__error']}>{formik.errors.first_name}</div>
						 ) : null}
					 </div>
					 <div className={styles['modal__content']}>
						 <label htmlFor="last_name">Last Name</label>
						 <input
						  id="last_name"
						  className={styles['modal__input']}
						  {...formik.getFieldProps('last_name')}
						 />
						 {formik.touched.last_name && formik.errors.last_name ? (
						  <div className={styles['modal__error']}>{formik.errors.last_name}</div>
						 ) : null}
					 </div>
				 </div>
				 {/*<div className={styles['modal__password']}>*/}
					{/* <div className={styles['modal__content']}>*/}
					{/*	 <label htmlFor="password">Sub Account Password</label>*/}
					{/*	 <div className={styles['modal__wrapper']}>*/}
					{/*		 <button*/}
					{/*		  type="button"*/}
					{/*		  className={`myBtn ${styles['modal__generateBtn']}`}*/}
					{/*		  onClick={handleGeneratePassword}*/}
					{/*		 >*/}
					{/*			 Generate*/}
					{/*		 </button>*/}
					{/*		 <input*/}
					{/*		  id="password"*/}
					{/*		  className={styles['modal__input']}*/}
					{/*		  type="text"*/}
					{/*		  value={generatedPassword}*/}
					{/*		  readOnly*/}
					{/*		 />*/}
					{/*	 </div>*/}
					{/* </div>*/}
				 {/*</div>*/}
				 <div className={styles['modal__footer']}>
					 <div className={styles['modal__footer-left']}>
						 <input
						  type="checkbox"
						  checked={sendWelcomeEmail}
						  onChange={() => setSendWelcomeEmail(!sendWelcomeEmail)}
						  className={styles['modal__footer-checkbox']}
						  id={"sendEmail"}
						 />
						 <label htmlFor={"sendEmail"}>Send new members the welcome email</label>
					 </div>
					 <div className={styles['modal__footer-right']}>
						 <Button
						  modifier={ModifierType.Transparent}
						  style={{color: '#767676', background: '#EFEFEF'}}
						  handleClick={handleToggleAddSubAccountModal}>
							 Cancel
						 </Button>
						 <Button modifier={ModifierType.Primary} disabled={isButtonDisabled}>
							 Add
						 </Button>
					 </div>
				 </div>
			 </form>
		 </div>
	 </div>

	)
}

export default AddSubAccountModalUI;