import _ from 'lodash';
import { ITableData} from '../../../../../../interfaces';
import styles from './TableMobileCard.module.scss';
import useBasicFuncs from '../../../../../../hooks/useBasisFuncs';
import PlatformBlockItem from '../PlatformBlockItem';
import './sectionsMenu.css';
import {
  depopLogo,
  etsyLogo,
  facebookLogo,
  grailedLogo,
  instagramLogo,
  kidizenLogo,
  listingPartyLogo,
  shopifyLogo,
  vestiaireLogo,
} from '../../../../../../assets/constants/platformsLogo';
import {
  AmazonLogo,
  PoshmarkLogo,
  EbayLogo,
  MercaryLogo,
  SoldTagSvg,
  PlatformIconShop, ViewTemplatesQuickLink, ViewListingQuickLink, ViewIcon
} from '../../../../../../assets/icons'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Dropdown,
  DropdownActive,
  Sectionsitem1,
  Sectionsitem2,
  Sectionsitem3,
  Sectionsitem4,
  Sectionsitem5, YellowTriangle,
} from '../../../../../../assets/icons';
import Tippy from '@tippyjs/react';
import { useDispatch } from 'react-redux';
import {
  iframeLoaderChanger,
  setCandidantLinkPure,
  setDuplicateListingId,
  setEditAddModalId,
  setEditAddModalShow,
  setEditModalActive,
  setEditModalId,
  setIframeLink,
  setIframeName,
  setListingTemplateId,
  setListingViewId,
  setShowEditTemplateModal,
  setShowIframe,
  setShowListingViewModal,
  setShowQuestionModal,
} from '../../../../../../redux/slices/NavigationSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { MoonLoader } from 'react-spinners';

import useRandomNumber from '../../../../../../hooks/UseRandomNumber';
import _get from "lodash/get";
import {FaImage} from '../../../../../../assets/icons/'
import PlatformBlockİtem from "../PlatformBlockItem";
import {IPlatform, platforms} from "../ColumnSearch/data/platforms";
import {instanceAxios} from "../../../../../../services";
import useAppPrefix from "../../../../../../hooks/UseAppPrefix";
import {useNavigate} from "react-router-dom";
import useExtensionVersion from "../../../../../../hooks/useExtensionVersion";
import Button, {ModifierType} from "../../../../../../components/UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import useRoles from "@hooks/useRoles";
import Auth from "@services/Auth";

interface CardProps {
  row: ITableData;
  tab: any;
  isMobile: boolean;
  customMarketplaces: any;
  shopifyDinamicLink: string | null;
  syncTime:any;
  tableData:any;
  setTableData:any;
  index:any;
  hasExtensionInstall?:any;
  shopifyName:any;
  shopifyURLFormat:any;
  setAllTableData:any;
  setTabCounts2:any;
}

interface Tooltip {
  ebay?: boolean;
  etsy?: boolean;
  poshmark?: boolean;
  mercari?: boolean;
  amazon?: boolean;
  grailed?: boolean;
  facebook?: boolean;
  depop?: boolean;
  kidizen?: boolean;
  shopify?: boolean;
  instagram?: boolean;
  vestiaire?: boolean;
}

interface NavigationState {
  iframeLink: string;
}

interface RootState {
  navigation: NavigationState;
}

const TableMobileCard: React.FC<CardProps> = (props) => {
  const { row, tab, isMobile, customMarketplaces, shopifyDinamicLink, syncTime, setTableData, tableData, index, hasExtensionInstall, shopifyName, shopifyURLFormat, setAllTableData, setTabCounts2  } = props;

  const isSmallScreenLaptop = UseIsSmallLaptop() ;

  const allowListingsDeletion = useSelector((state:any) => state.navigation.allowListingsDeletion);

  const { bdecode } = useBasicFuncs();
  const dispatch = useDispatch();
  const showIframe = useSelector(
      (state: any) => state.navigation.showIframe
  );
  const [tooltipsPlatformActive, setTooltipsPlatformActive] = useState<Tooltip>(
      {
        ebay: false,
        etsy: false,
        poshmark: false,
        mercari: false,
        amazon: false,
        grailed: false,
        facebook: false,
        depop: false,
        kidizen: false,
        shopify: false,
        instagram: false,
        vestiaire: false,
      }
  );
  const [tooltipsActive, setTooltipsActive] = useState<Tooltip>({
    ebay: false,
    etsy: false,
    poshmark: false,
    mercari: false,
    amazon: false,
    grailed: false,
    facebook: false,
    depop: false,
    kidizen: false,
    shopify: false,
    instagram: false,
    vestiaire: false,
  });
  const token: any = Auth.get_token();
  const {is_role_allowed, hasSimple, hasPro} = useRoles();
  const [lpPlatforms, setLpPlatforms] = useState<IPlatform[]>(platforms);
  const [loadingDimensions, setLoadingDimensions] = useState(true);
  const [dimensions, setDimensions] = useState<string | null>(null);
  const [listDeleted, setListDelete] = useState(false);
  const [exstensionActive, setExstensionActive] = useState(false);
  const [showDeleteTippy, setShowDeleteTippy] = useState(false)
  const isExtensionSupportedForSingleView = useExtensionVersion('0.18.5');
  const iframeLink = useSelector(
      (state: RootState) => state.navigation.iframeLink
  );
  const linkPrefix = useAppPrefix();
  const navigate = useNavigate();
  const [activePlatforms, setActivePlatforms] = useState<any[]>([]);
  const [customMarketplacesTableRow, setCustomMarketplacesTableRow] =
      useState(customMarketplaces);
  const [crossPostingActive, setCrossPostingActive] = useState(false);
  const [sectionActive, setSectionActive] = useState(false);
  const [newTableInfo, setNewTableInfo] = useState<any>({
    newPlatformIssue: {
      text: 'active',
      modalOpen: null,
      removed: [],
      platform: [],
      changed: false,
    },
  })
  const [rowCustomMarkets, setRowCustomMarkets] = useState<any>([]);

  const toggleSection = () => {
    setSectionActive(!sectionActive);
    setCrossPostingActive(false);
    setShowDeleteTippy(false);
  };

  const unescapedRowMetadata = useMemo(() => {
    const result: any = {};
    for (const key in row?.metadata) {
      if (row.metadata.hasOwnProperty(key)) {
        result[key] = _.get(row.metadata, key);
      }
    }
    return result;
  }, [row?.metadata]);

  const handleTooltipClickPlatforms = (tooltipName: any) => {
    setTooltipsPlatformActive((prevState) => ({
      ebay: tooltipName === 'eBay' ? !prevState.ebay : false,
      etsy: tooltipName === 'Etsy' ? !prevState.etsy : false,
      poshmark: tooltipName === 'Poshmark' ? !prevState.poshmark : false,
      mercari: tooltipName === 'Mercari' ? !prevState.mercari : false,
      amazon: tooltipName === 'Amazon' ? !prevState.amazon : false,
      grailed: tooltipName === 'Grailed' ? !prevState.grailed : false,
      facebook: tooltipName === 'Facebook' ? !prevState.facebook : false,
      instagram: tooltipName === 'Instagram' ? !prevState.instagram : false,
      depop: tooltipName === 'Depop' ? !prevState.depop : false,
      kidizen: tooltipName === 'Kidizen' ? !prevState.kidizen : false,
      shopify: tooltipName === 'Shopify' ? !prevState.shopify : false,
      vestiaire: tooltipName === 'Vestiaire' ? !prevState.vestiaire : false,
    }));
  };

  const handleDelete = (id: number) => {
     if(allowListingsDeletion === false){
       setTimeout(()=>{
         setShowDeleteTippy(true);
       }, 1000)
     }else{
       axios
           .put(
               'https://api.listperfectly.com/v1/listings/update',
               {
                 filter_by: {
                   id,
                 },
                 payload: {
                   status: 'trash',
                 },
               },
               {
                 headers: {
                   Authorization: `Bearer ${token}`,
                 },
               }
           )
           .then(() => setListDelete(true))
           .catch((error) => console.log('error', error));
     }
  };
  const randomNumber = useRandomNumber();
  const region = unescapedRowMetadata[727]?.region;
  const bucket = unescapedRowMetadata[727]?.bucket;
  const key = unescapedRowMetadata[727]?.key;
  const image = row?.thumbnail;
  const placeholderSrc = image?.replace('_TN.', '_CI.');
  const placeholderNewSrc = row?.newImage?.replace('_TN.', '_CI.');
  const link = row?.newImage !== undefined ? placeholderNewSrc : `${placeholderSrc}?rnd=${randomNumber}`

  
  useLayoutEffect(() => {
    setLoadingDimensions(true);

    const getImageDimensions = (src: string | undefined) => {
      try {
        if (src) {
          const img = new Image();
          img.src = src;

          img.onload = () => {
            if (img.width >= img.height) {
              setDimensions('width');
            } else {
              setDimensions('height');
            }

            // Set loader to false when image is loaded
            setLoadingDimensions(false);
          };
        }
      } catch (e) {
        console.error(e);
        setLoadingDimensions(false); // Handle errors and set loader to false
      }
    };

    getImageDimensions(link);
  }, [row?.thumbnail, tab, link, row?.newImage]);

  useEffect(() => {
    const rowMetaData = row.metadata;

    const getCustomMarket1 = _get(row, `new${customMarketplacesTableRow[0]?.platform?.title}`)
    const getCustomMarket2 = _get(row, `new${customMarketplacesTableRow[1]?.platform?.title}`)
    const getCustomMarket3 = _get(row, `new${customMarketplacesTableRow[2]?.platform?.title}`)
    const getCustomMarket4 = _get(row, `new${customMarketplacesTableRow[3]?.platform?.title}`)
    const getCustomMarket5 = _get(row, `new${customMarketplacesTableRow[4]?.platform?.title}`)

    const objectCustomPlatforms : any = {
      ...(getCustomMarket1 !== undefined ? { '892': getCustomMarket1 ? true : false } : {}),
      ...(getCustomMarket2 !== undefined ? { '893': getCustomMarket2 ? true : false } : {}),
      ...(getCustomMarket3 !== undefined ? { '894': getCustomMarket3? true : false } : {}),
      ...(getCustomMarket4 !== undefined ? { '895': getCustomMarket4 ? true : false } : {}),
      ...(getCustomMarket5 !== undefined ? { '896': getCustomMarket5 ? true : false } : {}),
    };

    if (rowMetaData !== null && typeof rowMetaData === 'object') {
      const platformsCode = Object.keys(row.metadata);

      const tempPlatforms = (lpPlatforms || []).map((item) => {
        if (
         item.platform.code &&
         item.platform.code.every((code) => {
           const valueToCheck = row.metadata[code];
           return (
            (platformsCode.includes(code) ||
             platformsCode.includes(code.toLowerCase())) &&
            valueToCheck !== null
           );
         })
        ) {
          return {
            ...item,
            platform: {
              ...item.platform,
              isChecked: true,
            },
          };
        } else {
          return item;
        }
      });
      const tempCustomMarketplaces = (customMarketplacesTableRow || []).map(
          (item: any) => {
            if (
                item.platform.code &&
                item.platform.code.every((code: any) => {
                  const valueToCheck = row.metadata[code];
                  return (
                      (platformsCode.includes(code) ||
                          platformsCode.includes(code.toLowerCase())) &&
                      valueToCheck !== null
                  );
                })
            ) {
              return {
                ...item,
                platform: {
                  ...item.platform,
                  isChecked: true,
                },
              };
            } else {
              return item;
            }
          }
      );

      if (tempCustomMarketplaces?.length > 0) {
        const updatedCustomPlatforms = tempCustomMarketplaces?.map((platformItem: any) => {
          const platformTitle = platformItem.platform.platformId;

          if (objectCustomPlatforms?.hasOwnProperty(platformTitle)) {
            // @ts-ignore
            return {
              ...platformItem,
              platform: {
                ...platformItem.platform,
                isChecked: objectCustomPlatforms[platformTitle],
              },
            };
          }
          return platformItem;
        });
        const tempCustomMarketplacesActive = updatedCustomPlatforms
            .filter((item: any) => item.platform.isChecked && item.platform.title)

            .map((item: any) => {
              return {
                title: item.platform.title,
                code: item.platform?.code[0],
                color: item?.color,
                platformId: item.platform.platformId,
              };
            });
        const tempPlatformsActive = tempPlatforms.filter((item : any) => item.platform.isChecked).map((item : any) => item.platform.title);
        const uniquePlatforms = [...new Set([...tempPlatformsActive])];
        setActivePlatforms(uniquePlatforms);
        setCustomMarketplacesTableRow(updatedCustomPlatforms);
        setRowCustomMarkets(tempCustomMarketplacesActive);
      }
    }

  }, [showIframe, tab]);


  const handleTooltipClick = (tooltipName: keyof typeof tooltipsActive) => {
    setTooltipsActive((prevState) => ({
      ebay: tooltipName === 'ebay' ? !prevState.ebay : false,
      etsy: tooltipName === 'etsy' ? !prevState.etsy : false,
      poshmark: tooltipName === 'poshmark' ? !prevState.poshmark : false,
      mercari: tooltipName === 'mercari' ? !prevState.mercari : false,
      amazon: tooltipName === 'amazon' ? !prevState.amazon : false,
      grailed: tooltipName === 'grailed' ? !prevState.grailed : false,
      facebook: tooltipName === 'facebook' ? !prevState.facebook : false,
      instagram: tooltipName === 'instagram' ? !prevState.instagram : false,
      depop: tooltipName === 'depop' ? !prevState.depop : false,
      kidizen: tooltipName === 'kidizen' ? !prevState.kidizen : false,
      shopify: tooltipName === 'shopify' ? !prevState.shopify : false,
      vestiaire: tooltipName === 'vestiaire' ? !prevState.vestiaire : false,
    }));
  };

  const unescapedRowMetadataIssues = useMemo(() => {
    const result: any = {};
    for (const key in row?.issues) {
      if (row?.issues?.hasOwnProperty(key)) {
        result[key] = _.get(row.issues, key);
      }
    }
    return result;
  }, [row?.issues]);

  const showModalChanger = async (
      name: string,
      value: null | true | string,
      platformName: string,
      keyNumber?: number
  ) => {
    const updatedItems = [...tableData];
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
      changed: true,
    };

    if (value === 'cancel') {
      const wordsArray = [...newTableInfo?.newPlatformIssue?.platform];
      const wordToRemove = platformName;
      const removeWordFromArray = (
          array: string[],
          wordToRemove: string
      ): string[] => {
        return array.filter((item) => item !== wordToRemove);
      };

      let newArray: string[] = removeWordFromArray(wordsArray, wordToRemove);
      setNewTableInfo({
        ...newTableInfo,
        ['newPlatformIssue']: {
          text: platformName,
          platform: newArray,
          modalOpen: false,
          removed: newTableInfo?.newPlatformIssue?.removed.filter(
              (item:any) => item !== platformName
          ),
          changed: true,
        },
      });
    } else if (value) {
      setNewTableInfo({
        ...newTableInfo,
        ['newPlatformIssue']: {
          text: platformName,
          platform: [...newTableInfo?.newPlatformIssue?.platform, platformName],
          modalOpen: true,
          removed: [...newTableInfo?.newPlatformIssue?.removed],
          changed: true,
        },
      });
    } else {
      const filteredObject: { [key: string]: unknown } = Object.entries(
          row?.issues || {}
      )
          .filter(([key]) => parseFloat(key) !== keyNumber)
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {} as { [key: string]: unknown });
      const response = await instanceAxios.put('/listings/issues/update', {
        filter_by: {id: row?.id},
        payload: {
          metadata: filteredObject,
        },
      });
      if (response) {
        setNewTableInfo({
          ...newTableInfo,
          ['newPlatformIssue']: {
            text: platformName,
            platform: [
              ...newTableInfo?.newPlatformIssue?.platform,
              platformName,
            ],
            removed: [...newTableInfo?.newPlatformIssue?.removed, platformName],
            modalOpen: false,
            changed: true,
          },
        });
      }

      const newObject = {
        ...row,
        issues: filteredObject,
      };

      let updatedTableData = tableData.map((obj:any) => {
        if (obj.id == row.id) {
          return {
            ...obj,
            ...newObject,
          };
        }
        return obj;
      });
      const updateListingPropertiesInAllTabs = (getIdOfThatListing: any, newObject: any, shouldDelete: boolean = false) => {
        setAllTableData((prevData: any) => {
          // Create a copy of the previous state
          const newData = { ...prevData };

          // Iterate over all tabs and update or delete the listing
          Object.keys(newData).forEach((tabName) => {
            newData[tabName].data = shouldDelete
                ? newData[tabName].data.filter((listing: any) => listing.id !== getIdOfThatListing)
                : newData[tabName].data.map((listing: any) =>
                    listing.id === getIdOfThatListing ? { ...listing, ...newObject } : listing
                );
          });

          return newData;
        });
      };
      if(!(filteredObject && Object.keys(filteredObject).length > 0)) {
        setTabCounts2((prevCounts:any) => ({
          ...prevCounts,
          issues: {
            ...prevCounts.issues,
            count: Math.max(prevCounts.issues.count - 1, 0), // Ensure the count doesn't go below 0
          },
        }));
        updateListingPropertiesInAllTabs(row.id, newObject, true);
        updatedTableData = tableData.filter((obj: any) => obj.id !== row.id);
      }
      else{
        updateListingPropertiesInAllTabs(row.id, newObject)
      }

      // Update the state with the modified tableData
      setTableData(updatedTableData);
    }
  };



  const shopifyLinkLocal =
    shopifyName && shopifyURLFormat === 2
    ? `https://admin.shopify.com/store/${shopifyName}/products/${unescapedRowMetadata[855]}`
    : shopifyName && shopifyURLFormat === 1 ?
    `https://${shopifyName}.myshopify.com/admin/products/${unescapedRowMetadata[855]}`
    : `https://.myshopify.com/admin/products/${unescapedRowMetadata[855]}`;

  const hrefValueeBay = _get(row, '618') || unescapedRowMetadata[618];
  const hrefValueEtsy = _get(row, '619') || unescapedRowMetadata[619];
  const hrefValuePoshmark = _get(row, '620') || unescapedRowMetadata[620];
  const hrefValueMercary = _get(row, '621') || unescapedRowMetadata[621];
  const hrefValueAmazon = _get(row, '836') || unescapedRowMetadata[836];
  const hrefValueGrailed = _get(row, '839') || unescapedRowMetadata[839];
  const hrefValueFacebook = _get(row, '840') || unescapedRowMetadata[840];
  const hrefValueDepop = _get(row, '841') || unescapedRowMetadata[841];
  const hrefValueKidizen = _get(row, '853') || unescapedRowMetadata[853];
  const hrefValueShpoify = _get(row, '855') || unescapedRowMetadata[855];
  const hrefValueInstagram = _get(row, '856') || unescapedRowMetadata[856];
  const hrefValueVestiaire = _get(row, '885') || unescapedRowMetadata[885];


  // @ts-ignore
  return !listDeleted ? (
      <div
          id="mobileCard"
          className={styles.cardContainer}
          data-lp_custom_chrome_extension_name={row?.id}
      >
        <div className={styles.imageContainer}>
          {(row?.thumbnail || row?.newImage) && row?.newImage !== null ? (
              loadingDimensions ? (
                  <MoonLoader color={'#1e3166'} loading={true} size={158} />
              ) : (
                  <div>
                    {(row?.newSOLD === 'SOLD' ||
                        unescapedRowMetadata[539.1] === 'SOLD') && (
                        <div className={styles.soldIcon}>Sold</div>
                    )}
                    <img
                        src={link}
                        alt=""
                        style={{
                          width: dimensions === 'width' ? '100%' : 'auto',
                          height: dimensions === 'height' ? (isSmallScreenLaptop  ? '240px' : '300px') : 'auto',
                        }}
                    />
                  </div>
              )
          ) : (
              <div>
                <FaImage/>
              </div>
          )}
        </div>
        <div className={styles.bottomContainer}>
          {bdecode(unescapedRowMetadata[133]) && (
              <div className={styles.title}>
                <a
                    style={{cursor: 'pointer', color: '#191D88'}}
                    className={styles.catalogNewTitle}
                    onClick={(e:any) => {
                      e.preventDefault();
                      dispatch(setEditAddModalShow(true));
                      dispatch(setEditAddModalId(row.id));
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={`/listings/edit/${row.id}`}
                >
                  {/* {newTableInfo.newTitle.changed
                    ? row!.newTitle
                    : bdecode(unescapedRowMetadata[133])} */}
                  {row?.newTitle !== undefined ? bdecode(row!.newTitle) : bdecode(unescapedRowMetadata[133])}
                </a>
              </div>
          )}
          {
            <div className={styles.platformsWrapper}>
              {(row?.newSOLD === 'SOLD' ||
                  unescapedRowMetadata[539.1] === 'SOLD') && row?.newSOLD !== null && (
                  <PlatformBlockItem
                      platformName={'SOLD'}
                      sync={row?.sync}
                      href=""
                      platformIcon={SoldTagSvg}
                      isLink={false}
                  />
              )}

              {(row.newListing_party === 'Listing Party' ||
                  unescapedRowMetadata[539.16] === 'Listing Party') && row?.newListing_party !== null && (
                  <PlatformBlockItem
                      platformName={'listing_party'}
                      sync={row?.sync}
                      href={`https://listingparty.com/listings?id=${row.id}`}
                      platformIcon={listingPartyLogo}
                      isLink={true}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}

              {(row?.neweBay === 'eBay' ||
                  unescapedRowMetadata[539.2] === 'eBay') && row?.neweBay  !== null && (
                  <PlatformBlockItem
                      platformName={'eBay'}
                      sync={row?.sync}
                      href={`https://ebay.com/itm/${hrefValueeBay}`}
					  platformIcon={EbayLogo}
                      syncIssueClick={() => showModalChanger('newPlatformIssue', true, 'ebay')}
                      removed={newTableInfo.newPlatformIssue.removed.includes('ebay')}
                      issueExist={unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('ebay') ? null : unescapedRowMetadataIssues[539.2] || null}
                      isLink={
                         // @ts-ignore
                          (row?.['618'] !== undefined && row?.['618'] !== '') ||
                          // @ts-ignore
                          (!row?.['618'] && row?.['618'] !== '' && unescapedRowMetadata[618] && unescapedRowMetadata[539.2] === 'eBay')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.ebay}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}
              {(row?.newEtsy === 'Etsy' ||
                  unescapedRowMetadata[539.3] === 'Etsy') && row?.newEtsy  !== null && (
                  <PlatformBlockItem
                      platformName={'Etsy'}
                      sync={row?.sync}
                      href={`https://www.etsy.com/listing/${hrefValueEtsy}`}
                      platformIcon={etsyLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('etsy')}
                      issueExist={unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('etsy') ? null : unescapedRowMetadataIssues[539.3] || null}
                      syncIssueClick={() => showModalChanger('newPlatformIssue', true, 'etsy')}
                      isLink={
                        // @ts-ignore
                          (row?.['619'] !== undefined && row?.['619'] !== '') ||
                          // @ts-ignore
                          (!row?.['619'] && row?.['619'] !== '' && unescapedRowMetadata[619] && unescapedRowMetadata[539.3] === 'Etsy')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.etsy}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}
              {(row?.newPoshmark === 'Poshmark' ||
                  unescapedRowMetadata[539.4] === 'Poshmark') && row?.newPoshmark  !== null && (
                  <PlatformBlockItem
                      platformName={'Poshmark'}
                      sync={row?.sync}
                      href={`https://poshmark.com/listing/${hrefValuePoshmark}`}
					  platformIcon={PoshmarkLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('poshmark') }
                      issueExist={unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('poshmark') ? null : unescapedRowMetadataIssues[539.4] || null}
                      syncIssueClick={() => showModalChanger('newPlatformIssue', true, 'poshmark')}
                      isLink={
                        // @ts-ignore
                          (row?.['620'] !== undefined && row?.['620'] !== '') ||
                          // @ts-ignore
                          (!row?.['620'] && row?.['620'] !== '' && unescapedRowMetadata[620] && unescapedRowMetadata[539.4] === 'Poshmark')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.poshmark}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}
              {(row?.newMercari === 'Mercari' ||
                  unescapedRowMetadata[539.5] === 'Mercari') && row?.newMercari  !== null && (
                  <PlatformBlockItem
                      platformName={'Mercari'}
                      sync={row?.sync}
                      href={`https://www.mercari.com/us/item/${hrefValueMercary}`}
					  platformIcon={MercaryLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('mercari') }
                      issueExist={unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('mercari') ? null : unescapedRowMetadataIssues[539.5] || null}
                      syncIssueClick={() =>  showModalChanger('newPlatformIssue', true, 'mercari')}
                      isLink={
                        // @ts-ignore
                          (row?.['621'] !== undefined && row?.['621'] !== '') ||
                          // @ts-ignore
                          (!row?.['621'] && row?.['621'] !== '' && unescapedRowMetadata[621] && unescapedRowMetadata[539.5] === 'Mercari')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.mercari}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}

              {/* {(row?.newTradesy === 'Tradesy' ||
                    unescapedRowMetadata[539.6] === 'Tradesy') && (
                    <PlatformBlockİtem
                      platformName={'Tradesy'}
                      sync={row?.sync}
                      href={`https://www.vestiairecollective.com/?utm_source=tradesy&utm_medium=referral&utm_campaign=US_EN_Tradesy_Tradesy_Buyer_All_All_Web`}
                      platformIcon={tradesyLogo}
                      isLink={
                        unescapedRowMetadata[833] &&
                        unescapedRowMetadata[539.6] === 'Tradesy'
                      }
                      fromPlatform={true}

                    />
                  )} */}
              {(row?.newAmazon === 'Amazon' ||
                  unescapedRowMetadata[539.7] === 'Amazon') && row?.newAmazon  !== null && (
                  <PlatformBlockItem
                      platformName={'Amazon'}
                      sync={row?.sync}
                      href={`https://www.amazon.com/${hrefValueAmazon}`}
					  platformIcon={AmazonLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('amazon')}
                      issueExist={unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('amazon') ? null : unescapedRowMetadataIssues[539.7] || null}
                      syncIssueClick={() =>  showModalChanger('newPlatformIssue', true, 'amazon')}
                      isLink={
                        // @ts-ignore
                          (row?.['836'] !== undefined && row?.['836'] !== '') ||
                          // @ts-ignore
                          (!row?.['836'] && row?.['836'] !== '' && unescapedRowMetadata[836] && unescapedRowMetadata[539.7] === 'Amazon')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.amazon}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}

              {(row?.newGrailed === 'Grailed' ||
                  unescapedRowMetadata[539.8] === 'Grailed') && row?.newGrailed  !== null  && (
                  <PlatformBlockItem
                      platformName={'Grailed'}
                      sync={row?.sync}
                      href={`https://www.grailed.com/listings/${hrefValueGrailed}`}
                      platformIcon={grailedLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('grailed')}
                      issueExist={unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('grailed') ? null : unescapedRowMetadataIssues[539.8] || null}
                      syncIssueClick={() =>   showModalChanger('newPlatformIssue', true, 'grailed')}
                      isLink={
                        // @ts-ignore
                          (row?.['839'] !== undefined && row?.['839'] !== '') ||
                          // @ts-ignore
                          (!row?.['839'] && row?.['839'] !== '' && unescapedRowMetadata[839] && unescapedRowMetadata[539.8] === 'Grailed')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.grailed}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}
              {(row?.newFacebook === 'Facebook' ||
                  unescapedRowMetadata[539.9] === 'Facebook') && row?.newFacebook  !== null && (
                  <PlatformBlockItem
                      platformName={'Facebook'}
                      sync={row?.sync}
                      href={`https://www.facebook.com/marketplace/category/${hrefValueFacebook}/`}
                      platformIcon={facebookLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('facebook')}
                      issueExist={unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('facebook') ? null : unescapedRowMetadataIssues[539.9] || null}
                      syncIssueClick={() => showModalChanger(
                       'newPlatformIssue',
                       true,
                       'facebook'
                      )}
                      isLink={
                        // @ts-ignore
                          (row?.['840'] !== undefined && row?.['840'] !== '') ||
                          // @ts-ignore
                          (!row?.['840'] && row?.['840'] !== '' && unescapedRowMetadata[840] && unescapedRowMetadata[539.9] === 'Facebook')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.facebook}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}

              {(row?.newDepop === 'Depop' ||
                  unescapedRowMetadata[539.11] === 'Depop') && row?.newDepop  !== null && (
                  <PlatformBlockItem
                      platformName={'Depop'}
                      sync={row?.sync}
                      href={`https://www.depop.com/products/${hrefValueDepop}`}
                      platformIcon={depopLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('depop')}
                      issueExist={unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('depop') ? null : unescapedRowMetadataIssues[539.11] || null}
                      syncIssueClick={() => showModalChanger('newPlatformIssue', true, 'depop')}
                      isLink={
                        // @ts-ignore
                          (row?.['841'] !== undefined && row?.['841'] !== '') ||
                          // @ts-ignore
                          (!row?.['841'] && row?.['841'] !== '' && unescapedRowMetadata[841] && unescapedRowMetadata[539.11] === 'Depop')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.depop}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}

              {(row?.newKidizen === 'Kidizen' ||
                  unescapedRowMetadata[539.12] === 'Kidizen') && row?.newKidizen !== null && (
                  <PlatformBlockItem
                      platformName={'Kidizen'}
                      sync={row?.sync}
                      href={`https://www.kidizen.com/items/${hrefValueKidizen}`}
                      platformIcon={kidizenLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('kidizen')}
                      issueExist={unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('kidizen') ? null : unescapedRowMetadataIssues[539.12] || null}
                      syncIssueClick={() => showModalChanger('newPlatformIssue', true, 'kidizen')}
                      isLink={
                        // @ts-ignore
                          (row?.['853'] !== undefined && row?.['853'] !== '') ||
                          // @ts-ignore
                          (!row?.['853'] && row?.['853'] !== '' && unescapedRowMetadata[853] && unescapedRowMetadata[539.12] === 'Kidizen')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.kidizen}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}
              {(row?.newShopify === 'Shopify' ||
                  unescapedRowMetadata[539.13] === 'Shopify') && row?.newShopify !== null && (
                  <PlatformBlockItem
                      platformName={'Shopify'}
                      sync={row?.sync}
                      href={shopifyLinkLocal}
                      platformIcon={shopifyLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('shopify')}
                      issueExist={unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('shopify') ? null : unescapedRowMetadataIssues[539.13] || null}
                      syncIssueClick={() => showModalChanger('newPlatformIssue', true, 'shopify')}
                      isLink={
                        // @ts-ignore
                          (row?.['855'] !== undefined && row?.['855'] !== '') ||
                          // @ts-ignore
                          (!row?.['855'] && row?.['855'] !== '' && unescapedRowMetadata[855] && unescapedRowMetadata[539.13] === 'Shopify')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.shopify}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}

              {(row?.newInstagram === 'Instagram' ||
                  unescapedRowMetadata[539.14] === 'Instagram') && row?.newInstagram !== null  && (
                  <PlatformBlockItem
                      platformName={'Instagram'}
                      sync={row?.sync}
                      href={`https://www.instagram.com/p/${hrefValueInstagram}/`}
                      platformIcon={instagramLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('instagram')}
                      issueExist={unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('instagram') ? null : unescapedRowMetadataIssues[539.14] || null}
                      syncIssueClick={() => showModalChanger(
                       'newPlatformIssue',
                       true,
                       'instagram'
                      )}
                      isLink={
                        // @ts-ignore
                          (row?.['856'] !== undefined && row?.['856'] !== '') ||
                          // @ts-ignore
                          (!row?.['856'] && row?.['856'] !== '' && unescapedRowMetadata[856] && unescapedRowMetadata[539.14] === 'Instagram')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.instagram}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}

              {(row?.newVestiaire === 'Vestiaire' ||
                  unescapedRowMetadata[539.17] === 'Vestiaire')  && row?.newVestiaire !== null  && (
                  <PlatformBlockItem
                      platformName={'Vestiaire'}
                      sync={row?.sync}
                      href={`https://vestiairecollective.com/'${hrefValueVestiaire}.shtml`}
                      platformIcon={vestiaireLogo}
                      removed={newTableInfo.newPlatformIssue.removed.includes('vestiaire')}
                      issueExist={unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number'}
                      syncIssue={newTableInfo.newPlatformIssue.removed.includes('vestiaire') ? null : unescapedRowMetadataIssues[539.17] || null}
                      syncIssueClick={() => showModalChanger(
                       'newPlatformIssue',
                       true,
                       'vestiaire'
                      )}
                      isLink={
                        // @ts-ignore
                          (row?.['885'] !== undefined && row?.['885'] !== '') ||
                          // @ts-ignore
                          (!row?.['885'] && row?.['885'] !== '' && unescapedRowMetadata[885] && unescapedRowMetadata[539.17] === 'Vestiaire')
                      }
                      fromPlatform={true}
                      visible={tooltipsPlatformActive.vestiaire}
                      handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                      tooltipsPlatformActive={tooltipsPlatformActive}
                      IsSold={unescapedRowMetadata[539.1]}
                  />
              )}
              {rowCustomMarkets?.map((item: any) => (
               <div
                key={item.title}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
               >
                 {unescapedRowMetadata[item?.platformId]?.length > 0 ? (
                  <a
                   href={unescapedRowMetadata[item.platformId]}
                   target="_blank"
                   style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     color: '#2185D0',
                     fontSize: isSmallScreenLaptop ? 12 : 15,
                     flexDirection: 'column',
                     margin:isSmallScreenLaptop ? '2px 0' : '3px 0'
                   }}
                   className={styles.customMarketWithPlatformId}

                  >
                    <div>
                      <div
                       style={{
                         width: isSmallScreenLaptop ? 32 : 40,
                         height: isSmallScreenLaptop ? 32 : 40,
                         borderRadius: isSmallScreenLaptop ? 3 : 4,
                         padding: isSmallScreenLaptop ? 4 : 5,
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         border: `1px solid ${
                          item?.color ? item.color : '#000'
                         }`,
                       }}
                       className={styles.customMarketIcon}

                      >
                        <PlatformIconShop
                         width={isSmallScreenLaptop ? "16px" : "20px"}
                         height={isSmallScreenLaptop ? "16px" : "20px"}
                         fill={item?.color ? item.color : '#000'}
                        />
                      </div>
                    </div>
                    <div
                     className={styles.customMarketName}
                    >{item.title}</div>
                  </a>
                 ) : (
                  <div
                   style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin:isSmallScreenLaptop ? '2px 0' : '3px 0'}}
                   className={styles.customMarket}

                  >
                    <div>
                      <div
                       style={{
                         width: isSmallScreenLaptop ? 32 : 40,
                         height:  isSmallScreenLaptop ? 32 : 40,
                         borderRadius: isSmallScreenLaptop ? 3 : 4,
                         padding: isSmallScreenLaptop ? 4 : 5,
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center',
                         border: `1px solid ${
                          item?.color ? item.color : '#000'
                         }`,
                       }}
                       className={styles.customMarketIcon}

                      >
                        <PlatformIconShop
                         width={isSmallScreenLaptop ? "16px" : "20px"}
                         height={isSmallScreenLaptop ? "16px" : "20px"}
                         fill={item?.color ? item.color : '#000'}
                        />
                      </div>
                    </div>
                    <div
                     style={{
                       fontSize: isSmallScreenLaptop ? 12 : 15,
                       color: '#393838',
                     }}
                     className={styles.customMarketName}

                    >
                      {item.title}
                    </div>
                  </div>
                 )}
               </div>
              ))}
            </div>
          }
          {
            <div className={styles.platformsIssues}>
              {unescapedRowMetadataIssues[539.2] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.2] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.2] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.2] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.2] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['618'] && row?.['618'] !== '' && unescapedRowMetadata[618] && unescapedRowMetadata[539.2] === 'eBay')&& is_role_allowed && hasPro) ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'ebay' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                          showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                             'ebay'
                          )
                  }>
                    <div className="issueModal">
                      <div
                       className="closeIcon"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }
                      >
                        &#10799;
                      </div>
                      <div>
                        {unescapedRowMetadataIssues[539.2] ===
                         'status_not_selected_active_listing_found' && (
                          <div
                           style={{
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             flexWrap: 'wrap',
                             gap: '5px',
                           }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                              Platform not selected for eBay, active listing
                                found on{' '}
                              <a
                               target="_blank"
                               style={{color: 'blue', display: 'inline'}}
                               href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
                              >
                                  eBay
                                </a>
                              </span>
                          </div>
                         )}
                        {unescapedRowMetadataIssues[539.2] ===
                         'status_selected_no_active_listing_found' && (
                          <div
                           style={{
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             flexWrap: 'wrap',
                             gap: '5px',
                           }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                              Platform selected eBay, no active listing found
                                on{' '}
                              <a
                               target="_blank"
                               style={{color: 'blue', display: 'inline'}}
                               href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
                              >
                                  eBay
                                </a>
                              </span>
                          </div>
                         )}
                        { // @ts-ignore
                            (!(!row?.['618'] && row?.['618'] !== '' && unescapedRowMetadata[618] && unescapedRowMetadata[539.2] === 'eBay')&& is_role_allowed && hasPro && unescapedRowMetadataIssues[539.2] === 'status_selected_empty_item_number') &&
                            <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                  gap: '5px',
                                }}
                            >
                              {' '}
                              <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                            </div>
                        }
                        {unescapedRowMetadataIssues[539.2] ===
                            'status_selected_out_of_stock' && (
                                <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                      gap: '5px',
                                    }}
                                >
                                  {' '}
                                  <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                    Platform selected as eBay, listed as "out of
                                stock" on{' '}
                                    <a
                                        target="_blank"
                                        style={{color: 'blue', display: 'inline'}}
                                        href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
                                    >
                                  eBay
                                </a>
                              </span>
                          </div>
                         )}
                        {unescapedRowMetadataIssues[539.2] ===
                         'sold_status_active_listing_found' && (
                          <div
                           style={{
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             flexWrap: 'wrap',
                             gap: '5px',
                           }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                              Sold status, active listing found on{' '}
                              <a
                               target="_blank"
                               style={{color: 'blue', display: 'inline'}}
                               href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
                              >
                                  eBay
                                </a>
                              </span>
                          </div>
                         )}

                        {unescapedRowMetadataIssues[539.2] ===
                         'status_selected_no_listing_found' && (
                          <div
                           style={{
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             flexWrap: 'wrap',
                             gap: '5px',
                           }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                              Unable to check status OR listing not found{' '}
                              <a
                               target="_blank"
                               style={{color: 'blue', display: 'inline'}}
                               href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
                              >
                                  eBay
                                </a>
                              </span>
                          </div>
                         )}
                      </div>
                      <a
                       target="_blank"
                       href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[618]}&catalog_listing_id=${row.id}`}
                       style={{
                         padding: '5px',
                         color: 'blue',
                         textAlign: 'center',
                         marginBottom: '5px',
                         display: 'block'
                       }}
                      >
                        Report incorrect status
                      </a>
                      <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20,margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                       showModalChanger(
                        'newPlatformIssue',
                        null,
                        'ebay',
                        539.2
                       )}>Remove</Button>
                    </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.3] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.3] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.3] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.3] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.3] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['619'] && row?.['619'] !== '' && unescapedRowMetadata[619] && unescapedRowMetadata[539.3] === 'Etsy') && hasPro && is_role_allowed  && unescapedRowMetadataIssues[539.3] === 'status_selected_empty_item_number')  ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'etsy' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'etsy'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.3] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Etsy, active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
                            >
                                  Etsy
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.3] ===
                       'status_selected_no_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected Etsy, no active listing found
                                on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
                            >
                                  Etsy
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.3] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Etsy, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
                            >
                                  Etsy
                                </a>
                              </span>
                        </div>
                       )}
                      { // @ts-ignore
                          (!(!row?.['619'] && row?.['619'] !== '' && unescapedRowMetadata[619] && unescapedRowMetadata[539.3] === 'Etsy') && hasPro && is_role_allowed) &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.3] ===
                          'sold_status_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
                            >
                                  Etsy
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.3] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
                            >
                                  Etsy
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[619]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>
                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'etsy',
                      539.3
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}

              {unescapedRowMetadataIssues[539.4] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.4] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.4] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.4] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.4] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['620'] && row?.['620'] !== '' && unescapedRowMetadata[620] && unescapedRowMetadata[539.4] === 'Poshmark') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.4] === 'status_selected_empty_item_number') ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'poshmark' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'poshmark'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.4] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Poshmark, active
                                listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
                            >
                                  Poshmark
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.4] ===
                       'status_selected_no_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected Poshmark, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
                            >
                                  Poshmark
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['620'] && row?.['620'] !== '' && unescapedRowMetadata[620] && unescapedRowMetadata[539.4] === 'Poshmark') && hasPro && is_role_allowed) &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.4] ===
                          'status_selected_out_of_stock' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected as Poshmark, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
                            >
                                  Poshmark
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.4] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
                            >
                                  Poshmark
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.4] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
                            >
                                  Poshmark
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[620]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>
                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'poshmark',
                      539.4
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.5] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.5] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.5] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.5] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.5] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['621'] && row?.['621'] !== '' && unescapedRowMetadata[621] && unescapedRowMetadata[539.5] === 'Mercari') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.5] === 'status_selected_empty_item_number') ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'mercari' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'mercari'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.5] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Mercari, active
                                listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
                            >
                                  Mercari
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['621'] && row?.['621'] !== '' && unescapedRowMetadata[621] && unescapedRowMetadata[539.5] === 'Mercari') && hasPro && is_role_allowed) &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.5] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected Mercari, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
                            >
                                  Mercari
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.5] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Mercari, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
                            >
                                  Mercari
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.5] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
                            >
                                  Mercari
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.5] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                           cursor: 'pointer'
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
                            >
                                  Mercari
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[621]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'mercari',
                      539.5
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.7] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.7] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.7] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.7] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.7] ===
              'status_selected_no_listing_found' ||
                 // @ts-ignore
                  !((!row?.['836'] && row?.['836'] !== '' && unescapedRowMetadata[836] && unescapedRowMetadata[539.7] === 'Amazon') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.7] === 'status_selected_empty_item_number')   ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'amazon' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'amazon'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.7] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Amazon, active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
                            >
                                  Amazon
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          !((!row?.['836'] && row?.['836'] !== '' && unescapedRowMetadata[836] && unescapedRowMetadata[539.7] === 'Amazon') && is_role_allowed && hasPro) &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.7] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected Amazon, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
                            >
                                  Amazon
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.7] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Amazon, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
                            >
                                  Amazon
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.7] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}} >
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
                            >
                                  Amazon
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.7] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
                            >
                                  Amazon
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[636]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'amazon',
                      539.7
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.8] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.8] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.8] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.8] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.8] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['839'] && row?.['839'] !== '' && unescapedRowMetadata[839] && unescapedRowMetadata[539.8] === 'Grailed') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.8] === 'status_selected_empty_item_number')? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'grailed' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'grailed'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.8] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Grailed, active
                                listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
                            >
                                  Grailed
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['839'] && row?.['839'] !== '' && unescapedRowMetadata[839] && unescapedRowMetadata[539.8] === 'Grailed') && is_role_allowed && hasPro) &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.8] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected Grailed, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
                            >
                                  Grailed
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.8] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Grailed, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
                            >
                                  Grailed
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.8] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
                            >
                                  Grailed
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.8] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
                            >
                                  Grailed
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[839]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'grailed',
                      539.8
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.9] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.9] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.9] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.9] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.9] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['840'] && row?.['840'] !== '' && unescapedRowMetadata[840] && unescapedRowMetadata[539.9] === 'Facebook') && is_role_allowed && hasPro)? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'facebook' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'facebook'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.9] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Facebook, active
                                listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
                            >
                                  Facebook
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['840'] && row?.['840'] !== '' && unescapedRowMetadata[840] && unescapedRowMetadata[539.9] === 'Facebook') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.9] === 'status_selected_empty_item_number') &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.9] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected Facebook, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
                            >
                                  Facebook
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.9] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Facebook, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
                            >
                                  Facebook
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.9] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
                            >
                                  Facebook
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.9] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}`}
                            >
                                  Facebook
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[840]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'facebook',
                      539.9
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.11] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.11] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.11] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.11] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.11] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['841'] && row?.['841'] !== '' && unescapedRowMetadata[841] && unescapedRowMetadata[539.11] === 'Depop') && hasPro && is_role_allowed)   ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'depop' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'depop'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.11] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Depop, active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
                            >
                                  Depop
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['841'] && row?.['841'] !== '' && unescapedRowMetadata[841] && unescapedRowMetadata[539.11] === 'Depop') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.11] === 'status_selected_empty_item_number') &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.11] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected Depop, no active listing found
                                on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
                            >
                                  Depop
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.11] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Depop, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
                            >
                                  Depop
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.11] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
                            >
                                  Depop
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.11] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
                            >
                                  Depop
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[841]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'depop',
                      539.11
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}

              {unescapedRowMetadataIssues[539.12] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.12] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.12] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.12] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.12] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['853'] && row?.['853'] !== '' && unescapedRowMetadata[853] && unescapedRowMetadata[539.12] === 'Kidizen') && hasPro && is_role_allowed) ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'kidizen' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'kidizen'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.12] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Kidizen, active
                                listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
                            >
                                  Kidizen
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['853'] && row?.['853'] !== '' && unescapedRowMetadata[853] && unescapedRowMetadata[539.12] === 'Kidizen') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.12] === 'status_selected_empty_item_number') &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.12] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected Kidizen, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
                            >
                                  Kidizen
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.12] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Kidizen, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
                            >
                                  Kidizen
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.12] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
                            >
                                  Kidizen
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.12] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
                            >
                                  Kidizen
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[853]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'kidizen',
                      539.12
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}

              {unescapedRowMetadataIssues[539.13] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.13] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.13] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.13] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.13] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['855'] && row?.['855'] !== '' && unescapedRowMetadata[855] && unescapedRowMetadata[539.13] === 'Shopify') && hasPro && is_role_allowed)   ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'shopify' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'shopify'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <p>
                      {unescapedRowMetadataIssues[539.13] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                              <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                Platform not selected for Shopify, active
                                listing found on
                                <a
                                 target="_blank"
                                 style={{color: 'blue', display: 'inline'}}
                                 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
                                >
                                  Shopify
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['855'] && row?.['855'] !== '' && unescapedRowMetadata[855] && unescapedRowMetadata[539.13] === 'Shopify') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.13] === 'status_selected_empty_item_number') &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.13] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                              <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                Platform selected Shopify, no active listing
                                found on
                                <a
                                 target="_blank"
                                 style={{color: 'blue', display: 'inline'}}
                                 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
                                >
                                  Shopify
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.13] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                              <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                Platform selected as Shopify, listed as "out of
                                stock" on
                                <a
                                 target="_blank"
                                 style={{color: 'blue', display: 'inline'}}
                                 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
                                >
                                  Shopify
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.13] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                              <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                Sold status, active listing found on
                                <a
                                 target="_blank"
                                 style={{color: 'blue', display: 'inline'}}
                                 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
                                >
                                  Shopify
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.13] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                              <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                Unable to check status OR listing not found{' '}
                                <a
                                 target="_blank"
                                 style={{color: 'blue', display: 'inline'}}
                                 href={`https://admin.shopify.com/store/listperfectly/products/${unescapedRowMetadata[855]}`}
                                >
                                  Shopify
                                </a>
                              </span>
                        </div>
                       )}
                    </p>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[855]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'shopify',
                      539.13
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.14] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.14] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.14] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.14] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.14] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['856'] && row?.['856'] !== '' && unescapedRowMetadata[856] && unescapedRowMetadata[539.14] === 'Instagram') && hasPro && is_role_allowed)  ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'instagram' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'instagram'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.14] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Instagram, active
                                listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
                            >
                                  Instagram
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                        (!(!row?.['856'] && row?.['856'] !== '' && unescapedRowMetadata[856] && unescapedRowMetadata[539.14] === 'Instagram') && hasPro && is_role_allowed && unescapedRowMetadataIssues[539.14] === 'status_selected_empty_item_number') &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.14] ===
                       'status_selected_no_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected Instagram, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
                            >
                                  Instagram
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.14] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Instagram, listed as "out
                                of stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
                            >
                                  Instagram
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.14] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
                            >
                                  Instagram
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.14] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}`}
                            >
                                  Instagram
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[856]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>

                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'instagram',
                      539.14
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
              {unescapedRowMetadataIssues[539.17] ===
              'status_not_selected_active_listing_found' ||
              unescapedRowMetadataIssues[539.17] ===
              'status_selected_no_active_listing_found' ||
              unescapedRowMetadataIssues[539.17] ===
              'status_selected_out_of_stock' ||
              unescapedRowMetadataIssues[539.17] ===
              'sold_status_active_listing_found' ||
              unescapedRowMetadataIssues[539.17] ===
              'status_selected_no_listing_found' ||
              // @ts-ignore
              (!(!row?.['885'] && row?.['885'] !== '' && unescapedRowMetadata[885] && unescapedRowMetadata[539.17] === 'Vestiaire') && is_role_allowed && hasPro) ? (
               <div>
                 {newTableInfo.newPlatformIssue.modalOpen &&
                 newTableInfo.newPlatformIssue.text === 'vestiaire' ? (
                  <div className="issueModalOverlay"
                       onClick={() =>
                        showModalChanger(
                         'newPlatformIssue',
                         'cancel',
                         'ebay'
                        )
                       }>
                  <div className="issueModal">
                    <div
                     className="closeIcon"
                     onClick={() =>
                      showModalChanger(
                       'newPlatformIssue',
                       'cancel',
                       'vestiaire'
                      )
                     }
                    >
                      &#10799;
                    </div>
                    <div>
                      {unescapedRowMetadataIssues[539.17] ===
                       'status_not_selected_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform not selected for Vestiare, active
                                listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
                            >
                                  Vestiare
                                </a>
                              </span>
                        </div>
                       )}
                      {
                        // @ts-ignore
                          (!(!row?.['885'] && row?.['885'] !== '' && unescapedRowMetadata[885] && unescapedRowMetadata[539.17] === 'Vestiaire') && is_role_allowed && hasPro && unescapedRowMetadataIssues[539.17] === 'status_selected_empty_item_number') &&
                          <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                gap: '5px',
                              }}
                          >
                            {' '}
                            <span style={{textAlign: 'center'}}>
                                A platform status is selected, but no platform ID is saved to the corresponding item number field in "inventory and sales"
                              </span>
                          </div>
                      }
                      {unescapedRowMetadataIssues[539.17] ===
                          'status_selected_no_active_listing_found' && (
                              <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                  }}
                              >
                                {' '}
                                <span style={{textAlign: 'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                                  Platform selected Vestiare, no active listing
                                found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
                            >
                                  Vestiare
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.17] ===
                       'status_selected_out_of_stock' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Platform selected as Vestiare, listed as "out of
                                stock" on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
                            >
                                  Vestiare
                                </a>
                              </span>
                        </div>
                       )}
                      {unescapedRowMetadataIssues[539.17] ===
                       'sold_status_active_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Sold status, active listing found on{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
                            >
                                  Vestiare
                                </a>
                              </span>
                        </div>
                       )}

                      {unescapedRowMetadataIssues[539.17] ===
                       'status_selected_no_listing_found' && (
                        <div
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           flexWrap: 'wrap',
                           gap: '5px',
                         }}
                        >
                          {' '}
                          <span style={{textAlign:'center'}}>
                                {syncTime && <p>Last sync {syncTime}</p>}
                            Unable to check status OR listing not found{' '}
                            <a
                             target="_blank"
                             style={{color: 'blue', display: 'inline'}}
                             href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
                            >
                                  Vestiare
                                </a>
                              </span>
                        </div>
                       )}
                    </div>
                    <a
                     target="_blank"
                     href={`https://app.listperfectly.com/support/bug-report/?auto-select=sync-issue&listing_id=${unescapedRowMetadata[885]}&catalog_listing_id=${row.id}`}
                     style={{padding: '5px', color: 'blue', textAlign: 'center', marginBottom:'5px', display:'block'}}
                    >
                      Report incorrect status
                    </a>
                    <Button style={{color:"#f31559", border:"1px solid #f31559",borderRadius:"5px",fontSize:20, margin:"0 auto"}} modifier={ModifierType.Transparent} handleClick={() =>
                     showModalChanger(
                      'newPlatformIssue',
                      null,
                      'vestiaire',
                      539.17
                     )}>Remove</Button>
                  </div>
                  </div>
                 ) : null}
               </div>
              ) : null}
            </div>
          }
          {
            <div className={styles.cardInfo}>
              {hasSimple ? (
                  <div className={styles.infoCintainer}>
                    <p className={styles.infoContainerTitle}>Notes</p>
                    <span
                        style={{
                          marginRight: 0,
                          marginLeft: 10,
                          wordBreak: 'break-word',
                        }}
                    >
                  {unescapedRowMetadata[726]
                      ? unescapedRowMetadata[726].length > 150
                          ? unescapedRowMetadata[726].substring(0, 150).trim() +
                          '...'
                          : unescapedRowMetadata[726]
                      : ' '}
                </span>
                  </div>
              ) : (
                  <>
                    <div className={styles.infoCintainer}>
                      <p className={styles.infoContainerTitle}>Qty</p>
                      <span>
                   {row?.newQuantity !== undefined ?  row!.newQuantity : bdecode(unescapedRowMetadata[851]) || '-'}
                  </span>
                    </div>
                    <div className={styles.infoCintainer}>
                      <p className={styles.infoContainerTitle}>SKU</p>
                      <span>{row?.newSku !== undefined
                          ? row.newSku
                          : unescapedRowMetadata[1] || '-'}</span>
                    </div>
                  </>
              )}

              <div className={styles.infoCintainer}>
                <p className={styles.infoContainerTitle}>Date Created</p>
                <span
                    style={{
                      marginLeft: hasSimple ? 10 : 0,
                    }}
                >
                {moment(row?.created_at).format('MM/DD/YYYY')}
              </span>
              </div>
            </div>
          }
          {
            <div className={`mobileActions ${styles.cardFooter}`}>
              <div className={styles.footerPrice}>
                {row.newPrice !== undefined
                    ? `$${Number(row.newPrice).toFixed(2)}`
                    : (unescapedRowMetadata[22] !== undefined
                        ? `$${Number(unescapedRowMetadata[22]).toFixed(2)}`
                        : '-')}
              </div>
              <Tippy
                  interactive={true}
                  zIndex={10}
                  visible={sectionActive}
                  arrow={false}
                  onClickOutside={() => {
                    setSectionActive(false);
                    setCrossPostingActive(false);
                    setShowDeleteTippy(false)
                  }}
                  content={
                    <>

                      {!crossPostingActive && !showDeleteTippy ? (
                       <div className={styles.actionsMenu}>
                         {isMobile ? (
                          <div className={styles.actionsMenuItem}>
                            <Sectionsitem1/> <p>Computer Only</p>
                          </div>
                         ) : (
                          <div
                           className={`${styles.actionsMenuItem} ${styles.crossPostingDesktop} lp_custom_crosspost_icon lp_custom_crosspost_icon_disabled`}
                           onClick={(event) => {
                             const element = event.currentTarget;

                             setTimeout(() => {
                               // Get the class after 2 seconds
                               if (
                                element?.classList.contains(
                                 'lp_custom_crosspost_icon_disabled'
                                )
                               ) {
                                 setCrossPostingActive(true);
                                 setExstensionActive(false);
                               } else if (
                                element?.classList.contains(
                                 'lp_custom_crosspost_icon_active'
                                )
                               ) {
                                 setSectionActive(false);
                                 setCrossPostingActive(false);
                                 setExstensionActive(true);
                               }
                             }, 1000);
                           }}
                          >
                            <Sectionsitem1/> <p>CrossPosting</p>
                          </div>
                         )}

                         <div
                          className={styles.actionsMenuItem}
                          onClick={() => {
                            dispatch(setEditAddModalShow(true));
                            dispatch(setEditAddModalId(row.id));
                          }}
                          // save for edit modal
                          // dispatch(setEditModalId(row.id));
                          // dispatch(setEditModalActive(true));
                         >
                           <Sectionsitem2/> <p>Edit</p>
                         </div>
                         <div
                          className={styles.actionsMenuItem}
                          onClick={() => {
                            dispatch(setDuplicateListingId(row?.id));
                            navigate('/listings/add');
                          }}
                         >
                           <Sectionsitem3/> <p>Duplicate</p>
                         </div>
                         <div
                          className={styles.actionsMenuItem}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(setShowEditTemplateModal(true))
                            dispatch(setListingTemplateId(row.id))
                          }}
                         >
                           <Sectionsitem4/> <p>Add Template</p>
                         </div>
                         <div
                          className={styles.actionsMenuItem}
                          onClick={(e: any) => {
                            e.preventDefault();
                            const location = window.location;
                            const searchParams = new URLSearchParams(location.search);
                            searchParams.set('modal', 'listing-view');
                            searchParams.set('modal-listing-id', `${row.id}`);
                            navigate(`?${searchParams.toString()}`);
                            if(!hasExtensionInstall) {
                              dispatch(setListingViewId(row.id));
                              dispatch(setShowListingViewModal(true));
                            }else {
                              if (isExtensionSupportedForSingleView) {
                                dispatch(setListingViewId(row.id));
                                dispatch(setShowListingViewModal(true));
                              }
                              else {
                                if (!iframeLink.length) {
                                  dispatch(setIframeName('title'));
                                  dispatch(setShowIframe());
                                  dispatch(iframeLoaderChanger(true));
                                  dispatch(
                                   setIframeLink(
                                    `listperfectly.com/listings/single/view/${row.id}`
                                   )
                                  );
                                }
                                else {
                                  dispatch(
                                   setShowQuestionModal(
                                    `https://app.listperfectly.com/listings/single/view/${row.id}`
                                   )
                                  );
                                  dispatch(
                                   setCandidantLinkPure(
                                    `listperfectly.com/listings/single/view/${row.id}`
                                   )
                                  );
                                }
                              }
                            }

                          }}
                         >
                             <ViewIcon />
                           <p>View</p>
                         </div>
                         <div
                          className={styles.actionsMenuItem}
                          onClick={() => handleDelete(row?.id)}
                         >
                           <Sectionsitem5 className={`${allowListingsDeletion === false ? styles.disabledDeleteIcon : ''}`} /> <p>Delete</p>
                         </div>

                       </div>
                      ) : showDeleteTippy ?  (
                          <div style={{padding: '10px'}}>
                            Request access from primary account
                          </div>
                      )  : !exstensionActive ? (
                       <p className={styles.crossPostingActive}>
                         Crossposting is not enabled.{' '}
                         <a
                          style={{width: '100%', color: 'blue'}}
                          target="_blank"
                          href="https://help.listperfectly.com/en/articles/8913965"
                         >
                           Install
                         </a>{' '}
                         or activate extension to enable.{' '}
                       </p>
                      )   :
                          null}
                        </>
                    }
              >
                <div
                 className={
                   sectionActive ? styles.actionsActive : styles.actions
                 }
                 onClick={toggleSection}
                >
                  actions
                  <div
                   className={
                     sectionActive
                      ? styles.dropdownBtnActive
                      : styles.dropdownBtnInactive
                   }
                  >
                    {sectionActive ? <DropdownActive/> : <Dropdown/>}
                  </div>
                </div>
              </Tippy>
            </div>
          }
        </div>
      </div>
  ) : null;
};

export default TableMobileCard;