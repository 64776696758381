import { useMemo } from "react";
import Auth from "@services/Auth";

// TODO use or camelCase or snake_case only one of them
interface RoleCheck {
    roles: string[];
    is_role_allowed: boolean;
    hasBusiness: boolean;
    hasSimple: boolean;
    hasPro: boolean;
    hasBetaTeamRole: boolean;
    hasBetaRole: boolean;
    is_role_allowed_master:boolean;
    hasSubRole:boolean;
    decodedToken:any;
    hasTicketsRole:boolean;
    hasSubscriber:any;
}

const useRoles = (): RoleCheck => {
    const token: any = Auth.get_token();
    const decodedToken: any = Auth.decode_token(token);

    // Memoize the roles initialization
    const roles = useMemo(() => decodedToken?.data?.user?.roles || [], [decodedToken]);

    const is_role_allowed_master = useMemo(
        () => Auth.is_role_allowed_master(roles),
        [roles]
    );
    const is_role_allowed = useMemo(
        () =>
            Auth.is_role_allowed(roles),
        [roles]
    );

    const hasBusiness = useMemo(
        () =>
            Auth.hasBusiness(roles),
        [roles]
    );

    const hasSimple = useMemo(
        () =>
            Auth.hasSimple(roles),
        [roles]
    );

    const hasSubscriber = useMemo(
        () =>
            Auth.hasSubscriber(roles),
        [roles]
    );

    const hasPro = useMemo(
        () =>
            Auth.hasPro(roles),
        [roles]
    );

    const hasBetaTeamRole = useMemo(
        () =>
            Auth.hasBetaTeamRole(roles),
        [roles]
    );

    const hasBetaRole = useMemo(
        () =>
            Auth.hasBetaRole(roles),
        [roles]
    );

    const hasSubRole = useMemo(
        () =>
            Auth.hasSubRole(roles),
        [roles]
    );
    const hasTicketsRole = useMemo(
        () =>
            Auth.hasTicketsRole(roles),
        [roles]
    );

    return {
        roles,
        is_role_allowed,
        hasBusiness,
        hasSimple,
        hasPro,
        hasBetaTeamRole,
        hasBetaRole,
        is_role_allowed_master,
        hasSubRole,
        decodedToken,
        hasSubscriber,
        hasTicketsRole,
    };
};

export default useRoles;
