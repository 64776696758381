import React, {useEffect, useMemo, useState} from 'react' ;
import styles from '../../SendToSalesModal.module.scss' ;

import {platforms} from "../../../Table/components/ColumnSearch/data/platforms";

import {EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,KidizenLogo,FacebookLogo,ListingPartyLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons" ;
import _ from "lodash";
import Select, {components} from "react-select";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

const defaultPlatformIconUrl: string =
 'https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/shop_light.svg';

interface ISoldOnPlatforms {
	index: number,
	metadata: any;
	updateModalListings: (name: string, value: string) => void;
	customMarketplaces?: any;
	checkShopId:(platformName:string, index:number) => void;
	checkPlatformListingId?:(metadata:any) =>string;
	handleChangeListingIdByPlatforms:(platformCode:string, index:number) => void;
	modalListings?:any;
	setNewModalInfo?:(newModalInfo:any) => void;
}

const SoldOnPlatforms = (props: ISoldOnPlatforms) => {
	const {
		index,
		metadata,
		updateModalListings,
		customMarketplaces,
		checkShopId,
		handleChangeListingIdByPlatforms,
		modalListings,
		setNewModalInfo

	} = props;
	const isSmallScreenLaptop = UseIsSmallLaptop();

	const [selectedOption, setSelectedOption] = useState<any>({
		value: "",
		label: "",
		icon: ""
	});

	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in metadata) {
			if (metadata.hasOwnProperty(key)) {
				result[key] = _.get(metadata, key);
			}
		}
		return result;
	}, [metadata]);

	useEffect(() => {
		const allPlatforms = platforms?.concat(customMarketplaces);

		for (let i = 0; i < allPlatforms.length; i++) {
			const platform = allPlatforms[i];
			const platformCode = platform?.platform.code;

			if (unescapedRowMetadata.hasOwnProperty(platformCode)) {
				if (platform?.platform.title === 'SOLD') {
					continue;
				}

				const platformName = platform?.platform.title;
				setSelectedOption({ value: platformName, label: platformName });
				return;
			}
		}

		setSelectedOption({ value: 'platforms', label: 'Platforms' });
	}, []);

	const checkPlatformImage = (value: string | null) => {
		let platformImage;
		switch (value?.toLowerCase()) {
			case 'ebay' :
				platformImage = <EbayLogo/>;
				break;
			case 'amazon':
				platformImage = <AmazonLogo/>;
				break;
			case 'depop':
				platformImage = <DepopLogo/>;
				break;
			case 'facebook':
				platformImage = <FacebookLogo/>;
				break;
			case 'etsy':
				platformImage = <EtsyLogo/>;
				break;
			case 'grailed':
				platformImage = <GrailedLogo/>;
				break;
			case 'instagram':
				platformImage = <InstagramLogo/>;
				break;

			case 'listing party':
				platformImage = <ListingPartyLogo/>;
				break;
			case 'mercari':
				platformImage = <MercaryLogo/>;
				break;
			case 'poshmark':
				platformImage = <PoshmarkLogo/>;
				break;
			case 'shopify':
				platformImage = <ShopifyLogo/>;
				break;
			case 'kidizen':
				platformImage = <KidizenLogo/>;
				break;
			case 'vestiaire':
				platformImage = <VestiaireLogo/>;
				break;

			default:

				platformImage = null;
				break;
		}

		return platformImage
	}

	const mappedPlatforms: any = platforms?.concat(customMarketplaces)
	?.filter((fItem: any) => fItem.platform.title !== 'SOLD' && fItem.platform.title !== 'Listing Party').map((item: any) => {
		return {
			label: item.platform.title,
			value: item.platform.title,
			icon:item.isCustom ? (<img className={styles.customImg} src={defaultPlatformIconUrl}/>) : checkPlatformImage(item.platform.title),
			...(item.isCustom) && {isCustom: true},
			code: item.platform.code[0]
		}
	})



	const customOption = (props: any) => {
		return (
		 <components.Option {...props}>
			 {props.data.icon}
			 {props.label}
		 </components.Option>
		);
	};

	const customStyles = {

		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: '#fff',
			color: '#1E3166',
			padding: '0',
			borderRadius: isSmallScreenLaptop ? '6px' : '8px',
			borderColor: state.isFocused ? '#4db89e' : '#393838',
			minWidth:isSmallScreenLaptop ? '127px' : '159px',
			cursor: 'pointer',
			outline: 'none',
			zIndex: 9,
			minHeight: !isSmallScreenLaptop ? '39px' : '31px',
			height: !isSmallScreenLaptop ? '39px' : '31px',
			boxShadow:"none",
			'&:hover': {
				...(state.isFocused) && {borderColor: '#4db89e',},
				boxShadow:"none"
			},

		}),
		menu: (provided: any, state: any) => ({
			...provided,
			width: '100%',
			minWidth:isSmallScreenLaptop ? '127px' : '159px',
			border: '1px solid #4db89e',
			borderRadius: isSmallScreenLaptop ? '6px' : '8px',
			paddingTop: isSmallScreenLaptop ? '8px' : '10px',
			position:'absolute',
			zIndex:9999,
			boxShadow:'none',
		}),
		menuList: (provided: any, state: any) => ({
			...provided,
			overflowX:"hidden",
		}),
		valueContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '31px',
			padding:  !isSmallScreenLaptop ? '0 10px' : '0 8px'

		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '31px',
		}),
		input: (provided: any) => ({
			...provided,
			margin: '0px',
		}),
		indicatorSeparator: (state:any) => ({
			display: 'none',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			fontWeight: 600,
			fontSize: isSmallScreenLaptop ? '13px' : '16px',
			display: 'flex',
			alignItems: "center",
			gap: isSmallScreenLaptop ? 8 : 10,
		}),
	};


	const dropDownChange = (selectedOption: any) => {

		setSelectedOption(selectedOption);
		checkShopId(selectedOption.value.toLowerCase(), index)

		if(modalListings?.length >1) {
			modalListings?.forEach((_:any,index:number ) => {
				handleChangeListingIdByPlatforms(selectedOption?.code, index)
			})
		} else {
			handleChangeListingIdByPlatforms(selectedOption?.code, index)

		}

		if(!selectedOption.isCustom) {
			updateModalListings('newSoldOn', selectedOption.value)
		} else {
			updateModalListings('newSoldOn', selectedOption.code)
		}

		setNewModalInfo && setNewModalInfo((prevNewModalInfo:any) => ({
			...prevNewModalInfo,
			newListingId: {
				text: '',
				changed: false
			}
		}));
	};


	return (
	 <div className={styles.soldOnPlatforms}>
		 <Select
		  styles={customStyles}
		  value={selectedOption}
		  onChange={dropDownChange}
		  options={mappedPlatforms}
		  isSearchable={false}
		  placeholder={null}
		  menuPlacement={index === 0 ? 'bottom' : 'top'}
		  components={{Option: customOption}}
		 />

	 </div>
	)
}

export default SoldOnPlatforms