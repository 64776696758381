import React from "react";
import useBasicFuncs from "@hooks/useBasisFuncs";
import styles from "../ListedMarkets.module.scss";

const PlatformsTitles = ({hasSimple, productInfo}:any) =>{
    const { bdecode } = useBasicFuncs();
    const metadataTitles = [
        { key: 791, label: 'eBay Title' },
        { key: 789, label: 'Mercari Title' },
        { key: 788, label: 'Grailed Title' },
        { key: 786, label: 'Poshmark Title' },
        { key: 869, label: 'Facebook Title' },
    ];
    return (
      <>
          {
              !hasSimple && <div className={styles.platformTitles}>
                  <div className={styles.fieldTitle}> Platform Titles</div>
                  {metadataTitles.map(({ key, label }) =>
                      productInfo?.metadata[key] ? (
                          <div key={key}>
                              <div className={styles.platformTitleObjectKeyName}>{label}</div>
                              <div>
                                  <div className={styles.platformTitleText}>
                                      {bdecode(productInfo?.metadata[key])}
                                  </div>
                              </div>
                          </div>
                      ) : null
                  )}
              </div>
          }
      </>
   )

}

export default PlatformsTitles;