import React from "react";
import {BusinessPlanIcon, GreenArrow, ProPlanIcon, ProPlusPlanIcon, SimplePlanIcon} from "@assets/icons";
import styles from "./PriceCard.module.scss";

const PriceCard = ({ type, price, planSource, description, featuresList, hasButtons, featuresTitle, comparePlans, mostAdvanced, viewFeatureLink }:any) => {
    return (
        <div className={`${styles["price-card"]} ${mostAdvanced ? styles["price-card--advanced"] : ""}`}>
            {
                mostAdvanced && <div className={styles["price-card__advancedBadge"]}>
                    ⚡️ MOST ADVANCED
                </div>
            }
            <div className={styles["price-card__content"]}>
                <h2 className={styles["price-card__title"]}>
                    {type === 'Simple' ? <SimplePlanIcon />
                        : type === 'Business' ? <BusinessPlanIcon />
                            : type === 'Pro' ? <ProPlanIcon />
                                : <ProPlusPlanIcon /> } {type}
                </h2>
                <p className={styles["price-card__price"]}>${price} / mo</p>
                {
                    Boolean(comparePlans) && <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${comparePlans}`}
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(`${comparePlans}`, "_blank");
                        }}
                        className={styles["price-card__comparePlans"]}
                    >
                        Compare plan
                    </a>
                }
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${planSource}`}
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `${planSource}`;
                    }}
                    className={styles["price-card__button"]}
                >
                    Choose plan
                </a>
                <div className={styles["price-card__separator"]}></div>
                {
                    Boolean(featuresTitle) &&
                    <>
                        <p className={styles["price-card__features-heading"]}>Features</p>
                        <p className={styles["price-card__features-title"]}>{featuresTitle}</p>
                    </>
                }
                {Boolean(description) && (
                    <p className={styles["price-card__description"]}>{description}</p>
                )}
                {
                    Boolean(featuresList) &&
                    <ul className={styles["price-card__features"]}>
                        {
                            featuresList.map((feature: any) => (
                                <li className={styles["price-card__features-item"]}>
                                    <GreenArrow/>
                                    <p>
                                        <span
                                            className={styles["price-card__features-item--bold"]}>{feature?.label} </span>
                                        <span
                                            className={styles["price-card__features-item--highlight"]}>{feature?.value}</span>
                                    </p>
                                </li>
                            ))
                        }

                    </ul>
                }
            </div>
            {
                hasButtons &&
                <div className={styles["price-card__actions"]}>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(`${viewFeatureLink}`, "_blank");
                        }}
                        className={`${styles["price-card__actions-link"]} ${styles["price-card__actions-link--primary"]}`}
                        target="_blank"
                        href={`${viewFeatureLink}`}
                        rel="noreferrer"
                    >
                        View Features Chart
                    </a>
                </div>
            }
        </div>
    );
};

export default PriceCard;