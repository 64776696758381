import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import styles from '../../Table.module.scss';

import _ from 'lodash';
import Select from 'react-select';
import moment from 'moment';
import {useSelector,useDispatch} from 'react-redux' ;
import {MoonLoader} from 'react-spinners';
import {toast} from "react-toastify";

import {IColumn, IConfirmModalShow} from '@interfaces/index';

import CatalogInModal from '../../../CatalogInModal';

import useBasicFuncs from '@hooks/useBasisFuncs';
import {instanceAxios} from '@services/index';
import {tableDataTransform} from '../../../../../../utils/tableDataTransform';

import { FaImage, FaRotateLeft, LinkedSVG, ListingPerfectlyNotActiveLogo, MarkSoldSVG, MultiOrderDefaultSVG, NotLinkedSvg, SVGNewTrash, PlatformIconShop, AmazonLogo, PoshmarkLogo, EbayLogo, MercaryLogo, SoldTagSvg,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,KidizenLogo,FacebookLogo,ListingPartyLogo,VestiaireLogo,InstagramLogo } from '@assets/icons';
import { depopLogo, etsyLogo, facebookLogo, grailedLogo, instagramLogo, kidizenLogo, listingPartyLogo, shopifyLogo, vestiaireLogo } from '@assets/constants/platformsLogo';


import PlatformBlockItem from '../../../../../CatalogPage/components/Table/components/PlatformBlockItem';
import convertToSlug from '../../../../../../utils/convertToSlug';

import formatedString from '../../../../../../utils/formatedString';
import useRandomNumber from '../../../../../../hooks/UseRandomNumber';
import ConfirmModal from "../../../../../CatalogPage/components/Table/components/ConfirmModal";
import NestedTableRow from "./components/NestedTableRow";
import {
	setListingViewId, setShowListingViewModal, setShowQuestionModal
} from "../../../../../../redux/slices/NavigationSlice";
import {useLocation, useNavigate} from "react-router-dom";
import formatPrice from '../../../../../../utils/formatPrice';
import Checkbox, {CheckboxModifier} from "../../../../../../components/UI/Checkbox";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

interface ITableRowProps {
	item: any;
	columns: IColumn[];
	handleRowSelected: (e: any, id: string) => void;
	handleChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isBulkEdit: boolean;
	tableData: any;
	index: number;
	setTableData: (tableData: any) => void;
	filterBy: any;
	metadata: any;
	handleBulkDelete: () => void;
	retryActive:boolean;
	setRetryActive:(retryActive:boolean) => void;
	saveActive:boolean;
	setSaveActive:(saveActive:boolean) => void;
	hasBusiness:boolean;
	hasPro:boolean;
	is_role_allowed_master:boolean
}

const TableRow = (props: ITableRowProps) => {
	const {
		item, // order
		columns,
		handleRowSelected,
		handleChangeCheckbox,
		isBulkEdit,
		tableData,
		index,
		setTableData,
		filterBy,
		metadata,
		handleBulkDelete,
		retryActive,
		setRetryActive,
		saveActive,
		setSaveActive,
		hasBusiness,
		hasPro,
		is_role_allowed_master
	} = props;

	const isSmallScreenLaptop = UseIsSmallLaptop() ;
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const filterByParam:any = queryParams.get('filter_by');
    const navigate = useNavigate();

	let decodedFilterBy: any;

	if (filterByParam) {
		try {
			decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));

		} catch (error) {
			console.error(error)
		}
	}

	const iframeLink = useSelector(
		(state: any) => state.navigation.iframeLink
	);
	const dispatch = useDispatch()

	const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces)
	const [isCheck] = useState(true);
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [dimensions, setDimensions] = useState<string | null>(null);
	const [loaderForImage, setLoaderForImage] = useState(true);
	const [isImageError,setIsImageError] = useState(false)

	const randomNumber = useRandomNumber();

	// TODO figure out what it does
	const [newTableInfo, setNewTableInfo] = useState<any>({
		newTitle: {
			text: '',
			changed: false,
		},
		newDateSold: {
			text: '',
			changed: false,
		},
		newLp: {
			text: '',
			changed: false,
		},
		newQuantityAvailable: {
			text: '',
			changed: false,
		},
		newSoldOn: {
			text: '',
			changed: false,
		},
		newShippingStatus: {
			text: '',
			changed: false,
		},
		newShop: {
			text: '',
			changed: false,
		},
		newLinked: {
			text: '',
			changed: false,
		},
		newSku: {
			text: '',
			changed: false,
		},
	});
	const [cachedNewTableInfo, setCachedNewTableInfo] = useState<any>(null);
	const isCanceled = useSelector((state: any) => state.sales.isCancel);
	const isSaved = useSelector((state: any) => state.sales.isSave);
	const {bdecode} = useBasicFuncs();

	const [isCatalogModalShow, setIsCatalogModalShow] = useState(false);

	// Check if order is linked with the catalog
	let order_listings = _.get(item, 'listings');
	if (!Array.isArray(order_listings)) order_listings = [];
	const is_multi_item_sale = order_listings.length > 1;
	const is_linked = !!(_.get(item, 'listings[0][catalog_id]') || _.get(item, 'catalog_id')); // * catalog_id

	const [selectedOption, setSelectedOption] = useState({
		value: _.get(item, 'shipping_status')
			? formatedString(_.get(item, 'shipping_status'))
			: '',
		label: _.get(item, 'shipping_status')
			? _.get(item, 'shipping_status')
			: '-',
	});

	const [selectedListing, setSelectedListing] = useState<any>(null);

	const [confirmModalShow, setConfirmModalShow] = useState<IConfirmModalShow>({
		status: false,
		role: '',
		listingCount: 0,
	});


	const handleChangeEditInput = (e: | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>) => {

		const name : string = e.target.name;
		let value : any = e.target.value;

		// Handle numbers for certain fields TODO, it's here temporarily only.
		if(e.target.type === 'number' && ['new__total_price', 'new__fees', 'new__shipping_cost_seller', 'new__shipping_cost_buyer', 'new__sales_tax', 'new__cogs', 'new__quantity_sold'].includes(name)){
			value = _.toNumber(value);
		}

		// TODO looks like this one is attempting to set the limit on input
		if (+value >= 100000000) return;
		const updatedItems = [...tableData];

		if(retryActive) setRetryActive(false);
		if(!saveActive) setSaveActive(true);

		// Looks like this one is handling shipping status change (dropdown)
		// TODO review it, I don't think it's used anywhere.
		if(e.target.nodeName === 'SELECT'){
			updatedItems[index] = {...updatedItems[index], ['newShippingStatus']: value, changed: true };
			setNewTableInfo({ ...newTableInfo, ['newShippingStatus']: {text: value, changed: true} });
		}

		else if( e.target instanceof HTMLInputElement && (e.target.type === 'checkbox' || e.target.type === 'radio') ){
			const checked = e.target.checked;
			updatedItems[index] = {
				...updatedItems[index],
				[name]: checked ? 'Draft' : null,
				changed: true,
			};
			setNewTableInfo({
				...newTableInfo,
				[name]: {checked: checked, changed: true},
			});
		}

		else {

			// TODO. Adding exception for cogs, quantity_sold, change later
			const exception_fields = ['new__cogs', 'new__quantity_sold'];
			if(exception_fields.includes(name)){

				const order = updatedItems[index];
				let order_listings = _.get(order, 'listings') || [];
				order_listings = order_listings.map((order_listing : any) => {
					_.set(order_listing, name, value);
					_.set(order_listing, 'multiItem', true);
					return order_listing;
				})
				_.set(order, 'listings', order_listings);
				updatedItems[index] = order;

				setNewTableInfo({
					...newTableInfo,
					[name]: {text: value, changed: true}
				});
			}

			if(!exception_fields.includes(name)){
				updatedItems[index] = {
					...updatedItems[index],
					[name]: value,
					changed: true,
				};
				setNewTableInfo({
					...newTableInfo,
					[name]: {text: value, changed: true}
				});
			}

		}
		setTableData(updatedItems);

	};



	const handleSendClickToMarkButton = () => {
		if (buttonRef.current) {
			buttonRef.current.click();
		}
	};

	const dropDownChange = (selectedOption: any) => {
		setSelectedOption(selectedOption);

		const updatedItems = [...tableData];

		updatedItems[index] = {
			...updatedItems[index],
			['newShippingStatus']: selectedOption.value,
			changed: true,
		};
		setNewTableInfo({
			...newTableInfo,
			['newShippingStatus']: {text: selectedOption.value, changed: true},
		});
		setTableData(updatedItems);

		const getShippingStatus = async () => {
			try {
				const response = await instanceAxios.put('/orders/update', {
					filter_by: {id: item.id},
					payload: {
						shipping_status: selectedOption.value
							? convertToSlug(selectedOption.value)
							: null,
					},
				});
			} catch (error) {
				console.error(error);
			}
		};

		getShippingStatus();
	};

	useEffect(() => {
		if ((newTableInfo && !cachedNewTableInfo) || isSaved) {
			setCachedNewTableInfo(newTableInfo);
		}
	}, [newTableInfo, cachedNewTableInfo, isSaved]);

	useEffect(() => {
		if (isCanceled && cachedNewTableInfo) {
			setNewTableInfo(cachedNewTableInfo);
			setCachedNewTableInfo(null);
		}
	}, [isCanceled]);

	const getNewTableData = async (pageNum: number) => {
		const localeFilterBy = {...filterBy}
		if(filterBy?.listingTitle) {
			delete localeFilterBy.listingTitle
		}
		if( (decodedFilterBy?.date_sold_range === 'all-time'  && filterBy?.order_ids) ) {
			delete localeFilterBy.date_sold_range
		}


		if(decodedFilterBy?.date_sold_range === 'all-time' ) {
			delete localeFilterBy.date_sold_range
		}
		try {
			const response = await instanceAxios.post('/orders/list', {
				metadata: {
					offset: pageNum * metadata.limit,
					limit: metadata.limit,
				},
				filter_by:localeFilterBy,
				options: {
					include_listings_catalog_metadata: true,
				},
			});

			if (response.status === 200) {
				const {data} = response.data;

				setTableData(tableDataTransform(data));
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleToogleShowCatalogModalShow = () => {
		setIsCatalogModalShow(!isCatalogModalShow);
		if(order_listings.length === 1) {
			setSelectedListing(order_listings[0])
		}

		const currentPageNumber = Number(localStorage.getItem('currentPageNumber'));

		if (isCatalogModalShow) {
			getNewTableData(currentPageNumber);
			document.body.style.overflow = 'auto';
		} else {
			document.body.style.overflow = 'hidden';
		}
	};

	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in item?.catalog_metadata) {
			if (item.catalog_metadata.hasOwnProperty(key)) {
				result[key] = _.get(item.catalog_metadata, key);
			}
		}
		return result;
	}, [_.get(item,'catalog_metadata')]);


	const options = [
		{value: '', label: '-'},
		{value: 'pending', label: 'Pending'},
		{value: 'shipped', label: 'Shipped'},
		{value: 'canceled', label: 'Canceled'},
		{value: 'returned', label: 'Returned'},

		{value: 'hold', label: 'Hold'},
		{value: 'awaiting_shipment', label: 'Awaiting Shipment'},
	];

	const customStyles = {

		control: (provided: any) => ({
			...provided,
			backgroundColor: '#fff',
			color: '#1E3166',
			borderColor: '#D5D5D5',
			padding: 1,
			borderRadius: !isSmallScreenLaptop ? '4px' : '3px',
			cursor: 'pointer',
			zIndex: 1,
			minHeight: !isSmallScreenLaptop ? '39px' : '32px',
			height:!isSmallScreenLaptop ? '39px' : '32px',
		}),
		menuPortal: (provided: any) => ({
			...provided,
			zIndex:999

		}),
		valueContainer: (provided: any) => ({
			...provided,
			height:!isSmallScreenLaptop ? '39px' : '32px',
			padding: !isSmallScreenLaptop ? '0 6px' : '0 5px'
		}),

		input: (provided: any) => ({
			...provided,
			margin: '0px',
		}),
		indicatorSeparator: (state:any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			height:!isSmallScreenLaptop ? '39px' : '32px',
		}),

		menu: (provided: any) => ({
			...provided,
			width: '100%',
			minWidth: !isSmallScreenLaptop ? '200px' : '160px',
			border: '1px solid #1E3166',
			borderRadius: !isSmallScreenLaptop ? '8px' : '6px',
			paddingTop: !isSmallScreenLaptop ? '10px' : '8px',

		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			fontWeight: 600,
			fontSize: !isSmallScreenLaptop ? '16px' : '13px',
		}),
	};





	useLayoutEffect(() => {
		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = src;

					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}
						setLoaderForImage(false);
					};

					img.onerror = () => {
						setIsImageError(true)
						setLoaderForImage(false);
					};
				}
			} catch (e) {
				console.log('e');
				setLoaderForImage(false);
			}
		};
		getImageDimensions(_.get(item,'thumbnail'));
	}, []);

	const handleChangeOrderStatus = async () => {
		const checkedListingCount = tableData.filter((item:any) => item.isChecked && item.status === 'active').length;

		setConfirmModalShow({
			...confirmModalShow,
			status: true,
			role: 'delete',
			listingCount: checkedListingCount >= 1 ? checkedListingCount : 1,
		});
	};

	const handleDeleteForOneSale = async () => {
		const previousTableData = [...tableData];
		const filteredTableData = tableData?.filter((tbData:any) => tbData.id !== item.id)
		try {
			const response = await instanceAxios.put('/orders/update', {
				filter_by: {id: item.id},
				payload: {status: 'trash'},
			});

			if(response.status === 200) {
				setTableData(filteredTableData)
				handleToggleConfirmModalShow()
			}

		} catch (error) {
			handleToggleConfirmModalShow()

			setTableData(previousTableData)

			toast.error(`Oops! We encountered an error while attempting to ${item.status === 'active' ? 'delete' : 'restore'} your listing. Please try again later.`, {
				position: 'bottom-right',
			});
			console.error(error);
		}
	}

	const handleToggleConfirmModalShow = () => [
		setConfirmModalShow({
			...confirmModalShow,
			status: !confirmModalShow.status,
		}),
	];

	const handleFuncDeleteOrRestore = (role: string) => {
		if (role === 'delete') {
			handleBulkDelete();
		}
		handleToggleConfirmModalShow();
	};

	const nestedTableData = item && is_multi_item_sale && order_listings;

	const trClass = `${styles.tr} ${
		item.isChecked ? styles.activeTr : ''
	} ${index % 2 === 0 ? styles.evenTr : styles.oddTr}`;


	const getCustomMarketplaces = (code:string) => {
		let customMarketplaces:any;

		globalCustomMarketplaces?.forEach((customMarket:any) => {
			if(customMarket.platform.code[0] === code) {
				customMarketplaces = {
					color:customMarket.color,
					title:customMarket.platform.title,
					platformId:customMarket.platform.platformId
				}
			}
		})

		return customMarketplaces
	}

	const customMarketplace = getCustomMarketplaces(item && item?.marketplaces)

	const handlePaste = (event:any) => {
		const pasteData = event?.clipboardData?.getData('Text');
		if (isNaN(pasteData)) {
			event.preventDefault();
		}
	};

	// TODO. Temporary solution for rendering table cells(tds). Start
	const render_table_td = (column_item: any) => {

		// Init
		let TD_Element_Content;
		let TD_Element_Props : any = {};

		let order_property_code = column_item.value;
		// TODO. Applied exception for total_sold_price. Change later.
		if(order_property_code === 'total_sold_price') order_property_code = 'total_price';

		const new_property_code = `new__${order_property_code}`;



		// TODO. Add some description or refactor later
		const properties_1 = ['total_price', 'fees', 'sales_tax', 'shipping_cost_seller', 'shipping_cost_buyer'];
		// const properties_2 = [''];

		// If bulk edit is NOT ACTIVATED.
		if(!isBulkEdit){

			if(properties_1.includes(order_property_code)){
				const price_text = _.get(newTableInfo, `${new_property_code}.text`) || item[order_property_code];
				TD_Element_Content = (<div style={{cursor: 'text'}}>{_.isFinite(price_text) ? `$${formatPrice(price_text)}` : '-'}</div>);
			}

			// TODO implement a method for update single-item sale update SKU functionality.
			if(order_property_code === 'sku'){
				TD_Element_Content = (<div style={{cursor: 'text'}}>
					{item && !is_multi_item_sale ? newTableInfo.newSku.text || item[order_property_code] || '-' : null}
				</div>);
			}

			// Handle COGS and do not display for multi-item sales
			if(order_property_code === 'cogs' && !is_multi_item_sale){
				const price_text = _.get(newTableInfo, `${new_property_code}.text`) || item[order_property_code];
				TD_Element_Content = (<div style={{cursor: 'text'}}>{_.isFinite(price_text) ? `$${formatPrice(price_text)}` : '-'}</div>);
			}

			// Handle quantity sold and do not display for multi-item sales
			if(order_property_code === 'quantity_sold' && !is_multi_item_sale){
				TD_Element_Content = (<div style={{cursor: 'text'}}>{_.get(newTableInfo, `${new_property_code}.text`) || item[order_property_code]}</div>);
			}

		}

		// If bulk edit is ACTIVATED.
		if(isBulkEdit){

			if(properties_1.includes(order_property_code)){
				TD_Element_Content = (
					<input
						className="myInput no-spinner"
						type="number"
						step="0.01"
						min={0}
						name={new_property_code}
						value={
							_.get(newTableInfo, `${new_property_code}.changed`)
								? _.get(newTableInfo, `${new_property_code}.text`)
								: _.get(newTableInfo, `${new_property_code}.text`)  ||
								item[order_property_code]
						} // TODO find out how to simplify this mess.
						onChange={handleChangeEditInput}
						onPaste={handlePaste}
					/>
				);
			}

			if(order_property_code === 'cogs'){
				TD_Element_Content = (item &&
					!is_multi_item_sale && (
						<input
							className="myInput no-spinner"
							type="number"
							step="0.01"
							min={0}
							name={new_property_code}
							value={
								_.get(newTableInfo, `${new_property_code}.changed`)
									? _.get(newTableInfo, `${new_property_code}.text`)
									: _.get(newTableInfo, `${new_property_code}.text`) ||
									_.get(item, 'listings[0].cogs')
							} // TODO find out how to simplify this mess.
							onChange={handleChangeEditInput}
							onPaste={handlePaste}
						/>
					)
				);
			}

			if(order_property_code === 'quantity_sold' && !is_multi_item_sale){
				TD_Element_Content = (
					<input
						className="myInput no-spinner"
						type="number"
						min={0}
						name={new_property_code}
						value={
							_.get(newTableInfo, `${new_property_code}.changed`)
								? _.get(newTableInfo, `${new_property_code}.text`)
								: _.get(newTableInfo, `${new_property_code}.text`) ||
								_.get(item, 'listings[0].quantity_sold')
						}
						onChange={handleChangeEditInput}
						onPaste={handlePaste}
					/>
				);
			}

		}


		// ALWAYS DISPLAY:
		if(order_property_code === 'image'){
			TD_Element_Props.className = styles.thumbnailTd;
			TD_Element_Content = (
				<div
					className={styles.thumbnail}
					style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

					{item && is_multi_item_sale ? (
						<div className={styles.multiOrderDefault}>
							<MultiOrderDefaultSVG/>
							<div>{nestedTableData?.length}</div>
						</div>
					) :( item.thumbnail && !isImageError) ? (
						loaderForImage ? (
							<MoonLoader color={'#1e3166'} loading={true} size={18}/>
						) : (
							<img
								src={`${_.get(item,'thumbnail')}?rnd=${randomNumber}`}
								alt=""
								style={{
									width: dimensions === 'width' ? (!isSmallScreenLaptop ? '100px' : '80px') : 'auto',
									height: dimensions === 'height' ? (!isSmallScreenLaptop ? '100px' : '80px') : 'auto',
								}}
							/>
						)
					) : (<FaImage/>)}

				</div>
			);
		}
		if(order_property_code === 'title'){
			TD_Element_Content = (<div style={{ cursor: 'text', color: '#393838', fontWeight: '600', textAlign: 'left' }}>
				{item && is_multi_item_sale ? 'Multi-Item Order' : bdecode(newTableInfo.newTitle.text) || bdecode(item[order_property_code]?.long) || '-'}
			</div>);
		}
		if(order_property_code === 'quantity_available' && !is_multi_item_sale){

			let quantity_available = _.get(item, `listings[0].catalog_metadata.851`);
			if(quantity_available) quantity_available = _.toNumber(quantity_available);
			quantity_available = _.isFinite(quantity_available) ? quantity_available : '-';
			TD_Element_Content = (<div style={{cursor: 'text'}}>{quantity_available}</div>);

		}
		if(order_property_code === 'shop_id'){
			TD_Element_Content = (
				<div style={{cursor: 'text'}}>
					{newTableInfo.newShop.text || item[order_property_code] || '-'}
				</div>
			);
		}
		if(order_property_code === 'sold_on'){

			TD_Element_Props.className = styles.platformTd;
			TD_Element_Props.style = {justifyContent: 'flex-start'};


			// Handle regular marketplace TODO iterate through array here, refactor later.
			// TODO links may be broken, check with Patrick.
			if(!customMarketplace){
				const marketplace = _.get(item, 'marketplace');
				const order_id = _.get(item, 'order_id');
				const listing_id = _.get(item, 'listing_id');

				if(marketplace === 'ebay'){
					TD_Element_Content = (<a href={`https://ebay.com/sh/ord/details?orderid=${order_id}`} target="_blank" key={index}><EbayLogo/></a>);
				}
				if(marketplace === 'poshmark'){
					TD_Element_Content = (<a href={`https://poshmark.com/order/sales/${order_id}`} target="_blank" key={index}><PoshmarkLogo/></a>);
				}
				if(marketplace === 'mercari'){
					TD_Element_Content = (<a href={`https://mercari.com/transaction/order_status/${listing_id}`} target="_blank" key={index}><MercaryLogo/></a>);
				}
				if(marketplace === 'etsy'){
					TD_Element_Content = (<a href={`https://etsy.com/your/orders/sold/completed?order_id=${order_id}`} target="_blank" key={index}><EtsyLogo/></a>);
				}
				if(marketplace === 'facebook'){
					TD_Element_Content = (<a href={`https://facebook.com/marketplace/you/shipping_orders/${order_id}`} target="_blank" key={index}><FacebookLogo/></a>);
				}
				if(marketplace === 'vestiaire'){
					TD_Element_Content = (<a href={`https://vestiaire.com/${order_id}.shtml`} target="_blank" key={index}><VestiaireLogo/></a>);
				}
				if(marketplace === 'depop'){
					TD_Element_Content = (<a href={`https://depop.com/sellinghub/sold-items/${order_id}/`} target="_blank" key={index}><DepopLogo/></a>);
				}
				if(marketplace === 'grailed'){
					TD_Element_Content = (<a href={`https://grailed.com/listings/${order_id}/order`} target="_blank" key={index}><GrailedLogo/></a>);
				}
				if(marketplace === 'kidizen'){
					TD_Element_Content = (<a href={`https://kidizen.com/orders/${order_id}`} target="_blank" key={index}><KidizenLogo/></a>);
				}
				if(marketplace === 'instagram'){
					TD_Element_Content = (<a href={`https://instagram.com/orders/${order_id}`} target="_blank" key={index}><InstagramLogo/></a>);
				}
				if(marketplace === 'amazon'){
					TD_Element_Content = (<a href={`https://amazon.com/orders/${order_id}`} target="_blank" key={index}><AmazonLogo/></a>);
				}
				if(marketplace === 'shopify'){
					TD_Element_Content = (<a href={`https://shopify.com/orders/${order_id}`} target="_blank" key={index}><ShopifyLogo/></a>);
				}
				if(marketplace === 'listing_party'){
					TD_Element_Content = (<a href={`https://listingparty.com/orders/${order_id}`} target="_blank" key={index}><ListingPartyLogo/></a>);
				}
			}

			// Handle custom marketplace
			if(customMarketplace){
				TD_Element_Content = (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
					<div>
						<div
							style={{
								width: !isSmallScreenLaptop ? 40 : 32,
								height: !isSmallScreenLaptop ? 40 : 32,
								borderRadius: !isSmallScreenLaptop? 4 : 3,
								padding: !isSmallScreenLaptop ? 5 : 4,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								border: `1px solid ${customMarketplace?.color ? customMarketplace.color : '#000'}`,
							}}
						>
							<PlatformIconShop width={!isSmallScreenLaptop ? "20px" : "16px"} height={!isSmallScreenLaptop ? "20px" : "16px"} fill={customMarketplace?.color ? customMarketplace.color : '#000'} />
						</div>
					</div>
					<div style={{ fontSize: !isSmallScreenLaptop ? 15 : 12 }}>{customMarketplace.title}</div>
				</div>);
			}
		}
		if(order_property_code === 'date_sold'){
			TD_Element_Content = (
				<div style={{cursor: 'text'}}>
					{newTableInfo.newDateSold.text ||
						moment(item[order_property_code]).format('MM/DD/YYYY') ||
						'-'}
				</div>
			);
		}
		if(order_property_code === 'shipping_status'){
			TD_Element_Props.className = styles.shippingStatusTd;
			TD_Element_Content = (
				<div style={{width: '100%', padding: '0 10px'}}>
					<Select
						styles={customStyles}
						value={selectedOption}
						options={options}
						isSearchable={false}
						onChange={dropDownChange}
						menuPlacement={'bottom'}
						menuPosition={'fixed'}
					/>
				</div>
			);
		}
		if(order_property_code === 'linked'){
			TD_Element_Props.className = styles.linkedTd;
			TD_Element_Content = (
				<div
					style={{cursor: 'text'}}
					className={styles.linked}
				>
					{is_linked && !is_multi_item_sale ? (
						<div
							onClick={(e: any) => {
								e.preventDefault();
								const id = `${_.get(item, 'listings[0].catalog_id')}`;
								if(id){
									const location = window.location;
									const searchParams = new URLSearchParams(location.search);
									searchParams.set('modal', 'listing-view');
									searchParams.set('modal-listing-id', `${id}`);
									navigate(`?${searchParams.toString()}`);
									dispatch(setListingViewId(Number(id)));
									dispatch(setShowListingViewModal(true));
								}
							}}>
							<ListingPerfectlyNotActiveLogo/>
						</div>
					) : (
						'-'
					)}
				</div>
			);
		} // TODO rebuild linked
		if(order_property_code === 'actions'){
			TD_Element_Content = (
				<div className={styles.actionButtons}>
					{item && order_listings && is_multi_item_sale
						&&
						(item.status === 'active' ?
							(
								<div
									style={{border: ' 1px solid #CA1010'}}
									className={styles.actionColumnSvg}
									onClick={handleChangeOrderStatus}
								>
									<SVGNewTrash/>
								</div>
							) :
							(<div
								style={{border: ' 1px solid #CA1010'}}
								className={`${styles.actionColumnSvg} ${styles.recoverySpan}`}
								onClick={handleChangeOrderStatus}

							>
								<FaRotateLeft/>
							</div>))
					}

					{!is_multi_item_sale && (is_linked ?
						(<div
							style={{border: '1px solid var(--Mint, #4DB89E)'}}
							className={styles.actionColumnSvg}
							onClick={handleToogleShowCatalogModalShow}
						><LinkedSVG/></div>) :
						(<div
							style={{border: ' 1px solid var(--Red, #CA1010)'}}
							className={styles.actionColumnSvg}
							onClick={handleToogleShowCatalogModalShow}
						><NotLinkedSvg/></div>))
					}

					{item &&
						!is_multi_item_sale &&
						_.get(unescapedRowMetadata, '539.1') !== 'SOLD' &&
						is_linked && ( // * catalog_id
							<div
								style={{border: '1px solid var(--Red, #CA1010)'
								}}
								className={`${styles.actionColumnSvg} markSoldButton ${
									is_linked && ((hasPro && is_role_allowed_master) || hasPro || hasBusiness)  ? 'linked' : ''
								}`}
								onClick={handleSendClickToMarkButton}
							>
								<MarkSoldSVG/>
							</div>
						)}
					{!is_linked && ( // * catalog_id
						<button
							ref={buttonRef}
							style={{
								visibility: 'hidden',
								height: 0,
								width: 0,
								padding: 0,
								margin: 0,
								border: 0,
								marginRight: -10,
							}}
							className="markSoldIcon"
						>
						</button>
					)}
					{item &&
						!is_multi_item_sale &&

						(item.status === 'active' ?
							(<div
								style={{border: ' 1px solid #CA1010'}}
								className={styles.actionColumnSvg}
								onClick={handleChangeOrderStatus}

							>
								<SVGNewTrash/>
							</div>) :
							(<div
								style={{border: ' 1px solid #CA1010'}}
								className={`${styles.actionColumnSvg} ${styles.recoverySpan}`}
								onClick={handleChangeOrderStatus}

							>
								<FaRotateLeft/>
							</div>))
					}
				</div>
			);
		}

		if(order_property_code === 'platforms'){
			TD_Element_Props.style = {overflow: 'visible', justifyContent:'flex-start'};
			TD_Element_Props.className = styles.platforms;
			TD_Element_Content = (
				!is_multi_item_sale &&
				(<div className={styles.platformsWrapper}>
					{(item?.newSOLD === 'SOLD' ||
						unescapedRowMetadata[539.1] === 'SOLD') && (
						<PlatformBlockItem
							platformName={'SOLD'}
							sync={item?.sync}
							href=""
							platformIcon={SoldTagSvg}
							isLink={false}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item.newListing_party === 'Listing Party' ||
						unescapedRowMetadata[539.16] === 'Listing Party') && (
						<PlatformBlockItem
							platformName={'listing_party'}
							href={`https://listingparty.com/listings?id=${item.id}`}
							platformIcon={listingPartyLogo}
							isLink={true}
							IsSold={unescapedRowMetadata[539.1]}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.neweBay === 'eBay' ||
						unescapedRowMetadata[539.2] === 'eBay') && (
						<PlatformBlockItem
							platformName={'eBay'}
							href={`https://ebay.com/itm/${unescapedRowMetadata[618]}`}
							platformIcon={EbayLogo}
							isLink={
								unescapedRowMetadata[618] &&
								unescapedRowMetadata[539.2] === 'eBay'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newEtsy === 'Etsy' ||
						unescapedRowMetadata[539.3] === 'Etsy') && (
						<PlatformBlockItem
							platformName={'Etsy'}
							href={`https://www.etsy.com/listing/${unescapedRowMetadata[619]}}`}
							platformIcon={etsyLogo}
							isLink={
								unescapedRowMetadata[619] &&
								unescapedRowMetadata[539.3] === 'Etsy'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newPoshmark === 'Poshmark' ||
						unescapedRowMetadata[539.4] === 'Poshmark') && (
						<PlatformBlockItem
							platformName={'Poshmark'}
							href={`https://poshmark.com/listing/${unescapedRowMetadata[620]}`}
							platformIcon={PoshmarkLogo}
							isLink={
								unescapedRowMetadata[620] &&
								unescapedRowMetadata[539.4] === 'Poshmark'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newMercari === 'Mercari' ||
						unescapedRowMetadata[539.5] === 'Mercari') && (
						<PlatformBlockItem
							platformName={'Mercari'}
							href={`https://www.mercari.com/us/item/${unescapedRowMetadata[621]}`}
							platformIcon={MercaryLogo}
							isLink={
								unescapedRowMetadata[621] &&
								unescapedRowMetadata[539.5] === 'Mercari'
							}
							fromPlatform={true}
							hasTitle={true}
						/>
					)}
					{(item?.newAmazon === 'Amazon' ||
						unescapedRowMetadata[539.7] === 'Amazon') && (
						<PlatformBlockItem
							platformName={'Amazon'}
							href={`https://www.amazon.com/${unescapedRowMetadata[836]}`}
							platformIcon={AmazonLogo}
							isLink={
								unescapedRowMetadata[836] &&
								unescapedRowMetadata[539.7] === 'Amazon'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newGrailed === 'Grailed' ||
						unescapedRowMetadata[539.8] === 'Grailed') && (
						<PlatformBlockItem
							platformName={'Grailed'}
							href={`https://www.grailed.com/listings/${unescapedRowMetadata[839]}`}
							platformIcon={grailedLogo}
							isLink={
								unescapedRowMetadata[839] &&
								unescapedRowMetadata[539.8] === 'Grailed'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newFacebook === 'Facebook' ||
						unescapedRowMetadata[539.9] === 'Facebook') && (
						<PlatformBlockItem
							platformName={'Facebook'}
							href={`https://www.facebook.com/marketplace/category/${unescapedRowMetadata[840]}/`}
							platformIcon={facebookLogo}
							isLink={
								unescapedRowMetadata[840] &&
								unescapedRowMetadata[539.9] === 'Facebook'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newDepop === 'Depop' ||
						unescapedRowMetadata[539.11] === 'Depop') && (
						<PlatformBlockItem
							platformName={'Depop'}
							href={`https://www.depop.com/products/${unescapedRowMetadata[841]}`}
							platformIcon={depopLogo}
							isLink={
								unescapedRowMetadata[841] &&
								unescapedRowMetadata[539.11] === 'Depop'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newKidizen === 'Kidizen' ||
						unescapedRowMetadata[539.12] === 'Kidizen') && (
						<PlatformBlockItem
							platformName={'Kidizen'}
							href={`https://www.kidizen.com/items/${unescapedRowMetadata[853]}`}
							platformIcon={kidizenLogo}
							isLink={
								unescapedRowMetadata[853] &&
								unescapedRowMetadata[539.12] === 'Kidizen'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newShopify === 'Shopify' ||
						unescapedRowMetadata[539.13] === 'Shopify') && (
						<PlatformBlockItem
							platformName={'Shopify'}
							href={`https://accounts.shopify.com/lookup?rid=70f0f0e2-3f19-4767-849c-f8e779c0005a`}
							platformIcon={shopifyLogo}
							isLink={
								unescapedRowMetadata[855] &&
								unescapedRowMetadata[539.13] === 'Shopify'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newInstagram === 'Instagram' ||
						unescapedRowMetadata[539.14] === 'Instagram') && (
						<PlatformBlockItem
							platformName={'Instagram'}
							href={`https://www.instagram.com/p/${unescapedRowMetadata[856]}/`}
							platformIcon={instagramLogo}
							isLink={
								unescapedRowMetadata[856] &&
								unescapedRowMetadata[539.14] === 'Instagram'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
					{(item?.newVestiaire === 'Vestiaire' ||
						unescapedRowMetadata[539.17] === 'Vestiaire') && (
						<PlatformBlockItem
							platformName={'Vestiaire'}
							href={`https://vestiairecollective.com/'${unescapedRowMetadata[885]}.shtml`}
							platformIcon={vestiaireLogo}
							isLink={
								unescapedRowMetadata[885] &&
								unescapedRowMetadata[539.17] === 'Vestiaire'
							}
							fromPlatform={true}
							type="sales"
							hasTitle={true}
						/>
					)}
				</div>)
			);
		} // TODO import it from the common location, it should be sharable component.
		if(order_property_code === 'profit'){
			TD_Element_Content = (
				<div style={{cursor: 'text'}}>
					{item['additional_properties'][order_property_code].toFixed(2) || '-'}
				</div>
			);
		}

		// Return generated cell(td)
		return (<td key={order_property_code} {...TD_Element_Props}>{TD_Element_Content}</td>);

	};
	// TODO. Temporary solution for rendering table cells(tds). End

	return (
		<>
			{confirmModalShow.status && (
				<ConfirmModal
					handleToggleConfirmModalShow={handleToggleConfirmModalShow}
					confirmModalShow={confirmModalShow}
					handleFuncDeleteOrRestore={handleFuncDeleteOrRestore}
					handleDeleteForOneSale={handleDeleteForOneSale}
					type={'orders'}
				/>
			)}

			<tr className={trClass}>

				{/*Checkbox column. Start*/}
				<td onClick={(e: any) => handleRowSelected(e, item.id)}>
					{isCheck && (
						<Checkbox
							name={item.id}
							checked={item?.isChecked || false}
							onChange={handleChangeCheckbox}
							modifier={CheckboxModifier.Primary}
						/>
					)}
				</td>
				{/*Checkbox column. End*/}

				{/* It follows order of items coming from the columns array */}
				{columns.map((column) => (render_table_td(column)))}

			</tr>

			{/* This section is displaying for displaying multi-item sales body. Start */}
			{is_multi_item_sale && (
				<>
					{nestedTableData?.map((itemTabledata: any, nestedIndex: number) => (
						<NestedTableRow
							key={nestedIndex}
							itemTabledata ={itemTabledata}
							columns={columns}
							trClass = {trClass}
							handleToogleShowCatalogModalShow ={handleToogleShowCatalogModalShow}
							buttonRef ={buttonRef}
							isBulkEdit={isBulkEdit}
							newTableInfo={newTableInfo}
							setNewTableInfo = {setNewTableInfo}
							tableData={tableData}
							setTableData ={setTableData}
							index ={index}
							nestedIndex ={nestedIndex}
							setSelectedListing={setSelectedListing}
							retryActive={retryActive}
							setRetryActive={setRetryActive}
							saveActive={saveActive}
							setSaveActive={setSaveActive}
							hasBusiness={hasBusiness}
							hasPro={hasPro}
							is_role_allowed_master={is_role_allowed_master}
						/>
					))}
				</>
			)}
			{/* This section is displaying for displaying multi-item sales body. End */}

			{isCatalogModalShow && (
				<CatalogInModal
					handleToogleShowCatalogModalShow={handleToogleShowCatalogModalShow}
					selectedListing={selectedListing}
					orderId={item.id}
				/>
			)}
		</>
	);
};

export default TableRow;
