import styles from './HeaderComponent.module.scss';
import {ExitButton, Lightbulb, SavingIcon} from "../../../../assets/icons";

import {useDispatch} from "react-redux";
import {setEditAddModalId, setEditAddModalShow,} from "../../../../redux/slices/NavigationSlice";
import {FooterComponent} from "../FooterComponent";
import React from "react";
import Button, {ModifierType} from "../../../UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

const HeaderComponent = ({SKUloader, savingProgress, showEditAddModal, afterCreate, disableSaveButtons, handleToggleListingAssistant, showRegenerate,regenerateAttempsLeftCount,  setAssistantTitleVision, changeListingAssistance, enabledButtons, handleSaveForm, setSaveAndClose, setSaveAndPreview, setSaveAndNew, editId, handleAddListing}:any) => {

    const dispatch = useDispatch();
    const isSmallScreenLaptop = UseIsSmallLaptop()

    return (
        <div className={`${styles.editHeader} ${ (showEditAddModal) ? styles.fixedHeader : styles.stickyHeader}`}>
            <div className={styles.headerLeftContainer}>
                <Button
                 modifier={ModifierType.Warning}
                 handleClick={() => {
                     if(!enabledButtons || savingProgress || SKUloader) return
                    setAssistantTitleVision('assistant')
                    changeListingAssistance(true)
                } }
                 disabled={!enabledButtons || savingProgress || SKUloader}
                >
                    <Lightbulb />
                    <span>Listing Assistant</span>
                </Button>
                {
                    (showRegenerate && regenerateAttempsLeftCount !== 0) &&
                 <Button
                  modifier={ModifierType.Warning}
                  handleClick={() => {
                     if(!enabledButtons  || savingProgress || SKUloader) return
                         setAssistantTitleVision('regenerate');
                     handleToggleListingAssistant();
                 }}
                 disabled={!enabledButtons  || savingProgress || SKUloader}
                 >
                    <Lightbulb/> Regenerate
                    </Button>
                }
            </div>
           <div className={styles.headerMiddleContainer}>
               <FooterComponent enabledButtons={enabledButtons}
                                handleSaveForm={handleSaveForm}
                                forTabletSize={true}
                                setSaveAndClose={setSaveAndClose}
                                setSaveAndPreview={setSaveAndPreview}
                                setSaveAndNew={setSaveAndNew}
                                handleAddListing={handleAddListing}
                                editId={editId}
                                disableSaveButtons={disableSaveButtons}
                                afterCreate={afterCreate}
                                showEditAddModal={showEditAddModal}
                                savingProgress={savingProgress}
                                SKUloader={SKUloader}
               />
               {
                   savingProgress && <div className={styles.loaderForSavingProgress}>
                       <SavingIcon/>
                       <p>Saving...</p>
                   </div>
               }
           </div>

            {/*<>*/}
            {/*    <div className={styles.editSubmitButtons}>*/}
            {/*        {*/}
            {/*                <button*/}
            {/*                    onClick={() => {*/}
            {/*                        if (enabledButtons) {*/}
            {/*                            editId ? handleSaveForm() : handleAddListing()*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    className={styles.saveProgress}*/}
            {/*                    disabled={!enabledButtons || disableSaveButtons}*/}
            {/*                    style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*                >*/}
            {/*                    <SaveProgress/>*/}
            {/*                    Save Progress*/}
            {/*                </button>*/}
            {/*        }*/}

            {/*        <button*/}
            {/*            className={styles.saveAndNew}*/}
            {/*            disabled={!enabledButtons || disableSaveButtons}*/}
            {/*            onClick={() => {*/}
            {/*                if (enabledButtons) {*/}
            {/*                    setSaveAndNew(true);*/}
            {/*                    editId ? handleSaveForm() : handleAddListing()*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*        >*/}
            {/*            <PlusIconNew/>*/}
            {/*            Save & New*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={styles.saveAndPreview}*/}
            {/*            onClick={() => {*/}
            {/*                if (enabledButtons) {*/}
            {/*                    setSaveAndPreview(true);*/}
            {/*                    editId ? handleSaveForm() : handleAddListing()*/}
            {/*                }*/}
            {/*            }}*/}
            {/*            disabled={!enabledButtons || disableSaveButtons}*/}
            {/*            style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*        >*/}
            {/*            <Previewicon/>*/}
            {/*            Save & Preview*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            className={styles.saveAndClose}*/}
            {/*            disabled={!enabledButtons || disableSaveButtons}*/}
            {/*            style={!enabledButtons || disableSaveButtons ? {opacity: 0.7} : {opacity: 1}}*/}
            {/*            onClick={() => {*/}
            {/*                if (enabledButtons) {*/}
            {/*                    setSaveAndClose(true);*/}
            {/*                    editId ? handleSaveForm() : handleAddListing()*/}
            {/*                }*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <Dashicon/>*/}
            {/*            Save & Close*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</>*/}
            {
                (showEditAddModal) ?
                    <Button
                     modifier={ModifierType.Error}
                     handleClick={() => {
                         dispatch(setEditAddModalShow(false))
                         dispatch(setEditAddModalId(null))
                     }}
                    >
                        <ExitButton className={styles.closeEditIcon}/> Cancel
                    </Button>
                 : <div style={{width: showRegenerate ? '0px' : `${isSmallScreenLaptop ? 120 : 150}px`}}></div>
            }

        </div>
    );
};

export default HeaderComponent;