// @ts-ignore
import _ from "lodash";
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';

class Auth{

    get_token(){

        try{

            // Get token from local storage
            const token = localStorage.getItem('lp_authorization_token');
            if(token) return token;

            // Get token from cookies
            const cookie_token = Cookies.get('lp_authorization_token');
            if(cookie_token) return cookie_token;

            // Default
            return null;

        } catch (e){
            console.log('get_token', e);
            return null;
        }

    }
    decode_token(token : string){

        try{
            return jwt_decode(token);
        } catch (e){
            console.log('decode_token', e);
            return null;
        }

    }
    is_token_expired(token : string){

        try{

            const decoded_token = jwt_decode(token);
            const current_time = Date.now() / 1000; // Convert to Unix time
            const expiration_time = _.get(decoded_token, 'exp') || 0;
            return expiration_time < current_time;

        } catch (e){
            console.log('is_token_expired', e);
            return true;
        }

    }

    is_role_allowed_master(roles : string[]){
        try{
            return roles.some((role: string) =>
                ["corporate master"].includes(role.toLowerCase())
            )

        } catch (e){
            return false;
        }

    }

    is_role_allowed(roles : string[]){
        try{
           return roles.some((role: string) =>
                ["corporate master", "corporate sub"].includes(role.toLowerCase())
            )

        } catch (e){
            return false;
        }

    }

    hasSubscriber(roles : string[]){
        try{
            return roles.map((role:string) => role.toLowerCase()).includes("subscriber")

        } catch (e){
            return false;
        }

    }

    hasBusiness(roles : string[]){
        try{
            return roles.map((role:string) => role.toLowerCase()).includes("business")

        } catch (e){
            return false;
        }

    }

    hasSimple(roles : string[]){
        try{
            return roles.map((role:string) => role.toLowerCase()).includes("simple")

        } catch (e){
            return false;
        }

    }

    hasPro(roles : string[]){
        try{
            return roles.map((role:string) => role.toLowerCase()).includes("pro")

        } catch (e){
            return false;
        }

    }

    hasBetaTeamRole(roles : string[]){
        try{
            return roles.some((role: string) => role.toLowerCase() === "beta team")

        } catch (e){
            return false;
        }

    }

    hasBetaRole(roles : string[]){
        try{
            return roles.some((role: string) => role.toLowerCase() === "beta")

        } catch (e){
            return false;
        }

    }

    hasSubRole(roles : string[]){
        try{
            return roles.some((role: string) => role.toLowerCase() === "corporate sub")

        } catch (e){
            return false;
        }

    }

    hasTicketsRole(roles : string[]){
        try{
            return roles.some((role: string) => role.toLowerCase() === "tickets")

        } catch (e){
            return false;
        }

    }

}

export default new Auth();
