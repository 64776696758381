import React from 'react' ;
import styles from './DataSetRow.module.scss';

import moment from 'moment';
import {useSelector} from "react-redux";
import _ from "lodash";

import useBasicFuncs from '../../../hooks/useBasisFuncs';
import defaultThumbnaiLPhoto from '../../../assets/icons/svg/defaultThumbnail.svg';
import {EbayLogo,GrailedLogo,EtsyLogo,DepopLogo,PoshmarkLogo,KidizenLogo,FacebookLogo,VestiaireLogo,MercaryLogo,ShopifyLogo,FaImage, PlatformIconShop} from "@assets/icons";
import {columns} from "@assets/constants/pickListColumns"
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

const imgPrefix = 'https://s3.amazonaws.com/listperfectly.orders/';

interface ITableRowProps {
    order_listing: any;
    index: number;
}

const DataSetRow = (props: ITableRowProps) => {
    const {order_listing} = props;
    const {bdecode} = useBasicFuncs();
    const isSmallScreenLaptop = UseIsSmallLaptop();
    const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces)

    const isArrayLengthGreaterThanOne = order_listing?.listings?.length > 1;
    const quantitySoldNumber = Number(order_listing?.quantity_sold || 0);
    const quantity_available = _.get(order_listing, 'catalog_metadata.851') || '-'; // TODO tmp solution.

    const getCustomMarketplaces = (code:string) => {
        let customMarketplaces:any;

        globalCustomMarketplaces?.forEach((customMarket:any) => {
            if(customMarket.platform.code[0] === code) {
                customMarketplaces = {
                    color:customMarket.color,
                    title:customMarket.platform.title,
                    platformId:customMarket.platform.platformId
                }
            }
        })

        return customMarketplaces
    }

    const customMarketplace = getCustomMarketplaces(order_listing && order_listing?.marketplace) ;

    const renderSoldOnCell = () => {
        const mk = order_listing?.marketplace?.toLowerCase();
        const iconsMap: Record<string, JSX.Element> = {
            ebay: <EbayLogo />,
            shopify: <ShopifyLogo />,
            poshmark: <PoshmarkLogo />,
            mercari: <MercaryLogo />,
            etsy: <EtsyLogo />,
            facebook: <FacebookLogo />,
            vestiare: <VestiaireLogo />,
            depop: <DepopLogo />,
            grailed: <GrailedLogo />,
            kidizen: <KidizenLogo />,
        };

        const customMarketplaceEl = customMarketplace ? (
         <div className={styles['customMarketplace']}>
             <div>
                 <div
                  style={{border: `1px solid ${customMarketplace?.color || '#000'}`,}}
                  className={styles['customMarketplaceItem']}>
                     <PlatformIconShop
                      width={isSmallScreenLaptop ? 16 : 20}
                      height={isSmallScreenLaptop ? 16 : 20}
                      fill={customMarketplace?.color || '#000'}
                     />
                 </div>
             </div>
             <div className={styles.customMarketplaceTitle}>{customMarketplace.title}</div>
         </div>
        ) : null;

        return (
         <>
             <div className={styles['platforms']}>
                 {iconsMap[mk!]}
             </div>
             {customMarketplaceEl}
         </>
        );
    };

    const renderTableCell = (col: any) => {
        const field = col.value;

        switch (field) {
            case 'image':
                return (
                 <td className={styles.thumbnailTd}>
                     <div className={styles.thumbnail}>
                         {order_listing?.thumbnail ? (
                          <img
                           src={
                               order_listing.thumbnail.includes('undefined')
                                ? defaultThumbnaiLPhoto
                                : imgPrefix + order_listing.thumbnail
                           }
                           alt="thumbnailPhoto"
                          />
                         ) : (<FaImage />)}

                         {(isArrayLengthGreaterThanOne || quantitySoldNumber > 1) && (
                          <div
                           className={styles['badge']}
                          >
                              <span>{order_listing.quantity_sold || ''}</span>
                          </div>
                         )}
                     </div>
                 </td>
                );

            case 'title':
                return (
                 <td>
                     <div className={styles.cellContent}>
                         {bdecode(order_listing[field]) || '-'}
                     </div>
                 </td>
                );

            case 'date_sold':
                return (
                 <td>
                     <div className={styles.cellContent}>
                         {order_listing[field]
                          ? moment(order_listing[field]).format('MM/DD/YYYY')
                          : '-'}
                     </div>
                 </td>
                );

            case 'sku':
                return (
                 <td>
                     <div className={styles.cellContent}>
                         {order_listing[field] || '-'}
                     </div>
                 </td>
                );

            case 'quantity_sold':
                return (
                 <td>
                     <div className={styles.cellContent}>
                         {order_listing[field] || '-'}
                     </div>
                 </td>
                );

            case 'quantity_available':
                return (
                 <td>
                     <div className={styles.cellContent}>{quantity_available}</div>
                 </td>
                );

            case 'sold_on':
                return <td>{renderSoldOnCell()}</td>;

            default:
                return (
                 <td>
                     <div className={styles.cellContent}>
                         {order_listing[field] || '-'}
                     </div>
                 </td>
                );
        }
    };

    return (
        <>
            <tr>
                <td>
                    <input
                        className={styles.myCheckbox}
                        type="checkbox"
                        checked={false}
                        name={order_listing.id}
                    />
                </td>
                {columns.map((col: any, i: number) => (
                 <React.Fragment key={i}>{renderTableCell(col)}</React.Fragment>
                ))}
            </tr>
        </>
    );
};
export default DataSetRow;
