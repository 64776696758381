import  {useEffect, useRef, useState} from 'react' ;
import {removeSubAccountAccessModalItems} from "@assets/constants/removeSubAccountAccessModalItems"
interface removeAccountAccessModalLogicProps {
	handleToggleRemoveAccountAccessModal : () => void;
}

export const RemoveAccountAccessModalLogic = (props:removeAccountAccessModalLogicProps) => {
	const {handleToggleRemoveAccountAccessModal} = props ;

	const [selectedAction, setSelectedAction] = useState<'detach' | 'delete'>('detach');

	const removeAccountAccessModalRef = useRef<HTMLDivElement | null>(null) ;

	const handleRadioChange = (action: 'detach' | 'delete') => {
		setSelectedAction(action);
	};

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
			 removeAccountAccessModalRef.current &&
			 !removeAccountAccessModalRef.current?.contains(event.target as Node)) {
				handleToggleRemoveAccountAccessModal();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [removeAccountAccessModalRef, handleToggleRemoveAccountAccessModal]);


	return {
		removeAccountAccessModalRef,removeSubAccountAccessModalItems,selectedAction,handleRadioChange
	}
}