import React, {useState} from "react"
import {Tab, TabList, Tabs,TabPanel} from "react-tabs";
import {useGlobalUploaderState } from "../hooks/hooks";
import UploaderModalMainLoader from "../components/UploaderModalMainLoader";
import {bucketUrl} from "../const/const";
import ModalFooter from "../components/ModalFooter";
import UploaderFilters from "../components/UploaderFilters";
import ModalBody from "../components/ModalBody";
import ImageUploaderHeader from "../components/ModalHeader";
import BackgroundRemoverColorPicker from "../components/BackgroundRemoverColorPicker";
import UploaderService from "../services";
import {getUniqueId, replaceSuffixInFilename} from "../common";
import {toast} from "react-toastify";

const ImageUploaderModal = ({handleImageEditComplete, hasSimple}: any) => {
    const {state, dispatch} = useGlobalUploaderState();
    const {activeStatus,activeFile,removeBackgroundStats: {total, available}, selected_remove_bg_color} = state;
    if (!activeFile) return <></>
    const {imagePreviewUrl,filename} = activeFile
    const removeBackground = async() => {
        if(available <= 0) {
            return toast.error('You have exceeded the monthly limit of background removals.');
        }
        dispatch({type: "MODALLOADERACTION", data:{status: true, text: 'Removing background...'}})
        try{
            await UploaderService.removeBackgroundByFilename(filename, selected_remove_bg_color);
            await UploaderService.fileGenerateSet(filename);
            const fileUrl = replaceSuffixInFilename(filename,'_LP', '_CI') + `?rnd=${getUniqueId()}`;
            const thumbnailUrl = replaceSuffixInFilename(filename,'_LP', '_TN') + `?rnd=${getUniqueId()}`;
            dispatch({type: "SETACTIVEFILE", data: {...activeFile, imageSmallPreviewUrl: bucketUrl + thumbnailUrl,imagePreviewUrl:  bucketUrl + fileUrl}})
            dispatch({type: "SETBACKGROUNDSTATS", data: {total, available: available - 1}})
        }catch (e:any){
            return toast.error(e.message);
        }
    }
    return <div className="image-uploader-background" >
        {
            <Tabs
                className="image-uploader-modal"
                selectedTabClassName="filter-tab--selected"
                selectedTabPanelClassName="filter-tab-panel--selected"
                onSelect={() => dispatch({type: "SETUPLOADERCROPVIEW", data: false})}
            >
                <ImageUploaderHeader hasSimple={hasSimple} />
                <UploaderModalMainLoader />
                <div className="image-uploader-body">
                    <div className="uploader-tabs-container">
                        <TabList className="filters-web-tab-list">
                            <Tab className="parameter-web-tab" selected key={"parameters"}>
                                <div className="parameters-web-tab disable-select">
                                    <div className="parameters-image-icon disable-select"></div>
                                    <span className="hide-in-mobile disable-select">  Tune </span>
                                </div>
                            </Tab>
                            {(activeStatus !== 0 && !hasSimple) && <Tab className="filter-web-tab disable-select" key={"filter"}>
                                <div className="filters-web-tab disable-select">
                                    <div className="filters-image-icon disable-select"></div>
                                    <span className="hide-in-mobile disable-select">  Filters </span>
                                </div>
                            </Tab>}
                            <Tab className="filter-web-tab disable-select" key={"photoroom"}>
                                <div className="filters-web-tab disable-select">
                                    <div className="photoroom-image-icon disable-select"></div>
                                    <span style={{textAlign: 'center'}}
                                          className="hide-in-mobile disable-select">  Remove <br/> Background </span>

                                </div>
                            </Tab>
                        </TabList>
                    </div>
                    <ModalBody handleImageEditComplete={handleImageEditComplete}/>
                </div>

                { imagePreviewUrl ? <div className="image-uploader-footer">

                    <TabPanel className="desktop-uploader-tab-panel" key={"parameters"}>
                        <ModalFooter hasSimple={hasSimple}/>
                    </TabPanel>

                    { (activeStatus !== 0 && !hasSimple) && <TabPanel className="desktop-uploader-tab-panel" key={"filters"}>
                        <UploaderFilters />
                    </TabPanel> }

                    <TabPanel className="desktop-uploader-tab-panel" key={"photoroom"}>
                        <div className={activeStatus === 2 ? "remove-background-tab-pro" : "remove-background-tab"} style={{width: 'calc(100% - 100px)', position: "relative", height: '100%', display: 'flex', alignItems: "center", justifyContent: 'center', flexDirection: 'column' }}>
                            <div className="remove-background-stats-container">

                                { total !== 0 && <span style={{marginBottom: activeStatus !== 2 ? 6 : 10}}>{available} of {total} available</span> }
                                <a href="#" style={{color: "gray", marginBottom: activeStatus !== 2 ? 6 : 10 }}  data-tooltip={`Each time you click the "Remove Background" button, 1 of the ${total} images (per calendar month) is used. Please click only once and let it load.`} onClick={(e) => e.preventDefault()} tabIndex={-1}
                                   className="custom-tooltip-big" >
                                    <i className="fa fa-question-circle"></i></a>
                            </div>
                            <div style={{marginLeft: 39,display: "flex",    alignItems: "center", flexDirection: "row"}}>
                                <button onClick={() => removeBackground()} disabled={+available === 0}  type="button" className="modal-body-btn confirm-remove-background upload-submit-btn  disable-select">Remove Background</button>
                                <BackgroundRemoverColorPicker  />
                            </div>
                            <a href="https://www.photoroom.com/" target="_blank"><div className="photoroom-banner"></div></a>
                        </div>
                    </TabPanel>
                </div> : null}

            </Tabs>
        }
    </div>
}

export default ImageUploaderModal;