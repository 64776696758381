import _ from 'lodash';
import axios from 'axios';

// Setup Axios client instance
const client = axios.create();

// Add a response interceptor
client.interceptors.response.use((response) => response, (error) => {

    // Init
    const status_code = _.get(error, 'response.status');

    // If Auth error received - add it to logs
    if([401, 403].includes(status_code)) console.error(error);

    // TODO implement 3 retries with exponential backoff on all requests @Konstantin.

    // Return error
    return Promise.reject(error);

});

// Export client instance
export default client;
