import React from 'react' ;
import styles from "./SubAccountTable.module.scss";

import Button, {ModifierType} from "../../../../components/UI/Button";
import {AddSolid, SettingsIconGearSvg, TrashCanDelete} from "@assets/icons";
import TableResizeColumn from "./components/TableResizeColumn";
import AddSubAccountModal from "./components/AddSubAccountModal";
import RemoveAccountAccessModal from "./components/RemoveAccountAccessModal";
import SettingsAccountModal from "./components/SettingsAccountModal";

interface ISubAccountTableUIProps {
	subAccounts:any;
	setIsOpenAddSubAccountModal:(isOpenAddSubAccountModal:boolean) => void;
	tableElementSubAccount:any;
	columns:any;
	mouseDown:(index: number) => void;
	activeIndex:number | null;
	isMobile:boolean;
	isOpenAddSubAccountModal:boolean;
	isOpenRemoveSubAccountAccessModal:boolean;
	isOpenSettingsAccountModal:boolean;
	subAccount:any;
	setSubAccount:(row:any) => void;
	setIsOpenSettingsAccountModal:(isOpenSettingsAccountModal:boolean) => void;
	setIsOpenRemoveSubAccountAccessModal:(isOpenRemoveSubAccountAccessModal:boolean) => void;
	handleToggleAddSubAccountModal:() => void;
	setSubAccounts:(subAccounts:any) => void;
	handleToggleRemoveAccountAccessModal:() => void;
	handleRemoveSubAccount:(action:'detach' | 'delete') =>void;
	handleToggleSettingsAccountModal:() => void;
	handleAddSubAccountButtonDisabledClick:() => void;
	subAccountLimit:number;
}

const SubAccountTableUI = (props:ISubAccountTableUIProps) => {
	const {subAccounts,setIsOpenAddSubAccountModal,tableElementSubAccount,columns,mouseDown,activeIndex,isMobile,isOpenAddSubAccountModal,isOpenRemoveSubAccountAccessModal,isOpenSettingsAccountModal,subAccount,setSubAccount,setIsOpenSettingsAccountModal,setIsOpenRemoveSubAccountAccessModal,handleToggleAddSubAccountModal,setSubAccounts,handleToggleRemoveAccountAccessModal,handleRemoveSubAccount,handleToggleSettingsAccountModal,handleAddSubAccountButtonDisabledClick,subAccountLimit} = props ;

	const renderTd = (col:any,row:any) => {

		const {email, username} = row?.metadata || {} ;

		if(col.value === 'username') {
			return <div className={`${styles['table__td-text']} ${styles['table__td-text--bold']}`}>{username || "-"}</div>
		} else if (col.value === 'email') {
			return <div className={styles['table__td-text']}>{email || "-"}</div>
		} else if (col.value === 'first_name') {
			return <div className={styles['table__td-text']}>{row?.first_name}</div>
		} else if (col.value === 'last_name') {
			return <div className={styles['table__td-text']}>{row?.last_name}</div>
		} else if(col.value === 'actions') {
			return <div className={styles['table__actions']}>
				<Button
				 modifier={ModifierType.Primary2}
				 handleClick={() => {
					 setSubAccount(row)
					 setIsOpenSettingsAccountModal(true)
				 }}
				>
					<SettingsIconGearSvg/>
					{!isMobile && <span>Settings</span>}
				</Button>
				<Button
				 modifier={ModifierType.ErrorNew}
				 handleClick={() =>{
					 setSubAccount(row)
					 setIsOpenRemoveSubAccountAccessModal(true)
				 }}
				>
					<TrashCanDelete/>
					{!isMobile && <span>Remove</span>}
				</Button>
			</div>
		}
	} ;

	return (
	 <>
		 <div className={styles.corporateTableUpBlock}>
			 <Button
			  modifier={ModifierType.Secondary}
			  handleClick={() => {
				  if(subAccounts?.length >= subAccountLimit || !subAccountLimit) handleAddSubAccountButtonDisabledClick()
				  else setIsOpenAddSubAccountModal(true)
			  }}
			  disabled={subAccounts?.length >= subAccountLimit || !subAccountLimit}
			 >
				 <AddSolid/>
				 <span>Add Sub Account</span>
			 </Button>

			 <div className={styles['corporateTableUpBlock__title--gray']}>{subAccounts?.length} out of {subAccountLimit} sub accounts in use.</div>

		 </div>

		 <div className={styles['subAccountTable']}>
			 <table
			  ref={tableElementSubAccount}
			  className={`resizable-table ${styles.subAccountTable__table}`}>
				 <thead className={styles['subAccountTable__thead']}>
				 <tr className={styles['subAccountTable__row']}>
					 {columns.map((col: any,i:number) => (
					  col.allowed && <TableResizeColumn  key={col.order} col={col} mouseDown={mouseDown} activeIndex={activeIndex} i={i}/>
					 ))}
				 </tr>

				 </thead>
				 <tbody className={styles['subAccountTable__tbody']}>
				 {subAccounts?.map((row: any) => (
				  <tr
				   key={row.id}
				   className={styles['subAccountTable__row']}>
					  {columns.map((col: any) => (
					   col.allowed && <td
						key={col.order}
						className={`${styles['subAccountTable__tbody-td']} ${col.value === 'actions' ? styles['subAccountTable__tbody-td--actions'] : ""}`}
					   >
						   {renderTd(col,row)}
					   </td>

					  ))}
				  </tr>
				 ))}
				 </tbody>
			 </table>
			 {!subAccounts.length && <div className={styles['subAccountTable__no_sub_account']}>No sub accounts have been added yet. Click <a onClick={() => {
				 if(subAccounts?.length >= subAccountLimit || !subAccountLimit) handleAddSubAccountButtonDisabledClick()
				 else setIsOpenAddSubAccountModal(true)
			 }} href={"#"}>here</a> to add one and get started! 🚀</div>}
		 </div>
		 {isMobile && <div className={styles['subAccountTable__paraqraph']}>To see the full table, access this page on your computer.</div>}
		 {isOpenAddSubAccountModal &&
		  <AddSubAccountModal handleToggleAddSubAccountModal={handleToggleAddSubAccountModal} setSubAccounts={setSubAccounts}/>}
		 {isOpenRemoveSubAccountAccessModal &&  <RemoveAccountAccessModal handleToggleRemoveAccountAccessModal={handleToggleRemoveAccountAccessModal} handleRemoveSubAccount={handleRemoveSubAccount}/>}
		 {isOpenSettingsAccountModal &&  <SettingsAccountModal handleToggleSettingsAccountModal={handleToggleSettingsAccountModal} subAccount={subAccount}/>}
	 </>
	)
}

export default SubAccountTableUI ;