import React,{useState} from 'react';
import {useGlobalUploaderState} from "../hooks/hooks";
import UploaderService from "../services";
import {bucketUrl} from "../const/const";
import {
    base64ToBlob,
    generateNewFilenameForOldImage,
    getUniqueId,
    loadImageToBase64,
    replaceSuffixInFilename,
} from "../common";
import {UploaderFile} from "../interfaces";

const ImageUploaderBulkActions = ({isValidFileAvailable}: {isValidFileAvailable: boolean}) => {
    const {state, dispatch} = useGlobalUploaderState();
    const {removeBackgroundStats,selected_remove_bg_color, selectedImageIndexes, files,activeStatus} = state;
    const [showSelection, setShowSelection] = useState(false);
    const [bulkType, setBulkType] = useState('');
    const [openBulkDropdown, setOpenBulkDropdown] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    
    const reuploadOldImageForBulk = (oldFilename: string,index: number,imagePreviewUrl: any) => {
        return  new Promise(async resolve => {
            const base64Image = imagePreviewUrl.startsWith('data:image') ? imagePreviewUrl : await loadImageToBase64(imagePreviewUrl);
            const {
                newImageFilename,
                newImageFilenameWithoutExtension,
                CI_filename
            } : {newImageFilename: string, CI_filename: string,newImageFilenameWithoutExtension: string}= generateNewFilenameForOldImage(oldFilename);
            UploaderService.getS3Policy(newImageFilename).then(async ({fields}: any) => {// @ts-ignore
                let file = base64ToBlob(base64Image);
                await UploaderService.uploadFileToS3(fields, file, newImageFilename);
                const {success} = await UploaderService.fileGenerateSet(newImageFilename);
                let s3Link = bucketUrl + CI_filename
                const fileLPId: number = await UploaderService.getImageIdFromAdminAjax(CI_filename)
                dispatch({
                    type: "SETNEWPROPERTYINFILE",
                    data: {
                        index, properties: {
                        isOldImage: false,
                        filenameWithoutExtension: newImageFilenameWithoutExtension,
                        filename: newImageFilename,
                        fileExtension: 'jpeg',
                        preview: false,
                        imagePreviewUrl: base64Image, 
                        fileS3Link: s3Link, 
                        lp_id: fileLPId
                    }}
                });
                resolve(newImageFilename)
            });
        })
    }
    
    const bulkRestoreOriginal  = async () => {
        const selectedIndexes  = [...selectedImageIndexes];
        updateSelectedImageIndexesArray([]);
        files.map(async ({filename,index, isOldImage, imagePreviewUrl}: UploaderFile) => {
            if (!selectedIndexes.includes(index)) return;
            dispatch({type: "SETNEWPROPERTYINFILE", data: { index: index, properties: {loading: true,imageSmallPreviewUrl: null }}});
            if(isOldImage){
                filename = await reuploadOldImageForBulk(filename,index,imagePreviewUrl);
            }
            const LP_filename: any = await UploaderService.restoreOriginalImageByFilename(filename);
            await UploaderService.fileGenerateSet(LP_filename);
            const fileUrl = replaceSuffixInFilename(filename,'_LP', '_CI') + `?rnd=${getUniqueId()}`;
            const thumbnailUrl = replaceSuffixInFilename(filename,'_LP', '_TN') + `?rnd=${getUniqueId()}`;
            dispatch({type: "SETNEWPROPERTYINFILE", data: { index: index, properties:
                        {
                            loading: false,
                            imageSmallPreviewUrl: bucketUrl + thumbnailUrl,
                            imagePreviewUrl:  bucketUrl + fileUrl
                        }
                }});
        })
    }
    const bulkBackgroundRemove = async () => {
        const selectedIndexes  = [...selectedImageIndexes];
        updateSelectedImageIndexesArray([]);
        files.map(async ({filename,index,isOldImage,imagePreviewUrl}: UploaderFile) => {
            if (!selectedIndexes.includes(index)) return;
            dispatch({type: "SETNEWPROPERTYINFILE", data: { index: index, properties: {loading: true, imageSmallPreviewUrl: null }}});
            if(isOldImage){
                filename = await reuploadOldImageForBulk(filename,index,imagePreviewUrl);
            }
            await UploaderService.removeBackgroundByFilename(filename, selected_remove_bg_color);
            await UploaderService.fileGenerateSet(filename);
            const fileUrl = replaceSuffixInFilename(filename,'_LP', '_CI') + `?rnd=${getUniqueId()}`;
            const thumbnailUrl = replaceSuffixInFilename(filename,'_LP', '_TN') + `?rnd=${getUniqueId()}`;
            dispatch({type: "SETNEWPROPERTYINFILE", data: { index: index, properties:
                        {
                            loading: false,
                            imageSmallPreviewUrl: bucketUrl + thumbnailUrl,
                            imagePreviewUrl:  bucketUrl + fileUrl
                        }
                }});
        })
    }

    const updateSelectedImageIndexesArray = (indexes: Array<number>) => {
        dispatch({type:'UPDATESELECTEDIMAGEINDEXES', data: indexes})
    }
    const  selectAllImages = (checked: boolean) => {
        let indexList: any = [];
        if (checked){
            files.map(({index, error, placeholder}) => !error && !placeholder && indexList.push(index))
        }
        updateSelectedImageIndexesArray(indexList);
        setSelectAll(checked)
    }
    return (
        <div className={showSelection ? "image-uploader-actions selection-opened" : "image-uploader-actions"}>
            {(!showSelection && activeStatus !== 0) && <div className="image-uploader-actions-item">
                <div onClick={(e) => e.preventDefault()}
                     className={openBulkDropdown ? "listperfectly-dropdown-button opened" : "listperfectly-dropdown-button"}>
                    <div onClick={() => {
                        setOpenBulkDropdown(!openBulkDropdown)
                    }} className="image-uploader-actions-item-btn-container">
                        <div className="btn-icon-container">
                            <div className="bulk-icon"></div>
                            <span>
                                Bulk Actions
                            </span>
                        </div>
                        <div className="dropdown-icon"></div>
                    </div>
                </div>
                <div
                    className={openBulkDropdown ? "listperfectly-dropdown-content opened" : "listperfectly-dropdown-content"}>
                    <div
                        onClick={() => {
                            setShowSelection(true)
                            setOpenBulkDropdown(false)
                            setBulkType('removeBackground')
                            dispatch({type: "SHOWIMAGESELECTION", data: true})
                        }}
                        className={!isValidFileAvailable ? "listperfectly-dropdown-content-item disabled" : "listperfectly-dropdown-content-item"}>
                        <button className="listperfectly-btn-icon">
                            <div className="photoroom-dark-blue-image-icon bulk-action-icon"></div>
                            <span>Remove Backgrounds </span>
                        </button>
                        <a
                            style={{
                                display: "flex",
                                justifyContent: 'center',
                                position: 'relative',
                                top: -6
                            }}>
                            <div className="photoroom-banner photoroom-banner-bulk-actions photoroom-logo-first-part"
                                 style={{margin: 0}}></div>
                            <div className="photoroom-banner photoroom-banner-bulk-actions photoroom-logo-second-part"
                                 style={{margin: 0}}></div>
                        </a>
                        <span
                            className="background-limit-stats">{removeBackgroundStats.available} of {removeBackgroundStats.total} available</span>
                    </div>
                    <div
                        className={!isValidFileAvailable ? "listperfectly-dropdown-content-item disabled" : "listperfectly-dropdown-content-item"}
                        onClick={() => {
                            setShowSelection(true)
                            setOpenBulkDropdown(false)
                            setBulkType('restoreOriginal')
                            dispatch({type: "SHOWIMAGESELECTION", data: true})
                        }}
                        >   
                        <button className="listperfectly-btn-icon " >
                            <div className="bulk-restore-button-icon bulk-action-icon"></div>
                            <span>Restore Images </span>
                        </button>
                    </div>

                </div>
            </div>}
            {
                showSelection && <>
                    <div className="listperfectly-checkbox">
                        <label htmlFor="allImagesSelect">Select All</label>
                        <input checked={selectAll} onChange={() => selectAllImages(!selectAll)} type="checkbox"
                               id="allImagesSelect"/>
                        <span className="listperfectly-checkbox-checkmark"></span>

                    </div>

                    <button onClick={() => {
                        bulkType === 'removeBackground' ? bulkBackgroundRemove().then() : bulkRestoreOriginal().then()
                        setShowSelection(false);
                        setSelectAll(false);
                        dispatch({type: "SHOWIMAGESELECTION", data: false});
                    }} className="listperfectly-btn" disabled={selectedImageIndexes.length === 0} style={{marginLeft: 10}}>
                        Confirm
                    </button>


                    <button className="listperfectly-btn" onClick={() => {
                        setShowSelection(false);
                        setSelectAll(false)
                        updateSelectedImageIndexesArray([])
                        dispatch({type: "SHOWIMAGESELECTION", data: false})
                    }} style={{marginLeft: 10}}>
                        Cancel
                    </button>
                </>
            }
        </div>
    );
};

export default ImageUploaderBulkActions;