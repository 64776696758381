import {useEffect} from 'react';

import styles from './DataSetTable.module.scss';
import './print.css';

import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {MoonLoader} from 'react-spinners';

import {CloseSvg} from '@assets/icons';
import {setHandleStopPrint} from '../../redux/slices/NavigationSlice';
import DataSetRow from './DataSetRow';
import Button, {ModifierType} from "../../components/UI/Button";
import {columns} from "@assets/constants/pickListColumns";

// Pick List
function DataSetTable() {
  const dataForPrint = useSelector((state: any) => state.navigation.dataForPrint);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePrint = () => {
    setTimeout(() => {
      if (dataForPrint?.length) window.print();
    }, 1000);
  };

  useEffect(() => {
    if (!dataForPrint?.length) {
      navigate('/sales');
    } else {
      handlePrint();
    }
  }, []);

  const handleClose = () => {
    dispatch(setHandleStopPrint());
    navigate('/sales');
  };

  return (
    <div className={styles.dataSetContainer}>
      <div className={styles.printHeader}>
        <Button handleClick={handlePrint} modifier={ModifierType.Primary} >Print</Button>
        <div
          className={styles.closeIcon}
          id="closePrint"
          onClick={handleClose}
        >
          <CloseSvg />
        </div>
      </div>
      {dataForPrint?.length ? (
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <th
               className={styles['emptyHeadCell']}></th>
              {columns?.map((col: any, i: any) => (
               <th className={col.value !== 'title' ? styles.thMaxWidth : ""}>
                 <div className={styles.cellContent}>{col.heading}</div>
               </th>
              ))}
            </thead>
            <tbody>
            {dataForPrint?.map((item: any, index: number) => (
             <DataSetRow
              key={index}
              order_listing={item}
              index={index}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div
         className={styles['loaderWrapper']}
        >
          <MoonLoader color="#1e3166" loading={true} />
          <div>Generate Table For Print</div>
        </div>
      )}
    </div>
  );
}

export default DataSetTable;
