import moment from 'moment';
import _ from 'lodash';
import convertToFormattedString from '../convertToFormattedString';

export const tableDataTransform = (orders: any) => {

	return orders.map((order: any) => {

		const order_listing_catalog_metadata = _.get(order, 'listings[0][catalog_metadata]');
		const field_order_listings = _.get(order, 'listings') || [];

		// Handle fields
		let field_cogs = _.get(field_order_listings, '[0].cogs');

		// TODO total_sold_price is temporary exception, need to find a way to replace it with just total_price
		let field_total_sold_price = _.get(order, 'total_price');


		const field_catalog_id = _.get(field_order_listings, '[0].catalog_id');

		let field_sku = _.get(field_order_listings, '[0].sku');
		if(!field_sku) field_sku = null; // TODO temporary measure to keep it consistent with the old code

		let field_quantity_sold = _.get(field_order_listings, '[0].quantity_sold');
		// if(!_.isNumber(field_quantity_sold)) field_quantity_sold = 1; // Set 1 to be default value

		// Fetches data from the linked listing
		let field_quantity_available = _.get(order_listing_catalog_metadata, '851') || null;

		// Get order listings and type
		const order_listings = _.get(order, 'listings') || [];
		const order_type = order_listings.length > 1 ? 'multi' : 'single';

		// Calculate profit
		let order_profit = 0;

		// Handle certain properties
		const order_shipping_status = _.get(order, 'shipping_status') || '';

		const order_sales_tax = _.get(order, 'sales_tax') || 0;
		const order_total_price = _.round( (_.get(order, 'total_price') || 0), 2);
		const order_fees = _.get(order, 'fees') || 0;
		const order_shipping_cost_seller = _.get(order, 'shipping_cost_seller') || 0;

		// TODO move this to the 1 component later so all computations to be performed there.
		// TODO I duplicated this code to utils. The plan here is the following:
		// TODO when you'll refactor it, you should de-couple structural part from presentational.
		// TODO so when we'll send it to the backend it to not require ommiting presentational properties
		// TODO every time, instead of them we can put them all into 1 prop, like additional_properties etc.
		// Handle COGS field
		let order_computed_cogs = 0;
		order_listings.forEach((order_listing : any) => {
			const order_listing_cogs = _.get(order_listing, 'cogs') || 0;
			order_computed_cogs += order_listing_cogs;
		});

		// Exclude canceled
		if(order_shipping_status !== 'canceled') order_profit = _.round( (order_total_price - order_fees - order_sales_tax - order_computed_cogs - order_shipping_cost_seller), 2);



		return {
			id: order.id,
			thumbnail: 'https://s3.amazonaws.com/listperfectly.orders/' + _.get(field_order_listings, '[0].thumbnail'),
			title: field_order_listings.length >= 1 && { long: _.get(field_order_listings, '[0].title') },
			total_price: field_total_sold_price,
			date_sold: moment(order?.date_sold).format('lll'),
			sold_on: `https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/${order.marketplace}.svg`,
			shop_id: _.get(order, 'shop_id'),
			marketplaces: _.get(order, 'marketplace'), // TODO looks like a wrong naming, replace singular form.
			marketplace: _.get(order, 'marketplace'),
			listings: field_order_listings,
			catalog_id: field_catalog_id,
			listing_id: _.get(field_order_listings, '[0].listing_id'),
			shipping_cost_buyer: _.get(order, 'shipping_cost_buyer'),
			shipping_cost_seller: _.get(order, 'shipping_cost_seller'),
			cogs: field_cogs,
			sales_tax: _.get(order, 'sales_tax'),
			shipping_status: convertToFormattedString( _.get(order, 'shipping_status') ),
			sku: field_sku,
			catalog_metadata: order_listing_catalog_metadata,
			order_id: _.get(order, 'order_id'),
			quantity_sold: field_quantity_sold,
			quantity_available: field_quantity_available,
			fees: _.get(order, 'fees'),
			status: _.get(order, 'status'),
			additional_properties: {
				profit: order_profit,
				type: order_type,
				cogs: order_computed_cogs
			}
		};
	});
};
