import React from 'react';
import styles from '../ShippingLabelModal.module.scss';
import {DropdownArrowPdf} from "@assets/icons";

interface DropdownMenuProps {
    selectedFormat: string;
    onSelectFormat: (value: string) => void;
}

const options = [
    { value: "2x1 Shipping Label Setting", label: "2x1 Shipping Label Setting", subtitle: "One 2x1 Shipping label" },
    { value: "1x2 Shipping Label Setting", label: "1x2 Shipping Label Setting", subtitle: "One 1x2 Shipping label" },
    { value: "2x2 Shipping Label Setting", label: "2x2 Shipping Label Setting", subtitle: "One 2x2 Shipping label" },
    { value: "2x3 Shipping Label Setting", label: "2x3 Shipping Label Setting", subtitle: "One 2x3 Shipping label" },
    { value: "3x2 Shipping Label Setting", label: "3x2 Shipping Label Setting", subtitle: "One 3x2 Shipping label" },
    { value: "2.5x4 Shipping Label Setting", label: "2.5x4 Shipping Label Setting", subtitle: "One 2.5x4 Shipping label" },
    { value: "4x2.5 Shipping Label Setting", label: "4x2.5 Shipping Label Setting", subtitle: "One 4x2.5 Shipping label" },
    { value: "3x5 Shipping Label", label: "3x5 Shipping Label", subtitle: "For smaller 3x5 labels" },
    { value: "5x3 Shipping Label", label: "5x3 Shipping Label", subtitle: "For smaller 5x3 labels" },
    { value: "4x6 Shipping Label", label: "4x6 Shipping Label", subtitle: "Formatted for thermal label printers" },
    // { value: "8.5x11” - 2 Shipping Labels per Page", label: "8.5x11” - 2 Shipping Labels per Page", subtitle: "Two 4x6” labels per letter-sized page" },
    // { value: "8.5x11” - 1 Shipping Label per Page - Left Side", label: "8.5x11” - 1 Shipping Label per Page - Left Side", subtitle: "One 4x6” label on the left side of a letter-sized page" },
    // { value: "8.5x11” - 1 Shipping Label per Page - Right Side", label: "8.5x11” - 1 Shipping Label per Page - Right Side", subtitle: "One 4x6” label on the right side of a letter-sized page" },
];


const DropdownMenu: React.FC<DropdownMenuProps> = ({ selectedFormat, onSelectFormat }) => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const handleSelect = (value: string) => {
        onSelectFormat(value);
        setDropdownOpen(false);
    };

    // Filter out the selected option from the dropdown
    const filteredOptions = options.filter(option => option.value !== selectedFormat);

    // Find the selected option to get its subtitle
    const selectedOption = options.find(option => option.value === selectedFormat);

    return (
        <div className={styles.dropdown}>
            <div
                className={`${styles.dropdownToggle} ${dropdownOpen ? styles.active : ''}`}
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                <p className={styles.title}>{selectedOption ? selectedOption.label : selectedFormat}</p>
                <p className={styles.subtitle}>{selectedOption ? selectedOption.subtitle : ''}</p>
                <div className={`${styles.arrowDropdown} ${dropdownOpen ? styles.arrowDropdownActive : ''}`}> <DropdownArrowPdf /></div>
            </div>
            {dropdownOpen && (
                <div className={styles.dropdownMenu}>
                    {filteredOptions.map(option => (
                        <div
                            key={option.value}
                            className={styles.dropdownItem}
                            onClick={() => handleSelect(option.value)}
                        >
                            <p className={styles.title}>{option.label}</p>
                            <p className={styles.subtitle}>{option.subtitle}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;