import React, {useEffect, useMemo, useState} from "react";

import styles from './SettingsPage.module.scss';
import 'react-toastify/dist/ReactToastify.css';

import { setSettingsActiveIndex } from '../../redux/slices/SettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {Helmet} from "react-helmet";
import { MoonLoader } from 'react-spinners';
import _, {debounce} from "lodash";
import {toast} from "react-toastify";
import Tippy from "@tippyjs/react";
import {useNavigate, useLocation as useReactRouterLocation} from 'react-router-dom';
import './components/ExtensionContent/tippy.css';

import SettingsHeader from "./components/SettingsHeaders";
import ExtensionTabs from "./components/ExtensionTabs";
import ExtensionContent from "./components/ExtensionContent";
import {instanceAxios} from "@services/index";

import {PlatformIconShop, PopoverIcon, SpaceIcon, UnderscoreIcon} from "@assets/icons";
import CustomMarketplaces from "./components/CustomMarketPlaces";
import Iframe from "../../components/UI/Iframe";
import {InputCount} from "../../components/ExpressLister/components/InputCount";
import CustomSkuPrefix from "./components/CustomSkuPrefix";
import CustomSkuFormat from "./components/CustomSkuFormat";
import SkuLocations from "./components/SkuLocations";
import UniqueIdFormat  from "./components/UniqueIdFormat";
import convertToFormattedString from "../../utils/convertToFormattedString";
import useRoles from "@hooks/useRoles";
import Auth from "@services/Auth";
import UserConfigs from "@services/UserConfigs";
import SubAccountTable from "./components/SubAccountTable";

interface ISettingsState {
	settings: {
		settingsActiveIndex: number | null;
	};
}
interface NavigationState {
	active: boolean;
	editModalShow: boolean;
	showAddlistingIframe: boolean;
	showAddTemplateIframe: boolean;
	showAddlistingIframeQuickLink: boolean;
	zIndexGenerator: number[];
	isBulkEdit: boolean;
	saved: boolean;
	zIndex: number;
	showIframe: boolean;
	iframeLink: string;
	iframeLoad: boolean;
	showModalQuestion: boolean;
	candidatIframeLink: string;
	showNotificationModal: boolean;
	showRedirectModal: boolean;
	isMobile: boolean;
}
interface RootState {
	navigation: NavigationState;
}
const SettingsPage = () => {
	const dispatch = useDispatch();
	const [customMarketplaces, setCustomMarketplaces] = useState<any>([]);
	const notificationChanged = false;
	const [loading, setLoading] = useState(true);
	const settingsActiveIndex = useSelector(
	 (state: ISettingsState) => state.settings.settingsActiveIndex
	);
	const [zipOrCityCode, setZipOrCityCode] = useState<any>('')
	const [zipChanged, setZipChanged] = useState(false)
	const [subAccountLimit, setSubAccountLimit] = useState<number>(0);

	const {roles,is_role_allowed_master,is_role_allowed,  hasBusiness, hasSimple, hasPro } = useRoles();
	const token: any = Auth.get_token();
	const navigate = useNavigate();
	const location = useReactRouterLocation();
	const [isFocused, setIsFocused] = useState(false);
	const isMobile = useSelector((state: RootState) => state.navigation.isMobile);
	const [configID, setConfigID] = useState("");
	const [configIDExtension, setConfigIDExtension] = useState(false);
	const [descriptionChanged, setDescriptionChanged] = useState(false)
	const [data, setData] = useState<any>(null)
	const [bodyAtribute, setBodyAtribute] = useState<any>(null);
	const[tabsData , setTabsData] = useState<any>(
	 [
		 {
			 name: "General",
			 tabChosen: true,
			 properties : {
				 checkboxes:[
					 {
						 propId: 'dontSaveOnExportToLP',
						 title: "Automatically save listing when imported to List Perfectly",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Requires manual saving of listings after import, allowing for edits before finalization.',
						 popoverDisabled: 'Disabled: Automatically saves listings to your catalog upon import.'
					 },
					 {
						 propId: 'keepTabsOnCopyTo',
						 title: "Leave edit tabs open when copying listings",
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Keeps the edit window open for immediate adjustments.',
						 popoverDisabled: 'Disabled: Closes the edit tab automatically during cross-posting.'
					 },
					 {
						 propId: 'showCopiedStatus',
						 title: "Show link tool and copied status",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Displays the options on active listings. ',
						 popoverDisabled: 'Disabled: Hides link tool and copied status.'
					 },
					 {
						 propId: 'modifyImagesBeforeUpload',
						 title: "Modify images before upload",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Assists in modifying images during upload to meet marketplace requirements and prevent unwanted cropping.  Keep this setting on to ensure images are appropriately adjusted for each marketplace\'s format, especially beneficial for images with unique dimensions like tall photographs.',
						 popoverDisabled: ''
					 },
					 {
						 propId: 'showCopyFromToButtons',
						 title: "Show crosspost icons on marketplace",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Displays cross-posting icons for easy access.',
						 popoverDisabled: 'Disabled: Hides the icons if preferred.'
					 },
					 {
						 propId: 'confirmationMessages',
						 title: "Confirmation Messages",
						 checked:true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Displays confirmation messages post-cross-posting and listing completions, alerting users to success or issues.',
						 popoverDisabled: ''
					 },
					 {
						 propId: 'prohibitedWordsDetector',
						 title: "Word alert",
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Allows for personalized alerts based on specified keywords to prevent potential issues across different marketplaces.',
						 popoverDisabled: ''
					 }
				 ],
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false
				 },
			 }
		 },
		 {
			 name: "Poshmark",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 propId: 'poshmarkSetSoldInsteadOfDeleting',
						 title: 'Set as “Not For Sale” when ending listings on Poshmark',
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: When marking sold or ending listings on Poshmark, they will be set as "not for sale" vs deleting on Poshmark',
						 popoverDisabled: 'Disabled: Linked listings will be deleted off Poshmark when ending.'
					 },
					 {
						 propId: 'poshmarkAddToBoutique',
						 title: 'Add to your boutique (for new items only)',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During the crosspost, "Add to boutique" will always be selected',
						 popoverDisabled: 'Disabled: During the crosspost no selection is made.'
					 }
				 ],
				 discount: {
					 propId: 'poshmarkSetDiscountedShipping',
					 value: '',
					 changed: false,
					 options: [
						 {label:'', value: 'null'},
						 {label:'No Discount', value: 'No Discount'},
						 {label:'$5.95', value: '$5.95'},
						 {label:'$4.99', value: '$4.99'},
						 {label:'FREE', value: 'FREE'},
					 ],
					 disabled: false,
					 popoverEnabled: 'When selection is made here it will result in every crosspost applying that selection to discounted shipping.',
					 popoverDisabled: ''
				 },
				 shopId1: {
					 propId: 'poshmarkShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'poshmarkShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: 'poshmarkSetProhibitedWords',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Poshmark only.',
					 popoverDisabled: ''
				 }
			 },
		 },
		 {
			 name: "Shopify",
			 tabChosen: false,
			 properties: {
				 checkboxes: [
					 {
						 propId: 'shopifySetGetTags',
						 title: 'Crosspost / Import Shopify tags',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During a crosspost tags will be gathered and copied to Shopify.  During an import, tags will be gathered and copied to List Perfectly.',
						 popoverDisabled: 'Disabled: No tags will be gathered or copied to Shopify duing a crosspost or imported to List Perfeclty.'
					 },
					 {
						 propId: 'shopifySetSoldInsteadOfDeleting',
						 title: 'Set “archived” on Shopify when marking sold',
						 checked: false,
						 changed: false,
						 disabled: false
					 }
				 ],
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Shopify only.',
					 popoverDisabled: ''
				 },
				 shopName: {
					 propId: 'shopifyName',
					 value: '',
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Add your Shopify shop name to link your Shopify shop to your List Perfectly extension for crossposting.',
					 popoverDisabled: ''
				 },
			 },

		 },
		 {
			 name: "Instagram",
			 tabChosen: false,
			 properties: {
				 checkboxes: [
					 {
						 propId: 'instagramNotIgnoreOnDelete',
						 title: 'Remove linked Instagram post when marking SOLD / End listing',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Linked Instagram posts will be removed from your IG account\n',
						 popoverDisabled: 'Disabled: Linked IG posts will remain even when ending / marking sold from your catalog.'
					 },
				 ],
				 instaDescSeparatorValue: {
					 propId: 'instagramDescriptionSeparator',
					 value: '',
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'When you Crosspost to Instagram, List Perfect converts your tags or keywords into #hashtags and separates these #hashtags from your description with the format of 4 • symbols. These • symbols help to optimize your Instagram post by “hiding” your #hashtags away from the description which is optimal to the Instagram viewer.',
					 popoverDisabled: ''
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Instagram only.',
					 popoverDisabled: ''
				 },

			 },

		 },
		 {
			 name: "Etsy",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 title: 'Alternate copy to',
						 propId: 'etsyAlternativeCopy',
						 checked: false,
						 tippyContent: 'Tippy content Etsy Alternate copy to',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled:Activated as a troubleshooting step if you are experiencing an issue with gathering item IDs after copying to Etsy. Enable if instructed to from LP support. ',
						 popoverDisabled: ''
					 },
					 {
						 title: 'Use new listing form',
						 propId:'etsyUseNewLinks',
						 checked: true,
						 tippyContent: 'Tippy content Etsy Use new listing form',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During the crosspost the new Etsy listing form will be utilized',
						 popoverDisabled: 'Disabled: During the crosspost the original Etsy listing form will be used'

					 },
					 {
						 title: 'Add core details',
						 propId: 'etsyCore',
						 checked: false,
						 tippyContent: 'Tippy content Etsy Add core details',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'These are core details that Etsy requires on every listing. If you are selling similar products then set these ONE time and they will ALWAYS be set when crossposting to Etsy.',
						 popoverDisabled: ''
					 }
				 ],
				 shopId1: {
					 propId: 'etsyShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'etsyShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 itemType: {
					 propId: 'etsyCoreDetailsType',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'Physical item', value: 'Physical item'},
						 {label:'Digital files', value: 'Digital files'},
					 ],
					 disabled: false
				 },
				 whoMaid: {
					 propId: 'etsyCoreWhoMade',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'I did', value: 'I did'},
						 {label:'A member of my shop', value: 'A member of my shop'},
						 {label:'Another company or person', value: 'Another company or person'},
					 ],
					 disabled: false
				 },
				 whatIsIt: {
					 propId: 'etsyCoreWhatIsIt',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'A Finished product', value: 'A finished product'},
						 {label:'A Supply or tool to make things', value: 'A supply or tool to make things'},
					 ],
					 disabled: false
				 },
				 whenWasItMade: {
					 propId: 'etsyCoreWhenItMade',
					 value: {label:'', value: ''},
					 changed: false,
					 options: [
						 {label:'', value: ''},
						 {label:'Made to order', value: 'Made to order'},
						 {label:'2020-2024', value: '2020-2024'},
						 {label:'2010-2019', value: '2010-2019'},
						 {label:'2005-2009', value: '2005-2009'},
						 {label:'Before 2005', value: 'Before 2005'},
						 {label:'2000-2004', value: '2000-2004'},
						 {label:'1990s', value: '1990s'},
						 {label:'1980s', value: '1980s'},
						 {label:'1970s', value: '1970s'},
						 {label:'1960s', value: '1960s'},
						 {label:'1950s', value: '1950s'},
						 {label:'1940s', value: '1940s'},
						 {label:'1930s', value: '1930s'},
						 {label:'1920s', value: '1920s'},
						 {label:'1910s', value: '1910s'},
						 {label:'1900-1909', value: '1900-1909'},
					 ],
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Etsy only.',
					 popoverDisabled: ''
				 },

			 },
		 },
		 {
			 name: "eBay",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 title: 'Enable Sell Similar on eBay',
						 propId: 'ebayPauseAtDraftSection',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: When crossposting to eBay from the crosspost will pause during the process to allow you to review and select a similar listing from the eBay catalog so you can take advantage these listings’ item specifics and other details that are already saved to speed up listing. ',
						 popoverDisabled: 'Disabled: No change to standard crossposting process to eBay.'
					 },
					 {
						 title: 'Alternate Image Upload',
						 propId:'ebayAlternativeImageUpload',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enable to utilize eBay’s “Upload from web” option when uploading photos.',
						 popoverDisabled: ''
					 },
					 {
						 title: 'Alternate ebay fill form flow',
						 propId:'ebayAlternativeFlow',
						 checked: true,
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: Enable only if instructed to by support. This is used to troubleshoot unique issues copying to eBay.',
						 popoverDisabled: 'Disabled: No change to standard crossposting process to eBay.'
					 },
					 {
						 title: 'Fast Image Upload',
						 propId:'ebayImportFromWeb',
						 checked: false,
						 changed: false,
						 disabled: false,
						 popoverEnabled : 'Enabled:  Enable only if instructed to by support. This is used to troubleshoot unique issues copying to eBay.',
						 popoverDisabled: 'Disabled: No change to standard crossposting process to eBay.'
					 },
				 ],
				 shopId1: {
					 propId: 'ebayShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'ebayShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to eBay only.',
					 popoverDisabled: ''
				 },
			 },

		 },
		 {
			 name: "Mercari",
			 tabChosen: false,
			 properties: {
				 checkboxes:[
					 {
						 propId: 'mercariSetProhibitedWords',
						 title: 'Override Mercari Automatic Suggestions',
						 checked: true,
						 tippyContent: 'Tippy content Override Mercari Automatic Suggestions',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled (recommended): LP will copy your details from your listing and override the suggestions made by Mercari based on uploaded photos.',
						 popoverDisabled: 'Disabled: Mercari’s suggestions and added details will remain added to your new listing based on uploaded photos. '
					 },
					 {
						 propId: 'mercariSmartPriceOff',
						 title: 'Turn off Smart Pricing feature on Mercari',
						 checked: true,
						 tippyContent: 'Tippy content Turn off Smart Pricing feature on Mercari',
						 changed: false,
						 disabled: false,
						 popoverEnabled: 'Enabled: During the crosspost the smart pricing option will be turned off.',
						 popoverDisabled: 'Disabled: Disabled will result in the default Mercari suggestion being applied for the smart pricing option'
					 }
				 ],
				 shopId1: {
					 propId: 'mercariShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'mercariShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Mercari only.',
					 popoverDisabled: ''
				 },
			 },

		 },
		 {
			 name: "Facebook",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'facebookShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'facebookShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Facebook only.',
					 popoverDisabled: ''
				 },
			 }
		 },
		 {
			 name: "Grailed",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'grailedShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'grailedShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Grailed only.',
					 popoverDisabled: ''
				 },
			 }
		 },
		 {
			 name: "Depop",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'depopShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'depopShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false,
					 popoverEnabled: 'Words added here will be detected in the description during the crosspost to Depop only.',
					 popoverDisabled: ''
				 },
			 }
		 },
		 {
			 name: "Kidizen",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'kidizenShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'kidizenShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 wordAlertValue: {
					 propId: '',
					 id: '',
					 value: [],
					 changed: false,
					 disabled: false
				 },
			 }
		 },
		 {
			 name: "Vestiaire",
			 tabChosen: false,
			 properties: {
				 shopId1: {
					 propId: 'vestiaireShopId1',
					 value: '',
					 changed: false,
					 disabled: false
				 },
				 shopId2: {
					 propId: 'vestiaireShopId2',
					 value: '',
					 changed: false,
					 disabled: false
				 },
			 }
		 },
	 ].map((tab: any) => {
		 if (hasSimple) {

			 if (tab.name === "Poshmark") {

				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'poshmarkAddToBoutique') {
						 checkbox.disabled = true;
					 }
				 });
				 if(tab.properties.discount?.propId === 'poshmarkSetDiscountedShipping') {
					 tab.properties.discount.disabled = true;
				 }
				 if(tab.properties.wordAlertValue?.propId === 'poshmarkSetProhibitedWords') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'Shopify') {
				 if(tab.properties.shopName?.propId === 'shopifyName') {
					 tab.properties.shopName.disabled = true;
				 }
				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'shopifySetGetTags') {
						 checkbox.disabled = true;
					 }
				 });
				 if(tab.properties.wordAlertValue?.propId === '') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'General'){
				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'prohibitedWordsDetector') {
						 checkbox.disabled = true;
					 }
				 });
			 }
			 if(tab.name === 'Instagram') {
				 if(tab.properties.instaDescSeparatorValue?.propId === 'instagramDescriptionSeparator') {
					 tab.properties.instaDescSeparatorValue.disabled = true;
				 }
				 if(tab.properties.wordAlertValue?.propId === '') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'Etsy' || tab.name === 'eBay' || tab.name === 'Mercari' || tab.name === 'Facebook' || tab.name === 'Grailed' || tab.name === 'Depop' || tab.name === 'Kidizen') {
				 if(tab.properties.wordAlertValue) {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
		 }
		 else if(hasBusiness){
			 if (tab.name === "Poshmark") {

				 tab.properties.checkboxes.forEach((checkbox:any) => {
					 if(checkbox.propId === 'poshmarkAddToBoutique') {
						 checkbox.disabled = true;
					 }
				 });
				 if(tab.properties.discount?.propId === 'poshmarkSetDiscountedShipping') {
					 tab.properties.discount.disabled = true;
				 }
				 if(tab.properties.wordAlertValue?.propId === 'poshmarkSetProhibitedWords') {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }
			 if(tab.name === 'General'){
				 tab.properties.checkboxes?.forEach((checkbox:any) => {
					 if(checkbox.propId === 'prohibitedWordsDetector') {
						 checkbox.disabled = true;
					 }
				 });
			 }
			 if(tab.name === 'Etsy' || tab.name === 'eBay' || tab.name === 'Instagram' || tab.name === 'Mercari' || tab.name === 'Facebook' || tab.name === 'Grailed' || tab.name === 'Depop' || tab.name === 'Kidizen') {
				 if(tab.properties.wordAlertValue) {
					 tab.properties.wordAlertValue.disabled = true;
				 }
			 }

		 }
		 return tab;
	 })
	)

	const [notificationSettings, setNotificationSettings] = useState({
		title: 'Disable support response notifications',
		checked: false
	})
	const [websiteSettings, setWebsiteSettings] = useState<any>({
		descriptions: [
			{propdId: 'mepr_ebay_description', name: 'eBay Description', value: ''},
			{propdId: 'mepr_poshmark_description', name: 'Poshmark Description', value: ''},
			{propdId: 'mepr_mercari_description', name: 'Mercari Description', value: ''},
			{propdId: 'mepr_etsy_description', name: 'Etsy Description', value: ''},
			{propdId: 'mepr_facebookmarketplace_description', name: 'Facebook Marketplace Description', value: ''},
			{propdId: 'mepr_shopify_description', name: 'Shopify Description', value: ''},
			{propdId: 'mepr_depop_description', name: 'Depop Description', value: ''},
			{propdId: 'mepr_kidizen_description', name: 'Kidizen Description', value: ''},
			{propdId: 'mepr_grailed_description', name: 'Grailed Description', value: ''},
			{propdId: 'mepr_instagram_description', name: 'Instagram Description', value: ''},
			{propdId: 'mepr_vestiaire_description', name: 'Vestiaire Description', value: ''},
		],
		filters: [
			{name: "All Listings", checked: false, propdId: 'mepr_default_filter'},
			{name: "Not Sold Listings", checked: false, propdId: 'mepr_default_filter'}
		]
	})

	const [skuLocations,setSkuLocations]  = useState<any>([
		{
			id:1,
			title:'SKU Location 1',
			payloadKey:'sku_generator_location_1',
			value:""

		},
		{
			id:2,
			title:'SKU Location 2',
			payloadKey:'sku_generator_location_2',
			value:""
		},
		{
			id:3,
			title:'SKU Location 3',
			payloadKey:'sku_generator_location_3',
			value:""
		},
		{
			id:4,
			title:'SKU Location 4',
			payloadKey:'sku_generator_location_4',
			value:""
		},
		{
			id:5,
			title:'SKU Location 5',
			payloadKey:'sku_generator_location_5',
			value:""
		},
	])
	const [skuLocationChanged,setSkuLocationChanged] = useState(false)
	const [skuFormatTags, setSkuFormatTags] = useState<any>([])
	const [availableTags,setAvailableTags] = useState([
		{
			id:1,
			value:'sku_prefix',
			label:'SKU Prefix'
		},
		{
			id:2,
			value:'unique_id',
			label:'Unique ID'
		},
		{
			id:3,
			value:'location_type',
			label:'Location'
		},
		{
			id:4,
			value:'location_number',
			label:'Location Number'
		},
		{
			id:6,
			value:'weight',
			label:'Weight'
		},
	])
	const [preview, setPreview] = useState<any>([])
	const [customSkuPrefix,setCustomSkuPrefix] = useState("")
	const [customSkuPrefixChange,setCustomSkuPrefixChange] = useState(false)
	const [skuGeneratorUniqueIdFormat,setSkuGeneratorUniqueIdFormat] = useState<any>(1)
	const [subAccounts,setSubAccounts] = useState([]) ;


	const handleAccordionClick = (index: number) => {
		dispatch(
		 setSettingsActiveIndex(settingsActiveIndex === index ? null : index)
		);
	};

	useEffect(() => {
	try{
		// Get the current hash from the URL
		const currentHash = location.hash;

		// Initialize the base URL based on settingsActiveIndex
		let baseUrl = '/settings';
		if (settingsActiveIndex === 1) {
			baseUrl = '/settings?page=extension';
		} else if (settingsActiveIndex === 0) {
			baseUrl = '/settings?page=sub-accounts';
		} else if (settingsActiveIndex === 3) {
			baseUrl = '/settings?page=website';
		}

		// Append the hash if it exists
		if (currentHash && settingsActiveIndex === 3) {
			navigate(`${baseUrl}${currentHash}`);
		} else {
			navigate(baseUrl);
		}
	}catch(e){
		console.log(e, 'error')
		}
	}, [settingsActiveIndex, location.hash, navigate]);

	useEffect(() => {
	try{
		const scrollToElement = () => {
			if (location.hash) {
				const element = document.getElementById(location.hash.substring(1)); // Remove the '#' from the hash
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
				} else {
					// Retry scrolling after a delay if the element is not found
					setTimeout(scrollToElement, 100);
				}
			}
		};

		scrollToElement();
	}catch(e){
		console.log(e, 'error')
	}
	}, [location.hash]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const page = queryParams.get('page');

		if (page === 'website') {
			dispatch(setSettingsActiveIndex(3));
		} else if (page === 'sub-accounts') {
			dispatch(setSettingsActiveIndex(0))
		}else if (page === 'extension') {
			dispatch(setSettingsActiveIndex(1));
		}
	}, [location.search, dispatch]);

	useEffect(() => {
		const bodyElement = document.body;

		const  handleAttributeChange = () => {
			const getRefactor = bodyElement.getAttribute(
			 'lp_custom_chrome_extension_version'
			);
			try {
				if(getRefactor) {
					const versions =  [0.114, 0.115]
					const parts = getRefactor?.split('.');
					const result = parts?.slice(0, 2)?.join('.');
					if (versions.includes(parseFloat(result))) {
						setBodyAtribute(true);
					}
					else {
						setBodyAtribute(false);
					}
				}
			}
			catch (e) {
				console.log('error', e)
			}

		};

		const observer = new MutationObserver(() => {
			handleAttributeChange();
		});

		observer.observe(bodyElement, {
			attributes: true,
			attributeFilter: ['lp_custom_chrome_extension_version'],
		});

		// Initial attribute check
		handleAttributeChange();

		return () => {
			observer.disconnect();
		};
	}, []);

	useEffect(() => {
		if (bodyAtribute) {
			setBodyAtribute(false)
			setTabsData((prevTabsData:any) => {
				// Clone the previous state to avoid mutating it directly
				const updatedTabsData = [...prevTabsData];

				// Find the General tab and add checkboxes conditionally
				const generalTab = updatedTabsData.find(tab => tab.name === "General");
				if (generalTab) {
					// Add checkboxes conditionally based on bodyAttribute
					if(hasSimple || hasPro || hasBusiness){
						generalTab.properties.checkboxes.push(
						 {
							 propId: 'turnOffBackgroundEnd',
							 title: "End listings - tabs will open in active window",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
						 }
						);
					}
					else {
						generalTab.properties.checkboxes.push(
						 {
							 propId: 'turnOffBackgroundUpdate',
							 title: "Update listings - tabs will open in active window",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
							 popoverEnabled: 'Enabled: Updates open in a new tab, providing visibility to changes and requires you to complete the updates',
							 popoverDisabled:'Disabled: Updates occur in the background, streamlining the process'
						 },
						 {
							 propId: 'turnOffBackgroundEnd',
							 title: "End listings - tabs will open in active window",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
							 popoverEnabled: 'Enabled: Ending lisitngs open in a new tab, providing visibility to confirmation messages.',
							 popoverDisabled: 'Disabled: Ending listings occur in the background, streamlining the process.'
						 },
						 {
							 propId: 'turnOffAutoEnd',
							 title: "Auto delist enabled",
							 checked: false,
							 changed: false,
							 disabled: hasSimple || hasBusiness || (hasPro && !is_role_allowed) ? true : false,
							 popoverEnabled: 'Automatically deactivates linked marketplace listings when a sale is detected on a List Perfectly listing.',
							 popoverDisabled: ''
						 }
						);
					}
				}

				return updatedTabsData;
			});
		}
	}, [bodyAtribute]);

	const getTypeWebsiteInfo = async() => {
		try {
			const response:any = await UserConfigs.get('website', token);
			if(response) {
				const { config, id } = response;
				let sku_generator_format = _.get(config,'sku_generator_format', [])
				const sku_generator_prefix = _.get(config,'sku_generator_prefix', "") ;
				const sku_generator_unique_id_format = _.get(config,'sku_generator_unique_id_format', 2) ;
				if(!sku_generator_format.length) {
					sku_generator_format = ['unique_id']
				}
				const filteredAvailableTags = availableTags.filter((tag:any) => !sku_generator_format.includes(tag.value)).map((mTag:any) =>{

					return {...mTag}
				} )

				const transformedSkuGeneratorFormat = sku_generator_format.map((skuFormat:any, index:any) => {
					return {
						id:Date.now() + index,
						value:skuFormat,
						label:skuFormat === '_' ? `Underscore` : skuFormat === ' ' ? 'Space' : skuFormat === 'unique_id' ? 'Unique ID' :  skuFormat === 'location_type' ? 'Location' : convertToFormattedString(skuFormat),
						isDivide:(skuFormat === ' ' || skuFormat === '_' || skuFormat === '-') ? true : false,
						icon:(skuFormat === ' ' ? SpaceIcon : skuFormat === '_' ? UnderscoreIcon : null),
					}
				})

				const capitalizedSkuGeneratorFormat = sku_generator_format.map((skuFormat:any,index:number) => skuFormat === '_' ?
				 {id:Date.now() + index, text:'_',value:skuFormat} :
				 skuFormat === 'unique_id' ? {id:Date.now() + index, text:'Unique ID',value:skuFormat}  :
				  skuFormat === 'location_type' ? {id:Date.now() + index, text:'Location',value:skuFormat} : {id:Date.now() + index, text:convertToFormattedString(skuFormat),value:skuFormat})
				.map((mSkuFormat:any) => {
					return {...mSkuFormat}
				})
				const updatedSkuLocations = skuLocations.map((skuLocation:any) => {
					const newValue = config[skuLocation.payloadKey]
					if(newValue !== undefined) {
						return {...skuLocation, value:newValue}
					}
					return skuLocation
				})

				setCustomSkuPrefix(sku_generator_prefix)
				setAvailableTags(filteredAvailableTags)
				setSkuFormatTags(transformedSkuGeneratorFormat)
				setPreview(capitalizedSkuGeneratorFormat)
				setSkuLocations(updatedSkuLocations)
				setSkuGeneratorUniqueIdFormat(sku_generator_unique_id_format)
				setConfigID(id)
				const myCustomMarketplaces = [];

				for (const key in config) {
					if (
					 key.startsWith('custom_marketplace_') &&
					 typeof config[key] === 'string'
					) {
						const index = parseInt(key.replace('custom_marketplace_', ''), 10);
						const colorKey = `${key}_color`;

						if (!config[key].includes('#')) {
							myCustomMarketplaces.push({
								id,
								platformName: config[key],
								platformIcon: <PlatformIconShop />,
								platformColor: config[colorKey],
								platformIndex: index,
							});
						}
					}
				}
				const businessMarkets = myCustomMarketplaces?.slice(0, 5)
				if(hasBusiness || hasSimple) {
					setCustomMarketplaces(businessMarkets);
				}
				else {
					setCustomMarketplaces(myCustomMarketplaces)
				}

				const getNotificationSettings = _.get(config, 'notifications_disable_support_response_notifications');
				setNotificationSettings((prevSettings) => ({
					...prevSettings,
					checked: getNotificationSettings ? getNotificationSettings : false,
				}))
				const getZip = _.get(config, 'default_shipping_zip');
				if(getZip){
					setZipOrCityCode(getZip)
				}
			}
		}
		catch (e) {
			console.log('error', e)
		}
	}

	const updateTabsData = (receivedData: any[]) => {
		try {
			// Check if the receivedData array is not empty and if it contains objects with a 'date' property
			if (receivedData.length > 0 && 'date' in receivedData[0]) {
				// Sort receivedData by date
				receivedData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
			} else {
				receivedData.sort((a, b) => a.word_or_phrase.localeCompare(b.word_or_phrase));
			}

			const updatedTabsData = [...tabsData];
			const generalValues: { id: string, value: string }[] = [];

			receivedData.forEach(item => {
				const marketplace = item.marketplace.toLowerCase();
				if (marketplace === 'all_marketplaces') {
					generalValues.push({ id: item.id, value: item.word_or_phrase });
				} else {
					const tabToUpdate = updatedTabsData.find(tab => tab.name.toLowerCase() === marketplace);
					if (tabToUpdate) {
						if (!tabToUpdate.properties.wordAlertValue.value) {
							tabToUpdate.properties.wordAlertValue.value = [];
						}
						tabToUpdate.properties.wordAlertValue.value.push({ id: item.id, value: item.word_or_phrase });
						tabToUpdate.properties.wordAlertValue.changed = true;
					}
				}
			});

			const generalTab = updatedTabsData.find(tab => tab.name.toLowerCase() === 'general');
			if (generalTab) {
				generalTab.properties.wordAlertValue.value = generalValues;
				generalTab.properties.wordAlertValue.changed = true;
			}
			setTabsData(updatedTabsData);
		} catch (e) {
			console.log(e, 'error');
		}
	};

	const getWordsAlertInfo = async() => {
		try {
			const response = await instanceAxios.post('/prohibited-words-and-phrases/list',  { metadata: { offset: 0, limit: 300 }});
			if(response.status === 200) {
				const data = response.data.data
				updateTabsData(data);
			}
		}
		catch (e) {
			console.log('error', e)
		}
	}

	const unescapedRowMetadata = useMemo(() => {
		const result: any = {};
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				result[key] = _.get(data, key);
			}
		}
		return result;
	}, [data]);

	const getTypeExtension = async() => {
		try {
			const payload: any = { type: 'chrome_extension' };
			const response = await instanceAxios.post('user-configs/get', payload);
			if(response.status === 200) {
				const { config, id } = response.data;
				setConfigIDExtension(id)
				setData(config)
			}
		}
		catch (e) {
			console.log('error', e)
		}
	}

	const handleChangeDescriptionFooter = (index: number, event: any) => {
		setDescriptionChanged(true);
		const updatedDescriptions = [...websiteSettings.descriptions];

		if (updatedDescriptions[index].value !== event.target.value) {
			updatedDescriptions[index] = {
				...updatedDescriptions[index],
				value: event.target.value,
				changed: true,
			};
		}

		setWebsiteSettings({
			...websiteSettings,
			descriptions: updatedDescriptions,
		});
	};

	async function fetchGlobalFooters(): Promise<void> {
		try {
			const response = await instanceAxios.post('/users/legacy/metadata/list', {
				metadata: { offset: 0, limit: 10 },
				filter_by: {},
			});
			if (response.status === 200) {
				const serverData = response.data.data;

				const updatedDescriptions = websiteSettings.descriptions.map((description:any) => {
					let serverKey = `mepr_${description.name.toLowerCase().replace(/\s+/g, '_')}`;
					if(serverKey === 'mepr_facebook_marketplace_description') {
						serverKey = 'mepr_facebookmarketplace_description'
					}
					return {
						...description,
						value: _.get(serverData, serverKey, ''),
					};
				});
				const updatedFilters = websiteSettings.filters.map((filter: any) => {
					return {
						...filter,
						checked: serverData.mepr_default_filter === "all" ? filter.name === "All Listings" : filter.name === "Not Sold Listings",
					};
				});
				setWebsiteSettings((prevSettings:any) => ({
					...prevSettings,
					descriptions: updatedDescriptions,
					filters: updatedFilters,
				}));
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	const sendRequestForGlobalDescriptions = async() => {
		try {
			const changedDescriptions = websiteSettings?.descriptions.filter((item: any) => item?.changed);

			const payload: any = {
				payload: {}
			};

			changedDescriptions.forEach((changedDescription:any) => {
				const changedDescriptionKey = changedDescription?.propdId;
				const changedDescriptionValue = changedDescription?.value;

				if (changedDescriptionKey !== undefined) {
					payload.payload[changedDescriptionKey] = changedDescriptionValue;
				}
			});

			const response = await instanceAxios.put(
			 '/users/legacy/metadata/update',
			 payload
			);
			if (response.status === 200) {
				setDescriptionChanged(false);
			}
		} catch (error) {
			toast.error(`Oops! We encountered an error while attempting to save your changes. Please try again.`, {
				position: 'bottom-right',
			});
			console.error(error);
		}
	};

	const updateNotificationSettings = async () => {
		try {
			const payload = {
				filter_by: {
					id : configID,
				},
				data: {
					config: {
						['notifications_disable_support_response_notifications']: notificationSettings.checked,
					},
				},
			};
			await instanceAxios.put(
			 'user-configs/website/update',
			 payload
			);
		} catch (error) {
			toast.error(`Oops! We encountered an error while attempting to save your changes. Please try again`, {
				position: 'bottom-right',
			});
			console.error(error);
		}
	};

	const updateZipSettings = async () => {
		try {
			const payload = {
				filter_by: {
					id : configID,
				},
				data: {
					config: {
						['default_shipping_zip']: zipOrCityCode,
					},
				},
			};
			await instanceAxios.put(
				'user-configs/website/update',
				payload
			);
			setZipChanged(false)
		} catch (error) {
			toast.error(`Oops! We encountered an error while attempting to save your changes. Please try again`, {
				position: 'bottom-right',
			});
			console.error(error);
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	// Function to handle blur event
	const handleBlur = () => {
		setIsFocused(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				await getTypeExtension();
				await getTypeWebsiteInfo();
				await fetchGlobalFooters();
				await getWordsAlertInfo();

			} catch (error) {
				console.error('Error fetching data:', error);
			}
			finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		let debouncedSendRequest : any;
		if (descriptionChanged) {
			try {
				debouncedSendRequest = debounce(() => {
					sendRequestForGlobalDescriptions();
				}, 500);

				debouncedSendRequest();
			} catch (e) {
				console.log('error', e);
			}
		}

		return () => {
			if (debouncedSendRequest) {
				debouncedSendRequest.cancel();
			}
		};
	}, [descriptionChanged, sendRequestForGlobalDescriptions, isFocused]);

	useEffect(() => {
		let debouncedSendRequest : any;
		if (zipChanged) {
			try {
				debouncedSendRequest = debounce(() => {
					updateZipSettings();
				}, 500);

				debouncedSendRequest();
			} catch (e) {
				console.log('error', e);
			}
		}

		return () => {
			if (debouncedSendRequest) {
				debouncedSendRequest.cancel();
			}
		};
	}, [descriptionChanged, updateZipSettings, zipChanged]);

	useEffect(() => {
		if(notificationChanged) {
			updateNotificationSettings()
		}
	},[notificationSettings])

	const handleChangeZipOrCityCode = (event:any)=>{
		setZipOrCityCode(event.target.value);
		setZipChanged(true);
	}

	const handleChangeSkuLocation = (index:number,event:any) => {
		setSkuLocationChanged(true)

		const updatedSkuLocations = [...skuLocations] ;
		if (updatedSkuLocations[index].value !== event.target.value) {
			updatedSkuLocations[index] = {
				...updatedSkuLocations[index],
				value: event.target.value,
				changed: true,
			};
		}

		setSkuLocations(updatedSkuLocations)
	}

	const sendRequestSkuLocation = async () => {

		try {
			const changedSkuLocations = skuLocations?.filter((item: any) => item?.changed);

			const payload: any = {
				filter_by:{
					id:configID
				},
				data: {
					config:{}
				}
			};

			changedSkuLocations.forEach((changedSkuLocation:any) => {
				const changedSkuLocationKey = changedSkuLocation?.payloadKey;
				const changedSkuLocationValue = changedSkuLocation?.value;


				if (changedSkuLocationKey !== undefined ) {
					if(changedSkuLocationValue.length > 5) return
					payload.data.config[changedSkuLocationKey] = changedSkuLocationValue;
				}
			});

			if(!Object.values(payload.data.config).length) return

			const response = await instanceAxios.put('/user-configs/website/update',payload)
			if(response.status === 200) {
				// setSkuLocationChanged(false)
			}
		} catch(error) {
			setSkuLocationChanged(false)
			console.error(error)
		}
	}

	useEffect(() => {
		let debouncedSendRequest : any;
		if (skuLocationChanged) {

			try {
				debouncedSendRequest = debounce(() => {
					sendRequestSkuLocation();
				}, 500);

				debouncedSendRequest();
			} catch (e) {
				console.log('error', e);
			}
		}

		return () => {
			if (debouncedSendRequest) {
				debouncedSendRequest.cancel();
			}
		};
	}, [skuLocationChanged,sendRequestSkuLocation]);

	const handleChangeCustomSkuPrefix = (e:any) => {
		setSkuLocationChanged(false)
		setCustomSkuPrefixChange(true)
		setCustomSkuPrefix(e.target.value)
	}

	const sendRequestCustomSkuPrefix = async () => {
		if(customSkuPrefix.length > 3) return
		try {
			const payload = {
				filter_by:{
					id:configID
				},
				data : {
					config : {
						sku_generator_prefix:customSkuPrefix?.toUpperCase()
					}
				}
			}
			const response = await instanceAxios.put('/user-configs/website/update', payload)
			if(response.status === 200) {
				setCustomSkuPrefixChange(false)
			}

		} catch(error) {
			console.error(error)
		}
	}

	const sendRequestUniqueIdFormat = async (id:number) => {
		try {
			const payload = {
				filter_by:{
					id:configID
				},
				data : {
					config : {
						sku_generator_unique_id_format:id
					}
				}
			}
			const response = await instanceAxios.put('/user-configs/website/update', payload)
		} catch(error) {
			console.error(error)
		}
	}


	useEffect(() => {
		let debouncedSendRequest : any;
		if (customSkuPrefixChange) {
			try {
				debouncedSendRequest = debounce(() => {
					sendRequestCustomSkuPrefix();
				}, 500);

				debouncedSendRequest();
			} catch (e) {
				console.log('error', e);
			}
		}

		return () => {
			if (debouncedSendRequest) {
				debouncedSendRequest.cancel();
			}
		};

	}, [customSkuPrefixChange,sendRequestCustomSkuPrefix]);

	useEffect(() => {
		const getSubAccounts = async () => {
			try {
				const response:any = await UserConfigs.sub_accounts_list(_, token) ;
				setSubAccounts(response.data)
			} catch(error:any) {
				console.error(error,'error')
			}
		}
		if(is_role_allowed_master) {
			getSubAccounts()
		}

	},[])

	useEffect(() => {
		const updateSubAccountLimit = (roles: string[]) => {
			const roleMap: Record<string, number> = {
				pro_plus_1: 1,
				pro_plus_4: 4,
				pro_plus_9: 9,
			};

			for (const role of roles) {
				if (roleMap[role] !== undefined) {
					setSubAccountLimit(roleMap[role]);
					return;
				}
			}

			setSubAccountLimit(0);
		};
		updateSubAccountLimit(roles)
	}, []);




	return (
		<>
			<Helmet>
				<title>{
					settingsActiveIndex === 0 ? 'Sub Account Settings | List Perfectly' :
					settingsActiveIndex === 1 ? 'Extension Settings | List Perfectly' :
					 settingsActiveIndex === 3 ? 'Site Settings | List Perfectly' :
					  'Settings | List Perfectly'
				}</title>
			</Helmet>

			{loading ? (
			 <div
			  style={{
				  width: '100%',
				  height: '80vh',
				  display: 'flex',
				  justifyContent: 'center',
				  alignItems: 'center',
			  }}
			 >
				 <MoonLoader color="#1e3166" loading={true}/>
			 </div>
			) : (
			 <>
				 <div className={styles.settingsPageMain}>
					 {
						 <SettingsHeader
						  text="Extension Settings"
						  activeClass={settingsActiveIndex === 1}
						  titleClick={() => handleAccordionClick(1)}
						  isMobile={isMobile}
						  isExtensionTab={true}
						 />
					 }
					 {
					 settingsActiveIndex === 1 && !isMobile && (
					   <div className={styles.SettingsContent}>
						   <ExtensionTabs tabsData={tabsData} setTabsData={setTabsData}/>
						   <ExtensionContent tabsData={tabsData} setTabsData={setTabsData}
											 unescapedRowMetadata={unescapedRowMetadata}
											 configIDExtension={configIDExtension}/>
					   </div>
					  )
					 }
					 {
					  !hasSimple && <>
						  <SettingsHeader
						   text="Website Settings"
						   activeClass={settingsActiveIndex === 3}
						   titleClick={() => handleAccordionClick(3)}
						  />
						  {
						   settingsActiveIndex === 3 && (
							<div className={styles.SettingsContent} id="settingsPageWebsite">
								<div className={styles.listingTitleContainer}>
									<div className={styles.TitlesContainer}>
										<div className={styles.title}>
											Shipping ZIP Or City Code
											<Tippy
											 interactive={true}
											 arrow={false}
											 zIndex={9999}
											 trigger="click"
											 content={
												 <div className={`${styles.popoverContent}`}>
													 <ul>
														 <li>
															 Enter the zip code for where the product is shipping from.

														 </li>

													 </ul>
													 <p>★ Crossposts to supported 'zip code' fields.</p>
												 </div>
											 }
											>
												<PopoverIcon
												 className={styles.popoverIcon}
												 color={'#fff'}
												></PopoverIcon>
											</Tippy>
										</div>
										<div className={styles.titlesMain}>
											<InputCount
											 placeholder="Shipping ZIP Or City Code"
											 name="input_837"
											 type="number"
											 handleChange={handleChangeZipOrCityCode}
											 value={zipOrCityCode}
											 limit={20}
											 height={40}
											/>
										</div>
									</div>
								</div>
								<CustomMarketplaces customMarketplaces={customMarketplaces}
													setCustomMarketplaces={setCustomMarketplaces} isMobile={isMobile}
													configID={configID}/>
								{
								 !hasBusiness && (
								  <div className={styles.customDescriptionFooters} id="custom-description-footers">
									  <h2>Custom Description Footers</h2>
									  <div className={styles.cstmDecsrpFootersWrapper}>
										  {websiteSettings?.descriptions?.map((item: any, index: number) => (
										   <div className={styles.cstmDscrptFItem} key={item.propdId}>
											   <h5>{item.name}</h5>
											   <div>
                                       <textarea
										value={item.value}
										onChange={(event) => handleChangeDescriptionFooter(index, event)}
										onFocus={handleFocus}
										onBlur={handleBlur}
									   ></textarea>
											   </div>
										   </div>
										  ))}
									  </div>
								  </div>
								 )
								}
								{
									<div className={styles.customSkuBlock} id="custom-sku">
										<div className={styles.customSkuPrefix}>Custom SKU

											{!(hasPro && is_role_allowed) &&
											 <Tippy
											  interactive={true}
											  arrow={false}
											  zIndex={9999}
											  trigger="click"
											  content={
												  <div className={`${styles.popoverContent}`}>
													  <p> Available only for <a href="https://listperfectly.com/pricing"
																				target="_blank">Pro Plus Plan</a></p>
												  </div>
											  }
											 >
												 <PopoverIcon
												  className={styles.popoverIcon}
												  color={'#fff'}
												 ></PopoverIcon>
											 </Tippy>
											}
										</div>
										<div className={styles.customSkuContainer}>
											<div className={!(hasPro && is_role_allowed) ? styles.disabledSku : ''}></div>
											<UniqueIdFormat
											 skuGeneratorUniqueIdFormat={skuGeneratorUniqueIdFormat}
											 setSkuGeneratorUniqueIdFormat={setSkuGeneratorUniqueIdFormat}
											 sendRequestUniqueIdFormat={sendRequestUniqueIdFormat}
											/>
											<CustomSkuPrefix
											 customSkuPrefix={customSkuPrefix}
											 handleChangeCustomSkuPrefix={handleChangeCustomSkuPrefix}
											/>
											<SkuLocations skuLocations={skuLocations}
														  handleChangeSkuLocation={handleChangeSkuLocation}
											/>
											<CustomSkuFormat
											 skuFormatTags={skuFormatTags}
											 setSkuFormatTags={setSkuFormatTags}
											 configID={configID}
											 availableTags={availableTags}
											 setAvailableTags={setAvailableTags}
											 preview={preview}
											 setPreview={setPreview}
											 setSkuLocationChanged={setSkuLocationChanged}
											/>
										</div>
									</div>
								}

							</div>
						   )
						  }
						  {
							  is_role_allowed_master &&   <SettingsHeader
							text="Sub Account Settings"
							activeClass={settingsActiveIndex === 0}
							titleClick={() => handleAccordionClick(0)}
							isMobile={isMobile}
							isExtensionTab={false}
						   />
						  }
						  {
						   is_role_allowed_master &&  settingsActiveIndex === 0 && <div className={styles.SettingsContent}>
							   <SubAccountTable  subAccounts={subAccounts} setSubAccounts={setSubAccounts} subAccountLimit={subAccountLimit}/>
						   </div>
						  }
					  </>
					 }
				 </div>
				 <Iframe/>
			 </>
			)}
		</>
	);

};

export default SettingsPage;