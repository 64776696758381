import React, {
  useState,
  MouseEvent,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { useCallback } from 'react';
import styles from './Table.module.scss';
import './table.css';
import './select.css';
import _ from 'lodash';
import TableRow from './components/TableRow';

import PlatformsModal from '../PlatformsModal';
import TableResizeColumn from './components/TableResizeColumn';

import {
  ITableData,
  ICatalogColumn,
  IFilterBy,
  IPayload,
} from '../../../../interfaces';
import { platforms } from './components/ColumnSearch/data/platforms';

import { LeftArrow, RightArrow } from '../../../../assets/icons';
import { MoonLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch, useSelector} from 'react-redux';
import TableMobileCard from './components/TableMobileCard';

import MobileSearch from '../MobileSearch';
import SearchResult from '../SearchResult';
import {instanceAxios} from "../../../../services";
import Checkbox, {CheckboxModifier} from "../../../../components/UI/Checkbox";
import useRoles from "@hooks/useRoles";
import UserConfigs from "@services/UserConfigs";
import Auth from "@services/Auth";

interface ITableProps {
  columns: ICatalogColumn[];
  handleChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tableData: ITableData[];
  handleRowSelected: (e: MouseEvent<HTMLDivElement>, id: string, checked?:any) => void;
  isBulkEdit: boolean;
  isChecked?: boolean;
  setFilterBy: (filter_by: IFilterBy) => void;
  setPayload: (payload: IPayload) => void;
  setTableData: (tableData: ITableData[]) => void;
  handleSortByClick: (
    thValue: string,
    sortValue: 'asc' | 'desc' | 'none'
  ) => void;
  loader: boolean;
  tab: string;
  handleGetColumnSearchDebounced: (
    col: string,
    value: string | number | string[]
  ) => void;
  handleGetColumnSearch: (
    searchParams?:
      | {
          platforms?: string[] | undefined;
          not_platforms?: string[] | undefined;
          custom_marketplaces: string[] | undefined;
          not_custom_marketplaces: string[] | undefined;
        }
      | undefined
  ) => void;
  updator: boolean;
  inputValues: Record<string, string | number>;
  handleInputChange: (col: string, value: string | number) => void;
  lpPlatforms?: any;
  setLpPlatforms?: (platforms: any) => void;
  activePlatforms: string[];
  setActivePlatforms: (activePlatforms: string[]) => void;
  customMarketplaces: any;
  setCustomMarketplaces: (customMarketplaces: any) => void;
  filterByArr: string[];
  setPagiInputValue: (value: string) => void;
  handleBulkDelete: () => void;
  handleBulkRestore: () => void;
  fetchMoreData: any;
  hasMore: boolean;
  setInputValues: any;
  setHasMore: any;
  setFilterByArr: any;
  filterBy: any;
  setSearchItem: any;
  setTabCounts2: any;
  handleFetchForSearch: any;
  tabCounts2: any;
  sendReguestColumnSearch: any;
  myHelpConditionFunc: (response: any, tab: string) => void;
  gridView: boolean;
  syncTime: any;
  hasBusiness:boolean;
  hasPro: boolean;
  hasBetaTeamRole:boolean;
  is_role_allowed: boolean;
  searchItem: any;
  setPlatformChangesMade: any;
  handleTogglePlatformsModalShow: any;
  platformsModalShow: any;
  setRetryActive:any;
  retryActive:any;
  saveActive:any;
  setSaveActive:any;
  iframeUpdate:any;
  handleToggleDateRangeModalShow:any;
  dateRangeModalShow:any;
  startChangedCreated:any;
  endChangedCreated:any;
  startDateCreated:any;
  endDateCreated:any;
  setStartChangedCreated:any;
  setEndChangedCreated:any;
  setStartDateCreated:any;
  setEndDateCreated:any;
  setCreatedCloseClicked:any;
  createdTippy?:any;
  platformsTippy?:any;
  selectedListings:any;
  selectedListingsData:any;
  setSelectedListingsData:(selectedListingsData:any) => void;
  listingsForDelete?:any;
  hasExtensionInstall?:any;
  setAllTableData:any;
  stickyColumnsList?:any;
  setStickyColumnsList?:any;
  sizes?:any;
  setSizes?:any;
  tippyElementStickyColumnsRef?:any;
}

const Table = (props: ITableProps) => {
  const {
    columns,
    handleChangeCheckbox,
    tableData,
    handleRowSelected,
    isBulkEdit,
    setFilterBy,
    setPayload,
    setTableData,
    handleSortByClick,
    loader,
    tab,
    handleGetColumnSearch,
    handleGetColumnSearchDebounced,
    updator,
    inputValues,
    handleInputChange,
    lpPlatforms,
    setLpPlatforms,
    activePlatforms,
    setActivePlatforms,
    filterByArr,
    setPagiInputValue,
    handleBulkDelete,
    handleBulkRestore,
    fetchMoreData,
    hasMore,
    setInputValues,
    setHasMore,
    setFilterByArr,
    filterBy,
    setSearchItem,
    setTabCounts2,
    handleFetchForSearch,
    tabCounts2,
    sendReguestColumnSearch,
    myHelpConditionFunc,
    gridView,
    syncTime,
    customMarketplaces,
    setCustomMarketplaces,
    hasBusiness,
    hasPro,
    hasBetaTeamRole,
    is_role_allowed,
    searchItem,
    setPlatformChangesMade,
    handleTogglePlatformsModalShow,
    platformsModalShow,
    setRetryActive,
    retryActive,
    saveActive,
    setSaveActive,
    iframeUpdate,
    handleToggleDateRangeModalShow,
    dateRangeModalShow,
    startChangedCreated,
    endChangedCreated,
    startDateCreated,
    endDateCreated,
    setStartChangedCreated,
    setEndChangedCreated,
    setStartDateCreated,
    setEndDateCreated,
    setCreatedCloseClicked,
    createdTippy,
    platformsTippy,
    selectedListings,
    selectedListingsData,
    setSelectedListingsData,
    listingsForDelete,
    hasExtensionInstall,
    setAllTableData,
    stickyColumnsList,
    setStickyColumnsList,
    sizes,
    setSizes,
    tippyElementStickyColumnsRef
  } = props;

  const minCellWidth = 100;
  const maxCeilWidth = 500;
  const token: any = Auth.get_token();
  const location = useLocation();
  const [shopifyName, setShopifyName] = useState<any>(null);
  const [shopifyURLFormat, setShopifyURLFormat] = useState<any>(null);
  const [shopifyDinamicLink, setShopifyDinamicLink] = useState(null);
  const {hasSimple} = useRoles();
  const navigation = useSelector((state: any) => state.navigation.active);
  const queryParams = new URLSearchParams(location.search);
  const isMobile = useSelector((state: any) => state.navigation.isMobile);
  const [cols, setCols] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const tableElement = useRef<HTMLTableElement>(null);
  const [distancefromRight, setDistanceFromRight] = useState<number>();
  const tableRef = useRef<HTMLTableElement>();
  const [resizableElementWidth, setResizableElementWidth] = useState(0);
  const [hasScroll, setHasScroll] = useState(false);
  const [directions, setDirections] = useState<{
    [columnId: string]: 'top' | 'bottom' | 'none';
  }>({});
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const handlePlatformChange = () => {
    setPlatformChangesMade(true);
  };

  const handleClick = (platformId: string) => {
    handlePlatformChange();


    const updatedPlatforms = lpPlatforms?.map((item: any) => {
      if (item.platform.code[0] === platformId) {
        return {
          ...item,
          platform: {
            ...item.platform,
            isChecked: !item.platform.isChecked,
          },
          notOnPlatform: {
            ...item.notOnPlatform,
            isChecked: false,
          },
        };
      } else if (item.notOnPlatform.code[0] === platformId) {
        return {
          ...item,
          platform: {
            ...item.platform,
            isChecked: false,
          },
          notOnPlatform: {
            ...item.notOnPlatform,
            isChecked: !item.notOnPlatform.isChecked,
          },
        };
      }
      return item;
    });

    if (setLpPlatforms) {
      setLpPlatforms(updatedPlatforms);
    }
    //

    const updatedFakePlatforms = customMarketplaces?.map((item: any) => {
      if (item.platform.code[0] === platformId) {
        return {
          ...item,
          platform: {
            ...item.platform,

            isChecked: !item.platform.isChecked,
          },
          notOnPlatform: {
            ...item.notOnPlatform,
            isChecked: false,
          },
        };
      } else if (item.notOnPlatform.code[0] === platformId) {
        return {
          ...item,
          platform: {
            ...item.platform,
            isChecked: false,
          },
          notOnPlatform: {
            ...item.notOnPlatform,
            isChecked: !item.notOnPlatform.isChecked,
          },
        };
      }
      return item;
    });

    if (setCustomMarketplaces) {
      setCustomMarketplaces(updatedFakePlatforms);
    }
  };

  const getTypeExtension = async() => {
    try {
      const response: any = await UserConfigs.get('chrome_extension', token)
      if(response) {
        const { config } = response;
        let shopifyName = _.get(config,'shopifyShopId1', "");
        let shopifyURLformat = _.get(config,'shopifyURLFormat', "")
        if(shopifyName?.length) {
          setShopifyName(shopifyName);
          setShopifyURLFormat(shopifyURLformat);
        }
      }
    }
    catch (e) {
      console.log('error', e)
    }
  }

  useEffect(() => {
      getTypeExtension();
  }, []);

  const fetchShopifyLink = async (): Promise<void> => {
    try {
      const response = await instanceAxios.post('/users/legacy/metadata/list', {
        metadata: { offset: 0, limit: 10 },
        filter_by: {},
      });

      if (response.status === 200) {
        const subdomain = _.get(
            response.data.data,
            'mepr_shopify_id_subdomain',
        );

        if (subdomain !== undefined) {
          setShopifyDinamicLink(subdomain);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchShopifyLink();
  }, []);

  useEffect(() => {
    const adjustHeaderWidth = () => {
      const thElements = tableRef.current?.querySelectorAll('th');
      const tdElements = tableRef.current?.querySelectorAll('td');

      thElements?.forEach((th, index) => {
        const tdWidth = tdElements?.[index]?.offsetWidth;
        th.style.width = `${tdWidth}px`;
      });
    };

    const handleMouseDown = (e: any) => {
      let isResizing = true;
      let currentTh = e.currentTarget;
      let initialX = e.clientX;
      let initialThWidth = currentTh.offsetWidth;

      const handleMouseMove = (e: any) => {
        if (!isResizing) return;

        const offset = e.clientX - initialX;
        const newWidth = initialThWidth + offset;

        currentTh.style.width = `${newWidth}px`;
      };

      const handleMouseUp = () => {
        isResizing = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        document.body.style.userSelect = 'auto';
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      document.body.style.userSelect = 'none';
    };

    window.addEventListener('resize', adjustHeaderWidth);
    adjustHeaderWidth();

    const headers = tableRef.current?.querySelectorAll('th');
    headers?.forEach((th) => {
      th.addEventListener('mousedown', handleMouseDown);
    });

    return () => {
      window.removeEventListener('resize', adjustHeaderWidth);
      headers?.forEach((th) => {
        th.removeEventListener('mousedown', handleMouseDown);
      });
    };
  }, []);

  let decodedSortBy: any;
  const getSortBy = (): any => {
    const SortByParam = queryParams.get('sort_by');
    if (SortByParam) {
      decodedSortBy = JSON.parse(decodeURIComponent(SortByParam));
    }
    if (_.get(decodedSortBy, '133')) {
      return {
        title:
          _.get(decodedSortBy, '133') === 'asc'
            ? 'top'
            : _.get(decodedSortBy, '133') === 'desc'
            ? 'bottom'
            : 'none',
      };
    } else if (_.get(decodedSortBy, '1')) {
      return {
        SKU:
          _.get(decodedSortBy, '1') === 'asc'
            ? 'top'
            : _.get(decodedSortBy, '1') === 'desc'
            ? 'bottom'
            : 'none',
      };
    } else if (_.get(decodedSortBy, '22')) {
      return {
        price:
          _.get(decodedSortBy, '22') === 'asc'
            ? 'top'
            : _.get(decodedSortBy, '22') === 'desc'
            ? 'bottom'
            : 'none',
      };
    } else if (_.get(decodedSortBy, '851')) {
      return {
        quantity:
          _.get(decodedSortBy, '851') === 'asc'
            ? 'top'
            : _.get(decodedSortBy, '851') === 'desc'
            ? 'bottom'
            : 'none',
      };
    } else if (_.get(decodedSortBy, 'updated_at')) {
      return {
        updated:
          _.get(decodedSortBy, 'updated_at') === 'asc'
            ? 'top'
            : _.get(decodedSortBy, 'updated_at') === 'desc'
            ? 'bottom'
            : 'none',
      };
    } else if (_.get(decodedSortBy, 'created_at')) {
      return {
        created:
          _.get(decodedSortBy, 'created_at') === 'asc'
            ? 'top'
            : _.get(decodedSortBy, 'created_at') === 'desc'
            ? 'bottom'
            : 'none',
      };
    }
  };

  useEffect(() => {
    const answer = getSortBy();
    if(!answer) {
      setDirections({ ...answer, updated: 'bottom' });
    }
    else {
      setDirections({ ...answer });
    }
  }, []);

  const handleChangeDirection = (
    columnId: string,
    direction: 'top' | 'bottom' | 'none'
  ) => {
    setDirections({ [columnId]: direction });

    handleSortByClick(
      columnId,
      direction === 'top' ? 'asc' : direction === 'none' ? 'none' : 'desc'
    );
    setPagiInputValue('1');
  };

  const mouseDown = (index: number) => {
    setActiveIndex(index);
  };

  const mouseMove = useCallback(
    (e: any) => {
      if (activeIndex !== null && tableElement.current) {
        // const updatedColumnSizes = columns.map((col, i) => {
        //   if (i === activeIndex && col.ref.current) {
        //     const width =
        //       e.clientX - col.ref.current.getBoundingClientRect().left;
        //     if (width >= minCellWidth && width < maxCeilWidth) {
        //       return { name: col.value, size: `${width}px` };
        //     }
        //   }
        //   return { name: col.value, size: `${col.ref.current?.offsetWidth}px` };
        // });

        const updatedColumnSizes = columns.map((col, i) => {
          if (i === activeIndex && col.ref.current) {
            const boundingRect = col.ref.current.getBoundingClientRect();

            // Reverse the scaling on the mouse position
            const adjustedMouseX = e.clientX ;

            // Calculate the size by comparing to the unscaled boundingRect
            const newWidth = adjustedMouseX - boundingRect.left;

            // Ensure the newWidth stays within the allowed limits
            if (newWidth >= minCellWidth && newWidth <= maxCeilWidth) {
              return { name: col.value, size: `${newWidth}px` };
            }
          }
          return { name: col.value, size: `${col.ref.current?.offsetWidth}px` };
        });

        // Add a default width for the first column
        updatedColumnSizes.unshift({ name: 'default', size: '70px' });
        setSizes(updatedColumnSizes);
        // Convert the array of objects to a JSON string
        const size = JSON.stringify(updatedColumnSizes);

        // Set the gridTemplateColumns property of tableElement
        const gridColumns = updatedColumnSizes.map((col) => col.size).join(' ');
        // console.log(gridColumns,'gridCOlumns')
        tableElement.current.style.gridTemplateColumns = gridColumns;

        // Save the JSON string in local storage
        localStorage.setItem('size', size);
      }
    },
    [activeIndex, columns, minCellWidth]
  );

  const getStickyStyle = (col: any) => {
    try {
      // Find the current column in the stickyColumnsList
      const currentColumn = stickyColumnsList.find(
          (obj: any) =>
              obj.value?.toLowerCase() === col.toLowerCase()
      );

      if (!currentColumn) return {}; // If the column isn't found, return an empty style object

      // Get position of the current column
      const currentPosition = currentColumn.position;

      // Fallback object for widths (from switch-case logic)
      const fallbackWidths: Record<string, string> = {
        image: '120px',
        title: '250px',
        actions: '100px',
        sku: '120px',
        price: '100px',
        platforms: '250px',
        quantity: '90px',
        issues: '95px',
        updated: '120px',
        created: '120px',
        notes: '200px',
        keywords: '200px',
        assignee: '100px',
        'date sold': '150px',
        'sold price': '100px',
        cogs: '100px',
        fees: '100px',
        'shipping cost': '150px',
        profit: '100px',
        drafts: '100px',
        condition: '120px',
        material: '120px',
        size: '100px',
        color: '150px',
        brand: '150px',
        upc: '150px',
        'shipping weight lb/kg': '150px',
        'shipping weight oz/g': '150px',
        'shipping package length': '150px',
        'shipping package width': '150px',
        'shipping package height': '150px',
        zip: '150px',
        sales: '150px'
      };

      // Helper to fetch size from the `sizes` array or fallback to the object
      const getSizeByName = (name: string) => {
        // Check if sizes is an array of objects or just an array of strings
        if (sizes.length > 0 && typeof sizes[0] === 'object' && 'name' in sizes[0]) {
          // When sizes is an array of objects, find the matching object
          const sizeEntry = sizes.find((size: any) => size.name.toLowerCase() === name.toLowerCase());
          return sizeEntry ? parseFloat(sizeEntry.size) : parseFloat(fallbackWidths[name.toLowerCase()] || '0');
        } else {
          // If sizes is not an array of objects or does not match, use fallbackWidths
          return parseFloat(fallbackWidths[name.toLowerCase()] || '0');
        }
      };


      // Ensure all previous columns become sticky if the current column is sticky
      let left = 70; // Initial offset for the first column
      stickyColumnsList.forEach((obj: any) => {
        if (
            obj.position <= currentPosition && // Ensure all previous and current columns
            (currentColumn.stickyColumn || currentColumn.activeColumn) // Are affected only if the current column is sticky
        ) {
          left += getSizeByName(obj.value || '');
        }
      });

      return {
        position: 'sticky',
        left: `${left - getSizeByName(currentColumn.value || '')}px`, // Subtract the current column's width
      };
    } catch (e) {
      console.log(e, 'error');
      return {};
    }
  };


  useLayoutEffect(() => {
    const savedGridTemplateColumns = localStorage.getItem('size');
    const cols = localStorage.getItem('cols');
    try {
      if (savedGridTemplateColumns) {
        const parsedColumnSizes = JSON.parse(savedGridTemplateColumns);
        setSizes(parsedColumnSizes);
        const columnSizeMap: any = {};

        parsedColumnSizes?.forEach((col: any) => {
          columnSizeMap[col.name] = col.size;
        });

        const checkerFunction = (value: string) => {
          switch (value) {
            case 'image':
              return '120px';
            case 'title':
              return '250px';
            case 'actions':
              return '100px';
            case 'SKU':
              return '120px';
            case 'price':
              return '100px';
            case 'platforms':
              return '250px';
            case 'quantity':
              return '90px';
            case 'issues':
              return '95px';
            case 'updated':
              return '120px';
            case 'created':
              return '120px';
            case 'notes':
              return '200px';
            case 'keywords':
              return '200px';
            case 'assignee':
              return '100px';
            case 'date sold':
              return '150px';
            case 'sold price':
              return '100px';
            case 'notes':
              return '100px';
            case 'cogs':
              return '100px';
            case 'fees':
              return '100px';
            case 'shipping cost':
              return '150px';
            case 'profit':
              return '100px';
            case 'assignee':
              return '100px';
            case 'drafts':
              return '100px';
            case 'condition':
              return '120px';
            case 'material':
              return '120px';
            case 'size':
              return '100px';
            case 'color':
              return '150px';
            case 'brand':
              return '150px';
            case 'UPC':
              return '150px';
            case 'shipping weight lb/kg':
              return '150px';
            case 'shipping weight oz/g':
              return '150px';
            case 'shipping package length':
              return '150px';
            case 'shipping package width':
              return '150px';
            case 'shipping package height':
              return '150px';
            case 'zip':
              return '150px';
            case 'sales':
              return '150px';
            default:
              return '100px'; // Default width
          }
        };

        const updatedColumnSizes = columns.map((col, i) => {
          const size = columnSizeMap[col.value] || checkerFunction(col.value);

          return {
            name: col.value,
            size,
          };
        });


        updatedColumnSizes.unshift({ name: 'default', size: '70px' });
        const size = JSON.stringify(updatedColumnSizes);
        const gridColumns = updatedColumnSizes.map((col) => col.size).join(' ');
        localStorage.setItem('size', size);
        if (tableElement.current) {
          tableElement.current.style.gridTemplateColumns = gridColumns;
        }
      } else {
        if (tableElement.current) {
          const columnWidthValues: any = columns.map((column) => {
            switch (column.value) {
              case 'image':
                return '120px';
              case 'title':
                return '250px';
              case 'actions':
                return '100px';
              case 'SKU':
                return '120px';
              case 'price':
                return '100px';
              case 'platforms':
                return '250px';
              case 'quantity':
                return '90px';
              case 'issues':
                return '95px';
              case 'updated':
                return '120px';
              case 'created':
                return '120px';
              case 'notes':
                return '200px';
              case 'keywords':
                return '200px';
              case 'assignee':
                return '100px';
              case 'date sold':
                return '150px';
              case 'sold price':
                return '100px';
              case 'notes':
                return '100px';
              case 'cogs':
                return '100px';
              case 'fees':
                return '100px';
              case 'shipping cost':
                return '150px';
              case 'profit':
                return '100px';
              case 'assignee':
                return '100px';
              case 'drafts':
                return '100px';
              case 'condition':
                return '120px';
              case 'material':
                return '120px';
              case 'size':
                return '100px';
              case 'color':
                return '150px';
              case 'brand':
                return '150px';
              case 'UPC':
                return '150px';
              case 'shipping weight lb/kg ':
                return '150px';
              case 'shipping weight oz/g':
                return '150px';
              case 'shipping package length':
                return '150px';
              case 'shipping package width':
                return '150px';
              case 'shipping package height':
                return '150px';
              case 'zip':
                return '150px';
              case 'sales':
                return '150px';
              default:
                return 'max-content'; // Default width
            }
          });
          columnWidthValues.unshift('70px');
          const size = JSON.stringify(columnWidthValues);
          tableElement.current.style.gridTemplateColumns =
            columnWidthValues.join(' ');
          localStorage.setItem('size', size);
        }
      }
    } catch (error) {
      localStorage.removeItem('size');
      localStorage.removeItem('cols');
      window.location.reload();
    }
  }, [columns, isMobile, gridView]);

  const removeListeners = useCallback(() => {
    window.removeEventListener('mousemove', mouseMove);
    window.removeEventListener('mouseup', removeListeners);
  }, [mouseMove]);

  const mouseUp = useCallback(() => {
    setActiveIndex(null);
    removeListeners();
  }, [setActiveIndex, removeListeners]);

  useEffect(() => {
    if (activeIndex !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }

    return () => {
      removeListeners();
    };
  }, [activeIndex, mouseMove, mouseUp, removeListeners]);

  useEffect(() => {
    const cols = localStorage.getItem('cols');
    if (cols) {
      setCols(cols);
    }
  }, []);


  useEffect(() => {
    if (tableElement.current) {
      const distanceFromRight =
          (window.innerWidth - tableElement.current.getBoundingClientRect().right) + 15;
      setDistanceFromRight(distanceFromRight);
    }
  }, [updator, mouseMove, mouseDown]);

  const handleHorizontalScroll = () => {
    const tableContainer = document.querySelector(
      '.resizable-table'
    ) as HTMLDivElement;
    const hasScrolll =
      tableContainer?.scrollWidth > tableContainer?.clientWidth;
    setHasScroll(hasScrolll);
  };

  const updateArrowsVisibility = (table:any) => {
    const scrollLeft = table.scrollLeft;
    const maxScrollLeft = table.scrollWidth - table.clientWidth;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < maxScrollLeft);
  };


  useEffect(() => {
    const table = tableElement.current;
    if (table) {
      updateArrowsVisibility(table);
      table.addEventListener("scroll", () => {
        updateArrowsVisibility(table);
      });
    }
  }, [tableElement.current]);

  useEffect(() => {
    const tableContainer = document.querySelector(
      '.resizable-table'
    ) as HTMLDivElement;
    const scrollLeftButton = document.querySelector('#left') as HTMLDivElement;
    const scrollRightButton = document.querySelector(
      '#right'
    ) as HTMLDivElement;

    if (tableContainer && scrollLeftButton && scrollRightButton) {
      let scrollInterval: NodeJS.Timeout;

      const startScrollLeft = () => {
        scrollInterval = setInterval(() => {
          tableContainer.scrollLeft -= 20;
        }, 20);
      };

      const startScrollRight = () => {
        scrollInterval = setInterval(() => {
          tableContainer.scrollLeft += 20;
        }, 20);
      };

      const stopScroll = () => {
        clearInterval(scrollInterval);
      };

      scrollLeftButton.addEventListener('mouseenter', startScrollLeft);
      scrollRightButton.addEventListener('mouseenter', startScrollRight);
      scrollLeftButton.addEventListener('mouseleave', stopScroll);
      scrollRightButton.addEventListener('mouseleave', stopScroll);
      window.addEventListener('resize', handleHorizontalScroll);

      // Initial check for horizontal scroll
      handleHorizontalScroll();

      return () => {
        window.removeEventListener('resize', handleHorizontalScroll);
        scrollLeftButton.removeEventListener('mouseenter', startScrollLeft);
        scrollRightButton.removeEventListener('mouseenter', startScrollRight);
        scrollLeftButton.removeEventListener('mouseleave', stopScroll);
        scrollRightButton.removeEventListener('mouseleave', stopScroll);
      };
    }
  }, [tableData, columns, cols, mouseMove, mouseUp, navigation, gridView]);

  return (
      <>

        {isMobile ? (
            <MobileSearch
                isMobile={isMobile}
                searchItem={searchItem}
                setSearchItem={setSearchItem}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                handleFetchForSearch={handleFetchForSearch}
                handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
                inputValues={inputValues}
                handleInputChange={handleInputChange}
                handleToggleDateRangeModalShow={handleToggleDateRangeModalShow}
                dateRangeModalShow={dateRangeModalShow}
                startChangedCreated={startChangedCreated}
                endChangedCreated={endChangedCreated}
                startDateCreated={startDateCreated}
                endDateCreated={endDateCreated}
                setStartChangedCreated={setStartChangedCreated}
                setEndChangedCreated={setEndChangedCreated}
                setStartDateCreated={setStartDateCreated}
                setEndDateCreated={setEndDateCreated}
                setCreatedCloseClicked={setCreatedCloseClicked}
                createdTippy={createdTippy}
            />
        ) : null}
        {isMobile ? (
            <SearchResult
                setHasMore={setHasMore}
                filterByArr={filterByArr}
                setFilterByArr={setFilterByArr}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                inputValues={inputValues}
                setInputValues={setInputValues}
                lpPlatforms={lpPlatforms}
                setLpPlatforms={setLpPlatforms}
                customMarketplaces={customMarketplaces}
                setCustomMarketplaces={setCustomMarketplaces}
                activePlatforms={activePlatforms}
                setActivePlatforms={setActivePlatforms}
                setSearchItem={setSearchItem}
                handleFetchForSearch={handleFetchForSearch}
                handleGetColumnSearchDebounced={handleGetColumnSearchDebounced}
                tabCounts2={tabCounts2}
                setTabCounts2={setTabCounts2}
                sendReguestColumnSearch={sendReguestColumnSearch}
                myHelpConditionFunc={myHelpConditionFunc}
                tab={tab}
                hasPro={hasPro}
                is_role_allowed={is_role_allowed}
                setStartDateCreated={setStartDateCreated}
                setEndDateCreated={setEndDateCreated}
            />
        ) : null}
        <div className="lp_custom_chrome_extension_selected_listings" style={{
          visibility: 'hidden',
          position: 'absolute',
          display: 'none',
          top: '-9999px'
        }}>{JSON.stringify(selectedListings)}</div>
        <div className='hiddenElementForTippyStickyColumns' ref={tippyElementStickyColumnsRef}></div>
        {isMobile && (
            <div className={styles.mobileTable}>
              <InfiniteScroll
                  dataLength={tableData.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  scrollThreshold={1}
                  loader={
                    hasMore ? (
                        <h4 style={{padding: '10px 0', fontFamily: 'sans-serif'}}>
                          Loading...
                        </h4>
                    ) : null
                  }
              >
                {loader ? (
                    <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '100%',
                          position: 'fixed',
                          top: '50%',
                          left: 0,
                          zIndex: 10,
                        }}
                    >
                      <MoonLoader color="#1e3166" loading={true}/>
                    </div>
                ) : (
                    tableData?.map((row, index) => (
                        <TableMobileCard shopifyDinamicLink={shopifyDinamicLink}
                                         setTabCounts2={setTabCounts2}
                                         row={row}
                                         setAllTableData={setAllTableData}
                                         tab={tab}
                                         tableData={tableData}
                                         setTableData={setTableData}
                                         index={index}
                                         isMobile={isMobile}
                                         syncTime={syncTime}
                                         customMarketplaces={customMarketplaces}
                                         hasExtensionInstall={hasExtensionInstall}
                                         shopifyName={shopifyName}
                                         shopifyURLFormat={shopifyURLFormat}
                        />
                    ))
                )}
              </InfiniteScroll>
            </div>
        )}
        {gridView && !isMobile ? (
            <div className={styles.gridTable}>
              <div
                  id="catalogPageTable"
                  className={styles.tableContainerGrid}
                  // style={{
                  //   marginTop: filterByArr?.length ? '0px' : '21px',
                  // }}
              >
                {loader ? (
                    <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '100%',
                          position: 'fixed',
                          top: '50%',
                          left: 0,
                          zIndex: 10,
                        }}
                    >
                      <MoonLoader color="#1e3166" loading={true}/>
                    </div>
                ) : (
                    tableData?.map((row, index) => (
                        <TableMobileCard shopifyDinamicLink={shopifyDinamicLink}
                                         row={row}
                                         setAllTableData={setAllTableData}
                                         tableData={tableData}
                                         setTableData={setTableData}
                                         index={index}
                                         isMobile={isMobile}
                                         tab={tab}
                                         syncTime={syncTime}
                                         customMarketplaces={customMarketplaces}
                                         shopifyName={shopifyName}
                                         shopifyURLFormat={shopifyURLFormat}
                                         setTabCounts2={setTabCounts2}
                        />
                    ))
                )}
              </div>
            </div>
        ) : (
            <>
              <div
                  className={`${styles.tableContainer} ${
                      tableData?.length === 0 || loader
                          ? styles.tableContainerWithNoResults
                          : ''
                  }`}
                  style={!gridView ?
                      {
                        overflow: "hidden",
                        // flexGrow: 1,
                        display: 'flex',
                        alignItems: 'flex-start',
                        height: 'fit-content'
                      } : {}}
              >
                <>
                  <div
                      className={
                        hasScroll && showLeftArrow ? styles.leftContainer : styles.hideLeftContainer
                      }
                      id="left"
                  >
                    <LeftArrow/>
                  </div>
                  <div
                      className={
                        hasScroll && showRightArrow ? styles.rightContainer : styles.hideRightContainer
                      }
                      id="right"
                      style={{right: distancefromRight}}
                  >
                    <RightArrow/>
                  </div>
                </>
                {!isMobile && (
                    <table
                        id="catalogPageTable"
                        className="resizable-table"
                        ref={tableElement}
                        style={!gridView ? {maxHeight: '100%'} : {}}
                    >
                      <thead>
                      <tr>
                        <th
                            className="sticky-element sticky-checkbox-column"
                            style={{
                              position: 'sticky',
                              top: '0',
                              background: '#F4F7FF',
                            }}
                        >

                          <Checkbox
                              name="allSelect"
                              checked={
                                  tableData?.length > 0 &&
                                  tableData?.filter((tbData) => tbData?.isChecked !== true)?.length < 1
                              }
                              onChange={handleChangeCheckbox}
                              modifier={selectedListings.length > 0 ? CheckboxModifier.Sticky : ''}
                              defaultStickyCheckbox={true}
                              visibility={!hasSimple}
                          />

                        </th>
                        {columns?.map((col, i) => (
                            <TableResizeColumn
                                key={col.value}
                                col={col}
                                i={i}
                                mouseDown={mouseDown}
                                id={col.value}
                                innerRef={col.ref}
                                activeIndex={activeIndex}
                                handleChangeDirection={handleChangeDirection}
                                resizableElementWidth={resizableElementWidth}
                                setResizableElementWidth={setResizableElementWidth}
                                directions={directions}
                                handleInputChange={handleInputChange}
                                inputValues={inputValues}
                                activePlatforms={activePlatforms}
                                handleTogglePlatformsModalShow={
                                  handleTogglePlatformsModalShow
                                }
                                filterByArr={filterByArr}
                                handleToggleDateRangeModalShow={handleToggleDateRangeModalShow}
                                dateRangeModalShow={dateRangeModalShow}
                                startChangedCreated={startChangedCreated}
                                endChangedCreated={endChangedCreated}
                                startDateCreated={startDateCreated}
                                endDateCreated={endDateCreated}
                                setStartChangedCreated={setStartChangedCreated}
                                setEndChangedCreated={setEndChangedCreated}
                                setStartDateCreated={setStartDateCreated}
                                setEndDateCreated={setEndDateCreated}
                                setCreatedCloseClicked={setCreatedCloseClicked}
                                createdTippy={createdTippy}
                                stickyColumnsList={stickyColumnsList}
                                setStickyColumnsList={setStickyColumnsList}
                                getStickyStyle={getStickyStyle}
                                tippyElementStickyColumnsRef={tippyElementStickyColumnsRef}
                            />
                        ))}
                      </tr>
                      </thead>
                      <tbody>
                      {loader ? (
                          <div className={styles.loaderTableCatalogOuter}>
                            <div className={styles.loaderTableCatalog}>
                              <MoonLoader color="#1e3166" size={35} loading={true}/>
                            </div>
                          </div>
                      ) : (
                          tableData?.map((row, index) => (
                              <TableRow iframeUpdate={iframeUpdate}
                                        key={row?.id}
                                        row={row}
                                        handleRowSelected={(e) =>
                                            handleRowSelected(e, row.id.toString(), row.isChecked)
                                        }
                                        setTabCounts2={setTabCounts2}
                                        setAllTableData={setAllTableData}
                                        columns={columns}
                                        isBulkEdit={isBulkEdit}
                                        tableData={tableData}
                                        setTableData={setTableData}
                                        index={index}
                                        tab={tab}
                                        resizableElementWidth={resizableElementWidth}
                                        handleBulkDelete={handleBulkDelete}
                                        handleBulkRestore={handleBulkRestore}
                                        customMarketplaces={customMarketplaces}
                                        syncTime={syncTime}
                                        saveActive={saveActive}
                                        setSaveActive={setSaveActive}
                                        shopifyDinamicLink={shopifyDinamicLink}
                                        setRetryActive={setRetryActive}
                                        retryActive={retryActive}
                                        platformsTippy={platformsTippy}
                                        hasBusiness={hasBusiness}
                                        hasPro={hasPro}
                                        hasBetaTeamRole={hasBetaTeamRole}
                                        is_role_allowed={is_role_allowed}
                                        selectedListings={selectedListings}
                                        selectedListingsData={selectedListingsData}
                                        setSelectedListingsData={setSelectedListingsData}
                                        listingsForDelete={listingsForDelete}
                                        hasExtensionInstall={hasExtensionInstall}
                                        shopifyName={shopifyName}
                                        shopifyURLFormat={shopifyURLFormat}
                                        stickyColumnsList={stickyColumnsList}
                                        getStickyStyle={getStickyStyle}
                                        mouseDown={mouseDown}
                                        activeIndex={activeIndex}
                                        tippyElementStickyColumnsRef={tippyElementStickyColumnsRef}
                              />
                          ))
                      )}

                      {tableData?.length === 0 && !loader && (
                          <div className={styles.noResultsOuter}>
                            <div
                                className={`${styles.noResultsContainer} ${
                                    gridView ? styles.noResultsContainerGridView : ''
                                }`}
                            >
                              {tableData?.length === 0 &&
                                  !loader &&
                                  filterBy.search_everything && (
                                      <p className={styles.noResult}>
                                        Can't search for word {filterBy.search_everything}
                                        . Try using more descriptive words
                                      </p>
                                  )}

                              {tableData?.length === 0 && !loader && (
                                  <p className={styles.noResult}>
                                    <p className={styles.noResult}>
                                      This search returned no results. <a target="_blank" href="/listings/express">Add a
                                      listing</a>
                                    </p>
                                  </p>
                              )}
                            </div>
                          </div>
                      )}
                      </tbody>
                    </table>
                )}
              </div>
            </>
        )}
        {platformsModalShow && (
            <PlatformsModal
                handleTogglePlatformsModalShow={handleTogglePlatformsModalShow}
                handleClick={handleClick}
                lpPlatforms={lpPlatforms}
                customMarketplaces={customMarketplaces}
                main={true}
            />
        )}
      </>
  );
};

export default Table
