import styles from './SettingsModal.module.scss';
import React from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';
import {instanceAxios} from "../../services";
import {toast} from "react-toastify";
import {debounce} from "lodash";
import ReactSelect from 'react-select';

import {CloseModal,PopoverIcon,EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,KidizenLogo,FacebookLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons" ;
import Tippy from "@tippyjs/react";
import './SettingsTippy.css';

const sendRequestToServerShopifyName = debounce(async(propId:any, newShopName:any, configIDExtension:any) => {
	try {
		const requestData = {
			filter_by: {
				id: configIDExtension,
			},
			data: {
				config: {
					[propId]: newShopName,
				}
			}
		};

		const response = await instanceAxios.put(
		 '/user-configs/extension/update',
		 requestData
		);
	}catch (error) {
		console.error(error);
	}
}, 500);

const SettingsModal = (props: any) => {
	const {
		setConnectionWizardActivate,
		setOpenSettingsModal,
		settingsMarketInfo,
		tabsData,
		setTabsData,
		unescapedRowMetadata,
		websiteSettings,
		setWebsiteSettings,
		setDescriptionChanged,
		configIDExtension,
		showGeneralSettings
	} = props;

	const handleCloseSettingsModal = () => {
		setOpenSettingsModal(false);
		setConnectionWizardActivate(false);
		setWebsiteSettings((prevSettings:any) => ({
			...prevSettings,
			descriptions: prevSettings.descriptions.map((description:any) => ({
				...description,
				changed: false
			}))
		}));
	};

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isDisabled ? 'rgba(182, 182, 182, 0.2)' : '#fff',
			color: '#1E3166',
			padding: 1,
			borderRadius: '8px',
			borderColor: '#B6B6B6',
			minWidth: '88px',
			cursor: 'pointer',
			width: '200px',
		}),
		menu: (provided: any, state: any) => ({
			...provided,
			zIndex: 999,
		}),
		option: (provided: any, state: any) => ({
			...provided,
			minHeight: '40px',
			cursor: 'pointer',
		}),
		menuList: (provided: any, state: any) => ({
			...provided,
			maxHeight: '200px',
			overflowY: 'auto',
		}),
	};

	const handleChangeDescriptionFooter = (index: number, event: any) => {
		setDescriptionChanged(true);
		const updatedDescriptions = [...websiteSettings.descriptions];

		if (updatedDescriptions[index].value !== event.target.value) {
			updatedDescriptions[index] = {
				...updatedDescriptions[index],
				value: event.target.value,
				changed: true,
			};
		}

		setWebsiteSettings({
			...websiteSettings,
			descriptions: updatedDescriptions,
		});
	};

	const sendCheckboxesRequest = async (propId:any, checked:any) => {
		try {
			const requestData = {
				filter_by: {
					id: configIDExtension,
				},
				data: {
					config: {
						[propId]: checked,
					}
				}
			};

			const response = await instanceAxios.put(
			 '/user-configs/extension/update',
			 requestData
			);
		}catch (error) {
			console.error(error);
		}
	};

	const tagsSettings:any = {
		duplicates : true,
		editTags:false,
	}

	const handleCheckboxChange = (e: any, tabName: any, propertyTitle: any, propId: any) => {
		const checked = e.target.checked;
		setTabsData((prevTabsData: any) => {
			const updatedTabsData = prevTabsData.map((tab: any) => {
				if (tab.name === tabName) {
					const updatedProperties = tab.properties.checkboxes.map((property: any) => {
						if (property.title === propertyTitle) {
							return {...property, checked: checked, changed: true};
						}
						return property;
					});

					return {...tab, properties: {...tab.properties, checkboxes: updatedProperties}};
				}
				return tab;
			});

			return updatedTabsData;
		});
		sendCheckboxesRequest(propId, checked); // Call sendCheckboxesRequest with propId and checked value
	};

	const handleShopNameChange = (tabName: any, newShopName: any, propId:any) => {
		const tabIndex = tabsData.findIndex((tab: any) => tab.name === tabName);
		if (tabIndex !== -1) {
			setTabsData((prevTabsData: any) => {
				const updatedTabsData = [...prevTabsData];
				updatedTabsData[tabIndex] = {
					...updatedTabsData[tabIndex],
					properties: {
						...updatedTabsData[tabIndex].properties,
						shopName: {
							...updatedTabsData[tabIndex].properties.shopName,
							value: newShopName,
							changed: true
						}
					}
				};
				return updatedTabsData;
			});
		}
		sendRequestToServerShopifyName.cancel();

		sendRequestToServerShopifyName(propId, newShopName, configIDExtension);
	};

	const handleInstaDescSeparatorChange = (tabName: any, newSeparatorValue: any) => {
		const tabIndex = tabsData.findIndex((tab: any) => tab.name === tabName);
		if (tabIndex !== -1) {
			setTabsData((prevTabsData: any) => {
				const updatedTabsData = [...prevTabsData];
				updatedTabsData[tabIndex] = {
					...updatedTabsData[tabIndex],
					properties: {
						...updatedTabsData[tabIndex].properties,
						instaDescSeparatorValue: {
							...updatedTabsData[tabIndex].properties.instaDescSeparatorValue,
							value: newSeparatorValue,
							changed: true
						}
					}
				};
				return updatedTabsData;
			});
		}
	};


	const handleAddTag = async (tabName: string, wordOrPhrase: string) => {
		try {
			let marketName = tabName;
			const generatedMarketName = marketName.toLowerCase() === 'general' ? 'all_marketplaces' : marketName.toLowerCase();
			const payload: any = {marketplace: generatedMarketName, word_or_phrase: wordOrPhrase};
			const response = await instanceAxios.post('prohibited-words-and-phrases/add', payload);
			if (response.status === 200) {
				const tagId = response.data.data.id;
				return tagId;
			}
		} catch (e) {
			toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
				position: 'bottom-right', // You can customize the notification position
			});
			console.log(e, 'error');
		}
	};

	const sendDropdownsRequest = async (propId:any, selectedValue:any, tabName:any) => {
		try {
			const emptyValue = tabName === 'Poshmark' ? null : " ";
			const valueToSend = selectedValue.length > 0 ? selectedValue : emptyValue;
			const requestData = {
				filter_by: {
					id: configIDExtension,
				},
				data: {
					config: {
						[propId]: valueToSend === 'null' ? null : valueToSend,
					}
				}
			};

			const response = await instanceAxios.put(
			 '/user-configs/extension/update',
			 requestData
			);

		}catch (error) {
			console.error(error);
		}
	};
	const handleDropDownChange = (selectedOption:any, tabName:any, propertyName:any, propId: any) => {
		const selectedValue = selectedOption.value;
		const updatedTabsData = tabsData.map((tab:any) => {
			if (tab.name === tabName) {
				return {
					...tab,
					properties: {
						...tab.properties,
						[propertyName]: {
							...tab.properties[propertyName],
							value: selectedOption,
							changed: true
						}
					}
				};
			}
			return tab;
		});
		setTabsData(updatedTabsData);

		const changedProperty = updatedTabsData.find((tab:any) => tab.name === tabName)?.properties[propertyName];
		if (changedProperty && changedProperty.changed) {
			sendDropdownsRequest(changedProperty.propId, selectedValue, tabName);
		}

	};
	const handleRemoveTag = async (id: any) => {
		try {
			const payload = {id};
			const response = await instanceAxios.delete('prohibited-words-and-phrases/delete', {data: payload});

		} catch (e) {
			toast.error(`Oops! We encountered an error while attempting to save your changes.  Please try again.`, {
				position: 'bottom-right',
			});
			console.log(e, 'error')
		}
	}

	let ids:any = [];
	const handleAddTagEvent = async(tag :any, tabName:string) => {
		try {

			const value = tag?.detail?.data?.value;
			const idFromCreated = await handleAddTag(tabName, value);
			let newObj = {
				id: idFromCreated,
				tagId: tag?.detail?.data?.__tagId
			}
			ids.push(newObj);

			// requestSent = false;
			if(idFromCreated) {
				setTabsData((prevTabsData: any) => {
					return prevTabsData.map((tab: any) => {
						if (tab.name === tabName) {
							const newTagObjects = {id: idFromCreated, value: value}
							const prevTags = tab?.properties?.wordAlertValue?.value || [];
							const updatedTags = [...prevTags, newTagObjects]
							return {
								...tab,
								properties: {
									...tab.properties,
									wordAlertValue: {
										...tab.properties.wordAlertValue,
										value: updatedTags,
									},
								},
							};
						}
						return tab;
					})
				})
			}
		}

		catch (e) {
			console.log('error' , e)
		}
	}
	const handleRemoveTagEvent = async(tag: any, tabName:string)=> {
		try {
			const value = tag?.detail?.data?.value
			const id = tag?.detail?.data?.id;
			const localAddedTagId = ids.find((item:any) => item.tagId === tag?.detail?.data?.__tagId)
			setTabsData((prevTabsData: any) => {
				return prevTabsData.map((tab: any) => {
					if (tab.name === tabName) {
						const removedTags = [{id: id ? id : localAddedTagId.id, value: value}]
						const prevTags = tab?.properties?.wordAlertValue?.value || [];
						const updatedTags =  [...prevTags.filter((prevTag: any) => !removedTags.some((removedTag: any) => removedTag.id === prevTag.id))];
						return {
							...tab,
							properties: {
								...tab.properties,
								wordAlertValue: {
									...tab.properties.wordAlertValue,
									value: updatedTags,
								},
							},
						};
					}
					return tab;
				})
			})
			await handleRemoveTag(id ? id : localAddedTagId.id);
		}catch (e) {
			console.log(e, 'error')
		}
	}

	return (
	 <div className={styles.settingsModal} id="settingsWizard">
		 <div className={styles.settingsModalContainer}>
			 <div className={styles.marketInfoContsiner}>
				 {settingsMarketInfo?.toLowerCase() === 'ebay' ? (
				  <EbayLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'etsy' ? (
				  <EtsyLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'poshmark' ? (
				  <PoshmarkLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'mercari' ? (
				  <MercaryLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'grailed' ? (
				  <GrailedLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'facebook' ? (
				  <FacebookLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'depop' ? (
				  <DepopLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'kidizen' ? (
				  <KidizenLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'shopify' ? (
				  <ShopifyLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'instagram' ? (
				  <InstagramLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'vestiaire' ? (
				  <VestiaireLogo height={40} width={40} />
				 ) : settingsMarketInfo?.toLowerCase() === 'amazon' ? (
				  <AmazonLogo height={40} width={40} />
				 ) : null}
				 <p>
					 <span style={{textTransform:settingsMarketInfo?.toLowerCase() !== 'ebay' ? "capitalize" : 'initial'}}> { settingsMarketInfo?.toLowerCase() === 'ebay' ? 'eBay' : settingsMarketInfo} </span>
					 <span> Settings</span>
				 </p>
			 </div>
			 {
				 tabsData.map((tab: any, index: number) => {
					 return(
					  <>
						  { tab?.name === 'General' && settingsMarketInfo?.toLowerCase() === 'general' &&  showGeneralSettings ? (
						   <div>
							   <div className={styles.checkBoxesExtension} >
								   {
									   tab.properties.checkboxes.map((item: any, index: number) => {
										   return (
											<div className={styles.extSettingsCheckboxesItem}>
												<input id={item.title} type="checkbox" className={styles.myCheckbox}
													   checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false}
													   onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)}
													   disabled={item.disabled ? true : false}/>
												<label className={`${item?.disabled ? styles.disabledText : ''}`}
													   htmlFor={item.title}>{item.title}

												</label>
												<span>
														{
														 (item.popoverEnabled || item.popoverDisabled) &&
														 <Tippy
														  interactive={true}
														  arrow={false}
														  zIndex={9999}
														  trigger="click"
														  content={
															    <div className={styles.popoverContent}>
															 		  <p>{`${item?.popoverEnabled}`}</p>
																	  <p>{`${item?.popoverDisabled}`}</p>
														    	</div>
														}
												>
															 <PopoverIcon
															  className={styles.popoverIcon}
															  color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
														}
													</span>

											</div>
										   )
									   })}

							   </div>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`}
									 htmlFor="">Customize word alert for all marketplaces:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>


						  ) :  tab?.name === 'Poshmark' && settingsMarketInfo?.toLowerCase() === 'poshmark' ? (
						   <div className={styles.contentInner}>
							   <div className={styles.checkBoxesExtensionPoshmark}>
								   {
									   tab.properties.checkboxes.map((item: any, index: number) => {
										   return (
											<div className={styles.extSettingsCheckboxesItem}>
												<input id={item.title} type="checkbox" className={styles.myCheckbox}
													   checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false}
													   onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)}
													   disabled={item.disabled ? true : false}/>
												<label className={`${item?.disabled ? styles.disabledText : ''}`}
													   htmlFor={item.title}>{item.title}

												</label>
												<span>
														{
														 (item.popoverEnabled || item.popoverDisabled) &&
														 <Tippy
														  interactive={true}
														  arrow={false}
														  zIndex={9999}
														  trigger="click"
														  content={
															  <div className={styles.popoverContent}>
																  <p>{`${item?.popoverEnabled}`}</p>
																  <p>{`${item?.popoverDisabled}`}</p>
															  </div>
														  }
														 >
															 <PopoverIcon
															  className={styles.popoverIcon}
															  color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
														}
													</span>

											</div>
										   )
									   })}
							   </div>
							   <div className={styles.poshmarkNewTitle}>
								   <p>Poshmark sharing settings are setup directly on the extension.</p>
							   </div>
							   {
								tab.properties?.discount && <div className={styles.dropdownPoshmark}>
									<div>
										<label className={`${tab.properties?.discount?.disabled ? styles.disabledText : ''}`}>Set discounted shipping on Poshmark
											{
											 (tab.properties?.discount?.popoverEnabled || tab.properties?.discount?.popoverDisabled) &&
											 <Tippy
											  interactive={true}
											  arrow={false}
											  zIndex={9999}
											  trigger="click"
											  content={
												  <div className={styles.popoverContent}>
													  <p>{`${tab.properties?.discount?.popoverEnabled}`}</p>
													  <p>{`${tab.properties?.discount?.popoverDisabled}`}</p>
												  </div>
											  }
											 >
												 <PopoverIcon
												  className={styles.popoverIcon}
												  color={'#fff'}
												 ></PopoverIcon>
											 </Tippy>
											}
										</label>
									</div>
									<ReactSelect
									 key={tab.name}
									 defaultValue={tab?.properties?.discount?.changed ? tab.properties?.discount?.value : {
										 label: unescapedRowMetadata[tab?.properties?.discount?.propId],
										 value: unescapedRowMetadata[tab?.properties?.discount?.propId]
									 }}
									 styles={customStyles}
									 placeholder=""
									 options={tab.properties.discount?.options}
									 onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'discount', tab?.properties?.discount?.propId)}
									 isDisabled={tab.properties.discount?.disabled}
									/>
								</div>
							   }
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) : tab?.name === 'Shopify' && settingsMarketInfo?.toLowerCase() === 'shopify' ? (
						   <div>
							   {/*<div className={styles.shopifyTabBlock}>*/}
								{/*   <label className={`platformLabel ${ tab?.properties?.shopName?.disabled ? styles.disabledText : ''}`} htmlFor="shopifyId3">*/}
								{/*	   Shopify shop name*/}
								{/*	   {*/}
								{/*		(tab?.properties?.shopName?.popoverEnabled || tab?.properties?.shopName?.popoverDisabled) &&*/}
								{/*		<Tippy*/}
								{/*		 interactive={true}*/}
								{/*		 arrow={false}*/}
								{/*		 zIndex={9999}*/}
								{/*		 trigger="click"*/}
								{/*		 content={*/}
								{/*			 <div className={styles.popoverContent}>*/}
								{/*				 <p>{`${tab?.properties?.shopName?.popoverEnabled}`}</p>*/}
								{/*				 <p>{`${tab?.properties?.shopName?.popoverDisabled}`}</p>*/}
								{/*			 </div>*/}
								{/*		 }*/}
								{/*		>*/}
								{/*			<PopoverIcon*/}
								{/*			 className={styles.popoverIcon}*/}
								{/*			 color={'#fff'}*/}
								{/*			></PopoverIcon>*/}
								{/*		</Tippy>*/}
								{/*	   }*/}
								{/*   </label>*/}
								{/*   <input*/}
								{/*	id="shopifyId3"*/}
								{/*	type="text"*/}
								{/*	className="platformTabInput"*/}
								{/*	value={tab?.properties?.shopName.changed ? tab?.properties?.shopName.value : unescapedRowMetadata[tab?.properties?.shopName.propId] || ''}*/}
								{/*	onChange={(e) => handleShopNameChange(tab.name, e.target.value, tab?.properties?.shopName.propId)}*/}
								{/*	disabled={tab?.properties?.shopName?.disabled ? true : false}*/}
								{/*   />*/}
							   {/*</div>*/}
							   <div className={styles.shopifyCheckboxBlock}>
								   {
									   tab.properties.checkboxes.map((item: any, index: number) => {
										   return (
											<div className={styles.extSettingsCheckboxesItem}>
												<input id={item.title} type="checkbox" className={styles.myCheckbox}
													   checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false}
													   onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)}
													   disabled={item.disabled ? true : false}/>
												<label className={`${item?.disabled ? styles.disabledText : ''}`}
													   htmlFor={item.title}>{item.title}

												</label>
												<span>
														{
														 (item.popoverEnabled || item.popoverDisabled) &&
														 <Tippy
														  interactive={true}
														  arrow={false}
														  zIndex={9999}
														  trigger="click"
														  content={
															  <div className={styles.popoverContent}>
																  <p>{`${item?.popoverEnabled}`}</p>
																  <p>{`${item?.popoverDisabled}`}</p>
															  </div>
														  }
														 >
															 <PopoverIcon
															  className={styles.popoverIcon}
															  color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
														}
													</span>

											</div>
										   )
									   })}

							   </div>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`}
									 htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) : tab?.name === 'Instagram' && settingsMarketInfo?.toLowerCase() === 'instagram' ? (
						   <div className={styles.instagramBlock}>
							   <div className={styles.labelTitle}>
								   <label htmlFor="instagramID4" className={`platformLabel ${tab?.properties?.instaDescSeparatorValue?.disabled ? styles.disabledText : ''}`}>
									   Instagram description seperator
									   {
										(tab?.properties?.instaDescSeparatorValue?.popoverEnabled || tab?.properties?.instaDescSeparatorValue?.popoverDisabled) &&
										<Tippy
										 interactive={true}
										 arrow={false}
										 zIndex={9999}
										 trigger="click"
										 content={
											 <div className={styles.popoverContent}>
												 <p>{`${tab?.properties?.instaDescSeparatorValue?.popoverEnabled}`}</p>
												 <p>{`${tab?.properties?.instaDescSeparatorValue?.popoverDisabled}`}</p>
											 </div>
										 }
										>
											<PopoverIcon
											 className={styles.popoverIcon}
											 color={'#fff'}
											></PopoverIcon>
										</Tippy>
									   }
								   </label>
								   <input
									type="text"
									id={`instagramID4`}
									className="platformTabInput"
									value={tab?.properties?.instaDescSeparatorValue.changed ? tab?.properties?.instaDescSeparatorValue.value : unescapedRowMetadata[tab?.properties?.instaDescSeparatorValue.propId] || ''}
									onChange={(e) => handleInstaDescSeparatorChange(tab.name, e.target.value)}
									disabled={tab?.properties?.instaDescSeparatorValue?.disabled ? true : false}
								   />
							   </div>
							   <div className={styles.instagramCheckboxBlock}>
								   {
									   tab.properties.checkboxes.map((item: any, index: number) => {
										   return (
											<div className={styles.extSettingsCheckboxesItem}>
												<input id={item.title} type="checkbox" className={styles.myCheckbox}
													   checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false}
													   onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)}
													   disabled={item.disabled ? true : false}/>
												<label className={`${item?.disabled ? styles.disabledText : ''}`}
													   htmlFor={item.title}>{item.title}

												</label>
												<span>
														{
														 (item.popoverEnabled || item.popoverDisabled) &&
														 <Tippy
														  interactive={true}
														  arrow={false}
														  zIndex={9999}
														  trigger="click"
														  content={
															  <div className={styles.popoverContent}>
																  <p>{`${item?.popoverEnabled}`}</p>
																  <p>{`${item?.popoverDisabled}`}</p>
															  </div>
														  }
														 >
															 <PopoverIcon
															  className={styles.popoverIcon}
															  color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
														}
													</span>

											</div>
										   )
									   })}
							   </div>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`}
									 htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) : tab?.name === 'Etsy' && settingsMarketInfo?.toLowerCase() === 'etsy' ? (
						   <div className={styles.etsyBlock}>
							   <div className={styles.etsyCheckboxes}>
								   {
									   tab.properties.checkboxes.map((item: any, index: number) => {
										   return (
											<div className={styles.extSettingsCheckboxesItem}>
												<input id={item.title} type="checkbox" className={styles.myCheckbox}
													   checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false}
													   onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)}
													   disabled={item.disabled ? true : false}/>
												<label className={`${item?.disabled ? styles.disabledText : ''}`}
													   htmlFor={item.title}>{item.title}

												</label>
												<span>
														{
														 (item.popoverEnabled || item.popoverDisabled) &&
														 <Tippy
														  interactive={true}
														  arrow={false}
														  zIndex={9999}
														  trigger="click"
														  content={
															  <div className={styles.popoverContent}>
																  <p>{`${item?.popoverEnabled}`}</p>
																  <p>{`${item?.popoverDisabled}`}</p>
															  </div>
														  }
														 >
															 <PopoverIcon
															  className={styles.popoverIcon}
															  color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
														}
													</span>

											</div>
										   )
									   })}
							   </div>
							   <div className={styles.etsyQuestionSelects}>
								   <div>
									   <div>
										   <label>What type of item is it?</label>
									   </div>
									   <ReactSelect
										key={tab.name}
										defaultValue={tab.properties.itemType.changed ? tab.properties.itemType.value : {
											label: unescapedRowMetadata[tab?.properties?.itemType.propId],
											value: unescapedRowMetadata[tab?.properties?.itemType.propId]
										}}
										styles={customStyles}
										placeholder=""
										options={tab.properties.itemType.options}
										onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'itemType', tab?.properties?.itemType.propId)}
									   />
								   </div>
								   <div>
									   <div>
										   <label>Who made it?</label>
									   </div>
									   <ReactSelect
										key={tab.name}
										defaultValue={tab.properties.whoMaid.changed ? tab.properties.whoMaid.value : {
											label: unescapedRowMetadata[tab?.properties?.whoMaid.propId],
											value: unescapedRowMetadata[tab?.properties?.whoMaid.propId]
										}}
										styles={customStyles}
										placeholder=""
										options={tab.properties.whoMaid.options}
										onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'whoMaid', tab?.properties?.itemType.propId)}
									   />
								   </div>
								   <div>
									   <div>
										   <label>What is it?</label>
									   </div>
									   <ReactSelect
										key={tab.name}
										defaultValue={tab.properties.whatIsIt.changed ? tab.properties.whatIsIt.value : {
											label: unescapedRowMetadata[tab?.properties?.whatIsIt.propId],
											value: unescapedRowMetadata[tab?.properties?.whatIsIt.propId]
										}}
										styles={customStyles}
										placeholder=""
										options={tab.properties.whatIsIt.options}
										onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'whatIsIt', tab?.properties?.itemType.propId)}
									   />
								   </div>
								   <div>
									   <div>
										   <label>When was it made?</label>
									   </div>
									   <ReactSelect
										key={tab.name}
										defaultValue={tab.properties.whenWasItMade.changed ? tab.properties.whenWasItMade.value : {
											label: unescapedRowMetadata[tab?.properties?.whenWasItMade.propId],
											value: unescapedRowMetadata[tab?.properties?.whenWasItMade.propId]
										}}
										styles={customStyles}
										placeholder=""
										options={tab.properties.whenWasItMade.options}
										onChange={(selectedOption) => handleDropDownChange(selectedOption, tab.name, 'whenWasItMade', tab?.properties?.itemType.propId)}
									   />
								   </div>
							   </div>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) : tab?.name === 'eBay' && settingsMarketInfo?.toLowerCase() === 'ebay' ? (
						   <div className={styles.ebayBlock}>
							   <div className={styles.ebayCheckboxes}>
								   {
									   tab.properties.checkboxes.map((item: any, index: number) => {
										   return (
											<div className={styles.extSettingsCheckboxesItem}>
												<input id={item.title} type="checkbox" className={styles.myCheckbox}
													   checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false}
													   onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)}
													   disabled={item.disabled ? true : false}/>
												<label className={`${item?.disabled ? styles.disabledText : ''}`}
													   htmlFor={item.title}>{item.title}

												</label>
												<span>
														{
														 (item.popoverEnabled || item.popoverDisabled) &&
														 <Tippy
														  interactive={true}
														  arrow={false}
														  zIndex={9999}
														  trigger="click"
														  content={
															  <div className={styles.popoverContent}>
																  <p>{`${item?.popoverEnabled}`}</p>
																  <p>{`${item?.popoverDisabled}`}</p>
															  </div>
														  }
														 >
															 <PopoverIcon
															  className={styles.popoverIcon}
															  color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
														}
													</span>
											</div>
										   )
									   })}
							   </div>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`}
									 htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }
						   </div>
						  ) : tab?.name === 'Mercari' && settingsMarketInfo?.toLowerCase() === 'mercari' ? (
						   <div className={styles.mercariBlock}>
							   <div className={styles.mercariCheckboxs}>
								   {
									   tab.properties.checkboxes.map((item: any, index: number) => {
										   return (
											<div className={styles.extSettingsCheckboxesItem}>
												<input id={item.title} type="checkbox" className={styles.myCheckbox}
													   checked={item.changed ? item.checked ? true : false : unescapedRowMetadata[item.propId] || false}
													   onChange={(e) => handleCheckboxChange(e, tab.name, item.title, item.propId)}
													   disabled={item.disabled ? true : false}/>
												<label className={`${item?.disabled ? styles.disabledText : ''}`}
													   htmlFor={item.title}>{item.title}

												</label>
												<span>
														{
														 (item.popoverEnabled || item.popoverDisabled) &&
														 <Tippy
														  interactive={true}
														  arrow={false}
														  zIndex={9999}
														  trigger="click"
														  content={
															  <div className={styles.popoverContent}>
																  <p>{`${item?.popoverEnabled}`}</p>
																  <p>{`${item?.popoverDisabled}`}</p>
															  </div>
														  }
														 >
															 <PopoverIcon
															  className={styles.popoverIcon}
															  color={'#fff'}
															 ></PopoverIcon>
														 </Tippy>
														}
													</span>
											</div>
										   )
									   })}
							   </div>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`}
									 htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) : tab?.name === 'Facebook' && settingsMarketInfo?.toLowerCase() === 'facebook' ? (
						   <div className={styles.facebookBlock}>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) : tab?.name === 'Grailed' && settingsMarketInfo?.toLowerCase() === 'grailed' ? (
						   <div className={styles.grailedBlock}>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) : tab?.name === 'Depop' && settingsMarketInfo?.toLowerCase() === 'depop' ? (
						   <div className={styles.depopBlock}>
							   {
								tab?.properties?.wordAlertValue && <div className={styles.textareaBlock}>
									<label className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledText : ''}`} htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings} onAdd={(tag) => handleAddTagEvent(tag, tab.name)}
										  onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}
										  defaultValue={tab?.properties?.wordAlertValue?.value}
										  className={styles.tags_container}/>
									<div
									 className={`${tab?.properties?.wordAlertValue?.disabled ? styles.disabledTags : ''}`}></div>
								</div>
							   }

						   </div>
						  ) :  tab?.name === 'Kidizen' && settingsMarketInfo?.toLowerCase() === 'kidizen' ? (
						   <div className={styles.kidizenBlock}>
							   {
								tab?.properties?.wordAlertValue &&  <div className={styles.textareaBlock}>
									<label htmlFor="">Customize word alert for {tab.name}:
										{
										 (tab?.properties?.wordAlertValue?.popoverEnabled || tab?.properties?.wordAlertValue?.popoverDisabled) &&
										 <Tippy
										  interactive={true}
										  arrow={false}
										  zIndex={9999}
										  trigger="click"
										  content={
											  <div className={styles.popoverContent}>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverEnabled}`}</p>
												  <p>{`${tab?.properties?.wordAlertValue?.popoverDisabled}`}</p>
											  </div>
										  }
										 >
											 <PopoverIcon
											  className={styles.popoverIcon}
											  color={'#fff'}
											 ></PopoverIcon>
										 </Tippy>
										}
									</label>
									<Tags settings={tagsSettings}  onAdd={(tag) => handleAddTagEvent(tag, tab.name)} onRemove={(tagId) => handleRemoveTagEvent(tagId, tab.name)}  defaultValue={tab?.properties?.wordAlertValue?.value}   className={styles.tags_container} />
								</div>
							   }

						   </div>
						  ) : tab?.name === 'Vestiaire' && settingsMarketInfo?.toLowerCase() === 'vestiaire' ? (
						   <div className={styles.vestiaireBlock}>
						   </div>
						  ) : null }
					  </>
					 )
				 })
			 }
			 {websiteSettings?.descriptions?.map((item: any, index: number) => (
			  <>
				  {
					  settingsMarketInfo?.toLowerCase() === item.marketplace.toLowerCase() ? (
					   <div className={styles.textareaBlock} key={item.propdId}>
						   <label>{item.name} Footer</label>
						   <div>
                     <textarea
					  value={item.value}
					  onChange={(event) => handleChangeDescriptionFooter(index, event)}
					 ></textarea>
						   </div>
					   </div>
					  ) : null
				  }
			  </>

			 ))}
			 <div
			  className={styles.closeModal}
			  onClick={() => handleCloseSettingsModal()}
			 >
				 <CloseModal />
			 </div>
		 </div>
	 </div>
	);
};

export default SettingsModal;