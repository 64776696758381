import React, { useEffect, useRef, useState } from 'react';
import {useSelector} from "react-redux";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProgressiveImg from './ProgresiveImg';
import FullImageSlider from "./FullImageSlider";
import styles from './Slider.module.scss';
import './slider.css';
import { ArrowLeftSlick, ArrowRightSlick } from '../../../../../assets/icons';

interface GalleryArrowProps {
    ref?: string;
    currentSlide?: number;
    slideCount?: number;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}
interface Images {
    id: number;
    link: string;
    type: string;
}

interface Index {
    imageUrls: Array<Images> | undefined;
    imagesLength: number;
}
const ImagesSlider: React.FC<Index> = ({ imageUrls, imagesLength }) => {
    const thumbNail = imageUrls?.find((i) => i.type === 'thumbnail');
    const thumbnailUrl = thumbNail!?.link ? thumbNail!?.link.replace('_LP', '_CI') : undefined;
    const image:any = `${thumbnailUrl}`;
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const itemIndex:any = null;
    let sliderRef: any = React.createRef();
    let arrowRef: any = useRef(null);
    let slidesToShow;
    const placeholderSrc = image?.replace('_TN.', '_CI.');
    const isMobile = useSelector((state: any) => state.navigation.isMobile);
    const [showFullImage, setShowFullImage] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [fullImageLoader, setFullImageLoader] = useState(false);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (windowWidth <= 768) {
        slidesToShow = 2;
    } else if (windowWidth >= 768 && windowWidth <= 1175) {
        slidesToShow = 1;
    } else if (windowWidth > 1175 && windowWidth <= 1315) {
        slidesToShow = 2;
    } else if (windowWidth > 1315 && windowWidth <= 1490) {
        slidesToShow = 3;
    } else if (windowWidth > 1490 && windowWidth <= 1630) {
        slidesToShow = 4;
    } else if (windowWidth > 1630 && windowWidth <= 1700) {
        slidesToShow = 5;
    } else if (windowWidth > 1700 && windowWidth <= 1900) {
        slidesToShow = 6;
    } else if (windowWidth > 1900) {
        slidesToShow = 7;
    } else {
        slidesToShow = 5;
    }

    if (!Array.isArray(imageUrls) || imageUrls.length <= 0) {
        return null;
    }

    const GalleryPrevArrow: React.FC<GalleryArrowProps> = ({currentSlide, slideCount, ...props}) => {
        const {onClick } = props;
        return (
            <>
                {currentSlide !== 0 ? (
                    <div {...props} className={styles.arrowContainer} onClick={onClick}>
                        <div className={styles.leftArrow}>
                            <ArrowLeftSlick className={styles.arrowSvg} />
                        </div>
                    </div>
                ) : (
                    <div {...props} className={styles.arrowContainer} onClick={onClick}>
                        <div className={styles.inactiveLeftArrow}>
                            <ArrowLeftSlick className={styles.arrowSvg} />
                        </div>
                    </div>
                )}
            </>
        );
    };
    const GalleryNextArrow: React.FC<GalleryArrowProps> = ({currentSlide, slideCount, ...props}) => {
        const { onClick } = props;

        return (
            <>
                {slideCount && currentSlide !== slideCount - settings.slidesToShow ? (
                    <div {...props} className={styles.arrowContainer} onClick={onClick}>
                        <div className={styles.rightArrow}>
                            <ArrowRightSlick className={styles.arrowSvg} />
                        </div>
                    </div>
                ) : (
                    <div {...props} className={styles.arrowContainer} onClick={onClick}>
                        <div className={styles.inactiveRightArrow}>
                            <ArrowRightSlick className={styles.arrowSvg} />
                        </div>
                    </div>
                )}
            </>
        );
    };

    const settings = {
        infinite: false,
        centerPadding: '150px',
        slidesToShow: slidesToShow,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    rows: 1,
                    slidesToShow: 2,
                },
            },
        ],
        rows: 2,
        speed: 500,
        slidesPerRow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <GalleryNextArrow ref={arrowRef} />,
        prevArrow: <GalleryPrevArrow />,
    };
    return (
        <>
            {showFullImage && (
                <FullImageSlider
                    resultImageUrls={imageUrls}
                    setShowFullImage={setShowFullImage}
                    imageIndex={imageIndex}
                    fullImageLoader={fullImageLoader}
                />
            )}
            {
                isMobile ? <div className={styles.SliderContainer}>
                    <ProgressiveImg
                        src={placeholderSrc}
                        placeholderSrc={image}
                        alt="item for sale"
                        resultImageUrls={imageUrls}
                    />

                    {imagesLength > 1 && (
                        <div className={styles.slider} id="singlePageViewSlider">
                            <Slider ref={sliderRef} {...settings}>
                                {imageUrls?.slice(1).map((slide, index) => {
                                    return (
                                        <div key={index} className={styles.slide}>
                                            <p className={styles.imageOrder}>{index + 2}</p>
                                            <img
                                                onClick={() => {
                                                    // handleItemClick(slide?.id, index);
                                                    setFullImageLoader(true);
                                                    setTimeout(()=>{
                                                        setFullImageLoader(false);
                                                    },1000)
                                                    setShowFullImage(true);
                                                    setImageIndex(index + 1);
                                                }}
                                                src={slide?.link?.replace('_LP', '_CI')}
                                                alt={`Img ${index}`}
                                                key={index}
                                                // className={styles.image}
                                                className={index === itemIndex ? 'imageSelected' : 'image'}
                                            />
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    )}
                </div> : (
                    <div className={styles.imagesGallery}>
                        {imageUrls?.map((image, index) => {
                            return (
                                <div key={index} className={styles.imagesGalleryContainer}>
                                    <p className={styles.imageOrder}>{index + 1}</p>
                                    <img
                                        onClick={() => {
                                            // handleItemClick(slide?.id, index);
                                            setFullImageLoader(true);
                                            setTimeout(()=>{
                                                setFullImageLoader(false);
                                            },1000)
                                            setShowFullImage(true);
                                            setImageIndex(index);
                                        }}
                                        src={image?.link?.replace('_LP', '_CI')}
                                        alt={`Img ${index}`}
                                        key={index}
                                        // className={styles.image}
                                        className={index === itemIndex ? 'imageSelected' : 'image'}
                                    />
                                </div>
                            );
                        })}

                    </div>
                )
            }
        </>

    );
};

export default ImagesSlider;