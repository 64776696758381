import React from 'react' ;
import styles from '../../SubAccountTable.module.scss' ;
import {useSelector} from "react-redux";

interface ITableResizeColumnProps {
	col:any;
	mouseDown:(index:number) => void;
	activeIndex:number | null ;
	i:number;
}

const TableResizeColumn = (props:ITableResizeColumnProps) => {
	const {col,mouseDown,activeIndex, i} = props ;
	const isMobile = useSelector((state:any) => state.navigation.isMobile)

	return (
	 <th
	  key={col.order}
	  ref={col.ref}
	 >
		 <div
		  className={styles['table__th-heading']}
		 >{col.heading}</div>
		 {
		  !isMobile && <div
		   style={{
			   height: '100%',
			   position: 'absolute',
			   zIndex: 534534,
			   display: col.value === 'actions' ? 'none' : 'block',
		   }}
		   onMouseDown={() => mouseDown(i)}
		   className={`resize-handle ${
			activeIndex === i ? 'active' : 'idle'
		   }`}
		  />
		 }
	 </th>
	)
}

export default TableResizeColumn;