import {useEffect, useMemo, useRef, useState} from "react";
import DragSort from "@yaireo/dragsort";
import Tags from "@yaireo/tagify/dist/react.tagify";
import '@yaireo/tagify/dist/tagify.css';
import './tagify.css';
import styles from './TextAreaTagify.module.scss';

const colors = [
    'Black',
    'Blue',
    'Brown',
    'Gold',
    'Gray',
    'Green',
    'Orange',
    'Pink',
    'Purple',
    'Red',
    'Silver',
    'White',
    'Yellow',
];

export const TextAreaTagify = ({value, name, handleTagifyChange, placeholder} : any) => {
    const [tagsComponentKey, setTagsComponentKey] = useState(Date.now());
    const [tagifyProps, setTagifyProps] = useState({});
    const tagifyRefDragSortKeywrods: any = useRef();

    useMemo(() => {
        if (tagifyRefDragSortKeywrods.current)
            new DragSort(tagifyRefDragSortKeywrods.current.DOM.scope, {
                selector: '.tagify__tag',
                callbacks: {
                    dragEnd: onDragEnd,
                },
            });
    }, [tagifyRefDragSortKeywrods.current]);

    useEffect(() => {
        if(name === 'input_209') {
            setTagifyProps({
                whitelist: colors,
                showFilteredDropdown: 'a', // Ensure dropdown shows on typing
                dropdown: {
                    enabled: 1, // Always show the dropdown
                },
            });
        }
    }, []);

    // must update Tagify's value according to the re-ordered nodes in the DOM
    function onDragEnd() {
        tagifyRefDragSortKeywrods.current.updateValueByDOMTags();
    }


    return (
     <div>
         <Tags
          {...tagifyProps}
          placeholder={placeholder}
          className={styles.tagsStyles}
          key={tagsComponentKey}
          tagifyRef={tagifyRefDragSortKeywrods}
          defaultValue={value}
          value={value}
          onChange={(e: any) => handleTagifyChange(e, name)}
         />
     </div>
    )
}