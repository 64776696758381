import React, { useEffect, useState } from 'react';
import styles from './TextAreaCount.module.scss'
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
export const TextareaWithCount = ({
									  placeholder,
									  name,
									  value,
									  limit,
									  height,
									  handleChange,
									  textareaRef,
									  showCount
								  }: any) => {
	const [characterCount, setCharacterCount] = useState(0);
	const id = Math.random().toString();

	useEffect(() => {
		if (value) {
			setCharacterCount(value.length);
		}
	}, [value]);

	const isSmallScreenLaptop = UseIsSmallLaptop() ;

	return (
	 <div style={{ width: '100%' }}>
		 <textarea
		  className={styles.brandInfoTextarea}
		  id={id}
		  ref={textareaRef}
		  value={value}
		  name={name}
		  style={{ height: isSmallScreenLaptop ? height * 0.8 : height }}
		  placeholder={placeholder}
		  maxLength={limit}
		  onChange={(e) => {
			  const newText = e.target.value;
			  if (newText.length <= limit) {
				  handleChange(e)
				  setCharacterCount(newText.length);
			  }
		  }}
		 />
		 {
		  showCount && 	<p className={styles.charactersQuantity}>
				 Character Count: {characterCount} / {limit}
			 </p>
		 }

	 </div>
	);
};