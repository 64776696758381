import React, { useState, ChangeEvent } from 'react';
import { ITableData } from '../../../../../../interfaces';
import styles from '../../PlatformsModal.module.scss';

import { getPlatformLink } from '../../../../../../utils/getPlatformLink';

import {PlatformIconShop,EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,SoldLogo,KidizenLogo,FacebookLogo,ListingPartyLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons"

interface IplatformModalRowProps {
  item: {
    id: number;
    platform: {
      code?:any;
      title: string;
      isChecked: boolean;
      platformId: string;
      platformIdFromIframe?: any;
    };
    notOnPlatform: {
      code?:any;
      title: string;
      isChecked: boolean;
    };
    color?: any;
  };
  handleClick?: (platformName: string) => void;
  main?: boolean;
  unescapedRowMetadata: any;
  tableData?: ITableData[];
  setTableData?: (tableData: ITableData[]) => void;
  index?: number;
  setChangedPatforms?: (value: any) => void;
  isCustom: boolean;
  saveActive?:any;
  setSaveActive?:any;
  setRetryActive?:any;
  retryActive?:any;
}

interface IPlatformInputs {
  [key: string]: string;
}

function PlatformModalRow(props: IplatformModalRowProps) {
  const {
    item,
    handleClick,
    main,
    unescapedRowMetadata,
    tableData,
    index,
    setTableData,
    setChangedPatforms,
    isCustom,
    saveActive,
    setSaveActive,
    setRetryActive,
    retryActive
  } = props;

  let initialPlatformInputs = main
      ? {}
      : item?.platform?.platformIdFromIframe  !== undefined ? {
        [isCustom ? `newPlatformIdByCustom${item.platform.title}` : `newPlatformIdBy${item.platform.title}`]:
        item?.platform?.platformIdFromIframe,
      }  : isCustom ?  {
           [`newPlatformIdByCustom${item.platform.title}`]:
            unescapedRowMetadata[item.platform?.platformId],
           } :  {
           [`newPlatformIdBy${item.platform.title}`]:
            unescapedRowMetadata[item.platform?.platformId],
   };

  const [platformInputs, setPlatformInputs] = useState<IPlatformInputs>(
      initialPlatformInputs,
  );


  const defaultPlatformIconUrl: string =
      'https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/shop_light.svg';
  const handleChangePlatformId = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPlatformInputs({
      ...platformInputs,
      [name]: value,
    });

    if (tableData && index !== undefined) {
      const updatedItems = [...tableData];
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
        changed: true,
      };

      if (setTableData) {
        setTableData(updatedItems);
      }
    }
    if (setChangedPatforms) {
      setChangedPatforms({ ...platformInputs });
    }
    if (setSaveActive && !saveActive) {
      setSaveActive(true);
    }
    if(retryActive && setRetryActive) {
      setRetryActive(false)
    }
  };

  const idValue = platformInputs[`newPlatformIdBy${item.platform.title}`];

  const PlatformLink: any = getPlatformLink(item.platform.title, idValue);

  return (
      <div className={styles.platformsModal__row}>
        <div className={styles.platformsModal__logo}>
          {item.platform.title !== 'SOLD' && !main ? (
              <a target="_blank" href={PlatformLink}>
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'eBay' ? (
                    <EbayLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Amazon' ? (
                    <AmazonLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Grailed' ? (
                    <GrailedLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Shopify' ? (
                    <ShopifyLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Etsy' ? (
                    <EtsyLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Depop' ? (
                    <DepopLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Poshmark' ? (
                    <PoshmarkLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'SOLD' ? (
                    <SoldLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Kidizen' ? (
                    <KidizenLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Facebook' ? (
                    <FacebookLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Listing Party' ? (
                    <ListingPartyLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Vestiaire' ? (
                    <VestiaireLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Instagram' ? (
                    <InstagramLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Mercari' ? (
                    <MercaryLogo />
                ) : null}
                {isCustom && <div style={item?.color ? {border:`1px solid ${item?.color}`, padding:'10px', display:'flex', justifyContent:'center', alignItems:'center', height:'40px', borderRadius:'4px'} : {border: '1px solid #000', padding:'10px', display:'flex', justifyContent:'center', alignItems:'center', height:'40px', borderRadius:'4px'}}>
                  <PlatformIconShop
                   width="20px"
                   height="20px"
                   fill={item?.color ? item?.color : '#000'}
                  />
                </div>
                }
              </a>
          ) : (
              <div className={styles.platformsModal__logo}>
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'eBay' ? (
                    <EbayLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Amazon' ? (
                    <AmazonLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Grailed' ? (
                    <GrailedLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Shopify' ? (
                    <ShopifyLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Etsy' ? (
                    <EtsyLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Depop' ? (
                    <DepopLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Poshmark' ? (
                    <PoshmarkLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'SOLD' ? (
                    <SoldLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Kidizen' ? (
                    <KidizenLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Facebook' ? (
                    <FacebookLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Listing Party' ? (
                    <ListingPartyLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Vestiaire' ? (
                    <VestiaireLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Instagram' ? (
                    <InstagramLogo />
                ) : null}
                {item.platform.title &&
                !isCustom &&
                item.platform.title === 'Mercari' ? (
                    <MercaryLogo />
                ) : null}
                {isCustom && <div style={item?.color ? {border:`1px solid ${item?.color}`, padding:'10px', display:'flex', justifyContent:'center', alignItems:'center', height:'40px', borderRadius:'4px'} : {border: '1px solid #000', padding:'10px', display:'flex', justifyContent:'center', alignItems:'center', height:'40px', borderRadius:'4px'}}>
                  <PlatformIconShop
                   width="20px"
                   height="20px"
                   fill={item?.color ? item?.color : '#000'}
                  />
                </div>
                }
              </div>
          )}
        </div>
        <div
            className={
              item.platform.isChecked
                  ? `${styles.platform__modal__button} ${styles.activeClassGreen}`
                  : styles.platformModal__button__green
            }
            onClick={() => {
              if (handleClick) {
                if (setSaveActive && !saveActive) {
                  setSaveActive(true);
                }
                if(retryActive && setRetryActive) {
                  setRetryActive(false)
                }
                handleClick(item.platform.code[0]);
              }
            }}
        >
          {item.platform.title}
        </div>
        {main ? (
            <div
                className={
                  item.notOnPlatform.isChecked
                      ? `${styles.platform__modal__button} ${styles.activeClassRed}`
                      : styles.platformModal__button__red
                }
                onClick={() => {
                  if (handleClick) {
                    handleClick(item.notOnPlatform.code[0]);
                  }
                }}
            >
              {item.notOnPlatform.title}
            </div>
        ) : (
            item.platform.title !== 'SOLD' &&
            item.platform.title !== 'Listing Party' &&
            item.platform.title !== 'Heroine' && (
                <input
                    type="text"
                    className="myInput"
                    name={
                      item.platform.title === 'Listing Party'
                          ? `newPlatformIdBy${'ListingParty'}`
                          : isCustom ? `newPlatformIdByCustom${item.platform.title}` :  `newPlatformIdBy${item.platform.title}`
                    }
                    value={isCustom ? platformInputs[`newPlatformIdByCustom${item.platform.title}`] : platformInputs[`newPlatformIdBy${item.platform.title}`]}
                    onChange={handleChangePlatformId}
                />
            )
        )}
      </div>
  );
}

export default PlatformModalRow;