import  {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react' ;
import getToken from "../../../../utils/getToken";
import UserConfigs from "@services/UserConfigs";
import {toast} from "react-toastify";

interface SubAccountTableProps {
	setSubAccounts:(subAccounts:any) => void;
}


const minCellWidth = 100;
const maxCeilWidth = 500;
export const SubAccountTableLogic = (props:SubAccountTableProps) => {
	const {setSubAccounts} = props ;

	const token:any = getToken() ;

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const userNameRef = useRef() ;
	const emailRef = useRef() ;
	const firstNameRef = useRef() ;
	const lastNameRef = useRef() ;
	const actionsRef = useRef() ;
	const tableElementSubAccount = useRef<any>(null);
	const baseColumns = [
		{ heading: 'Username', value: 'username', width: 110, order: 1, ref: userNameRef, allowed: true },
		{ heading: 'Email', value: 'email', width: 190, order: 2, ref: emailRef, allowed: !isMobile },
		{ heading: 'First Name', value: 'first_name', width: 110, order: 3, ref: firstNameRef, allowed: !isMobile },
		{ heading: 'Last Name', value: 'last_name', width: 110, order: 4, ref: lastNameRef, allowed: !isMobile },
		{ heading: 'Actions', value: 'actions', width: 110, order: 5, ref: actionsRef, allowed: true }
	] ;

	const [columns, setColumns] = useState(baseColumns) ;

	const [isOpenAddSubAccountModal,setIsOpenAddSubAccountModal] = useState(false) ;
	const [isOpenRemoveSubAccountAccessModal,setIsOpenRemoveSubAccountAccessModal] = useState(false) ;
	const [isOpenSettingsAccountModal,setIsOpenSettingsAccountModal] = useState(false) ;
	const [activeIndex, setActiveIndex] = useState<number | null>(null) ;

	const [subAccount, setSubAccount] = useState<any>(0) ;

	const handleToggleAddSubAccountModal = () => {
		setIsOpenAddSubAccountModal(!isOpenAddSubAccountModal)
	} ;

	const handleToggleRemoveAccountAccessModal = () => {
		setIsOpenRemoveSubAccountAccessModal(!isOpenRemoveSubAccountAccessModal)
	} ;

	const handleToggleSettingsAccountModal = () => {
		setIsOpenSettingsAccountModal(!isOpenSettingsAccountModal)
	} ;

	const mouseDown = (index: number) => {
		setActiveIndex(index);
	} ;

	const mouseMove = useCallback(
	 (e: any) => {
		 if (activeIndex !== null && tableElementSubAccount.current) {
			 const updatedColumnSizes = columns.map((col:any, i:any) => {
				 if (i === activeIndex && col.ref.current) {
					 const width =
					  e.clientX - col.ref.current.getBoundingClientRect().left;
					 if (width >= minCellWidth && width < maxCeilWidth) {
						 return { name: col.value, size: `${width}px` };
					 }
				 }
				 return { name: col.value, size: `${col.ref.current?.offsetWidth}px` };
			 });
			 // Convert the array of objects to a JSON string
			 const size = JSON.stringify(updatedColumnSizes);

			 // Set the gridTemplateColumns property of tableElement
			 const gridColumns = updatedColumnSizes.map((col:any) => col.size).join(' ');
			 tableElementSubAccount.current.style.gridTemplateColumns = gridColumns;

			 // Save the JSON string in local storage
			 localStorage.setItem('sizeSubAccountTable', size);
		 }
	 },
	 [activeIndex, columns, minCellWidth]
	);

	const removeListeners = useCallback(() => {
		window.removeEventListener('mousemove', mouseMove);
		window.removeEventListener('mouseup', removeListeners);
	}, [mouseMove]);

	const mouseUp = useCallback(() => {
		setActiveIndex(null);
		removeListeners();
	}, [setActiveIndex, removeListeners]);

	useLayoutEffect(() => {
		const savedGridSubAccountTableColumns = localStorage.getItem('sizeSubAccountTable');
		const cols = localStorage.getItem('colsSubAccountTable');
		try {
			if (savedGridSubAccountTableColumns) {
				const parsedColumnSizes = JSON.parse(savedGridSubAccountTableColumns);

				const columnSizeMap: any = {};

				parsedColumnSizes?.forEach((col: any) => {
					columnSizeMap[col.name] = col.size;
				});

				const checkerFunction = (value: string) => {
					switch (value) {
						case 'username':
							return isMobile ? '180px' : '250px';
						case 'email':
							return '300px';
						case 'first_name':
							return '250px';
						case 'last_name':
							return '250px';
						case 'actions':
							return isMobile ? '150px' : '250px';
						default:
							return '100px'; // Default width
					}
				};

				const updatedColumnSizes = columns
				.filter((col: any) => col.allowed)
				.map((col: any) => {
					const size = columnSizeMap[col.value] || checkerFunction(col.value);
					return {
						name: col.value,
						size,
					};
				});

				const size = JSON.stringify(updatedColumnSizes);
				const gridColumns = updatedColumnSizes.map((col: any) => col.size).join(' ');
				localStorage.setItem('sizeSubAccountTable', size);
				if (tableElementSubAccount.current) {
					tableElementSubAccount.current.style.gridTemplateColumns = gridColumns;
				}
			} else {
				if (tableElementSubAccount.current) {
					const columnWidthValues = columns
					.filter((column: any) => column.allowed)
					.map((column: any) => {
						switch (column.value) {
							case 'username':
								return isMobile ? '180px' : '250px';
							case 'email':
								return '300px';
							case 'first_name':
								return '250px';
							case 'last_name':
								return '250px';
							case 'actions':
								return isMobile ? '150px' : '250px';
							default:
								return '100px'; // Default width
						}
					});

					const size = JSON.stringify(columnWidthValues);
					tableElementSubAccount.current.style.gridTemplateColumns = columnWidthValues.join(' ');
					localStorage.setItem('sizeSubAccountTable', size);
				}
			}
		} catch (error) {
			localStorage.removeItem('sizeSubAccountTable');
			localStorage.removeItem('colsSubAccountTable');
			window.location.reload();
		}
	}, [columns]);

	useEffect(() => {
		if (activeIndex !== null) {
			window.addEventListener('mousemove', mouseMove);
			window.addEventListener('mouseup', mouseUp);
		}

		return () => {
			removeListeners();
		};
	}, [activeIndex, mouseMove, mouseUp, removeListeners]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
			const updatedColumns = baseColumns.map(col =>
			 isMobile && col.value !== 'username' && col.value !== 'actions'
			  ? { ...col, allowed: false }
			  : { ...col, allowed: true }
			);
			setColumns(updatedColumns);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [isMobile]);

	const handleRemoveSubAccount = async (action:'detach' | 'delete') => {
		if (!subAccount) return;
		try {
			const filter_by = {user_id:subAccount?.id} ;
			const options = {action}
			const response = await UserConfigs.sub_accounts_delete(token,filter_by, options) ;

			toast.success('Sub account removed successfully', {
				autoClose: 2000, // Set the duration for auto close
				transitionDuration: 500, // Set the transition duration for this specific notification
				position: 'bottom-right'
			} as any); // Type cast the options object
			setSubAccounts((prevSubAccounts:any) =>
			 prevSubAccounts?.filter((sbAcc: any) => sbAcc.id !== subAccount.id)
			);

		} catch(error) {
			console.log(error,'error')
		}

		handleToggleRemoveAccountAccessModal()
	}


	const handleAddSubAccountButtonDisabledClick = () => {
		toast.warning("The maximum number of sub accounts for your plan has been reached. Upgrade to the next tier to add more.", {
			autoClose: 3000,
			transitionDuration: 500,
			position: 'bottom-right'
		} as any);
	}

	return {
		columns,isMobile,subAccount,setSubAccount,setIsOpenSettingsAccountModal,setIsOpenRemoveSubAccountAccessModal,isOpenAddSubAccountModal,handleToggleAddSubAccountModal,isOpenRemoveSubAccountAccessModal,handleToggleRemoveAccountAccessModal,handleRemoveSubAccount,isOpenSettingsAccountModal,handleToggleSettingsAccountModal,mouseDown,tableElementSubAccount,activeIndex,setIsOpenAddSubAccountModal,handleAddSubAccountButtonDisabledClick
	}
}