import { useState, useEffect } from 'react';

import useRoles from "@hooks/useRoles";
import Auth from "@services/Auth";

function useAppPrefix() {
  const [prefix, setPrefix] = useState<string>('app');
  const token: any = Auth.get_token();
  const {roles, decodedToken } = useRoles();
  useEffect(() => {
    if (token) {
      if (decodedToken && decodedToken.iss.includes('apptest')) {
        setPrefix('apptest');
      } else if (decodedToken && decodedToken.iss.includes('app')) {
        setPrefix('app');
      }
    }
  }, []);

  return { prefix, roles };
}

export default useAppPrefix;
