import styles from './ExpressListerCustomize.module.scss';
import {CheckIconLister, FakeImageLister, SearchIconLister,} from "@assets/icons";
import React, {useState} from "react";
import {
	closestCenter,
	DndContext,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy,} from '@dnd-kit/sortable';
import {restrictToParentElement, restrictToVerticalAxis} from '@dnd-kit/modifiers';

import {SortableItem} from "./SortableItem";
import {SortableItemRight} from "./SortableItemRight";
import {useSelector} from "react-redux";
import Tippy from "@tippyjs/react";
import '../../express.css';
import Button, {ModifierType} from "../../../UI/Button";

export const ExpressListerCustomize = ({
			 mainListDataLister,
			 setMainListDataLister,
			 setCustomizeActivated,
			 listFromMainLister,
			 setListFromMainLister,
			 setExpressListerFields,
			 setSelectedFields,
			 handleUpdateSelectedFields,
			 }:any)=>{
	const leftMenuExpanded = Boolean(localStorage.getItem('leftMenuExpanded'));
	const navigation = useSelector((state: any) => state.navigation.active);
	const isMobile = useSelector((state: any) => state.navigation.isMobile);
	const sensors = useSensors(
	 useSensor(PointerSensor),
	 useSensor(KeyboardSensor, {
		 coordinateGetter: sortableKeyboardCoordinates,
	 }),
	 useSensor(TouchSensor, {
		 activationConstraint: {
			 delay: 100, // Adjusting delay to make sure touch is recognized
			 tolerance: 5, // Small tolerance for touch move before activating
		 },
	 })
	);

	const handleToggleFieldCheck = (id:any) => {
		setMainListDataLister((prevList:any) =>
		 prevList.map((field:any) => {
			 if (field.id === id) {
				 if (!field.alwaysVisible) {
					 return { ...field, checked: !field.checked };
				 }
			 }
			 return field;
		 })
		);
		setListFromMainLister((prevList:any) =>
		 prevList.map((field:any) => {
			 if (field.id === id) {
				 if (!field.alwaysVisible) {
					 return { ...field, checked: !field.checked };
				 }
			 }
			 return field;
		 })
		);

	};

	const backToExpressLister = () =>{
		setCustomizeActivated(false);
	}

	function handleDragEnd(event: any) {
		const { active, over } = event;

		if (active.id !== over.id) {
			setListFromMainLister((items: any) => {
				const oldIndex = items.findIndex((item: any) => item.id === active.id);
				const newIndex = items.findIndex((item: any) => item.id === over.id);
				return arrayMove(items, oldIndex, newIndex);
			});
		}
	}
	const [searchQuery, setSearchQuery] = useState('');

	const filteredListForBasic = mainListDataLister.filter((item:any) =>
	 item.title.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const filteredListForSelected = listFromMainLister.filter((item:any) =>
	 item.title.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const handleSaveAndClose = () =>{
		try{
			setExpressListerFields([...listFromMainLister]);
			const selectedFieldsLocal = listFromMainLister?.filter((item: any) => item?.checked || item?.alwaysVisible);

			const newSelectedListForBackend = selectedFieldsLocal?.map((item:any) => {
				if (item?.keyCode === '15') {
					return { code: 'condition' };
				} else {
					return { code: item?.keyCode };
				}
			});

			setSelectedFields(...newSelectedListForBackend);
			handleUpdateSelectedFields(newSelectedListForBackend);
		}
		catch(error){
			console.log(error, 'error')
		}
	}




	return(
	 <div className={styles.expressListerCustomizeContainer} style={{left: !isMobile && navigation && leftMenuExpanded ? '200px' : !isMobile ? '77px' : 0}}>
		 <div className={styles.leftContainer}>

				 <div className={`${styles.titles} ${styles.mobileHeaderContainer}`}>
					 <p>Customize</p>
					 <div className={styles.customizeHeadingButtons}>
						 <Button modifier={ModifierType.Primary} handleClick={handleSaveAndClose}>Save & Close</Button>
						 <Button  style={{border:"1px solid #1E3166",color:"#1E3166"}} modifier={ModifierType.Transparent} handleClick={backToExpressLister}>Cancel</Button>
					 </div>
				 </div>

			 <div className={styles.searchContainerMain}>
				 <div className={styles.searchContainer}>
					 <SearchIconLister className={styles.searchIcon}/>
					 <input
					  type="text"
					  placeholder="Search available fields"
					  value={searchQuery}
					  onChange={(e) => setSearchQuery(e.target.value)}
					 />
				 </div>
			 </div>
			 <div className={styles.scrollContainerMain}>
				 <div className={styles.selectedFieldsContainer}>
					 <p className={styles.fieldsTitle}>Selected Fields</p>
					 <div className={styles.selectedFieldsList}>
						 <div className={styles.selectedField}>
							 <div className={styles.leftSide}>
								 <p>Images</p>
							 </div>
						 </div>
						 <DndContext
						  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
						  sensors={sensors}
						  collisionDetection={closestCenter}
						  onDragEnd={handleDragEnd}
						 >
							 <SortableContext
							  items={filteredListForSelected}
							  strategy={verticalListSortingStrategy}
							 >
								 {filteredListForSelected.map((item: any) => {
									 if (item.title === 'SKU' && item.checked) {
										 const skuGenerator = filteredListForSelected.find((field: any) => field.title === 'SKU Generator' && field.checked);
										 return (
										  <SortableItem
										   key={item?.id}
										   item={item}
										   skuGenerator={skuGenerator}
										   setMainListDataLister={setMainListDataLister}
										   setListFromMainLister={setListFromMainLister}
										  />
										 );
									 }

									 if (item.title !== 'SKU Generator' && item?.checked && item?.allowed) {
										 return (
										  <SortableItem
										   key={item?.id}
										   item={item}
										   setMainListDataLister={setMainListDataLister}
										   setListFromMainLister={setListFromMainLister}
										  />
										 );
									 }

									 return null;
								 })}
							 </SortableContext>
						 </DndContext>
					 </div>
				 </div>
				 <div className={styles.basicInfoContainer} id="basicInfoList">
					 <p className={styles.fieldsTitle}>Basic Info</p>
					 <div className={styles.basicInfoList}>
						 <div
						  className={`${styles.basicField} ${styles.disabledField}`}>
							 <CheckIconLister/>
							 <p
							  className={`${styles.basicFieldTitleActive}`}>
								 Images
							 </p>
						 </div>
						 {filteredListForBasic.map((field: any, index: any) => {
							 if (field.title === 'SKU Generator' && !field.showGenerator) {
								 return null; // Do not render the 'SKU Generator' field if showGenerator is false
							 }
							 return (
								 <Tippy
									 interactive={true}
									 zIndex={5}
									 arrow={false}
									 trigger="focus click"
									 disabled={field.allowed}
									 content={
									    <div>
											{
												!field?.allowed && field?.title === 'SKU Generator' ? <p>
														Available for Pro Plus users. Upgrade to access
														this feature and many more.
														<a target="_blank"
														   href={'https://listperfectly.com/pricing'}>Upgrade
															Now </a>
													</p>
													: field?.disabledForBusiness ? <p>
														Available for Pro and Pro Plus users. Upgrade to access
														this feature and many more.
														<a target="_blank"
														   href={'https://listperfectly.com/pricing'}>Upgrade
															Now </a>
													</p> : <p>
														Available for Business, Pro and Pro Plus users. Upgrade to access
														this feature and many more.
														<a target="_blank"
														   href={'https://listperfectly.com/pricing'}>Upgrade
															Now </a>
													</p>
											}
										</div>

									 }
								 >
									 <div className={`${styles.marketplacesBtn} ${styles.marketplacesBtnDisabled}`}>
										 <div
											 key={field?.id}
											 className={`${styles.basicField} ${field.alwaysVisible ? styles.disabledField : ''} ${!field?.allowed ? styles.disabledFieldCheck : ''}`}
											 onClick={() => field?.allowed && handleToggleFieldCheck(field?.id)}
										 >
											 {field?.checked && <CheckIconLister/>}
											 <p className={`${field?.checked ? styles.basicFieldTitleActive : styles.basicFieldTitle}`}>
												 {field?.title}
											 </p>
										 </div>
									 </div>
								 </Tippy>

							 );
						 })}
					 </div>
				 </div>
			 </div>
		 </div>
		 <div className={styles.rightContainer}>
			 <div className={styles.customizeHeading}>
				 <div className={styles.titles}
					  style={{left: !isMobile && navigation && leftMenuExpanded ? '550px' : !isMobile ? '427px' : 0}}>
					 <p>Customize Express Lister</p>
					 <div className={styles.customizeHeadingButtons}>
						 <Button modifier={ModifierType.Primary} handleClick={handleSaveAndClose}>Save & Close</Button>
						 <Button  style={{border:"1px solid #1E3166",color:"#1E3166"}} modifier={ModifierType.Transparent} handleClick={backToExpressLister}>Cancel</Button>
					 </div>
				 </div>
			 </div>
			 <div className={styles.imageUploaderTemplate}>
				 <div className={styles.dragBtnImages}></div>
				 <p>Images</p>
				 <FakeImageLister/>
			 </div>

			 <div className={styles.listOfFieldsRight}>
				 <DndContext
				  sensors={sensors}
				  collisionDetection={closestCenter}
				  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
				  onDragEnd={handleDragEnd}
				 >
					 <SortableContext
					  items={listFromMainLister}
					  strategy={verticalListSortingStrategy}
					 >
						 {listFromMainLister.map((item: any) => {
							 if (item.title === 'SKU' && item.checked) {
								 const skuGenerator = listFromMainLister.find((field: any) => field.title === 'SKU Generator' && field.checked);
								 return (
								  <SortableItemRight
								   key={item?.id}
								   field={item}
								   skuGenerator={skuGenerator}
								   setMainListDataLister={setMainListDataLister}
								   setListFromMainLister={setListFromMainLister}
								  />
								 );
							 }

							 if (item.title !== 'SKU Generator' && item?.checked && item?.allowed) {
								 return (
								  <SortableItemRight
								   key={item?.id}
								   field={item}
								   setMainListDataLister={setMainListDataLister}
								   setListFromMainLister={setListFromMainLister}
								  />
								 );
							 }

							 return null;
						 })}
					 </SortableContext>
				 </DndContext>
			 </div>

		 </div>
	 </div>
	)
}