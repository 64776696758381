import styles from './PlansBoxes.module.scss';
import React from "react";
import PriceCard from "../../../../components/UI/PriceCard";

const PlansBoxes = ({plansList}:any) => {
    return(
        <div className={styles.plansBoxesMain}>
            <div className={styles.plansBoxesContainer}>
                {
                    plansList.map((item:any)=> (
                       <PriceCard type={item?.type} price={item?.price} planSource={item?.planSource} description={item?.description} featuresList={item?.featuresList} hasButtons={true} featuresTitle={item?.featuresTitle} comparePlans={item?.comparePlans} viewFeatureLink={item?.viewFeatureLink}  />
                    ))
                }
            </div>
        </div>
    )
}

export default PlansBoxes;