import React from 'react';

import {SubAccountTableLogic} from "./SubAccountTableLogic";
import SubAccountTableUI from "./SubAccountTableUI";


interface ISubAccountTableProps {
	subAccounts:any ;
	setSubAccounts:(subAccounts:any) => void;
	subAccountLimit:number;
}

const SubAccountTable = (props:ISubAccountTableProps) => {

	const {subAccounts, setSubAccounts,subAccountLimit} = props ;

	const {columns,isMobile,subAccount,setSubAccount,setIsOpenSettingsAccountModal,setIsOpenRemoveSubAccountAccessModal,isOpenAddSubAccountModal,handleToggleAddSubAccountModal,isOpenRemoveSubAccountAccessModal,handleToggleRemoveAccountAccessModal,handleRemoveSubAccount,isOpenSettingsAccountModal,handleToggleSettingsAccountModal,mouseDown,tableElementSubAccount,activeIndex,setIsOpenAddSubAccountModal,handleAddSubAccountButtonDisabledClick} = SubAccountTableLogic({setSubAccounts})

	return (
	 <SubAccountTableUI subAccounts={subAccounts} setIsOpenAddSubAccountModal={setIsOpenAddSubAccountModal} tableElementSubAccount={tableElementSubAccount} columns={columns} mouseDown={mouseDown} activeIndex={activeIndex} isMobile={isMobile} isOpenAddSubAccountModal={isOpenAddSubAccountModal} isOpenRemoveSubAccountAccessModal={isOpenRemoveSubAccountAccessModal} isOpenSettingsAccountModal={isOpenSettingsAccountModal} subAccount={subAccount} setSubAccount={setSubAccount} setIsOpenSettingsAccountModal={setIsOpenSettingsAccountModal} setIsOpenRemoveSubAccountAccessModal={setIsOpenRemoveSubAccountAccessModal} handleToggleAddSubAccountModal={handleToggleAddSubAccountModal} setSubAccounts={setSubAccounts} handleToggleRemoveAccountAccessModal={handleToggleRemoveAccountAccessModal} handleRemoveSubAccount={handleRemoveSubAccount} handleToggleSettingsAccountModal={handleToggleSettingsAccountModal}   handleAddSubAccountButtonDisabledClick={handleAddSubAccountButtonDisabledClick} subAccountLimit={subAccountLimit}/>
	)
}

export default SubAccountTable