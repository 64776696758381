import styles from './ListingTitleContainer.module.scss';
import Tippy from "@tippyjs/react";
import {PopoverIcon} from "../../../../assets/icons";
import {InputCount} from "../../InputCount";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import getNameGenerator from "../../../../hooks/useNameGenerator";
import {TextareaWithGrowHeight} from "../../TextareaGrowHeight";
import Button, {ModifierType} from "../../../UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

const ListingTitleContainer = ({showAllTitles, setShowAllTitles,showSuggestTitles, setShowSuggestTitles,  hasBusiness, hasSimple,formInfo, unescapedRowMetadata, setFormInfo, itemData, editIndex, setItemData, handleStandartInputChange, handleResetAllTitles}:any) => {

    const toggleShowAllTitles = () => {
        setShowAllTitles(!showAllTitles);
        if(showAllTitles){
            const updatedItem = [...itemData];
            updatedItem[editIndex] = {
                ...updatedItem[editIndex],
                ['showAllTitlesCheckbox']: null,
                changed: true,
            };
            setItemData(updatedItem);
            setFormInfo({
                ...formInfo,
                ['showAllTitlesCheckbox']: { text: null, changed: true },
            });
        }else{
            const updatedItem = [...itemData];
            updatedItem[editIndex] = {
                ...updatedItem[editIndex],
                ['showAllTitlesCheckbox']: "Show all Titles",
                changed: true,
            };
            setItemData(updatedItem);
            setFormInfo({
                ...formInfo,
                ['showAllTitlesCheckbox']: { text: "Show all Titles", changed: true },
            });
        }
    };
    // const toggleSuggestTitles = () => {
    //     setShowSuggestTitles(!showSuggestTitles);
    //     if(showSuggestTitles){
    //         const updatedItem = [...itemData];
    //         updatedItem[editIndex] = {
    //             ...updatedItem[editIndex],
    //             ['suggestTitleCheckbox']: null,
    //             changed: true,
    //         };
    //         setItemData(updatedItem);
    //         setFormInfo({
    //             ...formInfo,
    //             ['suggestTitleCheckbox']: { text: null, changed: true },
    //         });
    //     }else{
    //         const updatedItem = [...itemData];
    //         updatedItem[editIndex] = {
    //             ...updatedItem[editIndex],
    //             ['suggestTitleCheckbox']: "Yes",
    //             changed: true,
    //         };
    //         setItemData(updatedItem);
    //         setFormInfo({
    //             ...formInfo,
    //             ['showAllTitlesCheckbox']: { text: "Yes", changed: true },
    //         });
    //     }
    // };
    const { bdecode } = useBasisFuncs();
    const isSmallScreenLaptop = UseIsSmallLaptop();

    function limitToMaxCharacters(input: string, maxChars: number): any {
        try{
            let charCount = 0;
            let realIndex = 0;

            for (let i = 0; i < input.length; i++) {
                const char = input[i];

                // Check if adding this character exceeds the limit
                if (char === '\n') {
                    if (charCount + 2 > maxChars) break; // Exceeds limit, stop
                    charCount += 2;
                } else {
                    if (charCount + 1 > maxChars) break; // Exceeds limit, stop
                    charCount += 1;
                }

                // Only increase the realIndex if we are still within the character limit
                realIndex++;
            }

            // Return the substring up to the valid character index
            return input.substring(0, realIndex);
        }catch(e){
            console.log(e, 'error')
        }
    }


    const handleChangeTitleInput = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
            | React.ChangeEvent<HTMLSelectElement>,
        assistanceValue?: string,
    ) => {
        const {name, value} = e.target;
        const updatedItem = [...itemData];
        const generatedName = getNameGenerator(name);

        updatedItem[editIndex] = {
            ...updatedItem[editIndex],
            [generatedName]: assistanceValue ? assistanceValue : value,
            changed: true,
        };
        setItemData(updatedItem)
        setFormInfo({
            ...formInfo,
            [generatedName]: {
                text: assistanceValue ? assistanceValue : value,
                changed: true,
            },
            ...(
                (formInfo.newMercaryTitle.changed ||
                    (unescapedRowMetadata[789])) &&

                formInfo.newMercaryTitle.text !==  unescapedRowMetadata[133] &&

                (formInfo.newMercaryTitle.text !== formInfo.newTitle.text.substring(0, 80))
                    ? {}
                    : {
                        newMercaryTitle: {
                            text: assistanceValue ? limitToMaxCharacters(assistanceValue, 80) : limitToMaxCharacters(value, 80), // Sync with first input or use assistanceValue
                            changed: true, // Mark as changed
                        },
                    }
            ),
            ...(
                (formInfo.newPoshmarkTitle.changed ||
                    (unescapedRowMetadata[786] )) &&

                formInfo.newPoshmarkTitle.text !==  unescapedRowMetadata[133] &&

                ( formInfo.newPoshmarkTitle.text !== formInfo.newTitle.text.substring(0, 80))
                    ? {}
                    : {
                        newPoshmarkTitle: {
                            text: assistanceValue ? limitToMaxCharacters(assistanceValue, 80) : limitToMaxCharacters(value, 80), // Sync with first input or use assistanceValue
                            changed: true, // Mark as changed
                        },
                    }
            ),

            ...(
                (formInfo.newGrailedTitle.changed ||
                    (unescapedRowMetadata[788] )) &&

                formInfo.newGrailedTitle.text !==  unescapedRowMetadata[133] &&

                ( formInfo.newGrailedTitle.text !== formInfo.newTitle.text.substring(0, 60))
                    ? {}
                    : {
                        newGrailedTitle: {
                            text: assistanceValue ? limitToMaxCharacters(assistanceValue, 60) : limitToMaxCharacters(value, 60), // Sync with first input or use assistanceValue
                            changed: true, // Mark as changed
                        },
                    }
            ),

            ...(
                (formInfo.newAmazonEbayTitle.changed ||
                    (unescapedRowMetadata[791] )) &&

                formInfo.newAmazonEbayTitle.text !==  unescapedRowMetadata[133] &&

                ( formInfo.newAmazonEbayTitle.text !== formInfo.newTitle.text.substring(0, 80))

                    ? {}
                    : {
                        newAmazonEbayTitle: {
                            text: assistanceValue ? limitToMaxCharacters(assistanceValue, 80) : limitToMaxCharacters(value, 80), // Sync with first input or use assistanceValue
                            changed: true, // Mark as changed
                        },
                    }
            ),

            ...(
                (formInfo.newFacebookTitle.changed ||
                    (unescapedRowMetadata[869])) &&

                formInfo.newFacebookTitle.text !==  unescapedRowMetadata[133] &&

                ( formInfo.newFacebookTitle.text !== formInfo.newTitle.text.substring(0, 99))
                    ? {}
                    : {
                        newFacebookTitle: {
                            text: assistanceValue ? limitToMaxCharacters(assistanceValue, 99) : limitToMaxCharacters(value, 99), // Sync with first input or use assistanceValue
                            changed: true, // Mark as changed
                        },
                    }
            ),

        });
    };

    return (
        <div className={styles.listingTitleContainer}>
            <div className={styles.TitlesContainer}>
                <div className={styles.title}>
                    Listing Title
                    <Tippy
                        interactive={true}
                        zIndex={9991}
                        arrow={false}
                        trigger="click"
                        content={
                            <div className={`popover ${styles.popoverContent}`}>
                                <p className={styles.heading}>Title Character Count:</p>
                                <ul>
                                    <li>
                                        Etsy, Shopify & Kidizen: Title for these
                                        marketplaces will be copied from main title field
                                        and is limited to 140.
                                    </li>
                                    <li>Facebook: 99</li>
                                    <li>eBay: 80</li>
                                    <li>Grailed: 60</li>
                                    <li>Poshmark: 80</li>
                                    <li>Mercari: 80</li>
                                    <li>Tradesy, Instagram & Depop: No title</li>
                                    <div className={styles.line}></div>
                                    <p>★ Crossposts to supported 'title' fields.</p>
                                </ul>
                            </div>
                        }
                    >
                        <PopoverIcon
                            className={styles.popoverIcon}
                            color={'#fff'}
                        ></PopoverIcon>
                    </Tippy>
                </div>
                <div className={styles.titlesMain}>
                    <TextareaWithGrowHeight
                        placeholder=""
                        name="input_133"
                        value={
                            formInfo.newTitle.changed
                                ? bdecode(formInfo.newTitle.text).substring(0, 140)
                                : unescapedRowMetadata[133]
                                    ? bdecode(unescapedRowMetadata[133]).substring(0, 140)
                                    : ''
                        }
                        handleChange={handleChangeTitleInput}
                        hasLimit={true}
                        limit={140}
                    />
                    <div style={{visibility: 'hidden', position: 'absolute'}}>
                        <InputCount
                            placeholder=""
                            name="input_727"
                            value={
                                formInfo?.newThumbnail?.changed
                                    ? formInfo?.newThumbnail?.text
                                    : unescapedRowMetadata[727]
                            }
                            handleChange={handleStandartInputChange}
                            limit={250}
                            height={40}
                        />
                        <InputCount
                            placeholder=""
                            name="input_728"
                            value={
                                formInfo?.newImagesArray?.changed
                                    ? formInfo?.newImagesArray?.text
                                    : unescapedRowMetadata[728]
                            }
                            handleChange={handleStandartInputChange}
                            limit={250}
                            height={40}
                        />
                    </div>
                    {
                        !hasSimple && <>
                        <Button
                         style={{border:!showAllTitles ? "1px solid #1e3166" : "", color:!showAllTitles ? "#1e3166" : "", width:'fit-content',
                             // height:isSmallScreenLaptop ? 36 : 40,minHeight:isSmallScreenLaptop ? 36 : 40
                        }}
                         modifier={showAllTitles ? ModifierType.Secondary : ModifierType.Transparent}
                         handleClick={toggleShowAllTitles}>
                            Show All Titles
                        </Button>
                     </>
                    }

                </div>
            </div>
            {showAllTitles && (
             <div className={styles.showAllTitlesContainer}>
                 <div className={styles.showAllTitlesItem}>
                     <div className={styles.itemInner}>
                         <TextareaWithGrowHeight
                          placeholder=""
                          name="input_789"
                          value={
                                    formInfo.newMercaryTitle.changed
                                        ? bdecode(formInfo.newMercaryTitle.text).substring(0, 80)
                                        : unescapedRowMetadata[789]
                                            ? limitToMaxCharacters(bdecode(unescapedRowMetadata[789]), 80)
                                            : unescapedRowMetadata[133]
                                                ? limitToMaxCharacters(bdecode(unescapedRowMetadata[133]), 80)
                                                : ''
                                }
                                handleChange={handleStandartInputChange}
                                hasLimit={true}
                                limit={80}
                            />
                            <p className={styles.heading}>MERCARI TITLE</p>
                        </div>
                    </div>{' '}
                    <div className={styles.showAllTitlesItem}>
                        <div className={styles.itemInner}>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_786"
                                value={
                                    formInfo.newPoshmarkTitle.changed
                                        ? bdecode(formInfo.newPoshmarkTitle.text).substring(0, 80)
                                        : unescapedRowMetadata[786]
                                            ? limitToMaxCharacters(bdecode(unescapedRowMetadata[786]), 80)
                                            : unescapedRowMetadata[133]
                                                ? limitToMaxCharacters(bdecode(unescapedRowMetadata[133]), 80)
                                                : ''
                                }
                                handleChange={handleStandartInputChange}
                                hasLimit={true}
                                limit={80}
                            />
                            <p className={styles.heading}>POSHMARK TITLE</p>
                        </div>
                    </div>
                    <div className={styles.showAllTitlesItem}>
                        <div className={styles.itemInner}>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_788"
                                value={
                                    formInfo.newGrailedTitle.changed
                                        ? bdecode(formInfo.newGrailedTitle.text).substring(0, 60)
                                        : unescapedRowMetadata[788]
                                            ? limitToMaxCharacters(bdecode(unescapedRowMetadata[788]), 60)
                                            : unescapedRowMetadata[133]
                                                ? limitToMaxCharacters(bdecode(unescapedRowMetadata[133]), 60)
                                                : ''
                                }
                                handleChange={handleStandartInputChange}
                                hasLimit={true}
                                limit={60}
                            />
                            <p className={styles.heading}>GRAILED TITLE</p>
                        </div>
                    </div>
                    <div className={styles.showAllTitlesItem}>
                        <div className={styles.itemInner}>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_791"
                                value={
                                    formInfo.newAmazonEbayTitle.changed
                                        ? bdecode(formInfo.newAmazonEbayTitle.text).substring(0, 80)
                                        : unescapedRowMetadata[791]
                                            ? limitToMaxCharacters(bdecode(unescapedRowMetadata[791]), 80)
                                            : unescapedRowMetadata[133]
                                                ? limitToMaxCharacters(bdecode(unescapedRowMetadata[133]), 80)
                                                : ''
                                }
                                handleChange={handleStandartInputChange}
                                hasLimit={true}
                                limit={80}
                            />
                            <p className={styles.heading}>EBAY / AMAZON TITLE</p>
                        </div>
                    </div>
                    <div className={styles.showAllTitlesItem}>
                        <div className={styles.itemInner}>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_869"
                                value={
                                    formInfo.newFacebookTitle.changed
                                        ? bdecode(formInfo.newFacebookTitle.text).substring(0, 99)
                                        : unescapedRowMetadata[869]
                                            ? limitToMaxCharacters(bdecode(unescapedRowMetadata[869]), 99)
                                            : unescapedRowMetadata[133]
                                                ? limitToMaxCharacters(bdecode(unescapedRowMetadata[133]), 99)
                                                : ''
                                }
                                handleChange={handleStandartInputChange}
                                hasLimit={true}
                                limit={99}
                            />
                            <p className={styles.heading}>FACEBOOK TITLE</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListingTitleContainer;