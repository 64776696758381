
import React, {useLayoutEffect, useState,ClipboardEvent} from 'react'
import styles from '../../SendToSalesModal.module.scss' ;

import _ from 'lodash' ;
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";

import useBasisFuncs from "../../../../../../hooks/useBasisFuncs";
import SoldOnPlatforms from "../SoldOnPlatforms";
import convertToCamelCase from "../../../../../../utils/convertToCamelCase";
import {TrashSolidSvg} from "../../../../../../assets/icons";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

interface IModalInfo {
	[key: string]: {
		text: string;
		changed: boolean;
	};
}

interface IModalItemListingProps {
	index: number;
	thumbnail: string;
	metadata: any;
	modalFields: any;
	modalListings: any;
	setModalListings: (modalListings: any) => void;
	handleDeleteListingInModal: (id: number) => void;
	customMarketplaces:any;
	id: number;
	mpItem:any;
	checkShopId:(platformName:string, index:number) => void;
	checkPlatformListingId:(metadata:any) => string;
	handleChangeListingIdByPlatforms:(platformCode:string, index:number) => void;
}

const ModalItemListing = (props: IModalItemListingProps) => {
	const {
		index,
		thumbnail,
		metadata,
		modalFields,
		modalListings,
		setModalListings,
		customMarketplaces,
		handleDeleteListingInModal,
		id,
		mpItem,
		checkShopId,
		checkPlatformListingId,
		handleChangeListingIdByPlatforms
	} = props;

	const isSmallScreenLaptop = UseIsSmallLaptop();
	const {bdecode} = useBasisFuncs()
	const customStyles = {
		// Style the input element
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: '#fff',
			color: '#1E3166',
			borderColor: state.isFocused ? '#4db89e' : '#393838',
			borderRadius: !isSmallScreenLaptop ? "8px" : "6px",
			minWidth: !isSmallScreenLaptop ? "180px" : "140px",
			cursor: 'pointer',
			zIndex: 9,
			minHeight: !isSmallScreenLaptop ? '39px' : '31px',
			height: !isSmallScreenLaptop ? '39px' : '31px',
			boxShadow:"none",

			'&:hover': {
				...(state.isFocused) && {borderColor: '#4db89e',},
				boxShadow:"none"
			},
		}),
		valueContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '31px',
			padding:  !isSmallScreenLaptop ? '0 10px' : '0 8px'

		}),
		input: (provided: any) => ({
			...provided,
			margin: '0px',
		}),

		indicatorSeparator: (state:any) => ({
			display: 'none',
		}),
		indicatorsContainer: (provided: any) => ({
			...provided,
			height: !isSmallScreenLaptop ? '39px' : '31px',
		}),
		menu: (provided: any, state: any) => ({
			...provided,
			width: '100%',
			minWidth: !isSmallScreenLaptop ? "180px" : "140px",
			border: '1px solid #4db89e',
			borderRadius:  !isSmallScreenLaptop ? "8px" : "6px",
			paddingTop:  !isSmallScreenLaptop ? "10px" : "8px"/**/,
			position:'absolute',
			zIndex:9999,
			boxShadow:"none"
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			fontWeight: 600,
			fontSize: !isSmallScreenLaptop ? "16px" : "13px",
		}),
	};
	const options = [
		{value: '', label: '-'},
		{value: 'pending', label: 'Pending'},
		{value: 'shipped', label: 'Shipped'},
		{value: 'canceled', label: 'Canceled'},
		{value: 'returned', label: 'Returned'},

		{value: 'hold', label: 'Hold'},
		{value: 'awaiting_shipment', label: 'Awaiting Shipment'},
	];

	const [dimensions, setDimensions] = useState<string | null>(null);

	const initialDate = moment(metadata[846]).toDate(); // Convert moment to Date

	const [selectedDate, setSelectedDate] = useState(initialDate);

	const [newModalInfo, setNewModalInfo] = useState<IModalInfo>({
		newDateSold: {
			text: "",
			changed: false
		},
		newShippingStatus: {
			text: "",
			changed: false
		},
		newSku: {
			text: "",
			changed: false
		},
		newSoldOn: {
			text: "",
			changed: false
		},
		newShop: {
			text: "",
			changed: false
		},
		newSoldPrice: {
			text: "",
			changed: false
		},
		newSalesTax: {
			text: "",
			changed: false
		},
		newFees: {
			text: "",
			changed: false
		},
		newShippingCostBuyer: {
			text: "",
			changed: false
		},
		newShippingCostSeller: {
			text: "",
			changed: false
		},
		newCogs: {
			text: "",
			changed: false
		},
		newQuantitySold: {
			text: "",
			changed: false
		},
		newQuantityAvailable: {
			text: "",
			changed: false
		},

	})

	// custom select styles

	const [selectedOption, setSelectedOption] = useState({
		value: "",
		label: "-",
	});


	const dropDownChange = (selectedOption: any) => {
		setSelectedOption(selectedOption);

		setNewModalInfo({...newModalInfo, newShippingStatus: selectedOption})

		updateModalListings('newShippingStatus', selectedOption.value)

	};

	const handleChange = (date: any, name: string) => {

		const formattedDate = moment(date).toISOString();
		setSelectedDate(date);
		setNewModalInfo({
			...newModalInfo, newDateSold: {
				text: formattedDate,
				changed: true
			}
		})

		updateModalListings('newDateSold', formattedDate)

	}

	const checkInputType = (item: any) => {
		let type = "text"
		if (item.value === 'shipping_cost_buyer' ||item.value === 'shipping_cost_seller' || item.value === 'sales_tax' || item.value ==='cogs' || item.value === 'sold_price' || item.value === 'fees' || item.value === 'quantity_sold' || item.value === 'quantity_available') {
			type = 'number'
		}
		return type
	}

	const checkInputValue = (item: any) => {
		let value = ""
		if (item.value === 'sku') {
			value = metadata[1]
		} else if (item.value === 'shipping_cost_seller') {
			value = metadata[852]
		}
		else if (item.value === 'fees') {
			value = metadata[844]
		} else if (item.value === 'sold_price') {
			value = metadata[842]
		} else if (item.value === 'cogs') {
			value = metadata[847]
		} else if (item.value === 'quantity_available') {
			value = metadata[851]
		} else if(item.value === 'listing_id') {
			value =	checkPlatformListingId(metadata)
		} else if(item.value === 'quantity_sold') {
			value =	'1'
		}
		return value
	}

	const handleChangeInput = (e:
								| React.ChangeEvent<HTMLInputElement>
								| React.ChangeEvent<HTMLTextAreaElement>
								| React.ChangeEvent<HTMLSelectElement>) => {
		const {name, value} = e.target;

		updateModalListings(name, value)

	}

	function updateModalListings(name: string, value: string) {

		setNewModalInfo({...newModalInfo, [name]: {text: value, changed: true}})

		const updatedModalListings = [...modalListings]

		if (value === "") {
			delete updatedModalListings[index][name]
		} else {

			updatedModalListings[index] = {
				...updatedModalListings[index],
				[name]: value,
				changed: true,
			};
		}

		setModalListings(updatedModalListings)
	}

	useLayoutEffect(() => {
		const getImageDimensions = (src: string | undefined) => {
			try {
				if (src) {
					const img = new Image();
					img.src = src;


					img.onload = () => {
						if (img.width >= img.height) {
							setDimensions('width');
						} else {
							setDimensions('height');
						}
					};
				}
			} catch (e) {
				console.log('e');
			}
		};
		getImageDimensions(mpItem?.thumbnail);
	}, []);


	const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
		const inputType = event.currentTarget.type;
		const pasteData = event.clipboardData?.getData('Text');

		if (inputType === 'number' && isNaN(Number(pasteData))) {
			event.preventDefault();
		}
	};


	return (
	 <div className={styles.modalItem}>
		 <div className={styles.modalItemNumber}>
			 <span>   {index + 1}</span>
		 </div>
		 <div>
			 <div className={styles.modalHeader}>
				 <div
				  style={{
					  borderRadius: '8px',
					  background: thumbnail ? '0' : '#DBDBDB'
				  }}
				  className={styles.modalThumbnail}>
					 {thumbnail?  <img
					  style={{
						  width: dimensions === 'width' ? (isSmallScreenLaptop ? "68px" : "85px") : 'auto',
						  height: dimensions === 'height' ? (isSmallScreenLaptop ? "68px" : "85px") : 'auto',
					  }}
					  src={thumbnail}/> :  <div className={styles.noThumbnail}></div>}
				 </div>
				 <div className={styles.modalTitle}>
					 {bdecode(metadata[133]) || '-'}
				 </div>
			 </div>
			 <div className={styles.modalFields}>
				 {modalFields?.map((item: any) => (
				  <div
				   key={item.id}
				   className={styles.modalFieldItem}
				   style={{position: item.value === 'sold_on' ? 'relative' : 'static',}}
				  >
					  <label htmlFor={item.value}>
						  {item.value === 'sold_on' || item.value === 'order_id' ? `${item.title} *` : item.title}
					  </label>
					  {item.value === 'date_sold' ? (
					   <DatePicker
						selected={selectedDate}
						onChange={handleChange}
						className={styles.datePicker}
						name="newDateSold"
					   />
					  ) : item.value === 'shipping_status' ? (
					   <Select
						styles={customStyles}
						value={selectedOption}
						options={options}
						isSearchable={false}
						onChange={dropDownChange}
						menuPlacement={index === 0 ? 'bottom' : 'top'}
					   />
					  ) : (
					   item.value === 'sold_on' ?
						<div className={styles.spesificInputWrapper}>
							<SoldOnPlatforms
							 index={index}
							 metadata={metadata}
							 updateModalListings={updateModalListings}
							 customMarketplaces={customMarketplaces}
							 checkShopId={checkShopId}
							 checkPlatformListingId={checkPlatformListingId}
							 handleChangeListingIdByPlatforms={handleChangeListingIdByPlatforms}
							 setNewModalInfo={setNewModalInfo}
							/>
						</div>
						:
						<div>
							<input
							 type={checkInputType(item)}
							 disabled={item.value === 'quantity_available'} // Disabling quantity_available field.
							 style={{ width: item.width }}
							 className={styles['sendToSales__input']}
							 name={convertToCamelCase(item.value)}
							 value={newModalInfo[convertToCamelCase(item.value)]?.changed
							  ?
							  newModalInfo[convertToCamelCase(item.value)]?.text
							  :
							  item.value === 'shop' ? _.get(mpItem, 'newShop')
							   : (item.value === 'listing_id'
								&&  _.has(mpItem, 'newListingId')
							   ) ?  _.get(mpItem, 'newListingId')
								:
								newModalInfo[convertToCamelCase(item.value)]?.text || checkInputValue(item)}
							 onChange={handleChangeInput}
							 onPaste={handlePaste}
							/>
						</div>

					  )}

				  </div>
				 ))}
			 </div>
		 </div>

		 <TrashSolidSvg
		  className={styles.trashSoldSvg}
		  onClick={() => handleDeleteListingInModal(id)}
		 />

	 </div>
	)
}

export default ModalItemListing
