const htmlUnescapes: { [key: string]: string } = {
	'&amp;': '&',
	'&lt;': '<',
	'&gt;': '>',
	'&quot;': '"',
	'&#39;': "'",
};
const reDoubleEncodedHtml = /&amp;(\w+);/g;
const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g;
const reHasEscapedHtml = RegExp(reEscapedHtml.source);

const bdecode = (s: string | undefined | null) =>
 s && reHasEscapedHtml.test(s)
  ? s.replace(reDoubleEncodedHtml, (m, p1) => htmlUnescapes[m] || htmlUnescapes[`&${p1};`] || "'")
  .replace(reEscapedHtml, (m) => htmlUnescapes[m] || "'")
  .replace(/&#39;/g, "'") // Заменяем кодированное апострофом (&#39;) на обычный апостроф
  : s || '';

export default bdecode;