import React from 'react';

import {AddSubAccountModalLogic} from "./AddSubAccountModalLogic";
import AddSubAccountModalUI from "./AddSubAccountModalUI";

interface IAddSubAccountModalProps {
	handleToggleAddSubAccountModal : () => void;
	setSubAccounts:(subAccounts:any) => void ;
}

const AddSubAccountModal = (props:IAddSubAccountModalProps) => {
	const { handleToggleAddSubAccountModal,setSubAccounts } = props ;

	const {
		isButtonDisabled,
		// handleGeneratePassword,
		addSubAccountModalRef,
		formik, generatedPassword, sendWelcomeEmail, setSendWelcomeEmail} = AddSubAccountModalLogic({handleToggleAddSubAccountModal,setSubAccounts})

	return (
	 <AddSubAccountModalUI addSubAccountModalRef={addSubAccountModalRef} formik={formik}
						   // handleGeneratePassword={handleGeneratePassword}
						   sendWelcomeEmail={sendWelcomeEmail} setSendWelcomeEmail={setSendWelcomeEmail} isButtonDisabled={isButtonDisabled} handleToggleAddSubAccountModal={handleToggleAddSubAccountModal} generatedPassword={generatedPassword}/>
	)
}

export default AddSubAccountModal;