import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../UI/Header";
import styles from './EditAddListing.module.scss';
import {MoonLoader} from "react-spinners";
import {toast, ToastContainer} from "react-toastify";
import HeaderComponent from "./UI/HeaderComponent";
import EditTemplatesContainer from "./UI/EditTemplatesContainer";
import './EditPopover.css';
import ImageUploaderContainer from "../ImageUploader";
import ListingTitleContainer from "./UI/ListingTitleContainer";
import ShortDescriptionContainer from "./UI/ShortDescriptionContainer";
import DescriptionBuilderComponent from "./UI/DescriptionBuilderComponent";
import IntendedForComponent from "./UI/IntendedForComponent";
import ConditionsComponent from "./UI/ConditionsComponent";
import DropdownComponent from "./UI/DropdownComponent";
import {SellerNotesDraft} from "./UI/SellerNotesDraft";
import {instanceAxios} from "../../services";
import {FooterComponent} from "./UI/FooterComponent";

import {
    setDuplicateListingId,
    setEditAddModalId,
    setEditAddModalShow,
    setEditIndex,
    setEditTableData, setListingViewId, setShowListingViewModal,
} from "../../redux/slices/NavigationSlice";
import _, {debounce} from "lodash";
import getNameGenerator from "../../hooks/useNameGenerator";
import ListingAssistant from "../UI/ListingAssistant";
import {CloseSvg, Lightbulb} from "../../assets/icons";
import {ITableData} from "../../interfaces";
import useExtensionVersion from "../../hooks/useExtensionVersion";
import Listings from "../../services/Listings";
import useAppPrefix from "../../hooks/UseAppPrefix";
import {useNavigate} from "react-router-dom";
import {ListingAssistantVision} from "../UI/ListingAssistantVision";
import useBasisFuncs from "@hooks/useBasisFuncs";
import {isMobile} from "../ImageUploader/const/const";
import _get from "lodash/get";
import Auth from "@services/Auth";
import useRoles from "@hooks/useRoles";
import UserConfigs from "@services/UserConfigs";
import uploadedImageList from "../ImageUploader/container/UploadedImageList";

const option = {
    include_thumbnail: true,
    include_images: true,
    include_assignee: true,
}

interface Variation {
    Size: string;
    Color: string;
    Custom: string;
    Quantity: string;
    SKU: string;
}

const EditAndAddListingModal = ({setTableData}:any) => {
    const dispatch = useDispatch();
    const editTableData = useSelector((state: any) => state.navigation.editTableData);
    const editIndex = useSelector((state: any) => state.navigation.editIndex);
    const modalEditId = useSelector((state: any) => state.navigation.editAddModalShowId);
    const allowDraftsPageAccess =  useSelector(
        (state: any) => state.navigation.allowDraftsPageAccess
    );
    let idFromLink:any = null;
    try{
       const url = window.location.href; // Get the full URL
       idFromLink = Number(url.split("/").pop());
    } catch(e){
       console.log(e, 'error')
    }
    const [editId, setEditId] = useState<any>(modalEditId || idFromLink || null)
    const duplicateListingId = useSelector((state:any) => state.navigation.duplicateListingId);
    const [duplicateData, setDuplicateData] = useState(null)
    const showEditAddModal = useSelector((state:any) => state.navigation.editAddModalShow);
    const [imagesFromUploader, setImagesFromUploader] = useState<any>([]);
    const [itemData, setItemData] = useState<any>(editTableData ? [...editTableData]  : []);
    const [activeTemplateid, setActiveTemplateId] = useState(null);
    const [saveAndClose, setSaveAndClose] = useState(false);
    const [saveAndPreview, setSaveAndPreview] = useState(false);
    const [saveAndNew, setSaveAndNew] = useState(false);
    const [templates, setTemplates] = useState<any>([]);
    const [createFromImage, setCreateFromImage] = useState(false);
    const [listingAssistantContext, setListingAssistantContext] = useState('');
    const [regenerateAttempsLeftCount, setRegenerateAttempsLeftCount] = useState(0);
    const [regenerateTotal, setRegenerateTotal] = useState(0);
    const [showRegenerate, setShowRegenerate] = useState(false);
    const [regenerateId, setRegenerateId] = useState(null);
    const [listingAssistantTone, setListingAssistantTone] = useState("");
    const [configID, setConfigID] = useState(null);
    const [afterCreate, setAfterCreate] = useState(false)
    const [filesList, setFilesList] = useState<any>([]);
    const [failedBarcode, setFailedBarcode] = useState('');
    const extensionListRef = useRef<any>(null);
    const [assistantFromTitle, setAssistantFromTitle] = useState(false)
    const [hasExtensionInstall, setHasExtensionInstall] = useState(false);
    const isExtensionSupported =  useExtensionVersion('0.46.0');
    const [enableToShowSkuGenerator, setEnableToShowSkuGenerator] = useState(false)
    const [scanningFailed, setScanningFailed] = useState(false);
    const token: any = Auth.get_token();
    const {is_role_allowed,  hasBusiness, hasSimple, hasPro,  is_role_allowed_master } = useRoles();
    const {bdecode} = useBasisFuncs();

    const [hasExpandedDescription, setHasExpandedDescription] = useState(false)
    const [thumbnailCached, setThumbnailCached] = useState<any>(null);
    const [imagesArrayCached, setImagesArrayCached] = useState<any>(null)
    const [defaultTemplateMetdata, setDefaultTemplateMetadata] = useState(null);
    const [isShowInDropDown, setIsShowInDropDown] = useState(true);
    const [formInfo, setFormInfo] = useState<any>({
        newTitle: {
            text: '',
            changed: false,
        },
        newMercaryTitle: {
            text: null,
            changed: false,
        },
        newPoshmarkTitle: {
            text: null,
            changed: false,
        },
        newGrailedTitle: {
            text: null,
            changed: false,
        },
        newAmazonEbayTitle: {
            text: null,
            changed: false,
        },
        newFacebookTitle: {
            text: null,
            changed: false,
        },
        newDescription: {
            text: '',
            changed: false,
        },
        newBrand: {
            text: '',
            changed: false,
        },
        newColor: {
            text: '',
            changed: false,
        },
        newBullet:{
            text: '',
            changed: false,
        },
        newMeasure:{
            text: '',
            changed: false,
        },
        newBoth:{
            text: '',
            changed: false,
        },
        copyDesc:{
            text: '',
            changed: false,
        },
        newSize: {
            text: '',
            changed: false,
        },
        newMaterial: {
            text: '',
            changed: false,
        },
        newStyle: {
            text: '',
            changed: false,
        },
        newCondition:{
            text: '',
            changed: false,
        },
        newConditionNotes:{
            text: '',
            changed: false,
        },
        newSubCondition: {
            text: '',
            changed: false,
        },
        newPreOwnedCondition:{
            text: '',
            changed: false,
        },
        newMeasuerements: {
            text: '',
            changed: false,
        },
        newLength: {
            text: '',
            changed: false,
        },
        newLengthMeasure: {
            text: '',
            changed: false,
        },
        newWidth: {
            text: '',
            changed: false,
        },
        newWidthMeasure: {
            text: '',
            changed: false,
        },
        newHeight: {
            text: '',
            changed: false,
        },
        newHeigthMeasure: {
            text: '',
            changed: false,
        },
        newBottomOfDescription: {
            text: '',
            changed: false,
        },
        lineDetail1: {
            text: '',
            changed: false,
        },
        lineDetail2: {
            text: '',
            changed: false,
        },
        lineDetail3: {
            text: '',
            changed: false,
        },
        lineDetail4: {
            text: '',
            changed: false,
        },
        lineDetail5: {
            text: '',
            changed: false,
        },
        lineDetail6: {
            text: '',
            changed: false,
        },
        lineDetail7: {
            text: '',
            changed: false,
        },
        lineDetail8: {
            text: '',
            changed: false,
        },
        lineDetail9: {
            text: '',
            changed: false,
        },
        lineDetail10: {
            text: '',
            changed: false,
        },
        lineDetail11: {
            text: '',
            changed: false,
        },
        lineDetail12: {
            text: '',
            changed: false,
        },
        lineDetail13: {
            text: '',
            changed: false,
        },
        lineDetail14: {
            text: '',
            changed: false,
        },
        lineDetail15: {
            text: '',
            changed: false,
        },
        newPatternOrTexture: {
            text: '',
            changed: false,
        },
        newSeasonOrWeather: {
            text: '',
            changed: false,
        },
        newCare: {
            text: '',
            changed: false,
        },
        newMadein: {
            text: '',
            changed: false,
        },
        newKeywords: {
            text: '',
            changed: false,
        },
        newCogs:{
            text: '',
            changed: false,
        },
        newPrice:{
            text: '',
            changed: false,
        },
        newMSRP:{
            text: '',
            changed: false,
        },
        newSku:{
            text: '',
            changed: false,
        },
        newUpc:{
            text: '',
            changed: false,
        },
        newQuantity:{
            text: '',
            changed: false,
        },
        newDraft:{
            text: '',
            changed: false,
        },
        newShowInDropDown:{
            text: '',
            changed: false,
        },
        newNotes:{
            text: '',
            changed: false,
        },
        newShippingWeightlbkg:{
            text: '',
            changed: false,
        },
        newShippingWeightozg: {
            text: '',
            changed: false,
        },
        newShippingPackageLength: {
            text: '',
            changed: false,
        },
        newShippingPackageWidth: {
            text: '',
            changed: false,
        },
        newShippingPackageHeight: {
            text: '',
            changed: false,
        },
        newShippingZipOrCityCode: {
            text: '',
            changed: false,
        },
        newVariations: {
            text: '',
            changed: false,
        },
        newImagesArray: {
            text: '',
            changed: false,
        },
        newThumbnail: {
            text: '',
            changed: false,
        },
        newDescBuilder: {
            text: '',
            changed: false,
        },
        newPlatformIdByeBay: {
            text: '',
            changed: false,
        },
        newPlatformIdByEtsy: {
            text: '',
            changed: false,
        },
        newPlatformIdByPoshmark: {
            text: '',
            changed: false,
        },
        newPlatformIdByMercari: {
            text: '',
            changed: false,
        },
        newPlatformIdByAmazon: {
            text: '',
            changed: false,
        },
        newPlatformIdByGrailed: {
            text: '',
            changed: false,
        },
        newPlatformIdByFacebook: {
            text: '',
            changed: false,
        },
        newPlatformIdByDepop: {
            text: '',
            changed: false,
        },
        newPlatformIdByKidizen: {
            text: '',
            changed: false,
        },
        newPlatformIdByShopify: {
            text: '',
            changed: false,
        },
        newPlatformIdByInstagram: {
            text: '',
            changed: false,
        },
        newPlatformIdByVestiaire: {
            text: '',
            changed: false,
        },
        newIntendedFor: {
            text: '',
            changed: false,
        },
        newUpdated: 1,
        newPlatform: {
            text: '',
            changed: false,
        },
        newPlatformIssue: {
            text: 'active',
            modalOpen: null,
            removed: [],
            platform: [],
            changed: false,
        },
        newSold_price: {
            text: '',
            changed: false,
        },
        newShippingCost: {
            text: '',
            changed: false,
        },
        newDateSold_date: {
            text: '',
            changed: false,
        },
        newFees: {
            text: '',
            changed: false,
        },
        measurementLabel1:{
            text: '',
            changed: false,
        },
        measurementLabel2:{
            text: '',
            changed: false,
        },
        measurementLabel3:{
            text: '',
            changed: false,
        },
        measurementLabel4:{
            text: '',
            changed: false,
        },
        measurementLabel5:{
            text: '',
            changed: false,
        },
        measurementLabel6:{
            text: '',
            changed: false,
        },
        measurementLabel7:{
            text: '',
            changed: false,
        },
        measurementLabel8:{
            text: '',
            changed: false,
        },
        measurementLabel9:{
            text: '',
            changed: false,
        },
        measurementLabel10:{
            text: '',
            changed: false,
        },
        measurementLabel11:{
            text: '',
            changed: false,
        },
        measurementLabel12:{
            text: '',
            changed: false,
        },
        measurementLabel13:{
            text: '',
            changed: false,
        },
        measurementLabel14:{
            text: '',
            changed: false,
        },
        measurementLabel15:{
            text: '',
            changed: false,
        },
        measurementLabel16:{
            text: '',
            changed: false,
        },
        measurementLabel17:{
            text: '',
            changed: false,
        },
        measurementLabel18:{
            text: '',
            changed: false,
        },
        measurementLabel19:{
            text: '',
            changed: false,
        },
        measurementLabel20:{
            text: '',
            changed: false,
        },
        measurementLabelInCm1:{
            text: '',
            changed: false,
        },
        measurementLabelInCm2:{
            text: '',
            changed: false,
        },
        measurementLabelInCm3:{
            text: '',
            changed: false,
        },
        measurementLabelInCm4:{
            text: '',
            changed: false,
        },
        measurementLabelInCm5:{
            text: '',
            changed: false,
        },
        measurementLabelInCm6:{
            text: '',
            changed: false,
        },
        measurementLabelInCm7:{
            text: '',
            changed: false,
        },
        measurementLabelInCm8:{
            text: '',
            changed: false,
        },
        measurementLabelInCm9:{
            text: '',
            changed: false,
        },
        measurementLabelInCm10:{
            text: '',
            changed: false,
        },
        measurementLabelInCm11:{
            text: '',
            changed: false,
        },
        measurementLabelInCm12:{
            text: '',
            changed: false,
        },
        measurementLabelInCm13:{
            text: '',
            changed: false,
        },
        measurementLabelInCm14:{
            text: '',
            changed: false,
        },
        measurementLabelInCm15:{
            text: '',
            changed: false,
        },
        measurementLabelInCm16:{
            text: '',
            changed: false,
        },
        measurementLabelInCm17:{
            text: '',
            changed: false,
        },
        measurementLabelInCm18:{
            text: '',
            changed: false,
        },
        measurementLabelInCm19:{
            text: '',
            changed: false,
        },
        measurementLabelInCm20:{
            text: '',
            changed: false,
        },
        newAssignee: {
            text: '',
            changed: false,
        },
        showAllTitlesCheckbox: {
            text: '',
            changed: false,
        },
        suggestTitleCheckbox: {
            text: '',
            changed: false,
        },
        addMoreLinesCheckbox: {
            text: '',
            changed: false,
        },
        moreMeasurementCheckbox1: {
            text: '',
            changed: false,
        },
        moreMeasurementCheckbox2: {
            text: '',
            changed: false,
        },
        newEmoji:{
            text: '',
            changed: false,
        }
    });
    const isExtensionSupportedForSingleView =  useExtensionVersion('0.18.5');
    const [enabledButtons, setEnabledButtons] = useState(true);
    const [savingProgress, setSavingProgress] = useState(false);
    const [preventImagesFirstLoad, setPreventImagesFirstLoad] = useState(false);
    const [listInfo, setListInfo] = useState<any>(null);
    const [showLoader, setShowLoader] = useState(true);
    const [itemImages, setItemImages] = useState<any>([]);
    const [formChanged, setFormChanged] = useState(false);
    const [updateButtonsDisabled, setUpdateButtonsDisabled] = useState(false);
    const [isImagesUploaded, setIsImagesUploaded] = useState(true);
    const [loaderForSuggest, setLoaderForSuggest] = useState(false);
    const [showListingAssistant, setShowListingAssistant] = useState(false);
    const [preventFirstRender, setPreventFirstRender] = useState(false);
    const [addListingSendRequest, setAddListingSendRequest] = useState(false);
    const [editListingSendRequest, setEditListingSendRequest] = useState(false);
    const [dataFromExtension, setDataFromExtension] = useState<any>(null);
    const [disableSaveButtons, setDisableSaveButtons] = useState(true);
    const initialLoadRef = useRef(true);
    const [commonState, setCommonState] = useState({
        brand: '',
        size: '',
        description: '',
        keywords: '',
        color: '',
        title: '',
    });
    const [barCodeLimits, setBarCodeLimits] = useState({
        available: 0,
        total: 0,
    });
    const [quotasLimit, setQuotasLimit] = useState({
        available: 0,
        total: 0,
    });
    const [scanLoader, setScanLoader] = useState(false);
    const [assistantTitle, setAssistantTitle] = useState('');
    const [assistantTitleVision, setAssistantTitleVision] = useState('');
    const [generatedModal, setGeneratedModal] = useState(false);
    const [descriptionBuildItems, setDescriptionBuildItems] = useState([
        { id: 1, value: 'normall', text: 'Expanded description', active: editId ? false : true },
        { id: 2, value: 'shortenl', text: 'Short description only', active: false },
    ]);
    const [IntendedForItems, setIntendedItems] = useState([
        { id: 1, value: 'Unisex', text: 'Unisex', active: false },
        { id: 2, value: 'Mens', text: 'Men', active: false },
        { id: 3, value: 'Womens', text: 'Women', active: false },
        { id: 4, value: 'Unisex Kids', text: 'Unisex Kids', active: false },
        { id: 5, value: 'Girls', text: 'Girls', active: false },
        { id: 6, value: 'Boys', text: 'Boys', active: false },
        { id: 7, value: 'Babys', text: 'Babies', active: false },
        { id: 8, value: 'Maternity', text: 'Maternity', active: false },
        { id: 9, value: 'Pets', text: 'Pets', active: false },
        { id: 10, value: 'Cats', text: 'Cats', active: false },
        { id: 11, value: 'Dogs', text: 'Dogs', active: false },
        { id: 12, value: null, text: 'None', active: false },
    ]);
    const [showAllTitles, setShowAllTitles] = useState<any>(false);
    const [activeCondition, setActiveCondition] = useState(0);
    const [conditionItems, setConditionItems] = useState([
        { id: 1, value: 'New', text: 'New', active: false },
        { id: 2, value: 'Pre-Owned', text: 'Pre-Owned', active: false },
        { id: 3, value: null, text: 'None', active: false },
    ]);
    const [newConditionItems, setNewConditionItems] = useState([
        { id: 1, text: 'NWT', value: 'With Tags', active: false },
        { id: 2, text: 'NWB', value: 'With Box', active: false },
        { id: 3, text: 'NWOT', value: 'Without Tags', active: false },
        { id: 4, text: 'NWOB', value: 'Without Box', active: false },
        { id: 5, text: 'NWD', value: 'With Defects', active: false },
        { id: 6, text: 'None', value: null, active: false },
    ]);
    const [preOwnedItems, setPreOwnedItems] = useState([
        { id: 1, text: 'Like New', value: 'Like New', active: false },
        { id: 2, text: 'Good', value: 'Good', active: false },
        { id: 3, text: 'Fair', value: 'Fair', active: false },
        { id: 4, text: 'Poor', value: 'Poor', active: false },
        { id: 5, text: 'None', value: null, active: false },
    ]);
    const [bulletItems, setBulletItems] = useState([
        { id: 1, text: 'Normal Bullets', value: 'normalbullet', active: editId ? false : true  },
        { id: 2, text: 'Emoji', value: 'emoji', active: false },
        { id: 3, text: 'No Bullets', value: 'nobullet', active: false },
    ]);
    const [measureItems, setMeasureItems] = useState([
        { id: 1, text: 'Inches', value: 'in', active: editId ? false : true  },
        { id: 2, text: 'Centimeters', value: 'cm', active: false },
    ]);
    const [measureBothItems, setMeasureBothItems] = useState([
        { id: 1, value: 'in-cm', text: 'Yes', active: editId ? false : true  },
    ]);
    const [selectedOptionTemplate, setSelectedOptionTemplate] = useState({
        value: 'Select Template',
        label: 'Select Template',
        id: null
    });
    const [productInfoJSON, setProductInfoJSON] = useState<any>(null);
    const [salesListings, setSalesListings] = useState([
        {
            id: 0,
            title: 'SOLD',
            placeholder: 'newSOLD',
            checked: false,
            changed: false,
            code: '539.1',
        },
        {
            id: 1,
            title: 'eBay',
            placeholder: 'neweBay',
            checked: false,
            changed: false,
            code: '539.2',
        },
        {
            id: 2,
            title: 'Etsy',
            placeholder: 'newEtsy',
            checked: false,
            changed: false,
            code: '539.3',
        },
        {
            id: 3,
            title: 'Poshmark',
            placeholder: 'newPoshmark',
            checked: false,
            changed: false,
            code: '539.4',
        },
        {
            id: 4,
            title: 'Mercari',
            placeholder: 'newMercari',
            checked: false,
            changed: false,
            code: '539.5',
        },
        {
            id: 5,
            title: 'Amazon',
            placeholder: 'newAmazon',
            checked: false,
            changed: false,
            code: '539.7',
        },
        {
            id: 6,
            title: 'Grailed',
            placeholder: 'newGrailed',
            checked: false,
            changed: false,
            code: '539.8',
        },
        {
            id: 7,
            title: 'Facebook',
            placeholder: 'newFacebook',
            checked: false,
            changed: false,
            code: '539.9',
        },
        {
            id: 8,
            title: 'Depop',
            placeholder: 'newDepop',
            checked: false,
            changed: false,
            code: '539.11',
        },
        {
            id: 9,
            title: 'Kidizen',
            placeholder: 'newKidizen',
            checked: false,
            changed: false,
            code: '539.12',
        },
        {
            id: 19,
            title: 'Shopify',
            placeholder: 'newShopify',
            checked: false,
            changed: false,
            code: '539.13',
        },
        {
            id: 11,
            title: 'Instagram',
            placeholder: 'newInstagram',
            checked: false,
            changed: false,
            code: '539.14',
        },
        {
            id: 12,
            title: 'Listing Party',
            placeholder: 'newListing_party',
            checked: false,
            changed: false,
            code: '539.16',
        },
        {
            id: 13,
            title: 'Vestiaire',
            placeholder: 'newVestiaire',
            checked: false,
            changed: false,
            code: '539.17',
        },
    ]);
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
    const [variations, setVariations] = useState<Variation[]>([{
        Size: '',
        Color: '',
        Custom: '',
        Quantity: '',
        SKU: '',
    }]);
    const [addMoreInputs, setAddMoreInputs] = useState(false);
    const [moreMeasurementsPart1, setMoreMeasurementsPart1] = useState(false);
    const [moreMeasurementsPart2, setMoreMeasurementsPart2] = useState(false);
    const [subAccounts, setSubAccounts] = useState([]);
    const [optionsAssignee, setOptionsAssignee] = useState<any>([{ value: 'Select Assignee Use', label: 'Select Assignee Use', id: null }]);
    const [selectedOptionAssignee, setSelectedOptionAssignee] = useState({
        value: 'Select Assignee Use',
        label: 'Select Assignee Use',
        id: null
    });
    const [assigneeId, setAssigneeId] = useState<any>(null);
    const linkPrefix = useAppPrefix();
    const navigate = useNavigate();
    const [loaderForCustomMarkets, setLoaderForCustomMarkets] = useState(true);
    const [requestId, setRequestId] = useState(null);
    const [customMarketplacesTableRow, setCustomMarketplacesTableRow] =
        useState<any>([]);
    const [hideAssistantModal, setHideAssistantModal] = useState(false);
    const [uploaderChangedWhenLoad, setUploaderChangedWhenLoad] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [SKUloader, setSKUloader] = useState(false);
    const [activeItemSKU, setActiveItemSKU] = useState(null);
    const [activeItemSKUValue, setActiveItemSKUValue] = useState(null);
    const [SKUInputValue, setSKUInputValue] = useState('');
    const [SKUprefix, setSKUprefix] = useState("");
    const [SKUformat, setSKUformat] = useState([]);
    const [skuGeneratorUniqueIdFormat, setSkuGeneratorUniqueIdFormat] = useState(2);
    const [config, setConfig] = useState<any>({});
    const skuKeys = [
        'sku_generator_location_1',
        'sku_generator_location_2',
        'sku_generator_location_3',
        'sku_generator_location_4',
        'sku_generator_location_5'
    ];
    const globalCustomMarketplaces = useSelector((state: any) => state.customMarketplaces.customMarketplaces) ;
    const handleAddListing = ()=>{
        setSavingProgress(true);
        setAddListingSendRequest(true)
    }

    const handleSaveListing = () =>{
        setSavingProgress(true);
        setEditListingSendRequest(true);
    }

    const handleSKUitemClick = (key:any) => {
        setActiveItemSKU(key);
        setActiveItemSKUValue(config[key])
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        const inputValue = event.currentTarget.value;

        // Check if the pressed key is a dot
        if (charCode === 46) {
            // Check if there is already a dot in the input value
            if (inputValue.includes('.')) {
                event.preventDefault();
            }
        } else if ((charCode < 48 || charCode > 57)) {
            // Allow only digits
            event.preventDefault();
        }
    };

    const handleInputChangeSKU = (e:any) => {
        const value = e.target.value;
        if(value?.length > 10) return
        localStorage.setItem('SKUInputValue', value);
        setSKUInputValue(value);
    };

    // const getTypeWebsiteInfo = async() => {
    //     try {
    //         const payload: any = { type: 'website' };
    //         const response = await instanceAxios.post('user-configs/get', payload);
    //         if(response.status === 200) {
    //
    //         }
    //     }
    //     catch (e) {
    //         console.log('error', e)
    //     }
    //     // finally {
    //     //     setExpressLoader(false)
    //     // }
    // }

    useEffect(() => {
        // getTypeWebsiteInfo();
        const savedSKUValue:any = localStorage.getItem('SKUInputValue');
        if (savedSKUValue) {
            setSKUInputValue(savedSKUValue);
        }
    }, []);
    


    useEffect(() => {
        const bodyElement = document.body;
        const handleAttributeChange = () => {
            const extensionVersion = bodyElement.getAttribute('lp_custom_chrome_extension_version');
            try {
                if (extensionVersion) {
                    setHasExtensionInstall(true)
                }
                else {
                    setHasExtensionInstall(false)
                }
            } catch (e) {
                console.log('error', e);
            }
        };

        const observer = new MutationObserver(() => {
            handleAttributeChange();
        });

        observer.observe(bodyElement, {
            attributes: true,
            attributeFilter: ['lp_custom_chrome_extension_version'],
        });

        // Initial attribute check
        handleAttributeChange();

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const isExtensionSource = searchParams.get('source') === 'extension';

        const handleDataChange = () => {
           try{
               if (!dataFetched && isExtensionSource) { // Check if data has already been fetched
                   const element: any = document.querySelector('.lp_custom_chrome_extension_marketplace_listing_data');
                   if (element) {
                       const data = JSON.parse(element?.innerText); // Adjust depending on how the data is stored
                           setDataFromExtension(data?.data);
                           setDataFetched(true); // Set flag to true after data is fetched
                   }
               }
           }
           catch(e){
               console.log(e, 'error')
           }
        };

        const observer = new MutationObserver(handleDataChange);

        const targetNode = document.body; // or the closest parent container where the element might be added
        if (targetNode) {
            observer.observe(targetNode, {
                childList: true,
                subtree: true,
                characterData: true, // If you expect text content to change inside the element
            });
        }

        // Clean up observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [dataFetched]);

    useEffect(() => {
        try{
            if(dataFromExtension && dataFromExtension?.images){
                const files = dataFromExtension?.images?.map((base64:any, index:any) => {
                    const name = `image-${index + 1}.jpeg`;
                    return base64ToFile(base64, name);
                });
                setFilesList(files)
            }
        }
        catch(e){
            console.log(e, 'error')
        }
    }, [dataFromExtension]);


    useEffect(() => {
        getTemplatesList(editId);
    }, []);

    // useEffect(() => {
    //     const hasAnyChanged = Object.entries(formInfo)
    //         .filter(([key]) => key !== 'newImagesArray' && key !== 'newThumbnail' && key !== 'copyDesc') // Exclude specific keys
    //         .some(([, item]: [string, any]) => item?.changed === true);
    //     if(hasAnyChanged && (itemData[editIndex] && itemData[editIndex]?.changed)){
    //            setDisableSaveButtons(false);
    //     }
    // }, [formInfo, itemData]);

    useEffect(() => {
        const hasAnyChanged = Object.entries(formInfo)
            .filter(([key]) =>
                editId
                    ? key !== 'newImagesArray' && key !== 'newThumbnail' && key !== 'copyDesc'  && key !== 'newImage' // Exclude specific keys only if editID exists
                    : true // Include all keys if editID doesn't exist
            )
            .some(([, item]: [string, any]) => item?.changed === true);
        
        if (hasAnyChanged && itemData[editIndex]?.changed) {
            setDisableSaveButtons(false);
        }
    }, [formInfo, itemData, editId]);

    const [userConfigDuplicatePrevent, setUserConfigDuplicatePrevent] = useState(false);
    useEffect(() => {
        try {
            const handleGetCustomMarketsAndSku = async () => {
                try {
                    const response: any = await UserConfigs.get('website', token)
                    if (response) {
                        const {config, id} = response;
                        const user_config = {
                            config,
                        };
                        const defaultZip = _.has(config, 'default_shipping_zip');
                        if(defaultZip && !editId && !duplicateListingId){
                            setFormInfo({
                                ...formInfo,
                                ['newShippingZipOrCityCode']: { text: _.get(config, 'default_shipping_zip'), changed: true },
                            });
                            setItemData((prevItemData:any) => {
                                const updatedItem = [...prevItemData];
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newShippingZipOrCityCode']: _.get(config, 'default_shipping_zip'),
                                    changed: true,
                                };
                                return updatedItem;
                            });
                        }


                        setRequestId(response.id);
                        const hasListingAssistantTone = _.has(config, 'listing_assistant_tone');
                        if(hasListingAssistantTone) {
                            const listingAssistantTone = _.get(config, 'listing_assistant_tone');
                            setListingAssistantTone(listingAssistantTone)
                        }
                        setConfigID(id);
                        setConfig(config);
                        const skuPrefix = _get(config, 'sku_generator_prefix', '');
                        if (skuPrefix) {
                            setSKUprefix(skuPrefix);
                        }
                        const skuFormat = _get(config, 'sku_generator_format', []);
                        if (skuFormat.length > 0) {
                            setSKUformat(skuFormat);
                        }
                        const skuUniqueId = _.has(config, 'sku_generator_unique_id_format');
                        if(skuUniqueId){
                            const skuID = _.get(config, 'sku_generator_unique_id_format');
                            setSkuGeneratorUniqueIdFormat(skuID)
                        }
                        const firstAvailableKey:any = skuKeys.find(key => _.has(config, key));
                        if (firstAvailableKey) {
                            setActiveItemSKU(firstAvailableKey);
                            setActiveItemSKUValue(config[firstAvailableKey]);
                        }
                    }

                } catch (e) {
                    console.log(e, 'error');
                }
                finally {
                    setUserConfigDuplicatePrevent(true);
                }
            };
            handleUpdateCustomMarketplaces(listInfo)

            if(!userConfigDuplicatePrevent) {
                handleGetCustomMarketsAndSku()
            }
        }
        catch (e) {
            console.log(e, 'error')
        }
    }, [listInfo, editId, userConfigDuplicatePrevent]);


    const handleUpdateCustomMarketplaces = (listInfo:any) => {
        let customMarketPlaces = [...globalCustomMarketplaces];

        if (hasBusiness && customMarketPlaces.length > 5) {
            customMarketPlaces = customMarketPlaces.slice(0, 5);
        }

        if (hasSimple) {
            customMarketPlaces = [];
        }

        const isPlatformCodeValid = (platformCodes: string[], listInfo:any) => {
            if (!listInfo) return false;

            const platformsCode = Object.keys(listInfo);
            return platformCodes.every((code) => {
                const normalizedCode = code.toLowerCase();
                return (platformsCode.includes(code) || platformsCode.includes(normalizedCode))
                 && listInfo[code] !== null;
            });
        };

        const tempCustomMarketplaces = customMarketPlaces.map((item) => {
            const isValidPlatform = item.platform.code
             ? isPlatformCodeValid(item.platform.code, listInfo || {})
             : false;

            return {
                ...item,
                platform: {
                    ...item.platform,
                    isChecked: listInfo !== null && typeof listInfo === 'object'
                     ? isValidPlatform
                     : false
                }
            };
        });

        setCustomMarketplacesTableRow(tempCustomMarketplaces);
        setLoaderForCustomMarkets(false);
    };

    const getListInfo = async(editId:any) => {
        const metadata = { offset: 0, limit: 1 };
        const response :any = await Listings.list({id: editId}, metadata, option, null, token)
        if(response) {
            const { data } = response;
            setProductInfoJSON(JSON.stringify(data[0]));
            if(duplicateListingId){
                setDuplicateData(data[0]?.metadata);
                setDisableSaveButtons(false);
                setEnabledButtons(true);
            }else{
                if(data[0]?.metadata){
                    if (data[0]?.metadata?.[166] === 'normall') {
                        setHasExpandedDescription(true)
                    }
                    setListInfo(data[0]?.metadata);
                }
            }
            if(hasPro && is_role_allowed){
                if(data[0]?.metadata[1]){
                    setEnableToShowSkuGenerator(false);
                }else{
                    setEnableToShowSkuGenerator(true);
                }
            }
            setItemImages(data[0]?.images);
            setUploaderChangedWhenLoad(true);
            setShowLoader(false);
        }
    }

    const handleResetAllTitles = () => {
        // Create a deep copy of itemData to ensure immutability
        const titlesToReset = [
            'newTitle',
            'newMercaryTitle',
            'newPoshmarkTitle',
            'newGrailedTitle',
            'newAmazonEbayTitle',
            'newFacebookTitle'
        ];

        const updatedItem = [...itemData];

// Reset all titles and set `changed` to true in one go
        updatedItem[editIndex] = {
            ...updatedItem[editIndex],
            changed: true,
            ...titlesToReset.reduce((acc, title) => ({ ...acc, [title]: null }), {}),
        };

// Update itemData state
        setItemData(updatedItem);

        // Update formInfo state in one go
        setFormInfo((prev:any) => ({
            ...prev,
            newTitle:{ text: '', changed: true },
            newMercaryTitle: { text: '', changed: true },
            newPoshmarkTitle: { text: '', changed: true },
            newGrailedTitle: { text: '', changed: true },
            newAmazonEbayTitle: { text: '', changed: true },
            newFacebookTitle: { text: '', changed: true },
        }));
    };

    const checkModalExistence = () => {
        try {
            const modal:any = document.querySelector('.mobile-image-actions-modal');
            const closeIcon:any = document.querySelector('.full-form-close-icon');

            if (closeIcon) {
                if (modal) {
                    // Hide the close icon if the modal exists
                    closeIcon.style.display = 'none';
                } else {
                    // Show the close icon if the modal does not exist
                    if(isMobile) {
                        closeIcon.style.display = 'block';
                    }
                    else{
                        closeIcon.style.display = 'none';
                    }
                }
            }
        } catch (error) {
            console.error('Error checking modal or updating close icon:', error);
        }
    };

    useEffect(() => {
        // Run the check initially on component mount
        checkModalExistence();

        // MutationObserver callback function with requestAnimationFrame
        const mutationCallback = (mutationsList: MutationRecord[]) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    requestAnimationFrame(() => checkModalExistence());
                }
            }
        };

        // Set up the observer on the entire body
        const observer = new MutationObserver(mutationCallback);

        // Start observing the body for changes in its children
        observer.observe(document.body, {
            childList: true, // Detect addition/removal of children
            subtree: true,   // Detect deep changes within the subtree
        });

        // Cleanup function to disconnect the observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, []);



    const getSubAccounts = async() => {
        try {
            if (!is_role_allowed_master) return;
            const payload: any = {filter_by: {}};
            const response = await instanceAxios.post('users/sub-accounts/list', payload);
            if(response.status === 200) {
                setSubAccounts(response.data.data);
            }
        }
        catch (e) {
            console.log('error', e)
        }
    }

    useEffect(() => {
        getSubAccounts();
    }, []);

    useEffect(() => {
        const newOptions = subAccounts.map((acc: any) => ({
            value: `${acc?.first_name} ${acc?.last_name}`,
            label:`${acc?.first_name} ${acc?.last_name}`,
            id: `${acc?.id}`
        }));
        setOptionsAssignee([{ value: 'Select Assignee Use', label: 'Select Assignee Use', id: null }, ...newOptions]);

    }, [subAccounts]);

    const handleChangeUploaderInputs = (
        thumbnailValue: string,
        imagesArrayValue: string
    ) => {

        setItemData((prevItemData:any) => {
            let updatedItems = [...prevItemData]; // Copy the previous itemData
            let changes: any = {};
            if(thumbnailValue){
                changes['newThumbnail'] = thumbnailValue;
            }

            if (imagesArrayValue?.length > 0) {
                changes['newImagesArray'] = imagesArrayValue;
            }else{
                changes['newImagesArray'] = '';
            }
            updatedItems[editIndex] = {
                ...updatedItems[editIndex], // Keep existing properties
                ...changes, // Merge new changes
                changed: true, // Mark it as changed
            };
            return updatedItems; // Return the updated state
        });
        setFormInfo((prevTableInfo: any) => {
            const newTableInfoUpdates = { ...prevTableInfo }; // Copy previous formInfo
            newTableInfoUpdates['newThumbnail'] = {
                text: thumbnailValue,
                changed: !uploaderChangedWhenLoad,
            };
            newTableInfoUpdates['newImagesArray'] = {
                text: imagesArrayValue,
                changed: !uploaderChangedWhenLoad,
            };
            return newTableInfoUpdates; // Return the updated state
        });
        setUploaderChangedWhenLoad(false);
        setEnabledButtons(true);
        if (initialLoadRef.current) {
            initialLoadRef.current = false; // Set to false after the first run
            setThumbnailCached(thumbnailValue);
            setImagesArrayCached(imagesArrayValue);
        } else {
            // Code to execute only when values change (not on first load)
            setPreventImagesFirstLoad(true)
        }
    };

    useEffect(() => {
        if(editId && preventImagesFirstLoad){
            if(formInfo.newThumbnail.text !== thumbnailCached || formInfo.newImagesArray.text !== imagesArrayCached){
                setSavingProgress(true);
                handleEditListingRequest(saveAndPreview, saveAndNew, saveAndClose, 'onlyImages');
                setThumbnailCached(formInfo.newThumbnail.text);
                setImagesArrayCached(formInfo.newImagesArray.text);
            }
        }
    }, [formInfo.newThumbnail.text, formInfo.newImagesArray.text, preventImagesFirstLoad, editId]);

    // useEffect(() => {
    //     if(preventFirstRender) {
    //         setEnabledButtons(true);
    //     }
    // }, [preventFirstRender, itemData]);


    useEffect(() => {
        const updatedItem = [...itemData];
        // updatedItem[editIndex] = {
        //     ...updatedItem[editIndex],
        //     ['newShowInDropDown']: '1',
        //     changed: true,
        // };
        if(!hasSimple && !hasBusiness && (!editId && editId !== undefined ) && !duplicateListingId ){
            updatedItem[editIndex] = {
                ...updatedItem[editIndex],
                ['newDescBuilder']: 'normall',
                ['newBullet']: 'normalbullet',
                ['newMeasure']: 'in',
                ['newBoth']: 'in-cm',
                changed: true,
            };
        }
        setItemData(updatedItem);
    }, [hasSimple, hasBusiness, editId]);



    useEffect(() => {
        if(preventFirstRender){
            setItemData((prevState:any) => {
                let updatedItems = [...prevState];
                const changes: any = {};

                changes['newImage'] = imagesFromUploader[0];
                if(!imagesFromUploader?.length){
                    changes['newThumbnail'] = null;
                }
                updatedItems[editIndex] = {
                    ...updatedItems[editIndex],
                    ...changes,
                    changed: true,
                };

                return updatedItems;
            });
        }
    }, [imagesFromUploader]);


    useEffect(() => {
        setShowLoader(true)
        if(editId && !afterCreate) {
            getListInfo(editId);
        }
        else {
            setShowLoader(false);
        }
    }, [editId]);

    useEffect(() => {
        setShowLoader(true)
        if(duplicateListingId) {
            getListInfo(duplicateListingId);
        }
        else {
            setShowLoader(false);
        }
    }, [duplicateListingId]);


    useEffect(() => {
        return () => {
            dispatch(setEditAddModalShow(false));
            dispatch(setEditAddModalId(null));
            dispatch(setEditIndex(0));
            dispatch(setEditTableData([]));
            setDefaultTemplateMetadata(null);
            dispatch(setDuplicateListingId(null));
            setDuplicateData(null);
        }
    }, []);

    const handleAddListingRequest = async (saveAndPreviewLocal?:any, saveAndNewLocal?:any, saveAndCloseLocal?:any) => {
        try {
            const mapKeyToId: any = {
                newTitle: '133',
                newMercaryTitle: '789',
                newPoshmarkTitle: '786',
                newGrailedTitle: '788',
                newAmazonEbayTitle: '791',
                newFacebookTitle: '869',
                newSku: '1',
                newMSRP: '104',
                newCondition: '15',
                newConditionNotes: '17',
                newSubCondition: '795',
                newPreOwnedCondition: '796',
                newPrice: '22',
                newMaterial: '82',
                newBrand: '206',
                newColor: '209',
                newSize: '213',
                newKeywords: '453',
                newDescription: '505',
                newMeasuerements: '750',
                newLength: '48',
                newLengthMeasure: '380',
                newWidth: '46',
                newWidthMeasure: '386',
                newHeight: '47',
                newHeigthMeasure: '391',
                newShippingWeightlbkg: '2',
                newShippingWeightozg: '3',
                newShippingPackageLength: '531',
                newShippingPackageWidth: '532',
                newShippingPackageHeight: '533',
                newShippingZipOrCityCode: '837',
                newVariations: '857',
                newBullet: '44',
                newMeasure: '54',
                newBoth: '723.1',
                newImagesArray: '728',
                newThumbnail: '727',
                // copyDesc: '854',
                lineDetail1: '340',
                lineDetail2: '341',
                lineDetail3: '357',
                lineDetail4: '629',
                lineDetail5: '630',
                lineDetail6: '188',
                lineDetail7: '266',
                lineDetail8: '397',
                lineDetail9: '175',
                lineDetail10: '628',
                lineDetail11: '874',
                lineDetail12: '875',
                lineDetail13: '876',
                lineDetail14: '877',
                lineDetail15: '878',
                newPatternOrTexture: '210',
                newSeasonOrWeather: '36',
                newCare: '43',
                newMadein: '211',
                newBottomOfDescription: '722',
                newUpc: '704',
                newNotes: '726',
                newDescBuilder: '166',
                newIntendedFor: '4',
                newSold_price: '842',
                newCogs: '847',
                newFees: '844',
                newQuantity: '851',
                newSOLD: '539.1',
                newStyle: '205',
                newDateSold_date: '846',
                newEtsy: '539.3',
                newPoshmark: '539.4',
                neweBay: '539.2',
                newMercari: '539.5',
                newTradesy: '539.6',
                newAmazon: '539.7',
                newGrailed: '539.8',
                newFacebook: '539.9',
                newDepop: '539.11',
                newKidizen: '539.12',
                newShopify: '539.13',
                newInstagram: '539.14',
                newHeroine: '539.15',
                newListing_party: '539.16',
                newVestiaire: '539.17',
                newShippingCost: '852',
                newDraft: '859.1',
                newPlatformIdByeBay: '618',
                newPlatformIdByEtsy: '619',
                newPlatformIdByPoshmark: '620',
                newPlatformIdByMercari: '621',
                newPlatformIdByTradesy: '833',
                newPlatformIdByAmazon: '836',
                newPlatformIdByGrailed: '839',
                newPlatformIdByFacebook: '840',
                newPlatformIdByDepop: '841',
                newPlatformIdByKidizen: '853',
                newPlatformIdByShopify: '855',
                newPlatformIdByInstagram: '856',
                newPlatformIdByListingParty: '539.16',
                newPlatformIdByVestiaire: '885',
                measurementLabel4: '378',
                measurementLabel5: '384',
                measurementLabel6: '389',
                measurementLabel7: '70',
                measurementLabel8: '641',
                measurementLabel9: '642',
                measurementLabel10: '643',
                measurementLabel11: '185',
                measurementLabel12: '186',
                measurementLabel13: '187',
                measurementLabel14: '192',
                measurementLabel15: '194',
                measurementLabel16: '331',
                measurementLabel17: '405',
                measurementLabel18: '407',
                measurementLabel19: '411',
                measurementLabel20: '602',
                measurementLabelInCm4: '88',
                measurementLabelInCm5: '89',
                measurementLabelInCm6: '90',
                measurementLabelInCm7: '91',
                measurementLabelInCm8: '617',
                measurementLabelInCm9: '637',
                measurementLabelInCm10: '639',
                measurementLabelInCm11: '28',
                measurementLabelInCm12: '30',
                measurementLabelInCm13: '59',
                measurementLabelInCm14: '84',
                measurementLabelInCm15: '85',
                measurementLabelInCm16: '86',
                measurementLabelInCm17: '87',
                measurementLabelInCm18: '92',
                measurementLabelInCm19: '93',
                measurementLabelInCm20: '94',
                newSuggestTitle: '783',
                newAssignee: '883',
                showAllTitlesCheckbox: '822.1',
                suggestTitleCheckbox: '784.1',
                addMoreLinesCheckbox: '879.1',
                moreMeasurementCheckbox1: '377.1',
                moreMeasurementCheckbox2: '109.1',
                newEmoji: '45'
            };
            const updatedMapKeyToId = customMarketplacesTableRow.reduce(
                (acc: any, item: any) => {
                    if (item.platform && item.platform.title) {
                        const key = `newCustom${item.platform.title}`;
                        acc[key] = item.platform.code[0];
                    }
                    return acc;
                },
                {...mapKeyToId}
            );
            const finallyMapKeyToId: Record<string, string> = customMarketplacesTableRow.reduce(
                (acc: any, item: any, index: number) => {
                    if (item.platform && item.platform.title) {
                        const key = `newPlatformIdByCustom${item.platform.title.replace(
                            /[^a-zA-Z0-9' ]/g,
                            ''
                        )}`;

                        acc[key] =
                            index === 0
                                ? '892'
                                : index === 1
                                    ? '893'
                                    : index === 2
                                        ? '894'
                                        : index === 3
                                            ? '895'
                                            : index === 4
                                                ? '896'
                                                : index === 5
                                                    ? '897'
                                                    : index === 6
                                                        ? '898'
                                                        : index === 7
                                                            ? '899'
                                                            : index === 8
                                                                ? '900'
                                                                : index === 9
                                                                    ? '901'
                                                                    : (index + 891).toString();
                    }
                    return acc;
                },
                {...updatedMapKeyToId}
            );
            const updates: { key: keyof ITableData; index: number; value: any }[] = [];

            const customKeys = customMarketplacesTableRow.map(
                (item: any) => `newCustom${item.platform.title}`
            );
            const customKeysId = customMarketplacesTableRow.map(
                (item: any) => `newPlatformIdByCustom${item.platform.title}`
            );

            itemData?.forEach((item:any, index:any) => {
                if (item) {
                    const keys: (keyof ITableData)[] = [
                        'newTitle',
                        'newMercaryTitle',
                        'newPoshmarkTitle',
                        'newVariations',
                        'newGrailedTitle',
                        'newAmazonEbayTitle',
                        'newFacebookTitle',
                        'newMSRP',
                        'newSku',
                        'newUpc',
                        'newImagesArray',
                        'newThumbnail',
                        'newDescBuilder',
                        'newIntendedFor',
                        'newCondition',
                        'newConditionNotes',
                        'newSubCondition',
                        'newPreOwnedCondition',
                        'newMeasuerements',
                        'newLength',
                        'newLengthMeasure',
                        'newWidth',
                        'newWidthMeasure',
                        'newHeight',
                        'newHeigthMeasure',
                        'newShippingWeightlbkg',
                        'newShippingWeightozg',
                        'newShippingPackageLength',
                        'newShippingPackageWidth',
                        'newShippingPackageHeight',
                        'newShippingZipOrCityCode',
                        'newVariations',
                        'newBullet',
                        'newStyle',
                        'newMeasure',
                        'newBoth',
                        'copyDesc',
                        'lineDetail1',
                        'lineDetail2',
                        'lineDetail3',
                        'lineDetail4',
                        'lineDetail5',
                        'lineDetail6',
                        'lineDetail7',
                        'lineDetail8',
                        'lineDetail9',
                        'lineDetail10',
                        'lineDetail11',
                        'lineDetail12',
                        'lineDetail13',
                        'lineDetail14',
                        'lineDetail15',
                        'newPatternOrTexture',
                        'newSeasonOrWeather',
                        'newCare',
                        'newMadein',
                        'newBottomOfDescription',
                        'newPrice',
                        'newMaterial',
                        'newBrand',
                        'newColor',
                        'newSize',
                        'newKeywords',
                        'newDescription',
                        'newUpc',
                        'newNotes',
                        'newSold_price',
                        'newDateSold_date',
                        'newCogs',
                        'newFees',
                        'newQuantity',
                        'newSOLD',
                        'newShippingCost',
                        'newEtsy',
                        'newPoshmark',
                        'neweBay',
                        'newMercari',
                        'newTradesy',
                        'newAmazon',
                        'newGrailed',
                        'newFacebook',
                        'newDepop',
                        'newKidizen',
                        'newShopify',
                        'newInstagram',
                        'newHeroine',
                        'newListing_party',
                        'newVestiaire',
                        'newDraft',
                        'newPlatformIdByeBay',
                        'newPlatformIdByEtsy',
                        'newPlatformIdByPoshmark',
                        'newPlatformIdByMercari',
                        'newPlatformIdByTradesy',
                        'newPlatformIdByAmazon',
                        'newPlatformIdByGrailed',
                        'newPlatformIdByFacebook',
                        'newPlatformIdByDepop',
                        'newPlatformIdByKidizen',
                        'newPlatformIdByShopify',
                        'newPlatformIdByInstagram',
                        'newPlatformIdByHeroine',
                        'newPlatformIdByListingParty',
                        'newPlatformIdByVestiaire',
                        'measurementLabel4',
                        'measurementLabel5',
                        'measurementLabel6',
                        'measurementLabel7',
                        'measurementLabel8',
                        'measurementLabel9',
                        'measurementLabel10',
                        'measurementLabel11',
                        'measurementLabel12',
                        'measurementLabel13',
                        'measurementLabel14',
                        'measurementLabel15',
                        'measurementLabel16',
                        'measurementLabel17',
                        'measurementLabel18',
                        'measurementLabel19',
                        'measurementLabel20',
                        'measurementLabelInCm4',
                        'measurementLabelInCm5',
                        'measurementLabelInCm6',
                        'measurementLabelInCm7',
                        'measurementLabelInCm8',
                        'measurementLabelInCm9',
                        'measurementLabelInCm10',
                        'measurementLabelInCm11',
                        'measurementLabelInCm12',
                        'measurementLabelInCm13',
                        'measurementLabelInCm14',
                        'measurementLabelInCm15',
                        'measurementLabelInCm16',
                        'measurementLabelInCm17',
                        'measurementLabelInCm18',
                        'measurementLabelInCm19',
                        'measurementLabelInCm20',
                        'newSuggestTitle',
                        'newAssignee',
                        'showAllTitlesCheckbox',
                        'suggestTitleCheckbox',
                        'addMoreLinesCheckbox',
                        'moreMeasurementCheckbox1',
                        'moreMeasurementCheckbox2',
                        'newEmoji'
                    ];

                    keys?.concat(customKeys, customKeysId)?.forEach((key) => {
                        if (item[key] !== undefined) {

                            const newKeywordsString =
                                key === 'newKeywords' &&
                                (typeof item[key] === 'string'
                                        ? item[key]
                                        : item[key]?.map((itemLocal: any) => itemLocal?.value || itemLocal).join(', ')
                                );

                            const newColorString =
                                key === 'newColor' &&
                                (typeof item[key] === 'string'
                                        ? item[key]
                                        : item[key]?.map((itemLocal: any) => itemLocal?.value || itemLocal).join(', ')
                                );

                            const newImagesArrayChanger = key === 'newImagesArray'
                                ? (item[key] ? item[key]?.split(',')?.map(Number) : [])
                                : null;

                            updates.push({
                                key,
                                index,
                                value:
                                    (item[key] === null || item[key] === undefined || item[key] === '')
                                        ? undefined // Skip or assign undefined for invalid values
                                        :
                                    key === 'newPrice' ||
                                    key === 'newSold_price' ||
                                    key === 'newShippingCost' ||
                                    key === 'newCogs' ||
                                    key === 'newShippingPackageLength' ||
                                    key === 'newShippingPackageWidth' ||
                                    key === 'newShippingPackageHeight' ||
                                    key === 'newMSRP' ||
                                    key === 'newFees' ||
                                    key === 'newLengthMeasure' ||
                                    key === 'newWidthMeasure' ||
                                    key === 'newHeigthMeasure' ||
                                    key === 'measurementLabelInCm4' ||
                                    key === 'measurementLabelInCm5' ||
                                    key === 'measurementLabelInCm6' ||
                                    key === 'measurementLabelInCm7' ||
                                    key === 'measurementLabelInCm8' ||
                                    key === 'measurementLabelInCm9' ||
                                    key === 'measurementLabelInCm10' ||
                                    key === 'measurementLabelInCm11' ||
                                    key === 'measurementLabelInCm12' ||
                                    key === 'measurementLabelInCm13' ||
                                    key === 'measurementLabelInCm14' ||
                                    key === 'measurementLabelInCm15' ||
                                    key === 'measurementLabelInCm16' ||
                                    key === 'measurementLabelInCm17' ||
                                    key === 'measurementLabelInCm18' ||
                                    key === 'measurementLabelInCm19' ||
                                    key === 'measurementLabelInCm20' ||
                                    key === 'newAssignee'
                                        ? +item[key]!
                                        :
                                        key === 'newQuantity'
                                            ?
                                            +item[key]!
                                            :
                                            key === 'newImagesArray'
                                                ? newImagesArrayChanger
                                                :
                                                key === 'newKeywords'
                                                    ? newKeywordsString
                                                    : key === 'newColor' ?
                                                       newColorString
                                                    : key === 'newTitle' || key ===  'newSeasonOrWeather' || key === 'newPatternOrTexture' || key === 'newCare' || key === 'newStyle' || key === 'newBrand' || key === 'newMaterial' || key === 'newMadein' || key === 'newMercaryTitle' || key === 'newPoshmarkTitle' || key === 'newGrailedTitle' || key === 'newAmazonEbayTitle' || key === 'newFacebookTitle' ?
                                                      (item[key] as string)?.replace(/\n+/g, '')
                                                        : item[key]!,

                            });
                        }
                    });
                }
            });

            const groupedUpdates: { [id: number]: { [key: string]: any } } = {};
            function getDecimalDigitFromString(numberString: string) {
                const dotIndex = numberString.indexOf('.');

                if (dotIndex === -1) {
                    return 0;
                }

                const decimalPart = numberString.slice(dotIndex + 1);

                return parseInt(decimalPart, 10).toString();
            };


            for (let update of updates) {
                const { index, key, value } = update;
                // Use index as the identifier
                const id = 0;

                if (!groupedUpdates[id]) {
                    groupedUpdates[id] = {};
                }
                if (finallyMapKeyToId[key]!?.includes('891')) {
                    if (value) {
                        groupedUpdates[id][finallyMapKeyToId[key]!] =
                            getDecimalDigitFromString(finallyMapKeyToId[key]!);
                    } else {
                        groupedUpdates[id][finallyMapKeyToId[key]!] = null;
                    }
                } else {
                    groupedUpdates[id][finallyMapKeyToId[key]!] = value
                }
            }

            const payload = {
                metadata: groupedUpdates[0]
            };
            const response: any = await Listings.add(token, payload);
            if (response) {
                setSavingProgress(false);
                const { id } = response.data;
                setEditId(id);
                setAfterCreate(true);
                if(saveAndCloseLocal){
                    navigate('/listings');
                    setSaveAndClose(false);
                }else if(saveAndPreviewLocal){
                    const titleLink = `/listings/view/${id}`
                    navigate(titleLink);
                    setSaveAndPreview(false);

                }else if(saveAndNewLocal){
                    window.location.reload();
                    setSaveAndNew(false);
                }else{
                    navigate(`/listings/edit/${id}`);
                }
                if(duplicateListingId){
                    dispatch(setDuplicateListingId(null));
                    setDuplicateData(null);
                }
                setItemData([]);
                setDisableSaveButtons(true);
            }
        }
        catch (e) {
            console.log(e, 'error')
        }
        finally{
            setEnabledButtons(true);
            setSavingProgress(false);
            setAddListingSendRequest(false);
        }
    }


    const handleEditListingRequest = async (saveAndPreviewLocal?:any, saveAndNewLocal?:any, saveAndCloseLocal?:any, onlyImages?:any) => {
        try {
            const mapKeyToId: any = {
                newTitle: '133',
                newMercaryTitle: '789',
                newPoshmarkTitle: '786',
                newGrailedTitle: '788',
                newAmazonEbayTitle: '791',
                newFacebookTitle: '869',
                newSku: '1',
                newMSRP: '104',
                newCondition: '15',
                newConditionNotes: '17',
                newSubCondition: '795',
                newPreOwnedCondition: '796',
                newPrice: '22',
                newMaterial: '82',
                newBrand: '206',
                newColor: '209',
                newSize: '213',
                newKeywords: '453',
                newDescription: '505',
                newMeasuerements: '750',
                newLength: '48',
                newLengthMeasure: '380',
                newWidth: '46',
                newWidthMeasure: '386',
                newHeight: '47',
                newHeigthMeasure: '391',
                newShippingWeightlbkg: '2',
                newShippingWeightozg: '3',
                newShippingPackageLength: '531',
                newShippingPackageWidth: '532',
                newShippingPackageHeight: '533',
                newShippingZipOrCityCode: '837',
                newVariations: '857',
                newBullet: '44',
                newMeasure: '54',
                newBoth: '723.1',
                newImagesArray: '728',
                newThumbnail: '727',
                copyDesc: '854',
                lineDetail1: '340',
                lineDetail2: '341',
                lineDetail3: '357',
                lineDetail4: '629',
                lineDetail5: '630',
                lineDetail6: '188',
                lineDetail7: '266',
                lineDetail8: '397',
                lineDetail9: '175',
                lineDetail10: '628',
                lineDetail11: '874',
                lineDetail12: '875',
                lineDetail13: '876',
                lineDetail14: '877',
                lineDetail15: '878',
                newPatternOrTexture: '210',
                newSeasonOrWeather: '36',
                newCare: '43',
                newMadein: '211',
                newBottomOfDescription: '722',
                newUpc: '704',
                newNotes: '726',
                newDescBuilder: '166',
                newIntendedFor: '4',
                newSold_price: '842',
                newCogs: '847',
                newFees: '844',
                newQuantity: '851',
                newSOLD: '539.1',
                newStyle: '205',
                newDateSold_date: '846',
                newEtsy: '539.3',
                newPoshmark: '539.4',
                neweBay: '539.2',
                newMercari: '539.5',
                newTradesy: '539.6',
                newAmazon: '539.7',
                newGrailed: '539.8',
                newFacebook: '539.9',
                newDepop: '539.11',
                newKidizen: '539.12',
                newShopify: '539.13',
                newInstagram: '539.14',
                newHeroine: '539.15',
                newListing_party: '539.16',
                newVestiaire: '539.17',
                newShippingCost: '852',
                newDraft: '859.1',
                newPlatformIdByeBay: '618',
                newPlatformIdByEtsy: '619',
                newPlatformIdByPoshmark: '620',
                newPlatformIdByMercari: '621',
                newPlatformIdByTradesy: '833',
                newPlatformIdByAmazon: '836',
                newPlatformIdByGrailed: '839',
                newPlatformIdByFacebook: '840',
                newPlatformIdByDepop: '841',
                newPlatformIdByKidizen: '853',
                newPlatformIdByShopify: '855',
                newPlatformIdByInstagram: '856',
                newPlatformIdByListingParty: '539.16',
                newPlatformIdByVestiaire: '885',
                measurementLabel4: '378',
                measurementLabel5: '384',
                measurementLabel6: '389',
                measurementLabel7: '70',
                measurementLabel8: '641',
                measurementLabel9: '642',
                measurementLabel10: '643',
                measurementLabel11: '185',
                measurementLabel12: '186',
                measurementLabel13: '187',
                measurementLabel14: '192',
                measurementLabel15: '194',
                measurementLabel16: '331',
                measurementLabel17: '405',
                measurementLabel18: '407',
                measurementLabel19: '411',
                measurementLabel20: '602',
                measurementLabelInCm4: '88',
                measurementLabelInCm5: '89',
                measurementLabelInCm6: '90',
                measurementLabelInCm7: '91',
                measurementLabelInCm8: '617',
                measurementLabelInCm9: '637',
                measurementLabelInCm10: '639',
                measurementLabelInCm11: '28',
                measurementLabelInCm12: '30',
                measurementLabelInCm13: '59',
                measurementLabelInCm14: '84',
                measurementLabelInCm15: '85',
                measurementLabelInCm16: '86',
                measurementLabelInCm17: '87',
                measurementLabelInCm18: '92',
                measurementLabelInCm19: '93',
                measurementLabelInCm20: '94',
                newSuggestTitle: '783',
                newAssignee: '883',
                showAllTitlesCheckbox: '822.1',
                suggestTitleCheckbox: '784.1',
                addMoreLinesCheckbox: '879.1',
                moreMeasurementCheckbox1: '377.1',
                moreMeasurementCheckbox2: '109.1',
                newEmoji: '45'
            };
            const updatedMapKeyToId = customMarketplacesTableRow.reduce(
                (acc: any, item: any) => {
                    if (item.platform && item.platform.title) {
                        const key = `newCustom${item.platform.title}`;
                        acc[key] = item.platform.code[0];
                    }
                    return acc;
                },
                {...mapKeyToId}
            );
            const finallyMapKeyToId: Record<string, string> = customMarketplacesTableRow.reduce(
                (acc: any, item: any, index: number) => {
                    if (item.platform && item.platform.title) {
                        const key = `newPlatformIdByCustom${item.platform.title.replace(
                            /[^a-zA-Z0-9' ]/g,
                            ''
                        )}`;

                        acc[key] =
                            index === 0
                                ? '892'
                                : index === 1
                                    ? '893'
                                    : index === 2
                                        ? '894'
                                        : index === 3
                                            ? '895'
                                            : index === 4
                                                ? '896'
                                                : index === 5
                                                    ? '897'
                                                    : index === 6
                                                        ? '898'
                                                        : index === 7
                                                            ? '899'
                                                            : index === 8
                                                                ? '900'
                                                                : index === 9
                                                                    ? '901'
                                                                    : (index + 891).toString();
                    }
                    return acc;
                },
                {...updatedMapKeyToId}
            );
            const updates: { key: keyof ITableData; index: number; value: any }[] = [];

            const customKeys = customMarketplacesTableRow.map(
                (item: any) => `newCustom${item.platform.title}`
            );
            const customKeysId = customMarketplacesTableRow.map(
                (item: any) => `newPlatformIdByCustom${item.platform.title}`
            );

            itemData?.forEach((item:any, index:any) => {
                if (item) {
                    const keys: (keyof ITableData)[] = [
                        'newTitle',
                        'newMercaryTitle',
                        'newPoshmarkTitle',
                        'newVariations',
                        'newGrailedTitle',
                        'newAmazonEbayTitle',
                        'newFacebookTitle',
                        'newMSRP',
                        'newSku',
                        'newUpc',
                        'newImagesArray',
                        'newThumbnail',
                        'newDescBuilder',
                        'newIntendedFor',
                        'newCondition',
                        'newConditionNotes',
                        'newSubCondition',
                        'newPreOwnedCondition',
                        'newMeasuerements',
                        'newLength',
                        'newLengthMeasure',
                        'newWidth',
                        'newWidthMeasure',
                        'newHeight',
                        'newHeigthMeasure',
                        'newShippingWeightlbkg',
                        'newShippingWeightozg',
                        'newShippingPackageLength',
                        'newShippingPackageWidth',
                        'newShippingPackageHeight',
                        'newShippingZipOrCityCode',
                        'newVariations',
                        'newBullet',
                        'newStyle',
                        'newMeasure',
                        'newBoth',
                        'copyDesc',
                        'lineDetail1',
                        'lineDetail2',
                        'lineDetail3',
                        'lineDetail4',
                        'lineDetail5',
                        'lineDetail6',
                        'lineDetail7',
                        'lineDetail8',
                        'lineDetail9',
                        'lineDetail10',
                        'lineDetail11',
                        'lineDetail12',
                        'lineDetail13',
                        'lineDetail14',
                        'lineDetail15',
                        'newPatternOrTexture',
                        'newSeasonOrWeather',
                        'newCare',
                        'newMadein',
                        'newBottomOfDescription',
                        'newPrice',
                        'newMaterial',
                        'newBrand',
                        'newColor',
                        'newSize',
                        'newKeywords',
                        'newDescription',
                        'newUpc',
                        'newNotes',
                        'newSold_price',
                        'newDateSold_date',
                        'newCogs',
                        'newFees',
                        'newQuantity',
                        'newSOLD',
                        'newShippingCost',
                        'newEtsy',
                        'newPoshmark',
                        'neweBay',
                        'newMercari',
                        'newTradesy',
                        'newAmazon',
                        'newGrailed',
                        'newFacebook',
                        'newDepop',
                        'newKidizen',
                        'newShopify',
                        'newInstagram',
                        'newHeroine',
                        'newListing_party',
                        'newVestiaire',
                        'newDraft',
                        'newPlatformIdByeBay',
                        'newPlatformIdByEtsy',
                        'newPlatformIdByPoshmark',
                        'newPlatformIdByMercari',
                        'newPlatformIdByTradesy',
                        'newPlatformIdByAmazon',
                        'newPlatformIdByGrailed',
                        'newPlatformIdByFacebook',
                        'newPlatformIdByDepop',
                        'newPlatformIdByKidizen',
                        'newPlatformIdByShopify',
                        'newPlatformIdByInstagram',
                        'newPlatformIdByHeroine',
                        'newPlatformIdByListingParty',
                        'newPlatformIdByVestiaire',
                        'measurementLabel4',
                        'measurementLabel5',
                        'measurementLabel6',
                        'measurementLabel7',
                        'measurementLabel8',
                        'measurementLabel9',
                        'measurementLabel10',
                        'measurementLabel11',
                        'measurementLabel12',
                        'measurementLabel13',
                        'measurementLabel14',
                        'measurementLabel15',
                        'measurementLabel16',
                        'measurementLabel17',
                        'measurementLabel18',
                        'measurementLabel19',
                        'measurementLabel20',
                        'measurementLabelInCm4',
                        'measurementLabelInCm5',
                        'measurementLabelInCm6',
                        'measurementLabelInCm7',
                        'measurementLabelInCm8',
                        'measurementLabelInCm9',
                        'measurementLabelInCm10',
                        'measurementLabelInCm11',
                        'measurementLabelInCm12',
                        'measurementLabelInCm13',
                        'measurementLabelInCm14',
                        'measurementLabelInCm15',
                        'measurementLabelInCm16',
                        'measurementLabelInCm17',
                        'measurementLabelInCm18',
                        'measurementLabelInCm19',
                        'measurementLabelInCm20',
                        'newSuggestTitle',
                        'newAssignee',
                        'showAllTitlesCheckbox',
                        'suggestTitleCheckbox',
                        'addMoreLinesCheckbox',
                        'moreMeasurementCheckbox1',
                        'moreMeasurementCheckbox2',
                        'newEmoji'
                    ];

                    keys?.concat(customKeys, customKeysId)?.forEach((key) => {
                        if (item[key] !== undefined) {

                            const newKeywordsString =
                                key === 'newKeywords' &&
                                (typeof item[key] === 'string'
                                        ? item[key]
                                        : item[key]?.map((itemLocal: any) => itemLocal?.value || itemLocal).join(', ')
                                );

                            const newColorString =
                                key === 'newColor' &&
                                (typeof item[key] === 'string'
                                        ? item[key]
                                        : item[key]?.map((itemLocal: any) => itemLocal?.value || itemLocal).join(', ')
                                );

                            const newImagesArrayChanger = key === 'newImagesArray'
                                ? (item[key] ? item[key]?.split(',')?.map(Number) : [])
                                : null;

                            updates.push({
                                key,
                                index,
                                value:
                                    key === 'newPrice' ||
                                    key === 'newSold_price' ||
                                    key === 'newShippingCost' ||
                                    key === 'newCogs' ||
                                    key === 'newShippingPackageLength' ||
                                    key === 'newShippingPackageWidth' ||
                                    key === 'newShippingPackageHeight' ||
                                    key === 'newMSRP' ||
                                    key === 'newFees' ||
                                    key === 'newLengthMeasure' ||
                                    key === 'newWidthMeasure' ||
                                    key === 'newHeigthMeasure' ||
                                    key === 'measurementLabelInCm4' ||
                                    key === 'measurementLabelInCm5' ||
                                    key === 'measurementLabelInCm6' ||
                                    key === 'measurementLabelInCm7' ||
                                    key === 'measurementLabelInCm8' ||
                                    key === 'measurementLabelInCm9' ||
                                    key === 'measurementLabelInCm10' ||
                                    key === 'measurementLabelInCm11' ||
                                    key === 'measurementLabelInCm12' ||
                                    key === 'measurementLabelInCm13' ||
                                    key === 'measurementLabelInCm14' ||
                                    key === 'measurementLabelInCm15' ||
                                    key === 'measurementLabelInCm16' ||
                                    key === 'measurementLabelInCm17' ||
                                    key === 'measurementLabelInCm18' ||
                                    key === 'measurementLabelInCm19' ||
                                    key === 'measurementLabelInCm20' ||
                                    key === 'newAssignee'
                                        ? +item[key]!
                                        :
                                        key === 'newQuantity'
                                            ?
                                            +item[key]!
                                            :
                                            key === 'newImagesArray'
                                                ? newImagesArrayChanger :
                                                key === 'newTitle' || key === 'newFacebookTitle' || key === 'newPoshmarkTitle' || key === 'newMercaryTitle' || key === 'newGrailedTitle' || key === 'newAmazonEbayTitle' ?
                                                    item[key] ? (item[key] as string)?.replace(/\n+/g, '') : null
                                                    :
                                                key === 'newKeywords'
                                                    ? newKeywordsString
                                                    : key === 'newColor' ?
                                                        newColorString
                                                        :  key ===  'newSeasonOrWeather' || key === 'newPatternOrTexture' || key === 'newCare' || key === 'newStyle' || key === 'newBrand' || key === 'newMaterial' || key === 'newMadein'  ?
                                                            (item[key] as string)?.replace(/\n+/g, '')
                                                            : item[key]!,

                            });
                        }
                    });
                }
            });


            const groupedUpdates: { [id: number]: { [key: string]: any } } = {};
            function getDecimalDigitFromString(numberString: string) {
                const dotIndex = numberString.indexOf('.');

                if (dotIndex === -1) {
                    return 0;
                }

                const decimalPart = numberString.slice(dotIndex + 1);

                return parseInt(decimalPart, 10).toString();
            };


            for (let update of updates) {
                const { index, key, value } = update;
                // Use index as the identifier
                const id = editId;

                if (!groupedUpdates[id]) {
                    groupedUpdates[id] = {};
                }
                if (finallyMapKeyToId[key]!?.includes('891')) {
                    if (value) {
                        groupedUpdates[id][finallyMapKeyToId[key]!] =
                            getDecimalDigitFromString(finallyMapKeyToId[key]!);
                    } else {
                        groupedUpdates[id][finallyMapKeyToId[key]!] = null;
                    }
                } else {
                    groupedUpdates[id][finallyMapKeyToId[key]!] = value
                }
            }


            if(groupedUpdates[editId] === undefined) return;

            let imagesPayload;

            if(onlyImages){
                imagesPayload = {
                    727: groupedUpdates[editId]?.[727],
                    728: groupedUpdates[editId]?.[728]
                };
            }

            const requestData = {
                filter_by: {id: +editId},
                payload: imagesPayload ? imagesPayload : groupedUpdates[editId],
            };
            const response = await instanceAxios.put(
                '/listings/metadata/update',
                requestData
            );
            if (response) {
                // setSavingProgress(false);
                if (response?.data?.data?.metadata && Object.hasOwn(response?.data?.data?.metadata, '854')) {
                    setFormInfo({
                        ...formInfo,
                        ['copyDesc']: { text: response?.data?.data?.metadata['854'], changed: true },
                    });
                }
               setTimeout(()=>{
                   if(saveAndCloseLocal){
                       if(showEditAddModal){
                           dispatch(setEditAddModalShow(false));
                           dispatch(setEditAddModalId(null));
                       }else{
                           navigate('/listings')
                       }
                       setSaveAndClose(false);
                   }
                   else if(saveAndPreviewLocal){
                       if(showEditAddModal){
                           const location = window.location;
                           const searchParams = new URLSearchParams(location.search);
                           searchParams.set('modal', 'listing-view');
                           searchParams.set('modal-listing-id', `${editId}`);
                           navigate(`?${searchParams.toString()}`);
                           dispatch(setShowListingViewModal(true));
                           dispatch(setListingViewId(editId));
                           dispatch(setEditAddModalShow(false));
                           dispatch(setEditAddModalId(null));
                       }else{
                           const titleLink = `/listings/view/${editId}`
                           navigate(titleLink);
                       }
                       setSaveAndPreview(false);
                       dispatch(setEditAddModalShow(false));
                       dispatch(setEditAddModalId(null));
                   }
                   else if(saveAndNewLocal){
                       if(afterCreate){
                          window.location.reload();
                       }else{
                           navigate('/listings/add');
                       }
                       setSaveAndNew(false);
                       dispatch(setEditAddModalShow(false));
                       dispatch(setEditAddModalId(null));
                   }
               }, 4000);

               if(response?.data?.data){
                   setProductInfoJSON(JSON.stringify(response?.data?.data));
                   const {metadata, id} = response.data.data;
                   if(setTableData){
                       const replacedString = imagesFromUploader[0]?.replace('_CI', '_TN');

                       setTableData((prevData:any) => {
                           const updatedItems = [...prevData]; // Create a shallow copy of tableData
                           const updatedItem = response.data.data; // Assume this is the new item data

                           const itemIndex = updatedItems.findIndex((item) => item.id === updatedItem.id); // Find the item by id

                           if (itemIndex !== -1) {
                               // Update the specific item using its index
                               updatedItems[itemIndex] = {
                                   ...updatedItems[itemIndex],      // Copy existing properties
                                   ...groupedUpdates[id],           // Apply dynamic field updates
                                   metadata: updatedItem.metadata,  // Update the metadata field with new value
                                   newImage: replacedString ? replacedString : null,
                               };
                           }

                           return updatedItems; // Return the updated array to trigger a state update
                       });
                   }
               }
                if(onlyImages){
                    if (
                        itemData.every((item: any) => {
                            const keys = Object.keys(item); // Get all keys of the item
                            // Check if all keys are limited to 'newThumbnail', 'newImagesArray', and 'changed'
                            return keys.every((key) => ['newThumbnail', 'newImagesArray', 'changed'].includes(key));
                        })
                    ) {
                        // If no other properties exist, clear itemData
                        setItemData([]);
                    } else {
                        // Otherwise, remove specified properties and update itemData
                        setItemData(
                            itemData.map((item: any) => {
                                const updatedItem = { ...item }; // Create a shallow copy of the item
                                delete updatedItem.newThumbnail; // Remove newThumbnail property
                                delete updatedItem.newImagesArray; // Remove newImagesArray property
                                return updatedItem;
                            })
                        );
                    }

                }else{
                    setItemData([]);
                }
                setDisableSaveButtons(true);
            }
        }
        catch (e) {
            console.log(e, 'error')
        }
        finally{
            setTimeout(()=> {
                setEnabledButtons(true);
                setSavingProgress(false);
            }, 4000);
            setEditListingSendRequest(false);
        }

    }

    useEffect(() => {
        if(addListingSendRequest) {
            setEnabledButtons(false);
            handleAddListingRequest(saveAndPreview, saveAndNew, saveAndClose);
        }
    }, [addListingSendRequest]);

    useEffect(() => {
        if(editListingSendRequest) {
            setEnabledButtons(false);
            handleEditListingRequest(saveAndPreview, saveAndNew, saveAndClose);
        }
    }, [editListingSendRequest]);


    const handleStandartInputChange = (e: any) => {
        const {name, value} = e.target;
        if(name === 'input_854'){
            return
        }
        const updatedItem = [...itemData];
        const generatedName = getNameGenerator(name);
        updatedItem[editIndex] = {
            ...updatedItem[editIndex],
            [generatedName]: value,
            changed: true,
        };
        setItemData(updatedItem)
        setFormInfo({
            ...formInfo,
            [generatedName]: { text: value, changed: true },
        });
    }

    const getTemplatesList = async (editIdLocal:any) => {
        let allTemplates:any = [];
        let offset = 0;
        let hasMore = true;

        try {
            while (hasMore) {
                const response = await instanceAxios.post('/templates/list', {
                    metadata: { offset: offset, limit: 100 },
                    filter_by: {122.1:'1'},
                    sort_by: {133:'asc'},
                    options: { include_assignee: true },
                });

                if (response?.data && response?.data?.data?.length > 0) {
                    allTemplates = [...allTemplates, ...response.data.data];
                    offset += 100;
                    if (response.data.data.length < 100) {
                        hasMore = false;
                    }
                    const {data} = response.data;
                    const defaultTemplate = data.find((item:any) => item?.metadata?.default_template === true);
                    const searchParams = new URLSearchParams(window.location.search);
                    const isExtensionSource = searchParams.get('source') === 'extension';

                    if (defaultTemplate && !isExtensionSource && !editIdLocal && !dataFromExtension && !duplicateListingId) {
                        setDefaultTemplateMetadata(defaultTemplate?.metadata);

                        const value = defaultTemplate?.metadata["133"];
                        const newSelected = {
                            value: bdecode(value),
                            label: bdecode(value),
                            id: defaultTemplate.id,
                        }
                        setSelectedOptionTemplate(newSelected);

                        toast.success(`Default template applied`, {
                            autoClose: 2500,
                            position: 'bottom-right',
                        });
                    }

                } else {
                    hasMore = false;
                }
            }
            setTemplates(allTemplates);
        } catch (error) {
            console.log(error, 'error')
        }
    };

    const moveLongTagsToLast = (keywordsString: string) => {
        try {
            const resultArray = keywordsString?.split(', ').map((item) => item.trim());
            // Move items with more than 20 characters to the end
            const movedItems = resultArray.filter((item) => item.length > 20);
            const remainingItems = resultArray.filter((item) => item.length <= 20);
            const finalResultArray = [...remainingItems, ...movedItems];

            // Convert the array to a string
            const finalResultString = finalResultArray.join(', ');
            return finalResultString;
        }
        catch (e) {
            console.log(e)
        }
    };

    const handleTagifiesChange = (
        e:any,
        tagifyVal: string,
    ) => {
        try{
            const newValue = e.detail.value;
            let parsedValue :any;
            let emptyValue:any;
            if(tagifyVal === 'input_209') {
                if(newValue){
                    const data = JSON.parse(newValue);
                    parsedValue = data.map((item : any) => item.value).join(', ');
                }
                else {
                    emptyValue = ''
                }
            }else{
                if(newValue) {
                    parsedValue = JSON.parse(newValue);
                }
                else {
                    emptyValue = []
                }
            }
            const generatedName = getNameGenerator(tagifyVal);
            setItemData((prevItemData: any) => {
                const updatedItems = [...prevItemData];
                updatedItems[editIndex] = {
                    ...updatedItems[editIndex],
                    [generatedName]: parsedValue ? parsedValue : emptyValue,
                    changed: true,
                };
                return updatedItems;
            });
            setFormInfo((prevTableInfo: any) => {
                const newTableInfoUpdates = { ...prevTableInfo };
                newTableInfoUpdates[generatedName] = {
                    text: newValue,
                    changed: true,
                };
                return newTableInfoUpdates;
            });
        }
        catch (e:any){
            console.log(e, 'error')
        }
    };

    const unescapedRowMetadata = useMemo(() => {
        const result: any = {};
        for (const key in listInfo) {
            if (listInfo.hasOwnProperty(key)) {
                result[key] = _.get(listInfo, key);
            }
        }
        return result;
    }, [listInfo]);


    useEffect(() => {
        setPreventFirstRender(true);
        if(preventFirstRender) {
            if(!isImagesUploaded) {
                setEnabledButtons(false);
            }
        }
    },[updateButtonsDisabled])

    const sendRequestForSuggestDescription = async (name: string) => {
        // Increment the count by 1
        setLoaderForSuggest(true);
        setEnabledButtons(false);
        try {
            if (!loaderForSuggest) {
                if(name === 'description') {
                    const dataObject: any = {};
                    if (
                        formInfo?.newMaterial?.changed &&
                        formInfo?.newMaterial?.text?.length
                    ) {
                        dataObject[82] = formInfo?.newMaterial?.text;
                    } else if (unescapedRowMetadata[82]) {
                        dataObject[82] = unescapedRowMetadata[82];
                    }
                    if (
                        formInfo?.newTitle?.changed &&
                        formInfo?.newTitle?.text.length
                    ) {
                        dataObject[133] = formInfo?.newTitle?.text;
                    } else if (unescapedRowMetadata[133]) {
                        dataObject[133] = unescapedRowMetadata[133];
                    }
                    if (
                        formInfo?.newTitle?.changed &&
                        formInfo?.newTitle?.text.length
                    ) {
                        dataObject[133] = formInfo?.newTitle?.text;
                    } else if (unescapedRowMetadata[133]) {
                        dataObject[133] = unescapedRowMetadata[133];
                    }
                    if (
                        formInfo?.newStyle?.changed &&
                        formInfo?.newStyle?.text.length
                    ) {
                        dataObject[205] = formInfo.newStyle.text;
                    } else if (unescapedRowMetadata[205]) {
                        dataObject[205] = unescapedRowMetadata[205];
                    }
                    if (
                        formInfo?.newBrand?.changed &&
                        formInfo?.newBrand?.text.length
                    ) {
                        dataObject[206] = formInfo?.newBrand?.text;
                    } else if (unescapedRowMetadata[206]) {
                        dataObject[206] = unescapedRowMetadata[206];
                    }
                    if (
                        formInfo?.newColor?.changed &&
                        formInfo?.newColor?.text.length
                    ) {
                        dataObject[209] = formInfo?.newColor?.text;
                    } else if (unescapedRowMetadata[209]) {
                        dataObject[209] = unescapedRowMetadata[209];
                    }
                    if (formInfo?.newSize?.changed && formInfo?.newSize?.text.length) {
                        dataObject[213] = formInfo.newSize.text;
                    } else if (unescapedRowMetadata[213]) {
                        dataObject[213] = unescapedRowMetadata[213];
                    }
                    if (
                        formInfo?.newTitle?.changed &&
                        formInfo?.newTitle?.text.length > 40
                    ) {
                        const response = await instanceAxios.post(
                            'listings/tools/generator/add',
                            {
                                data: { ...dataObject },
                                options: { version: 1 },
                            }
                        );

                        // Check if the request was successful (you can adjust this check as needed)
                        if (response.data) {
                            if (_.has(response?.data?.data, 505)) {
                                setCommonState((prevState:any) => ({
                                    ...prevState,
                                    description: response.data.data[505],
                                }));
                            }
                            if (_.has(response?.data?.data, 453)) {
                                const keywordsString = response.data.data[453];
                                const modifiedString: any = moveLongTagsToLast(keywordsString);
                                setCommonState((prevState:any) => ({
                                    ...prevState,
                                    keywords: modifiedString,
                                }));
                            }
                            setGeneratedModal(true);
                            setShowListingAssistant(false);
                            setLoaderForSuggest(false);
                        }
                    } else if (
                        !formInfo.newTitle.changed &&
                        unescapedRowMetadata[133]?.length > 40
                    ) {
                        const response = await instanceAxios.post(
                            'listings/tools/generator/add',
                            {
                                data: { ...dataObject },
                                options: { version: 1 },
                            }
                        );
                        if (response?.data) {
                            if (_.has(response?.data?.data, 505)) {
                                setCommonState((prevState:any) => ({
                                    ...prevState,
                                    description: response.data.data[505],
                                }));
                            }
                            if (_.has(response?.data?.data, 453)) {
                                const keywordsString = response.data.data[453];
                                const modifiedString: any = moveLongTagsToLast(keywordsString);
                                setCommonState((prevState:any) => ({
                                    ...prevState,
                                    keywords: modifiedString,
                                }));
                            }
                            setGeneratedModal(true);
                            setShowListingAssistant(false);
                            setLoaderForSuggest(false);
                        }
                    } else {
                        toast.error(
                            'Min chars for title field "Suggest description" button to become active is 40 characters.',
                            {
                                position: 'bottom-right', // You can customize the notification position
                            }
                        );
                        setTimeout(() => {
                            setLoaderForSuggest(false);
                        }, 1000);
                    }
                }
                else if(name === 'assistance') {
                    const dataObject: any = {};
                    if (
                        assistantTitle
                    ) {
                        dataObject[133] =  assistantTitle;
                    }

                    if (
                        assistantTitle?.length > 40
                    ) {
                        const options = {
                            version: 2,
                        }

                        const response : any = await Listings.tools_generator_add(token, {...dataObject}, options);
                        // Check if the request was successful (you can adjust this check as needed)
                        if (response.data) {
                            const fieldMapping:any = {
                                505: 'description',
                                82: !hasSimple ? 'material' : null,
                                213: !hasSimple ? 'size' : null,
                                206: !hasSimple ? 'brand' : null,
                                4: !hasSimple ? 'gender' : null,
                            };
                            // Filter out null values from the fieldMapping
                            const filteredFieldMapping:any = Object.fromEntries(
                                Object.entries(fieldMapping).filter(([key, value]) => value !== null)
                            );
                            Object.entries(filteredFieldMapping).forEach(([key, field]:any) => {
                                if (response?.data && _.has(response.data, key)) {
                                    setCommonState((prevState: any) => ({
                                        ...prevState,
                                        [field]: response.data[key],
                                    }));
                                }
                            });
                            if (_.has(response?.data, 453) && !hasSimple) {
                                const keywordsString = response.data[453];
                                const modifiedString = moveLongTagsToLast(keywordsString);
                                setCommonState((prevState: any) => ({
                                    ...prevState,
                                    keywords: modifiedString,
                                }));
                            }
                            // if (_.has(response?.data?.data, 505)) {
                            //     setCommonState((prevState:any) => ({
                            //         ...prevState,
                            //         description: response.data.data[505],
                            //     }));
                            // }
                            // if (_.has(response?.data?.data, 453) && !hasSimple) {
                            //     const keywordsString = response.data.data[453];
                            //     const modifiedString: any = moveLongTagsToLast(keywordsString);
                            //     setCommonState((prevState:any) => ({
                            //         ...prevState,
                            //         keywords: modifiedString,
                            //     }));
                            // }
                            setGeneratedModal(true);
                            setShowListingAssistant(false);
                            setLoaderForSuggest(false);
                        }
                    }  else {
                        toast.error(
                            'Min chars for title field "Suggest description" button to become active is 40 characters.',
                            {
                                position: 'top-right', // You can customize the notification position
                            }
                        );
                        setTimeout(() => {
                            setLoaderForSuggest(false);
                        }, 1000);
                    }
                }
            }
        } catch (error) {
            setLoaderForSuggest(false);
            console.error('Error occurred:', error);
        }
        finally{
            setEnabledButtons(true);
        }
    };

    const base64ToFile = (base64String:any, filename:any) => {
        // Split the base64 string into content type and base64 data
        const arr = base64String?.split(',');
        const mime = arr[0]?.match(/:(.*?);/)[1]; // Get the MIME type from the data
        const bstr = atob(arr[1]); // Decode base64 string
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        // Convert base64 string to binary data
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        // Create and return a File object
        return new File([u8arr], filename, { type: mime });
    };


    const handleGetBase64Images = async (images:any) => {
        try {
            const promises = images.map((image:any) => {
                return instanceAxios.post('/image-handler/download-files-by-urls', {
                    urls: [image], // Send the image link as part of the request
                });
            });

            const responses = await Promise.all(promises); // Await all requests

            // Extract base64 strings from the responses
            const base64Images = responses.map(response => response?.data[0]?.base64);
            const imageNames = responses.map(response => response?.data[0]?.name);

            // Convert each base64 image to a File object
            const files = base64Images.map((base64, index) => {
                const name = imageNames[index] || `image-${index + 1}.png`; // Use the name or fallback to default name
                return base64ToFile(base64, name);
            });
            setFilesList(files)

        } catch (e) {
            console.log(e, 'error');
        }
    };

    function limitToMaxCharacters(input: string, maxChars: number): any {
        try{
            let charCount = 0;
            let realIndex = 0;

            for (let i = 0; i < input.length; i++) {
                const char = input[i];

                // Check if adding this character exceeds the limit
                if (char === '\n') {
                    if (charCount + 2 > maxChars) break; // Exceeds limit, stop
                    charCount += 2;
                } else {
                    if (charCount + 1 > maxChars) break; // Exceeds limit, stop
                    charCount += 1;
                }

                // Only increase the realIndex if we are still within the character limit
                realIndex++;
            }

            // Return the substring up to the valid character index
            return input.substring(0, realIndex);
        }catch(e){
            console.log(e, 'error')
        }
    }

    const suggestOnchange = ( e:
                                  | React.ChangeEvent<HTMLInputElement>
                                  | React.ChangeEvent<HTMLTextAreaElement>
                                  | React.ChangeEvent<HTMLSelectElement>,
                              shortDescription?: string,
                              keywords?: any,
                              listingTitle?: string,
                              brand?: string,
                              size?: string,
                              color?: string,
                              titleText?: string,
                              material?: any,
                              upc?: any,
                              condition?: any,
                              style?: any,
                              madeIn?: any,
                              shippingWeightlbkg?: any,
                              shippingWeightOzg?: any,
                              subCondition?: any,
                              preOwnedCondition?: any,
                              conditionNotes?: any,
                              patternOrTexture?: any,
                              care?: any,
                              seasonOrWeather?: any,
                              gender?:any,
                              images?:any,
                              price?:any
    ) => {
        const {name, value} = e.target;
       try{
           if (name === 'accept') {
               let updatedItems: any = [...itemData];
               const changes: any = {};

               const activateConditionItem = (items: any[], conditionValue: string, setItems: Function) => {
                   const indexToActivate = items?.findIndex((item) => item.value === conditionValue);
                   if (indexToActivate !== -1) {
                       const updatedItems = items.map((item, index) => ({
                           ...item,
                           active: index === indexToActivate, // Set the matched item to active
                       }));
                       setItems(updatedItems);
                   }
               };

               const fields = {
                   newDescription: shortDescription,
                   newKeywords: keywords?.length > 1 ? keywords : null,
                   newTitle: listingTitle || titleText,
                   newBrand: brand,
                   newSize: size,
                   newColor: color,
                   newMaterial: material,
                   newUpc: upc,
                   newCondition: condition,
                   newStyle: style,
                   newMadein: madeIn,
                   newShippingWeightlbkg: shippingWeightlbkg,
                   newShippingWeightozg: shippingWeightOzg,
                   newSubCondition: subCondition,
                   newPreOwnedCondition: preOwnedCondition,
                   newConditionNotes: conditionNotes,
                   newPatternOrTexture: patternOrTexture,
                   newCare: care,
                   newSeasonOrWeather: seasonOrWeather,
                   newIntendedFor: gender,
                   newPrice: price
               };

               Object.entries(fields).forEach(([key, value]) => {
                   if (value) changes[key] = value;
               });

               if(images){
                   handleGetBase64Images(images);
               }

               if (condition) {
                   changes['newCondition'] = condition;

                   if (condition === 'New') {
                       setActiveCondition(1);
                   } else if (condition === 'Pre-Owned') {
                       setActiveCondition(2);
                   } else {
                       setActiveCondition(0);
                   }

                   activateConditionItem(conditionItems, condition, setConditionItems);
               }

               if (subCondition) {
                   changes['newSubCondition'] = subCondition;
                   activateConditionItem(newConditionItems, subCondition, setNewConditionItems);
               }

               if (preOwnedCondition) {
                   changes['newPreOwnedCondition'] = preOwnedCondition;
                   activateConditionItem(preOwnedItems, preOwnedCondition, setPreOwnedItems);
               }

               if(gender){
                   const activeItemIntendedFor = gender;
                   const indexToActivateIntendedFor = IntendedForItems?.findIndex(
                       (item:any) => item?.value.toLowerCase().includes(activeItemIntendedFor.toLowerCase())
                   );
                   if (indexToActivateIntendedFor !== -1) {
                       const updatedItems = IntendedForItems.map((item, index) => ({
                           ...item,
                           active: index === indexToActivateIntendedFor,
                       }));
                       setIntendedItems(updatedItems);
                       changes['newIntendedFor'] = updatedItems[indexToActivateIntendedFor]?.value ;
                   }else{
                       const updatedItems = [...IntendedForItems];
                       updatedItems[11] = {
                           ...updatedItems[11],
                           active: true,
                       };
                       setIntendedItems(updatedItems);
                       changes['newIntendedFor'] = null;
                   }

               }

               // Update items and set changes
               updatedItems[editIndex] = {
                   ...updatedItems[editIndex],
                   ...changes,
                   changed: true,
               };

               setItemData(updatedItems);
               setFormInfo((prevTableInfo: any) => {
                   const fields = {
                       newDescription: shortDescription,
                       newKeywords: keywords?.length > 1 ? keywords : null,
                       newTitle: listingTitle || titleText,
                       newBrand: brand,
                       newSize: size,
                       newColor: color,
                       newMaterial: material,
                       newUpc: upc,
                       newCondition: condition,
                       newStyle: style,
                       newMadein: madeIn,
                       newShippingWeightlbkg: shippingWeightlbkg,
                       newShippingWeightozg: shippingWeightOzg,
                       newSubCondition: subCondition,
                       newPreOwnedCondition: preOwnedCondition,
                       newConditionNotes: conditionNotes,
                       newPatternOrTexture: patternOrTexture,
                       newCare: care,
                       newSeasonOrWeather: seasonOrWeather,
                       newIntendedFor: gender,
                       newPrice: price,
                   };

                   const newTableInfoUpdates = { ...prevTableInfo };

                   // Add basic fields logic
                   Object.entries(fields).forEach(([key, value]) => {
                       if (value) {
                           newTableInfoUpdates[key] = {
                               text: value,
                               changed: true,
                           };
                       }
                   });

                   // Add additional title-specific logic
                   const addTitleLogic = (key: string, maxLength: number, rowKey: number) => {
                       if (
                           !prevTableInfo[key]?.changed &&
                           (!unescapedRowMetadata[rowKey] ||
                               prevTableInfo[key]?.text === unescapedRowMetadata[133] ||
                               prevTableInfo[key]?.text === prevTableInfo.newTitle?.text?.substring(0, maxLength))
                       ) {
                           newTableInfoUpdates[key] = {
                               text: limitToMaxCharacters(
                                   listingTitle || "",
                                   maxLength
                               ),
                               changed: true,
                           };
                       }
                   };

               if(listingTitle){
                   addTitleLogic("newMercaryTitle", 80, 789);
                   addTitleLogic("newPoshmarkTitle", 80, 786);
                   addTitleLogic("newGrailedTitle", 60, 788);
                   addTitleLogic("newAmazonEbayTitle", 80, 791);
                   addTitleLogic("newFacebookTitle", 99, 869);
               }

                   return newTableInfoUpdates;
               });
           }
       }
       catch(e){
          console.log(e, 'error')
       }
    }


    useEffect(() => {
        if(editId){
            changeListingAssistance(false, 'hide')
        }
    }, [editId]);


    const changeListingAssistance = (value: boolean, text?: any) => {
        setShowListingAssistant(value);
        if (text) {
            if(text === 'show'){
                if (localStorage.getItem('showAssistant') !== null) {
                    localStorage.removeItem('showAssistant');
                }
            }else if(text === 'hide'){
                localStorage.setItem('showAssistant', JSON.stringify(true));
            }
        }
    };

    const sendRequestForScan = async (barcodeValue: string) => {
        setScanLoader(true);
        try {
            const response = await instanceAxios.post(
                'listings/tools/barcode-scanner/add',
                {
                    data: { barcode: barcodeValue, catalog_id: null },
                    options: {},
                }
            );
            const { data } = response.data;

            if (data) {
                setBarCodeLimits(prevState => ({
                    ...prevState,  // Copy the previous state
                    available: prevState.available - 1,  // Update total to be one less
                }));
                const neededNames = ['description', 'title', 'images', 'stores'];

                if (!hasSimple) {
                    neededNames.push('brand', 'size', 'color', 'gender', 'barcode_number');
                }

                neededNames.forEach((name: any) => {
                    if (data[0][name]) {
                        setCommonState((prevState) => ({
                            ...prevState,
                            [name]: data[0][name],
                        }));
                    }
                });
                setGeneratedModal(true);
                setShowListingAssistant(false);
                setScanLoader(false);
            }
        } catch (e) {
            setFailedBarcode(barcodeValue)
            setScanLoader(false);
            setScanningFailed(true);
        }
    };

    function parsePhpSerializedString(serializedString:any) {
        // Match the entire array structure
        const outerArrayRegex = /a:\d+:{((?:i:\d+;a:\d+:{(?:s:\d+:"(.*?)";s:\d+:"(.*?)";)*}?)*)}/;
        const outerMatch = outerArrayRegex.exec(serializedString);
        if (!outerMatch) {
            throw new Error("Invalid serialized string");
        }

        const outerContent = outerMatch[1];

        // Regex to match each object in the array
        const objectRegex = /i:\d+;a:\d+:{((?:s:\d+:"(.*?)";s:\d+:"(.*?)";)*)}/g;
        const keyValuePairRegex = /s:\d+:"(.*?)";s:\d+:"(.*?)";/g;

        let objectMatch;
        const resultArray = [];

        // Iterate over each object match in the string
        while ((objectMatch = objectRegex.exec(outerContent)) !== null) {
            const objectContent = objectMatch[1];
            const resultObject:any = {};
            let keyValueMatch;

            // Extract key-value pairs for each object
            while ((keyValueMatch = keyValuePairRegex.exec(objectContent)) !== null) {
                const key = keyValueMatch[1];
                const value = keyValueMatch[2];
                resultObject[key] = value;
            }

            resultArray.push(resultObject);
        }

        return resultArray;
    }


    useEffect(() => {
        try{
            const activeItemIntendedFor = unescapedRowMetadata[4];
            const indexToActivateIntendedFor = IntendedForItems?.findIndex(
                (item) => item.value === activeItemIntendedFor
            );
            if (indexToActivateIntendedFor !== -1) {
                const updatedItems = [...IntendedForItems];
                updatedItems[indexToActivateIntendedFor] = {
                    ...updatedItems[indexToActivateIntendedFor],
                    active: true,
                };
                setIntendedItems(updatedItems);
            }

            const activeBoth = unescapedRowMetadata[723.1];
            if(activeBoth){
                const indexToActivateActiveBoth = measureBothItems?.findIndex(
                    (item) => item.value === activeBoth
                );
                if (indexToActivateActiveBoth !== -1) {
                    const updatedItems = [...measureBothItems];
                    updatedItems[indexToActivateActiveBoth] = {
                        ...updatedItems[indexToActivateActiveBoth],
                        active: true,
                    };
                    setMeasureBothItems(updatedItems);
                }
            }

            const activeBullets = unescapedRowMetadata[44];
            if(activeBullets){
                const indexToActivateActiveBullets = bulletItems?.findIndex(
                    (item) => item.value === activeBullets
                );
                if (indexToActivateActiveBullets !== -1) {
                    const updatedItems = bulletItems.map((item, index) => ({
                        ...item,
                        active: index === indexToActivateActiveBullets, // Only set the matched item to active: true
                    }));
                    setBulletItems(updatedItems);
                }
            }

            const activeMeasure = unescapedRowMetadata[54];
            if(activeMeasure){
                const indexToActivateActiveMeasure = measureItems?.findIndex(
                    (item) => item.value === activeMeasure
                );
                if (indexToActivateActiveMeasure !== -1) {
                    const updatedItems = measureItems.map((item, index) => ({
                        ...item,
                        active: index === indexToActivateActiveMeasure, // Only set the matched item to active: true
                    }));
                    setMeasureItems(updatedItems);
                }
            }

            const variations = unescapedRowMetadata[857];

            if(variations){
                const parsedVariations:any = Array.isArray(variations) ? variations : parsePhpSerializedString(variations);
                setVariations(parsedVariations);
            }else {
                setVariations([
                    {
                        Size: '',
                        Color: '',
                        Custom: '',
                        Quantity: '',
                        SKU: '',
                    },
                ]);
            }

            const activeItemForCondition = unescapedRowMetadata[15];
            const indexToActivateCondition = conditionItems?.findIndex(
                (item) => item.value === activeItemForCondition
            );
            if (activeItemForCondition) {
                if (activeItemForCondition === 'New') {
                    setActiveCondition(1);
                } else if (activeItemForCondition === 'Pre-Owned') {
                    setActiveCondition(2);
                } else {
                    setActiveCondition(0);
                }
            }
            if (indexToActivateCondition !== -1) {
                const updatedItems = [...conditionItems];
                updatedItems[indexToActivateCondition] = {
                    ...updatedItems[indexToActivateCondition],
                    active: true,
                };
                setConditionItems(updatedItems);
            }
            const activeItemForNewCondition = unescapedRowMetadata[795];
            const indexToActivateNewCondition = newConditionItems?.findIndex(
                (item) => item.value === activeItemForNewCondition
            );

            if (indexToActivateNewCondition !== -1) {
                const updatedItems = [...newConditionItems];
                updatedItems[indexToActivateNewCondition] = {
                    ...updatedItems[indexToActivateNewCondition],
                    active: true,
                };
                setNewConditionItems(updatedItems);
            }
            const activeItemForPreOwnedCondition = unescapedRowMetadata[796];

            const indexToActivatePreOwnedCondition = preOwnedItems?.findIndex(
                (item) => item.value === activeItemForPreOwnedCondition
            );

            if (indexToActivatePreOwnedCondition !== -1) {
                const updatedItems = [...preOwnedItems];
                updatedItems[indexToActivatePreOwnedCondition] = {
                    ...updatedItems[indexToActivatePreOwnedCondition],
                    active: true,
                };
                setPreOwnedItems(updatedItems);
            }
            const activeItemForBuilder = unescapedRowMetadata[166];
            if(activeItemForBuilder){
                const indexToActivateBuilder = descriptionBuildItems?.findIndex(
                    (item:any) => item.value === activeItemForBuilder
                );
                if (indexToActivateBuilder !== -1) {
                    const updatedItems = descriptionBuildItems.map((item, index) => ({
                        ...item,
                        active: index === indexToActivateBuilder, // Only set the matched item to active: true
                    }));
                    setDescriptionBuildItems(updatedItems);
                }
            }
            if(unescapedRowMetadata['122.1']){
                setIsShowInDropDown(true)
            }else{
                setIsShowInDropDown(false)
            }
            if (
                unescapedRowMetadata[859.1]?.includes('Draft') &&
                formInfo.newDraft.changed !== true
            ) {
                setIsCheckBoxChecked(true);
            }
            if(unescapedRowMetadata['883']){
                setAssigneeId(`${unescapedRowMetadata['883']}`)
            }

            if (unescapedRowMetadata['879.1']) {
                setAddMoreInputs(true);
            }

            if (unescapedRowMetadata['109.1']) {
                setMoreMeasurementsPart2(true);
            }

            if (unescapedRowMetadata['377.1']) {
                setMoreMeasurementsPart1(true);
            }

            if(unescapedRowMetadata['822.1']){
                setShowAllTitles(true);
            }
        }catch(e){
            console.log('error', e)
        }
    }, [listInfo]);


    useEffect(() => {
        const storedValue = localStorage.getItem('showAssistant');
        if (!storedValue) {
            setShowListingAssistant(true);
            setHideAssistantModal(false);
            setAssistantTitleVision('assistant')
        }else{
            setHideAssistantModal(true)
        }
    }, []);

    useEffect(() => {
        try{
            if (activeTemplateid || defaultTemplateMetdata || duplicateData || dataFromExtension) {
                const template:any = templates.find((template:any) => Number(template?.id) === Number(activeTemplateid));
                if (template || defaultTemplateMetdata || duplicateData || dataFromExtension) {
                    let metadata =  template?.metadata || defaultTemplateMetdata || duplicateData || dataFromExtension;
                    if(template){
                        setDefaultTemplateMetadata(null);
                    }
                    const updatedFormInfo = { ...formInfo };
                    const updatedItem = [...itemData];
                    for (let key in metadata) {
                        if (metadata?.hasOwnProperty(key) &&
                            key !== '842' &&
                            key !== '844' &&
                            key !== '852' &&
                            key !== '846' &&
                            key !== '539.1' &&
                            key !== '539.2' &&
                            key !== '539.3' &&
                            key !== '539.5' &&
                            key !== '539.6' &&
                            key !== '539.7' &&
                            key !== '539.8' &&
                            key !== '539.9' &&
                            key !== '539.11' &&
                            key !== '539.12' &&
                            key !== '539.13' &&
                            key !== '539.14' &&
                            key !== '539.15' &&
                            key !== '539.16' &&
                            key !== '539.17' &&
                            key !== '618' &&
                            key !== '619' &&
                            key !== '620' &&
                            key !== '621' &&
                            key !== '833' &&
                            key !== '836' &&
                            key !== '839' &&
                            key !== '840' &&
                            key !== '841' &&
                            key !== '853' &&
                            key !== '855' &&
                            key !== '856' &&
                            key !== '539.16' &&
                            key !== '885' &&
                            key !== '891.1' &&
                            key !== '891.2' &&
                            key !== '891.3' &&
                            key !== '891.4' &&
                            key !== '891.5' &&
                            key !== '891.6' &&
                            key !== '891.7' &&
                            key !== '891.8' &&
                            key !== '891.9' &&
                            key !== '891.10' &&
                            key !== '892' &&
                            key !== '893' &&
                            key !== '894' &&
                            key !== '895' &&
                            key !== '896' &&
                            key !== '897' &&
                            key !== '898' &&
                            key !== '899' &&
                            key !== '900' &&
                            key !== '901' &&
                            key !== '783'
                            ) {
                            let inputName = 'input_' + key;
                            let inputValue = metadata[key];

                            const generatedName = getNameGenerator(inputName);

                            if (key !== '883') {
                                updatedFormInfo[generatedName] = {text: inputValue, changed: true};
                            }
                            if (metadata['453'] && typeof metadata['453'] === "string") {
                                const arr = metadata['453'].split(",").map((item: any) => item.trim());
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newKeywords']: arr,
                                    changed: true,
                                };
                            }

                            if (key === '857' && metadata['857']) {
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newVariations']: Array.isArray(inputValue) ? inputValue : parsePhpSerializedString(inputValue),
                                    changed: true,
                                };
                            } else if (key !== '883' && key !== '859.1') {
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    [generatedName]: inputValue,
                                    changed: true,
                                };
                            }
                            if(key === '859.1'){
                                if(allowDraftsPageAccess !== false && allowDraftsPageAccess !== null){
                                    updatedItem[editIndex] = {
                                        ...updatedItem[editIndex],
                                        [generatedName]: inputValue,
                                        changed: true,
                                    };
                                }
                            }

                            if (key === '883' && metadata['883']) {
                                setAssigneeId(`${metadata['883']}`)
                            }


                            const activeItemForBuilder = metadata[166];
                            if (activeItemForBuilder) {
                                const indexToActivateBuilder = descriptionBuildItems?.findIndex(
                                    (item: any) => item.value === activeItemForBuilder
                                );
                                if (indexToActivateBuilder !== -1) {
                                    const updatedItems = descriptionBuildItems.map((item, index) => ({
                                        ...item,
                                        active: index === indexToActivateBuilder, // Only set the matched item to active: true
                                    }));
                                    setDescriptionBuildItems(updatedItems);
                                }
                            }

                            const activeItemIntendedFor = metadata[4];

                            if (activeItemIntendedFor) {
                                const indexToActivateIntendedFor = IntendedForItems?.findIndex(
                                    (item: any) => item.value === activeItemIntendedFor
                                );
                                if (indexToActivateIntendedFor !== -1) {
                                    const updatedItems = IntendedForItems.map((item, index) => ({
                                        ...item,
                                        active: index === indexToActivateIntendedFor, // Only set the matched item to active: true
                                    }));
                                    setIntendedItems(updatedItems);
                                }
                            }

                            const activeItemForCondition = metadata[15];

                            if (activeItemForCondition) {
                                const indexToActivateCondition = conditionItems?.findIndex(
                                    (item: any) => item.value === activeItemForCondition
                                );
                                if (activeItemForCondition) {
                                    if (activeItemForCondition === 'New') {
                                        setActiveCondition(1);
                                    } else if (activeItemForCondition === 'Pre-Owned') {
                                        setActiveCondition(2);
                                    } else {
                                        setActiveCondition(0);
                                    }
                                }

                                if (indexToActivateCondition !== -1) {
                                    const updatedItems = conditionItems.map((item, index) => ({
                                        ...item,
                                        active: index === indexToActivateCondition, // Only set the matched item to active: true
                                    }));
                                    setConditionItems(updatedItems);
                                }
                            }

                            const activeItemForNewCondition = metadata[795];
                            if (activeItemForNewCondition) {
                                const indexToActivateNewCondition = newConditionItems?.findIndex(
                                    (item: any) => item.value === activeItemForNewCondition
                                );

                                if (indexToActivateNewCondition !== -1) {
                                    const updatedItems = newConditionItems.map((item, index) => ({
                                        ...item,
                                        active: index === indexToActivateNewCondition, // Only set the matched item to active: true
                                    }));
                                    setNewConditionItems(updatedItems);
                                }
                            }

                            const activeItemForPreOwnedCondition = metadata[796];
                            if (activeItemForPreOwnedCondition) {
                                const indexToActivatePreOwnedCondition = preOwnedItems?.findIndex(
                                    (item: any) => item.value === activeItemForPreOwnedCondition
                                );
                                if (indexToActivatePreOwnedCondition !== -1) {
                                    const updatedItems = preOwnedItems.map((item, index) => ({
                                        ...item,
                                        active: index === indexToActivatePreOwnedCondition, // Only set the matched item to active: true
                                    }));
                                    setPreOwnedItems(updatedItems);
                                }
                            }

                            const activeBoth = metadata[723.1];

                            if (activeBoth) {
                                const indexToActivateActiveBoth = measureBothItems?.findIndex(
                                    (item) => item.value === activeBoth
                                );
                                if (indexToActivateActiveBoth !== -1) {
                                    const updatedItems = [...measureBothItems];
                                    updatedItems[indexToActivateActiveBoth] = {
                                        ...updatedItems[indexToActivateActiveBoth],
                                        active: true,
                                    };
                                    setMeasureBothItems(updatedItems);
                                }
                            }else if((!activeBoth && defaultTemplateMetdata) || (!activeBoth && template)){
                                const indexToActivateActiveBoth = measureBothItems?.findIndex(
                                    (item) => item.value === 'in-cm'
                                );
                                if (indexToActivateActiveBoth !== -1) {
                                    const updatedItems = [...measureBothItems];
                                    updatedItems[indexToActivateActiveBoth] = {
                                        ...updatedItems[indexToActivateActiveBoth],
                                        active: false,
                                    };
                                    setMeasureBothItems(updatedItems);
                                }
                            }

                            const activeBullets = metadata[44];
                            if (activeBullets) {
                                const indexToActivateActiveBullets = bulletItems?.findIndex(
                                    (item) => item.value === activeBullets
                                );
                                if (indexToActivateActiveBullets !== -1) {
                                    const updatedItems = bulletItems.map((item, index) => ({
                                        ...item,
                                        active: index === indexToActivateActiveBullets, // Only set the matched item to active: true
                                    }));
                                    setBulletItems(updatedItems);
                                }
                            }


                            const activeMeasure = metadata[54];
                            if (activeMeasure) {
                                const indexToActivateActiveMeasure = measureItems?.findIndex(
                                    (item) => item.value === activeMeasure
                                );
                                if (indexToActivateActiveMeasure !== -1) {
                                    const updatedItems = measureItems.map((item, index) => ({
                                        ...item,
                                        active: index === indexToActivateActiveMeasure, // Only set the matched item to active: true
                                    }));
                                    setMeasureItems(updatedItems);
                                }
                            }

                            if (metadata[859.1] &&
                                metadata[859.1]?.includes('Draft') &&
                                formInfo.newDraft.changed !== true
                            ) {
                                setIsCheckBoxChecked(true);
                            }

                            const variations = metadata[857];
                            if (variations) {
                                const parsedVariations: any = Array.isArray(variations) ? variations : parsePhpSerializedString(variations);
                                setVariations(parsedVariations);
                            } else {
                                setVariations([
                                    {
                                        Size: '',
                                        Color: '',
                                        Custom: '',
                                        Quantity: '',
                                        SKU: '',
                                    },
                                ]);
                            }
                            if (metadata['879.1']) {
                                setAddMoreInputs(true);
                            }

                            if (metadata['109.1']) {
                                setMoreMeasurementsPart2(true);
                            }

                            if (metadata['377.1']) {
                                setMoreMeasurementsPart1(true);
                            }

                            if (metadata['822.1']) {
                                setShowAllTitles(true);
                            }

                            if (
                                metadata[789] &&
                                metadata[789] !== metadata['133']
                            ) {
                                updatedFormInfo['newMercaryTitle'] = {text: metadata['789'], changed: true};
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newMercaryTitle']: metadata['789'],
                                    changed: true,
                                };
                            }
                            else if (metadata['133'] && unescapedRowMetadata['789']) {
                                updatedFormInfo['newMercaryTitle'] = { text: metadata['133'], changed: true };
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newMercaryTitle']: null,
                                    changed: true,
                                };
                            }
                            else if(metadata['133'] && !unescapedRowMetadata['789']){
                                updatedFormInfo['newMercaryTitle'] = { text: metadata['133'], changed: true };
                            }

                            if (
                                metadata[786] &&
                                metadata[786] !== metadata['133']
                            ) {
                                updatedFormInfo['newPoshmarkTitle'] = {text: metadata['786'], changed: true};
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newPoshmarkTitle']: metadata['786'],
                                    changed: true,
                                };
                            }
                            else if (metadata['133'] && unescapedRowMetadata['786']) {
                                updatedFormInfo['newPoshmarkTitle'] = { text: metadata['133'], changed: true };
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newPoshmarkTitle']: null,
                                    changed: true,
                                };
                            }
                            else if(metadata['133'] && !unescapedRowMetadata['786']){
                                updatedFormInfo['newPoshmarkTitle'] = { text: metadata['133'], changed: true };
                            }

                            if (
                                metadata[788] &&
                                metadata[788] !== metadata['133']
                            ) {
                                updatedFormInfo['newGrailedTitle'] = {text: metadata['788'], changed: true};
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newGrailedTitle']: metadata['788'],
                                    changed: true,
                                };
                            }
                            else if (metadata['133'] && unescapedRowMetadata['788']) {
                                updatedFormInfo['newGrailedTitle'] = { text: metadata['133'], changed: true };
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newGrailedTitle']: null,
                                    changed: true,
                                };
                            }
                            else if(metadata['133'] && !unescapedRowMetadata['788']){
                                updatedFormInfo['newGrailedTitle'] = { text: metadata['133'], changed: true };
                            }

                            if (
                                metadata[791] &&
                                metadata[791] !== metadata['133']
                            ) {
                                updatedFormInfo['newAmazonEbayTitle'] = {text: metadata['791'], changed: true};
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newAmazonEbayTitle']: metadata['791'],
                                    changed: true,
                                };
                            }
                            else if (metadata['133'] && unescapedRowMetadata['791']) {
                                updatedFormInfo['newAmazonEbayTitle'] = { text: metadata['133'], changed: true };
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newAmazonEbayTitle']: null,
                                    changed: true,
                                };
                            }
                            else if(metadata['133'] && !unescapedRowMetadata['791']){
                                updatedFormInfo['newAmazonEbayTitle'] = { text: metadata['133'], changed: true };
                            }

                            if (
                                metadata[869] &&
                                metadata[869] !== metadata['133']
                            ) {
                                updatedFormInfo['newFacebookTitle'] = {text: metadata['869'], changed: true};
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newFacebookTitle']: metadata['869'],
                                    changed: true,
                                };
                            }
                            else if (metadata['133'] && unescapedRowMetadata['869']) {
                                updatedFormInfo['newFacebookTitle'] = { text: metadata['133'], changed: true };
                                updatedItem[editIndex] = {
                                    ...updatedItem[editIndex],
                                    ['newFacebookTitle']: null,
                                    changed: true,
                                };
                            }
                            else if(metadata['133'] && !unescapedRowMetadata['869']){
                                updatedFormInfo['newFacebookTitle'] = { text: metadata['133'], changed: true };
                            }
                        }

                    }
                    setFormInfo(updatedFormInfo);

                    const cleanedItems = updatedItem.map(item => {
                        // Create a copy of the item without the 'copyDesc' key
                        const { copyDesc, ...rest } = item;

                        // Filter out undefined values
                        const cleanedItem = Object.fromEntries(
                            Object.entries(rest).filter(([key, value]) => value !== undefined)
                        );

                        return cleanedItem;
                    });

                    // Update the state with the cleaned items
                    setItemData(cleanedItems);
                    // setItemData(updatedItem);
                }

            }
        }catch (e){
            console.log('error', e)
        }
    }, [activeTemplateid, listInfo, defaultTemplateMetdata, duplicateData, dataFromExtension]);



    useEffect(() => {
        if (showEditAddModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [showEditAddModal]);


    const handleToggleListingAssistant = ()=>{
        setCreateFromImage(true);
        changeListingAssistance(false);
    }
    useEffect(() => {
        if (listInfo) {
            try {
                const unescapedRowMetadata2 = {
                    539.1: 'SOLD',
                    539.2: 'eBay',
                    539.3: 'Etsy',
                    539.4: 'Poshmark',
                    539.5: 'Mercari',
                    539.7: 'Amazon',
                    539.8: 'Grailed',
                    539.9: 'Facebook',
                    539.11: 'Depop',
                    539.12: 'Kidizen',
                    539.13: 'Shopify',
                    539.14: 'Instagram',
                    539.16: 'Listing Party',
                    539.17: 'Vestiaire',
                };

                const updatedSalesListings = salesListings.map((listing:any) => {
                    const key = Object.keys(unescapedRowMetadata2).find(
                        (k) => (unescapedRowMetadata[k] === listing.title)
                    );
                    if (key) {
                        return { ...listing, checked: true};
                    } else {
                        return { ...listing, checked: false };
                    }
                });

                setSalesListings(updatedSalesListings);
            } catch (e) {
                console.log(e, 'error');
            }
        }
    }, [listInfo]);

    useEffect(() => {
        if(dataFromExtension){
            try {
                const unescapedRowMetadata2 = {
                    539.1: 'SOLD',
                    539.2: 'eBay',
                    539.3: 'Etsy',
                    539.4: 'Poshmark',
                    539.5: 'Mercari',
                    539.7: 'Amazon',
                    539.8: 'Grailed',
                    539.9: 'Facebook',
                    539.11: 'Depop',
                    539.12: 'Kidizen',
                    539.13: 'Shopify',
                    539.14: 'Instagram',
                    539.16: 'Listing Party',
                    539.17: 'Vestiaire',
                };

                const updatedSalesListings = salesListings.map((listing:any) => {
                    const key = Object.keys(unescapedRowMetadata2).find(
                        (k) => dataFromExtension[k] === listing.title
                    );
                    if (key) {
                        return { ...listing, checked: true };
                    } else {
                        return listing;
                    }
                });

                setSalesListings(updatedSalesListings);
            } catch (e) {
                console.log(e, 'error');
            }
        }
    }, [dataFromExtension]);

    // need on add page
    // useEffect(() => {
    //     if (listInfo || dataFromExtension) {
    //         setItemData((prevItemData:any) => {
    //             const updatedItemData = [...prevItemData]; // Create a copy of the previous itemData
    //
    //             salesListings.forEach((listing: any) => {
    //                 const sendName = listing.placeholder; // Define the dynamic property to update
    //
    //                 if (listing.checked && listing.changed) {
    //                     updatedItemData[editIndex] = {
    //                         ...updatedItemData[editIndex], // Keep existing properties
    //                         [sendName]: listing.title, // Update the dynamic property
    //                         changed: true, // Mark it as changed
    //                     };
    //                 }
    //             });
    //
    //             return updatedItemData; // Return the updated state
    //         });
    //     }
    // }, [salesListings, listInfo, dataFromExtension, editIndex]);

    useEffect(() => {
        if (extensionListRef.current) {
            // Create a new MutationObserver instance
            const observer = new MutationObserver((mutations) => {
                mutations.forEach(() => {
                    const newJson = extensionListRef?.current?.textContent; // Get the current text content of the div
                    try {
                        const parsedJson = JSON.parse(newJson); // Parse the JSON
                        const extensionMetadata = parsedJson.metadata;
                        // setListInfo(parsedJson.metadata);

                        const unescapedRowMetadata2 =
                            {
                                539.1: 'SOLD',
                                539.2: 'eBay',
                                539.3: 'Etsy',
                                539.4: 'Poshmark',
                                539.5: 'Mercari',
                                539.7: 'Amazon',
                                539.8: 'Grailed',
                                539.9: 'Facebook',
                                539.11: 'Depop',
                                539.12: 'Kidizen',
                                539.13: 'Shopify',
                                539.14: 'Instagram',
                                539.16: 'Listing Party',
                                539.17: 'Vestiaire',
                            }
                        const updatedSalesListings = salesListings.map((listing: any) => {
                            const key = Object.keys(unescapedRowMetadata2).find(
                                (k) => extensionMetadata[k] === listing.title
                            );

                            // Check if `539.1` exists in `extensionMetadata`
                            if (extensionMetadata?.hasOwnProperty('539.1')) {
                                // Update all listings based on `extensionMetadata`
                                return key ? { ...listing, checked: true } : { ...listing, checked: false };
                            } else {
                                // Only update the item with title 'SOLD' and code '539.1'
                                if (listing.title === 'SOLD') {
                                    return { ...listing, checked: false };
                                }else{
                                    return listing;
                                }

                            }
                        });
                        setSalesListings(updatedSalesListings);
                        // setEnabledButtons(true);
                        // setDisableSaveButtons(false);
                        // setJsonData(parsedJson); // Update the state with the new JSON
                    } catch (error) {
                        console.error("Invalid JSON format:", newJson);
                    }
                });
            });

            // Start observing the target node for configured mutations
            observer.observe(extensionListRef.current, {
                childList: true, // Observe direct children
                characterData: true, // Observe character data changes
                subtree: true, // Observe all descendants
            });

            // Cleanup function to disconnect the observer when the component unmounts
            return () => {
                observer.disconnect();
            };
        }
    }, [extensionListRef, productInfoJSON, editId, afterCreate, formInfo]);

    return(
        <div className={`${styles.editAddModalContainer} ${(showEditAddModal) ? styles.editAddModalFixed : styles.editAddModalPage}`} id="editMainContainer">
            <div className="lp_custom_chrome_extension_marketplace_listing_data"></div>
            {
                showLoader ? <div className={`${styles.loadercontainer} ${editId && showEditAddModal ? styles.loaderContainerModal : ''}`}><MoonLoader/></div> : (
                    <>
                        {
                           (savingProgress && isMobile) && <div className={styles.loaderForSavingProgress}>
                                <MoonLoader size={20}/>
                                <p>Saving...</p>
                            </div>
                        }
                        {
                            (editId && !afterCreate) ? <p className={`${styles.headerTitle} ${editId && showEditAddModal ? styles.modalTitle : ''}`}>Edit Listing</p> :
                                <p className={styles.headerTitle}>Add Listing</p>
                        }
                        {
                            (showEditAddModal) && <div className={`${styles.closeEditAddModalMobile} full-form-close-icon`} onClick={() => {
                                dispatch(setEditAddModalShow(false));
                                dispatch(setEditAddModalId(null));
                            }}><CloseSvg/></div>
                        }
                        <div className={styles.editModalInner}>
                            {(productInfoJSON && editId && !afterCreate) && <div ref={extensionListRef} className="lp_custom_chrome_extension_listing_data">{productInfoJSON}</div>}
                            <ToastContainer containerId="editToast"/>
                            {
                                !createFromImage ?
                                    <>
                                        {showListingAssistant && (
                                            <ListingAssistant
                                                sendRequestForSuggestDescription={
                                                    sendRequestForSuggestDescription
                                                }
                                                failedBarcode={failedBarcode}
                                                setFailedBarcode={setFailedBarcode}
                                                itemData={itemData}
                                                setItemData={setItemData}
                                                editIndex={editIndex}
                                                changeListingAssistance={changeListingAssistance}
                                                barCodeLimits={barCodeLimits}
                                                scanLoader={scanLoader}
                                                loaderForSuggest={loaderForSuggest}
                                                assistantTitle={assistantTitle}
                                                setAssistantTitle={setAssistantTitle}
                                                sendRequestForScan={sendRequestForScan}
                                                handleChangeEditInput={suggestOnchange}
                                                setNewTableInfo={setFormInfo}
                                                newTableInfo={formInfo}
                                                setCreateFromImage={setCreateFromImage}
                                                hideAssistantModal={hideAssistantModal}
                                                setHideAssistantModal={setHideAssistantModal}
                                                imagesFromUploader={imagesFromUploader}
                                                mainTitle={formInfo.newTitle?.text || unescapedRowMetadata['133'] || ''}
                                                isModal={(showEditAddModal)}
                                                setAssistantFromTitle={setAssistantFromTitle}
                                                unescapedRowMetadata={unescapedRowMetadata}
                                                setScanningFailed={setScanningFailed}
                                                scanningFailed={scanningFailed}
                                                setScanLoader={setScanLoader}
                                                setBarCodeLimits={setBarCodeLimits}
                                                setQuotasLimit={setQuotasLimit}
                                                quotasLimit={quotasLimit}
                                            />
                                        )}
                                    </> :
                                    <>
                                        <ListingAssistantVision
                                            setShowListingAssistantModal={setCreateFromImage}
                                            imagesFromUploader={imagesFromUploader}
                                            setFormChanged={setFormChanged}
                                            listingAssistantTone={listingAssistantTone}
                                            setListingAssistantTone={setListingAssistantTone}
                                            listingAssistantContext={listingAssistantContext}
                                            setListingAssistantContext={setListingAssistantContext}
                                            configID={configID}
                                            setShowRegenerate={setShowRegenerate}
                                            setRegenerateAttempsLeftCount={setRegenerateAttempsLeftCount}
                                            assistantTitle={assistantTitleVision}
                                            setRegenerateId={setRegenerateId}
                                            regenerateId={regenerateId}
                                            regenerateAttempsLeftCount={regenerateAttempsLeftCount}
                                            regenerateTotal={regenerateTotal}
                                            setRegenerateTotal={setRegenerateTotal}
                                            setGeneratedModal={setGeneratedModal}
                                            setCommonState={setCommonState}
                                            isModal={(showEditAddModal)}
                                            quotasLimit={quotasLimit}
                                        />
                                    </>
                            }
                            <HeaderComponent changeListingAssistance={changeListingAssistance}
                                             enabledButtons={enabledButtons}
                                             handleSaveForm={handleSaveListing}
                                             setSaveAndClose={setSaveAndClose}
                                             setSaveAndPreview={setSaveAndPreview}
                                             setSaveAndNew={setSaveAndNew}
                                             editId={editId}
                                             handleAddListing={handleAddListing}
                                             setAssistantTitleVision={setAssistantTitleVision}
                                             handleToggleListingAssistant={handleToggleListingAssistant}
                                             showRegenerate={showRegenerate}
                                             regenerateAttempsLeftCount={regenerateAttempsLeftCount}
                                             disableSaveButtons={disableSaveButtons}
                                             afterCreate={afterCreate}
                                             showEditAddModal={showEditAddModal}
                                             savingProgress={savingProgress}
                                             SKUloader={SKUloader}
                            />
                            <div className={styles.editModalBody}>

                                {/*<div className={styles.expressListerSwitcher}>*/}
                                {/*    <div className={`${styles.switcherItem} ${styles.fullListingForm}`}>Full Listing Form</div>*/}
                                {/*    <div className={`${styles.switcherItem} ${styles.expressListerForm}`}*/}
                                {/*    onClick={()=>{*/}
                                {/*        navigate('/listings/express');*/}
                                {/*    }}*/}
                                {/*    >Express Lister</div>*/}
                                {/*</div>*/}

                                <EditTemplatesContainer
                                    isShowInDropDown={isShowInDropDown}
                                    setIsShowInDropDown={setIsShowInDropDown}
                                    templates={templates}
                                    setActiveTemplateId={setActiveTemplateId}
                                    formInfo={formInfo}
                                    setFormInfo={setFormInfo}
                                    itemData={itemData}
                                    editIndex={editIndex}
                                    setItemData={setItemData}
                                    selectedOptionTemplate={selectedOptionTemplate}
                                    setSelectedOptionTemplate={setSelectedOptionTemplate}
                                />
                                <div className={styles.mobileAssistantContainer}>
                                    <button
                                        className={styles.listingAssistButton}
                                        disabled={!enabledButtons}
                                        style={!enabledButtons ? {opacity: 0.7} : {opacity: 1}}
                                        onClick={() => {
                                            setAssistantTitleVision('assistant')
                                            changeListingAssistance(true)
                                        }}
                                    >
                                        <Lightbulb/>
                                        <span>Listing Assistant</span>

                                    </button>
                                    {
                                        (showRegenerate && regenerateAttempsLeftCount !== 0) &&
                                        <button className={styles.listingAssistButton}
                                                disabled={!enabledButtons}
                                                style={{
                                                    backgroundColor: '#F2711C',
                                                    opacity: !enabledButtons ? 0.7 : 1
                                                }}
                                                onClick={() => {
                                                    setAssistantTitleVision('regenerate');
                                                    handleToggleListingAssistant();
                                                }}>
                                            <Lightbulb/>
                                            Regenerate
                                        </button>
                                    }
                                </div>
                                <div className={styles.uploadImagesContainer}>
                                    <div className={styles.uploadImagesSlider}>
                                        <ImageUploaderContainer
                                            handleImageEditComplete={() => setEnabledButtons(true)}
                                            setImagesFromUploader={setImagesFromUploader}
                                            handleChangeUploaderInputs={handleChangeUploaderInputs}
                                            isUploaderOpen={(status: boolean) => console.log(status)}
                                            displayUploaderTitle={false}
                                            displayBulkActions={true}
                                            expressListerView={true}
                                            allowListingSave={(status: boolean) => {
                                                setUpdateButtonsDisabled(!updateButtonsDisabled)
                                                setIsImagesUploaded(status)
                                            }}
                                            images={itemImages}
                                            filesList={filesList}
                                        />
                                    </div>
                                </div>
                                <ListingTitleContainer formInfo={formInfo} setFormInfo={setFormInfo} itemData={itemData}
                                                       editIndex={editIndex} setItemData={setItemData}
                                                       unescapedRowMetadata={unescapedRowMetadata}
                                                       handleStandartInputChange={handleStandartInputChange}
                                                       hasSimple={hasSimple}
                                                       showAllTitles={showAllTitles}
                                                       setShowAllTitles={setShowAllTitles}
                                                       handleResetAllTitles={handleResetAllTitles}
                                />
                                <ShortDescriptionContainer enabledButtons={enabledButtons}
                                                           suggestOnchange={suggestOnchange}
                                                           setCommonState={setCommonState} commonState={commonState}
                                                           setAssistantTitle={setAssistantTitle}
                                                           assistantTitle={assistantTitle}
                                                           generatedModal={generatedModal}
                                                           setGeneratedModal={setGeneratedModal}
                                                           loaderForSuggest={loaderForSuggest}
                                                           sendRequestForSuggestDescription={sendRequestForSuggestDescription}
                                                           handleTagifiesChange={handleTagifiesChange}
                                                           handleStandartInputChange={handleStandartInputChange}
                                                           formInfo={formInfo}
                                                           unescapedRowMetadata={unescapedRowMetadata}
                                                           hasSimple={hasSimple}
                                                           hasBusiness={hasBusiness}
                                                           isModal={(showEditAddModal)}
                                                           setAssistantFromTitle={setAssistantFromTitle}
                                                           assistantFromTitle={assistantFromTitle}
                                />
                                {
                                    (!hasSimple && !hasBusiness) &&
                                    <DescriptionBuilderComponent itemData={itemData} setItemData={setItemData}
                                                                 editIndex={editIndex} listInfo={listInfo}
                                                                 unescapedRowMetadata={unescapedRowMetadata}
                                                                 descriptionBuildItems={descriptionBuildItems}
                                                                 setDescriptionBuildItems={setDescriptionBuildItems}
                                                                 setDisableSaveButtons={setDisableSaveButtons}
                                    />
                                }
                                {
                                    (!hasSimple && !hasBusiness) &&
                                    <IntendedForComponent itemData={itemData} setItemData={setItemData}
                                                          editIndex={editIndex}
                                                          listInfo={listInfo}
                                                          unescapedRowMetadata={unescapedRowMetadata}
                                                          IntendedForItems={IntendedForItems}
                                                          setIntendedItems={setIntendedItems}
                                                          setDisableSaveButtons={setDisableSaveButtons}
                                    />
                                }
                                {
                                    (!hasSimple && !hasBusiness) &&
                                    <ConditionsComponent itemData={itemData} editIndex={editIndex}
                                                         setItemData={setItemData}
                                                         handleStandartInputChange={handleStandartInputChange}
                                                         listInfo={listInfo}
                                                         formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata}
                                                         activeCondition={activeCondition}
                                                         setActiveCondition={setActiveCondition}
                                                         conditionItems={conditionItems}
                                                         setConditionItems={setConditionItems}
                                                         newConditionItems={newConditionItems}
                                                         setNewConditionItems={setNewConditionItems}
                                                         preOwnedItems={preOwnedItems}
                                                         setPreOwnedItems={setPreOwnedItems}
                                                         setDisableSaveButtons={setDisableSaveButtons}
                                    />
                                }
                                <DropdownComponent setFormInfo={setFormInfo} editId={editId}
                                                   handleTagifiesChange={handleTagifiesChange}
                                                   handleStandartInputChange={handleStandartInputChange}
                                                   editIndex={editIndex}
                                                   itemData={itemData}
                                                   setItemData={setItemData}
                                                   listInfo={listInfo}
                                                   formInfo={formInfo}
                                                   unescapedRowMetadata={unescapedRowMetadata}
                                                   addMoreInputs={addMoreInputs}
                                                   setAddMoreInputs={setAddMoreInputs}
                                                   moreMeasurementsPart1={moreMeasurementsPart1}
                                                   setMoreMeasurementsPart1={setMoreMeasurementsPart1}
                                                   moreMeasurementsPart2={moreMeasurementsPart2}
                                                   setMoreMeasurementsPart2={setMoreMeasurementsPart2}
                                                   optionsAssignee={optionsAssignee}
                                                   selectedOptionAssignee={selectedOptionAssignee}
                                                   setSelectedOptionAssignee={setSelectedOptionAssignee}
                                                   assigneeId={assigneeId}
                                                   hasSimple={hasSimple}
                                                   hasBusiness={hasBusiness}
                                                   bulletItems={bulletItems}
                                                   setBulletItems={setBulletItems}
                                                   measureItems={measureItems}
                                                   setMeasureItems={setMeasureItems}
                                                   measureBothItems={measureBothItems}
                                                   setMeasureBothItems={setMeasureBothItems}
                                                   variations={variations}
                                                   setVariations={setVariations}
                                                   subAccounts={subAccounts}
                                                   setSubAccounts={setSubAccounts}
                                                   setOptionsAssignee={setOptionsAssignee}
                                                   customMarketplacesTableRow={customMarketplacesTableRow}
                                                   setCustomMarketplacesTableRow={setCustomMarketplacesTableRow}
                                                   loaderForCustomMarkets={loaderForCustomMarkets}
                                                   requestId={requestId}
                                                   setDisableSaveButtons={setDisableSaveButtons}
                                                   dataFromExtension={dataFromExtension}
                                                   salesListings={salesListings}
                                                   setSalesListings={setSalesListings}
                                                   afterCreate={afterCreate}
                                                   hasExtensionInstall={hasExtensionInstall}
                                                   isExtensionSupported={isExtensionSupported}
                                                   skuKeys={skuKeys}
                                                   config={config}
                                                   SKUInputValue={SKUInputValue}
                                                   activeItemSKUValue={activeItemSKUValue}
                                                   SKUprefix={SKUprefix}
                                                   SKUformat={SKUformat}
                                                   skuGeneratorUniqueIdFormat={skuGeneratorUniqueIdFormat}
                                                   handleSKUitemClick={handleSKUitemClick}
                                                   activeItemSKU={activeItemSKU}
                                                   handleKeyPress={handleKeyPress}
                                                   handleInputChangeSKU={handleInputChangeSKU}
                                                   setSKUloader={setSKUloader}
                                                   SKUloader={SKUloader}
                                                   savingProgress={savingProgress}
                                                   enabledButtons={enabledButtons}
                                                   is_role_allowed={is_role_allowed}
                                                   hasPro={hasPro}
                                                   enableToShowSkuGenerator={enableToShowSkuGenerator}
                                                   hasExpandedDescription={hasExpandedDescription}
                                />
                                <SellerNotesDraft itemData={itemData} editIndex={editIndex} setItemData={setItemData}
                                                  formInfo={formInfo} setFormInfo={setFormInfo}
                                                  handleStandartInputChange={handleStandartInputChange}
                                                  unescapedRowMetadata={unescapedRowMetadata}
                                                  hasSimple={hasSimple}
                                                  hasBusiness={hasBusiness}
                                                  isCheckBoxChecked={isCheckBoxChecked}
                                                  setIsCheckBoxChecked={setIsCheckBoxChecked}
                                                  allowDraftsPageAccess={allowDraftsPageAccess}
                                />
                            </div>
                        </div>
                        {
                            isMobile &&  <FooterComponent enabledButtons={enabledButtons}
                                                          handleSaveForm={handleSaveListing}
                                                          setSaveAndClose={setSaveAndClose}
                                                          setSaveAndPreview={setSaveAndPreview}
                                                          setSaveAndNew={setSaveAndNew}
                                                          editId={editId}
                                                          handleAddListing={handleAddListing}
                                                          disableSaveButtons={disableSaveButtons}
                                                          afterCreate={afterCreate}
                                                          showEditAddModal={showEditAddModal}
                                                          savingProgress={savingProgress}
                                                          SKUloader={SKUloader}
                            />
                        }
                    </>

                )
            }
        </div>
    )
}

export default EditAndAddListingModal;