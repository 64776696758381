import React from 'react'
import styles from './QuickLinks.module.scss'

import quickLinks from "@assets/constants/quickLinks";
import QuickLinkItem from "./components/QuickLinkItem";
import useRoles from "@hooks/useRoles";


interface IQuickLinksProps {
}

const QuickLinks = (props: IQuickLinksProps) => {
	const {is_role_allowed_master } = useRoles();

	const filteredQuickLinks = is_role_allowed_master
	 ? quickLinks.filter(link => link.id !== 1)
	 : quickLinks;

	return (
	 <div className={styles.quickLinks}>
		 <div className={styles.quickLinksWrapper}>
			 {filteredQuickLinks?.map((item: any) => (
			  <QuickLinkItem
			   key={item.id}
			   icon={item.icon}
			   title={item.title}
			   link={item.link}/>
			 ))}
		 </div>

	 </div>
	)
}

export default QuickLinks