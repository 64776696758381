
import styles from '../../../ConnectedMarketplacesModal/ConnectedMarketplacesModal.module.scss';
import Tippy from '@tippyjs/react';
import React, {useEffect, useRef, useState} from 'react';

import {SVGSettingsIcon,WizardTrashIcon,PopoverIconMarkets,ActiveIconMarkets,ErrorIconMarkets,EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,KidizenLogo,FacebookLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons" ;

import {MoonLoader} from "react-spinners";
import useExtensionVersion from "../../../../hooks/useExtensionVersion";
interface IListItemProps {
	marketName: any;
	account: any;
	extensionActive: any;
	extensionError: any;
	onSettingsClick: any;
}
const ListItem = (props: IListItemProps) => {
	const {
		marketName,
		account,
		extensionActive,
		extensionError,
		onSettingsClick,
	} = props;
	const [connectBtnLoading, setConnectBtnLoading] = useState(false);
	const buttonRef = useRef<any>(null);
	const handleClickSettings = (marketName: any) => {
		const newSettingsMarket = marketName;
		onSettingsClick(newSettingsMarket);
	};
	const isTrashIconSupported =  useExtensionVersion('0.26.7');

	useEffect(() => {
		const handleAttributeChange = () => {
			if (buttonRef.current) {
				const hasClass = buttonRef.current.classList.contains('lp_custom_connection_wizard_modal_connect_platform_button_loading');
				setConnectBtnLoading(hasClass);
			}
		};

		const observer = new MutationObserver(handleAttributeChange);

		if (buttonRef.current) {
			observer.observe(buttonRef.current, {
				attributes: true,
				attributeFilter: ['class'],
			});
		}

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
	 <div className={styles.listItemContainer}>
		 <div className={styles.nameContainer}>
			 <div className={styles.image}>
				 {marketName?.toLowerCase() === 'ebay' ? (
				  <EbayLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'etsy' ? (
				  <EtsyLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'poshmark' ? (
				  <PoshmarkLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'mercari' ? (
				  <MercaryLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'grailed' ? (
				  <GrailedLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'facebook' ? (
				  <FacebookLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'depop' ? (
				  <DepopLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'kidizen' ? (
				  <KidizenLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'shopify' ? (
				  <ShopifyLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'instagram' ? (
				  <InstagramLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'vestiaire' ? (
				  <VestiaireLogo height={60} width={60} />
				 ) : marketName?.toLowerCase() === 'amazon' ? (
				  <AmazonLogo height={60} width={60} />
				 ) : null}
			 </div>
			 <div className={marketName?.toLowerCase() === 'ebay' ? styles.marketNameEbay : styles.marketName}>
				 {marketName?.toLowerCase() === 'ebay' ? 'eBay' : marketName}
			 </div>
		 </div>
		 <div className={styles.accountContainer}>
			 <div className={styles.account}>{account ? account : '-'}</div>
			 <div className={styles.extensionContainer}>
				 <p className={styles.extensionTitle}>
					 Extension
				 </p>
				 <div className={styles.buttonsContainer}>
					 {
					  extensionActive && <button className={styles.activeButton}>
						  <ActiveIconMarkets />
						  <span>Active</span>
					  </button>
					 }
					 {
					  (!extensionActive && extensionError) &&  <div className={styles.errBtnContainer}>
						  <button className={styles.errorButton}>
							  <ErrorIconMarkets />
							  <span>Error</span>
						  </button>
						  <Tippy
						   interactive={true}
						   zIndex={1}
						   arrow={false}
						   trigger="click"
						   content={
							   <div className={styles.popoverContentDisabledActions}>
								   {extensionError}
							   </div>
						   }
						  >
                            <span>
                               <PopoverIconMarkets className={styles.popovericon} />
                            </span>
						  </Tippy>
					  </div>
					 }
					 {
						 extensionActive ?
						  (
						   <button className={styles.disconnectButton}>Disconnect</button>
						  ) : (
						   <button ref={buttonRef} className={`lp_custom_connection_wizard_modal_connect_platform_button ${styles.connectButton}`}>
							   {connectBtnLoading ? (
								<div className={styles.loadingBtn}>
									<MoonLoader size={20} />
									<p>Loading</p>
								</div>
							   ) : (
								<span>Connect</span>
							   )}
						   </button>
						  )
					 }
				 </div>
			 </div>
		 </div>
		 <div className={styles.actionsContainer}>
			 <div
			  className={styles.settings}
			  onClick={() => handleClickSettings(marketName)}
			 >
				 <SVGSettingsIcon/>
			 </div>
			 {
			  isTrashIconSupported && <div
			   className={`${styles.deleteMarket} lp_custom_chrome_extension_connection_wizard_modal_delete_platform_button`}>
				  <WizardTrashIcon/>
			  </div>
			 }
		 </div>
	 </div>
	);
};

export default ListItem;