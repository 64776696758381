import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {MoonLoader} from "react-spinners";
import Button, {ModifierType} from "../../../../Button";
import styles from './FullImageModal.module.scss';
import './fullImageSlider.css';
import {ArrowLeftSlick, ArrowRightSlick, ExitButton} from '@assets/icons';

interface GalleryArrowProps {
    ref?: string;
    currentSlide?: number;
    slideCount?: number;
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
    setShowFullImage?: any;
    fullImageLoader?:any;
}
const FullImageSlider = ({
     resultImageUrls,
     setShowFullImage,
     imageIndex,
     fullImageLoader }: any) => {
    let arrowRef: any = useRef(null);
    const [zoomedIndex, setZoomedIndex] = useState(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const GalleryPrevArrow: React.FC<GalleryArrowProps> = ({
        currentSlide,
        slideCount,
        ...props}) => {
        const {onClick } = props;
        return (
            <>
                <div {...props} className={styles.arrowContainer} onClick={onClick}>
                    <div className={styles.leftArrow}>
                        <ArrowLeftSlick className={styles.arrowSvg} />
                    </div>
                </div>
            </>
        );
    };
    const GalleryNextArrow: React.FC<GalleryArrowProps> = ({
        currentSlide,
        slideCount,
        ...props}) => {
        const {  onClick } = props;

        return (
            <>
                <div {...props} className={styles.arrowContainer} onClick={onClick}>
                    <div className={styles.rightArrow}>
                        <ArrowRightSlick className={styles.arrowSvg} />
                    </div>
                </div>
            </>
        );
    };


    const handleMouseMove = (e: any) => {
        if (zoomedIndex !== null) {
            const { left, top, width, height } = e.target.getBoundingClientRect();
            const x = ((e.clientX - left) / width) * 100;
            const y = ((e.clientY - top) / height) * 100;
            setPosition({ x, y });
        }
    };
    const handleImageClick = (index: any) => {
        setZoomedIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const handleMouseLeave = () => {
        setZoomedIndex(null);
    };
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        initialSlide: imageIndex,
        nextArrow: <GalleryNextArrow ref={arrowRef} />,
        prevArrow: <GalleryPrevArrow />,
    };

    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => {
            document.body.classList.remove('overflow-hidden');
        }
    }, []);

    return (
        <div className={`${styles.fullImageModalContainer} fullImageSlider`}>
            <div className={styles.CloseModal}>
                <Button handleClick={() => setShowFullImage(false)}
                        modifier={ModifierType.Primary2}
                        style={{
                            backgroundColor: '#FFDADA',
                            color: '#FF4D4D',
                        }}>
                    <ExitButton className={styles.closeSvgRed}/> Close
                </Button>
            </div>
            {
                fullImageLoader && <div className={styles.fullImageLoader}> <MoonLoader /> </div>
            }

            <Slider {...settings}>
                {resultImageUrls?.map((slide: any, index: any) => (
                    <div key={index} className={styles.slide}>
                        <div
                            className={styles.imageOuter}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                        >
                            <img
                                src={slide.link}
                                alt={`img ${index}`}
                                className={zoomedIndex === index ? styles.zoomed : ''}
                                style={{
                                    transform: zoomedIndex === index ? 'scale(3)' : '',
                                    transformOrigin:
                                        zoomedIndex === index
                                            ? `${position.x}% ${position.y}%`
                                            : 'center center',
                                }}
                                onClick={() => handleImageClick(index)}
                            />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};
export default FullImageSlider;