import React, {useEffect, useRef, useState} from 'react';
import styles from './TableEditColumn.module.scss';

import {objectToString} from '../../../../utils/objectToString';
import {SVGArrow} from '../../../../assets/icons';
import formatString from '../../../../utils/formatString';
import Button, {ModifierType} from "../../../../components/UI/Button";
import useRoles from "@hooks/useRoles";

interface IColumnData {
	name: string;
	value: string;
	isChecked: boolean;
}


interface ITableEditColumn {
	handleChangeModal: () => void;
	columns: any;
	handleEditColumns: (cols: string[]) => void;
}

const TableEditColumn = (props: ITableEditColumn) => {
	const { handleChangeModal, columns, handleEditColumns } = props;
	const {hasSubRole} = useRoles();
	const columnData: IColumnData[] = [
		{ name: 'Default Template', value: 'default_template', isChecked: true },
		{ name: 'Updated', value: 'updated', isChecked: true },
		...(!hasSubRole ? [{ name: 'Assignee', value: 'assignee', isChecked: true }] : []),
		{ name: 'Created', value: 'created', isChecked: true },
	];
	const myColumns = [
	    'default_template',
	    'updated',
	    'assignee',
	    'created',
	];


	const settingsRef = useRef<HTMLDivElement>(null);

	const [editColumns, setEditColumns] = useState<any>([]);

	const [userEditColumns, setUserEditColumns] = useState<any>([]);

	const [arranges, setArranges] = useState<string[]>([]);

	const [selectedItem, setSelectedItem] = useState<string>('');

	useEffect(() => {
		const top__headers = document.querySelectorAll('.top-headers-wrapper');
		Array.from(top__headers)?.forEach((top_header) => {
			if (top_header instanceof HTMLElement) {
				top_header.style.display = 'none';
			}
		});

		const footers = document.querySelectorAll('.site-footer-copyright-area');
		Array.from(footers)?.forEach((footer) => {
			if (footer instanceof HTMLElement) {
				footer.style.display = 'none';
			}
		});

		const contents = document.querySelectorAll('.site-content');
		Array.from(contents)?.forEach((content) => {
			if (content instanceof HTMLElement) {
				content.style.width = '100%';
			}
		});

		const questions = document.querySelectorAll(
		 '.lp_custom_help_bubble_button_container'
		);
		Array.from(questions)?.forEach((question) => {
			if (question instanceof HTMLElement) {
				question.style.display = 'none';
			}
		});

		setEditColumns(columnData.map((item: any) => (item.isChecked = false)));

		columnData?.forEach((item: any) => {
			columns?.forEach((itemItem: any) => {
				if (item.value === itemItem.value) {
					item.isChecked = true;
				}
			});
		});

		setEditColumns(columnData);
		setUserEditColumns(objectToString(columns));
		//
		setSelectedItem(objectToString(columns)[0]);

		//
		setArranges(objectToString(columns));

		return () => {
			const top__headers = document.querySelectorAll('.top-headers-wrapper');
			Array.from(top__headers)?.forEach((top_header) => {
				if (top_header instanceof HTMLElement) {
					top_header.style.display = 'block';
				}
			});

			const footers = document.querySelectorAll('.site-footer-copyright-area');
			Array.from(footers)?.forEach((footer) => {
				if (footer instanceof HTMLElement) {
					footer.style.display = 'block';
				}
			});

			const contents = document.querySelectorAll('.site-content');
			Array.from(contents)?.forEach((content) => {
				if (content instanceof HTMLElement) {
					content.style.width = 'calc(100% - 40px)';
				}
			});

			const questions = document.querySelectorAll(
			 '.lp_custom_help_bubble_button_container'
			);
			Array.from(questions)?.forEach((question) => {
				if (question instanceof HTMLElement) {
					question.style.display = 'flex';
				}
			});
		};
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked, placeholder } = e.target;
		if (name === 'allSelect') {
			setUserEditColumns(myColumns);

			let tempColumns = editColumns.map((user: any) => {
				return { ...user, isChecked: checked };
			});
			setEditColumns(tempColumns);
		} else {
			if (checked) {
				setUserEditColumns(
				 [...userEditColumns, placeholder].sort((a, b) => {
					 return myColumns.indexOf(a) - myColumns.indexOf(b);
				 })
				);

				setArranges([...arranges, placeholder]);

				setSelectedItem(placeholder);
			} else {
				setUserEditColumns(
				 userEditColumns.filter((item: any) => item !== placeholder)
				);

				setArranges(arranges.filter((item) => item !== placeholder));
				setSelectedItem('');
			}
			let tempColumns = editColumns.map((column: any) =>
			 column.name === name ? { ...column, isChecked: checked } : column
			);
			setEditColumns(tempColumns);
		}
		if (!checked && name === 'allSelect') {
			setUserEditColumns([]);
			setArranges([]);
		}
	};

	const moveItemDown = () => {
		if (arranges.indexOf(selectedItem) < arranges.length - 1) {
			const updatedArray = [...arranges];

			const item = updatedArray.splice(arranges.indexOf(selectedItem), 1)[0];
			updatedArray.splice(arranges.indexOf(selectedItem) + 1, 0, item);
			localStorage.removeItem('salesSize');
			setArranges(updatedArray);
		}
	};

	const moveItemUp = () => {
		if (arranges.indexOf(selectedItem) > 0) {
			const updatedArray = [...arranges];

			const item = updatedArray.splice(arranges.indexOf(selectedItem), 1)[0];
			updatedArray.splice(arranges.indexOf(selectedItem) - 1, 0, item);
			localStorage.removeItem('salesSize');
			setArranges(updatedArray);
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
			 settingsRef.current &&
			 !settingsRef.current.contains(event.target as Node)
			) {
				handleChangeModal();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [settingsRef, handleChangeModal]);

	return (
	 <>
		 <div className={styles.tableSettings__overlay}>
			 <div ref={settingsRef} className={styles.modal}>
				 <h1 className={styles.modal__title}>Columns</h1>
				 <div className={styles.modal__wrapper}>
					 <div className={styles.modal__viewColumns}>
						 <h3 className={styles.columns__title}>Available Columns</h3>
						 <div className={styles.modal__viewColumns_wrapper}>
							 {editColumns?.map((column: IColumnData, index: number) => (
							  <div className={styles.viewColumnItem}>
								  <input
								   type="checkbox"
								   className={styles.myCheckbox}
								   id={column.value}
								   name={column.name}
								   checked={column?.isChecked || false}
								   onChange={handleChange}
								   placeholder={column.value}
								  />
								  <label htmlFor={column.value}>
									  {column.name === 'Cogs' ? 'COGS' : column.name}
								  </label>
							  </div>
							 ))}
						 </div>
					 </div>
					 {arranges.length > 0 && (
					  <div className={styles.arrange__block}>
						  <h3 className={styles.columns__title}>Arrange</h3>
						  <div className={styles.arrange__items}>
							  <div className={styles.arrange__wrapper}>
								  {arranges?.map((item, index) => (
								   <span
									key={index}
									className={
										arranges.indexOf(selectedItem) === index
										 ? styles.activeSpan
										 : undefined
									}
									onClick={() => setSelectedItem(item)}
								   >
                        {item === 'cogs'
						 ? 'COGS'
						 : item === 'sku'
						  ? 'SKU'
						  : formatString(item)}
                      </span>
								  ))}
							  </div>
							  <div className={styles.arrange__directions}>
                    <span
					 style={{
						 opacity:
						  arranges.indexOf(selectedItem) === 0
						   ? '0.4'
						   : undefined,
						 cursor:
						  arranges.indexOf(selectedItem) === 0
						   ? 'not-allowed'
						   : undefined,
					 }}
					 onClick={moveItemUp}
					>
                      <SVGArrow />
                    </span>
								  <span
								   style={{
									   opacity:
										arranges.indexOf(selectedItem) === arranges.length - 1
										 ? '0.4'
										 : undefined,
									   cursor:
										arranges.indexOf(selectedItem) === arranges.length - 1
										 ? 'not-allowed'
										 : undefined,
								   }}
								   onClick={moveItemDown}
								  >
                      <SVGArrow />
                    </span>
							  </div>
						  </div>
					  </div>
					 )}
				 </div>
				 <div className={styles.tableSettingFooter}>
					 <div className={styles.modal__btn__block}>
						 <div>
							 <Button modifier={ModifierType.Primary} handleClick={() => handleEditColumns(arranges)}>Save</Button>
							 <Button
							  style={{
								  border:'1px solid #173593',
								  color:'#173593'
							  }}
							  modifier={ModifierType.Transparent}
							  handleClick={handleChangeModal}
							 >Cancel</Button>
						 </div>
					 </div>
				 </div>
			 </div>
		 </div>
		 <footer className="site-footer-copyright-area"></footer>
	 </>
	);
};

export default TableEditColumn;
