import {useEffect, useRef, useState} from 'react';
import getToken from "../../../../../../utils/getToken";
import UserConfigs from "@services/UserConfigs";
import {settingsAccountModalCheckers} from "@assets/constants/settingsAccountModalCheckers"
import {toast} from "react-toastify";

interface SettingsAccountModalLogic {
	handleToggleSettingsAccountModal:() => void;
	subAccount:any;
}

export const SettingsAccountModalLogic = (props:SettingsAccountModalLogic)=>{
	const {handleToggleSettingsAccountModal,subAccount}  =props;

	const token:any = getToken() ;

	const settingsAccountModalRef = useRef<HTMLDivElement | null>(null) ;

	const initialCheckedState = settingsAccountModalCheckers.reduce((acc, checker) => {
		acc[checker.value] = false;
		return acc;
	}, {} as { [key: string]: boolean });

	const [checkedState, setCheckedState] = useState(initialCheckedState);
	const [isLoading,setIsLoading] = useState(true)

	const handleCheckboxChange = (value: any) => {

		setCheckedState((prevState) => ({
			...prevState,
			[value]: !prevState[value],
		}));

	};

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
			 settingsAccountModalRef.current &&
			 !settingsAccountModalRef.current?.contains(event.target as Node)) {
				handleToggleSettingsAccountModal();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [settingsAccountModalRef, handleToggleSettingsAccountModal]);


	useEffect(() => {
		const getSubAccounts = async () => {
			try {
				const filter_by = {id:subAccount?.id} ;
				const response:any = await UserConfigs.sub_accounts_list(filter_by, token) ;
				if(response?.data) {
					setIsLoading(false)
					const metadata = response.data[0]?.metadata || {} ;
					const {lp_subaccount_settings} = metadata || {} ;
					if(lp_subaccount_settings) {
						setCheckedState(lp_subaccount_settings)
					}
				}

			} catch(error:any)  {
				console.error(error,'error')
			}

		}
		getSubAccounts()
	}, []);

	const handleSettingsSubAccount = async (lp_subaccount_settings:any) => {
		if (!subAccount) return;
		setIsLoading(true)
		try {
			const filter_by = {user_id:subAccount?.id} ;
			const payload = {lp_subaccount_settings}
			const response:any = await UserConfigs.sub_accounts_update(token, filter_by, payload) ;

			setTimeout(() => {
				handleToggleSettingsAccountModal();
				toast.success('Settings saved successfully', {
					autoClose: 2000, // Set the duration for auto close
					transitionDuration: 500, // Set the transition duration for this specific notification
					position: 'bottom-right'
				} as any); // Type cast the options object
			}, 4000);
		} catch(error) {
			console.log(error,'error')
		}
	}




	return {
		settingsAccountModalRef,
		settingsAccountModalCheckers,
		isLoading,
		checkedState,
		handleCheckboxChange,
		handleSettingsSubAccount
	}
}