import React from 'react';

import styles from './DownloadCSV.module.scss'
import bdecode from "../../../../utils/bdecode";

import {toast} from "react-toastify";
import OrdersService from "@services/Orders";
import Papa from "papaparse";
import {saveAs} from "file-saver";
import moment from "moment/moment";
import _ from "lodash";
import {CsvNavigationSVG} from "@assets/icons";

const DownloadCSV = (props: any) => {

    const { filter_by, options, token, globalCustomMarketplaces,download_csv_percentage,set_download_csv_percentage } = props;

    const handleDownloadCSVButtonClick = async () => {
        if(download_csv_percentage) return ;

        toast.warning(`The CSV file is being prepared for download. Please do not refresh the page.`, {
            position: 'bottom-right',
            autoClose: 3000
        });

        try {


            // Fetch all orders
            const orders_list_all_response : any = await OrdersService.list_all({ parallel_threads: 3 }, filter_by, { offset: 0, limit: 100 }, options, null, token, (progress: number) => {

                if(progress === 100){
                    toast.success(`The CSV file has been prepared and is now ready for download.`, {
                        position: 'bottom-right',
                        autoClose: 2000
                    });
                }
                // TODO add error handlers for failures
                if(progress === 100) progress = 0;
                set_download_csv_percentage(progress);

            });
            // console.log('orders_list_all_response', orders_list_all_response);


            // Init records[]
            const records : any = [];

            // Loop through all orders
            orders_list_all_response.forEach((order : any) => {

                // Get order listings and type
                const order_listings = _.get(order, 'listings') || [];
                const order_type = order_listings.length > 1 ? 'multi' : 'single';

                // Handle certain properties
                const shipping_status = _.get(order, 'shipping_status') || '';

                const sales_tax = _.get(order, 'sales_tax') || 0;
                let total_price = 0;
                if(shipping_status !== 'canceled') total_price = _.round( (_.get(order, 'total_price') || 0), 2);
                const order_fees = _.get(order, 'fees') || 0;
                const order_date_sold = moment(_.get(order, 'date_sold')).format('MM/DD/YYYY') || '';
                const order_shipping_cost_seller = _.get(order, 'shipping_cost_seller') || 0;
                const order_shipping_cost_buyer = _.get(order, 'shipping_cost_buyer') || 0;
                const order_shop_id = _.get(order, 'shop_id') || '';

                // TODO move this to the 1 component later so all computations to be performed there.
                // Handle COGS field
                let order_computed_cogs = 0;
                order_listings.forEach((order_listing : any) => {
                    const order_listing_cogs = _.get(order_listing, 'cogs') || 0;
                    order_computed_cogs += order_listing_cogs;
                });

                // Handle profit
                let order_profit = 0;
                if(shipping_status !== 'canceled') order_profit = _.round( (total_price - order_fees - sales_tax - order_computed_cogs - order_shipping_cost_seller), 2);

                // Loop through order listings
                order_listings.forEach((order_listing : any, order_listing_index : number) => {

                    // Get order listing catalog metadata
                    const order_listing_catalog_metadata = _.get(order_listing, 'catalog_metadata');

                    // Get additional properties
                    const order_listing_sku = _.get(order_listing, 'sku') || '';
                    const order_listing_cogs = _.get(order_listing, 'cogs') || 0;
                    const order_listing_quantity_sold = _.get(order_listing, 'quantity_sold') || '';

                    // Handle custom marketplace mappings
                    // TODO @Murad, @Etibar, please, revisit this component, it's much more complicated than it should be.
                    let order_marketplace = _.get(order, 'marketplace');
                    if(order_marketplace?.includes('891')){
                        const matchingMarket = globalCustomMarketplaces.find(
                            (customMarket: any) => customMarket?.platform?.code[0] === order_marketplace
                        );
                        order_marketplace = matchingMarket?.platform?.title || order_marketplace;
                    }


                    // Format records
                    const record = {
                        'Order ID': _.get(order, 'id'),
                        'Title': bdecode( _.get(order_listing, 'title') || ''),
                        'Sold Date': order_date_sold,
                        'Order Type': order_type,
                        'Sold On': order_marketplace,
                        'Shop': order_shop_id,
                        'Mkt Order ID': _.get(order, 'order_id'),
                        'Brand': bdecode(_.get(order_listing_catalog_metadata, '206') || ''),
                        'Color': bdecode(_.get(order_listing_catalog_metadata, '209') || ''),
                        'Size': bdecode(_.get(order_listing_catalog_metadata, '213') || ''),
                        'Material': bdecode(_.get(order_listing_catalog_metadata, '82') || ''),
                        'SKU': order_listing_sku,
                        'UPC': _.get(order_listing_catalog_metadata, '704') || '',
                        'Quantity Sold': order_listing_quantity_sold,
                        'Quantity Available': _.get(order_listing_catalog_metadata, '851') || '',
                        'Notes': bdecode(_.get(order_listing_catalog_metadata, '726') || ''),
                        'Shipping Status': order_type === 'multi' ? '' : shipping_status,
                        'Sold Price': order_type === 'multi' ? '' : total_price,
                        'Shipping Cost Seller': order_type === 'multi' ? '' : order_shipping_cost_seller,
                        'Shipping Cost Buyer': order_type === 'multi' ? '' : order_shipping_cost_buyer,
                        'Cost of Goods': order_type === 'multi' ? '' : order_listing_cogs,
                        'Taxes': order_type === 'multi' ? '' : sales_tax,
                        'Fees': order_type === 'multi' ? '' : order_fees,
                        'Profit': order_type === 'multi' ? '' : order_profit
                    };



                    // Add header row, order_listing_index should be 0, in opposite case it will duplicate the row.
                    if(order_type === 'multi' && order_listing_index === 0){
                        const header_record = _.mapValues(record, (value : any, key : any) => {
                            if(key === 'Title') return 'Multi-item Order';
                            if(key === 'Shipping Status') return shipping_status;
                            if(key === 'Sold Date') return order_date_sold;
                            if(key === 'Sold Price') return total_price;
                            if(key === 'Shipping Cost Seller') return order_shipping_cost_seller;
                            if(key === 'Shipping Cost Buyer') return order_shipping_cost_buyer;
                            if(key === 'Taxes') return sales_tax;
                            if(key === 'Fees') return order_fees;
                            if(key === 'Sold On') return order_marketplace;
                            if(key === 'Order Type') return order_type;
                            if(key === 'Cost of Goods') return order_computed_cogs;
                            if(key === 'Profit') return order_profit;

                            return null; // Default
                        });
                        records.push(header_record);
                    }
                    records.push(record);


                });

            });




            const file_name = `ListPerfectly__Sales__${moment().format('ddd_MMM_DD_YYYY__HH_mm_ss')}.csv`;

            const csv = Papa.unparse(records);

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, file_name);


        } catch (error) {
            console.error(error);
        }

    };


    const DownloadCSVButton = () => {
        return (
         <div className={`${styles.csvBtn} ${download_csv_percentage ? styles.csvBtnDisabled : null}`} onClick={handleDownloadCSVButtonClick}>
             <CsvNavigationSVG className="settingsIcon"/>
         </div>
        );
    };

    return (<DownloadCSVButton/>);
};

export default DownloadCSV;
