import styles from './Customizations.module.scss';
import Tippy from "@tippyjs/react";
import {useEffect, useRef, useState} from "react";
import {CopyIcon, PopoverIcon} from "../../../../assets/icons";
import {TextareaWithCount} from "../../TextAreaCount";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import getNameGenerator from "../../../../hooks/useNameGenerator";
import {InputCount} from "../../InputCount";
import Select from "react-select";
import Button, {ModifierType} from "../../../UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

export const CustomizationsComponent = ({afterCreate, setDisableSaveButtons, editId, measureBothItems,setMeasureBothItems,  measureItems, setMeasureItems, bulletItems, setBulletItems,  optionsAssignee, selectedOptionAssignee, setSelectedOptionAssignee, assigneeId, setFormInfo, formInfo, unescapedRowMetadata, listInfo, itemData, setItemData, editIndex, handleStandartInputChange }:any) => {
    const { bdecode } = useBasisFuncs();
    const isSmallScreenLaptop = UseIsSmallLaptop()
    const textareaRef = useRef<HTMLTextAreaElement>(null);


    const [showAssignee, setShowAssignee] = useState(true);
    const [isEmojiActive, setIsEmojiActive] = useState(false);

    const customStylesAssignee = {
        control: (provided: any) => ({
            ...provided,
            width: '100%',
            cursor: 'pointer',
            minHeight: !isSmallScreenLaptop ? '39px' : '32px',
            height: !isSmallScreenLaptop ? '39px' : '32px',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: !isSmallScreenLaptop ? '39px' : '32px',
            padding:  !isSmallScreenLaptop ? '0 10px' : '0 8px'
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: !isSmallScreenLaptop ? '39px' : '32px',
        }),

        menu: (provided: any) => ({
            ...provided,
            zIndex: 9991
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: isSmallScreenLaptop ? '13px' : '16px',
        }),
    };

    const toggleBulletItems = (id: number, value: any) => {
        setDisableSaveButtons(false);
        let updatedItems: any = [...itemData];

        const generatedName = getNameGenerator('input_44');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName]: value,
            changed: true,
        };
        console.log(updatedItems, 'updateItems')
        setItemData(updatedItems);
        setBulletItems((prevItems:any) => {
            const updatedItems = prevItems.map((item:any) => ({
                ...item,
                active: item.id === id && !item.active,
            }));

            const clickedItem = updatedItems.find((item:any) => item.id === id);
            if (clickedItem && clickedItem.active) {
                return updatedItems;
            }
            return updatedItems.map((item:any) => ({
                ...item,
                active: item.id === id,
            }));
        });
    };
    const toggleMeasureItems = (id: number, value:any) => {
        setDisableSaveButtons(false);
        let updatedItems: any = [...itemData];
        const generatedName = getNameGenerator('input_54');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName]: value,
            changed: true,
        };
        setItemData(updatedItems);
        setMeasureItems((prevItems:any) => {
            const updatedItems = prevItems.map((item:any) => ({
                ...item,
                active: item.id === id && !item.active,
            }));

            const clickedItem = updatedItems.find((item:any) => item.id === id);
            if (clickedItem && clickedItem.active) {
                return updatedItems;
            }
            return updatedItems.map((item:any) => ({
                ...item,
                active: item.id === id,
            }));
        });
    };
    const toggleMeasureBothItems = (id: number, value:any, active:any) => {
        setDisableSaveButtons(false);
        let updatedItems: any = [...itemData];
        const generatedName = getNameGenerator('input_723.1');
        updatedItems[editIndex] = {
            ...updatedItems[editIndex],
            [generatedName]: !active ? 'in-cm' : null,
            changed: true,
        };
        setItemData(updatedItems);

        setMeasureBothItems((prevItems:any) =>
            prevItems.map((item:any) => ({
                ...item,
                active: item.id === id ? !item.active : false,
            }))
        );
    };
    function formatText(inputText: string) {
        // Replace HTML entities with their corresponding characters
        let formattedText = inputText?.replace(/&#\d+;/g, (match) =>
            String.fromCharCode(parseInt(match.substring(2, match.length - 1)))
        );
        // Remove HTML tags and preserve line breaks
        formattedText = formattedText?.replace(/<[^>]+>/g, '');
        // Replace multiple spaces with a single space
        formattedText = formattedText?.replace(/ +/g, ' ');
        // Replace leading and trailing whitespace
        formattedText = formattedText?.trim();
        return formattedText;
    }
    const handleCopyClick = () => {
        if (textareaRef.current) {
            textareaRef.current.select(); // Select the textarea's content
            document.execCommand('copy'); // Copy the selected text to the clipboard
        }
    };

    const handleSelectChangeAssignee = (selectedOption: any) => {
        setSelectedOptionAssignee(selectedOption);
        const updatedItem = [...itemData];
        updatedItem[editIndex] = {
            ...updatedItem[editIndex],
            ['newAssignee']: selectedOption?.id,
            changed: true,
        };
        setItemData(updatedItem)
        setFormInfo({
            ...formInfo,
            ['newAssignee']: { text: selectedOption?.id, changed: true },
        });
    };

    useEffect(() => {
        if(assigneeId){
            const foundItem:any = optionsAssignee.find((item:any) => item?.id?.toString() === assigneeId?.toString());
            if(foundItem){
                setSelectedOptionAssignee(foundItem);
                const updatedItem = [...itemData];
                updatedItem[editIndex] = {
                    ...updatedItem[editIndex],
                    ['newAssignee']: foundItem?.value,
                    changed: true,
                };
                setItemData(updatedItem)
                setFormInfo({
                    ...formInfo,
                    ['newAssignee']: { text: foundItem?.value, changed: true },
                });
            }
        }
    }, [assigneeId]);

    useEffect(() => {
        const isEmojiActiveLocal:any = bulletItems.find((item:any) => item.value === 'emoji' && item.active);
        setIsEmojiActive(isEmojiActiveLocal)
    }, [bulletItems]);


    return(
        <div>
            <div className={styles.accordionContent}>
                <div className={styles.accordionContent5}>
                    <p className={styles.heading}>
                        Bullet Style
                        <Tippy
                            interactive={true}
                            zIndex={5}
                            trigger="click"
                            arrow={false}
                            content={
                                <div
                                    className={`popover ${styles.popoverContent}`}
                                >
                                    <ul>
                                        <li>
                                    <span className={styles.heading}>
                                      Normal Bullet –
                                    </span>{' '}
                                            By default normal bullet will display
                                            standard bullet points for the line details.
                                        </li>
                                        <li>
                                            {' '}
                                            <span className={styles.heading}>
                                      Emoji –
                                    </span>{' '}
                                            Allows for a custom emoji to be added. See
                                            screenshot. Please note that some
                                            marketplaces do not allow emojis to be added
                                            to the description field. In this case they
                                            may be removed from your description when
                                            copying to those marketplaces.
                                        </li>
                                        <li>
                                    <span className={styles.heading}>
                                      No Bullets –
                                    </span>{' '}
                                            Display the list with NO bullets at all. The
                                            listed details will simply be indented
                                        </li>
                                        <div className={styles.line}></div>

                                        <p>
                                            ♦ Pro{' '}
                                            <a
                                                href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                                target="_blank"
                                            >
                                                {' '}
                                                Description Builder Settings
                                            </a>
                                        </p>
                                    </ul>
                                </div>
                            }
                        >
                            <PopoverIcon
                                className={styles.popoverIcon}
                                color={'#fff'}
                            ></PopoverIcon>
                        </Tippy>
                    </p>
                    <div className={styles.bulletsContainer}>
                        <div className={styles.menuItems}>
                            {bulletItems.map((item: any) => (
                                <div
                                    data-name="input_44"
                                    className={
                                        item.active ? styles.itemActive : styles.item
                                    }
                                    key={item.id}
                                    onClick={() => toggleBulletItems(item.id, item.value)}
                                >
                                    <span>{item.text}</span>
                                </div>
                            ))}
                        </div>
                        {
                            isEmojiActive &&
                            <div className={styles.emojiInput}>
                                <InputCount
                                    placeholder="Emoji"
                                    name="input_45"
                                    value={
                                        formInfo.newEmoji.changed
                                            ? bdecode(formInfo.newEmoji.text)
                                            : unescapedRowMetadata[45]
                                                ? bdecode(unescapedRowMetadata[45])
                                                : ''
                                    }
                                    handleChange={handleStandartInputChange}
                                    limit={250}
                                    height={40}
                                    hideCount={true}
                                />
                            </div>
                        }

                    </div>
                    <div className={styles.measurementContainer}>
                        <div>
                            <p className={styles.heading}>
                                Enter Measurements in
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    This determines how the information that
                                                    has been added to the specific
                                                    measurements field will display. Inches,
                                                    centimeters, or both. List Perfectly
                                                    will auto convert any details added to
                                                    CM
                                                </li>
                                                <li>
                                                    If set to Inches, measurements will be
                                                    converted into cm with both displayed.
                                                </li>
                                                <li>
                                                    If set to centimeters, measurements will
                                                    be converted into inches with both
                                                    displayed.
                                                </li>
                                                <div className={styles.line}></div>

                                                <p>
                                                    ♦ Pro{' '}
                                                    <a
                                                        href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        Description Builder Settings
                                                    </a>
                                                </p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <div className={styles.menuItems}>
                                {measureItems.map((item: any) => (
                                    <div
                                        data-name="input_54"
                                        className={
                                            item.active
                                                ? styles.itemActive
                                                : styles.item
                                        }
                                        key={item.id}
                                        onClick={() => toggleMeasureItems(item.id, item.value)}
                                    >
                                        <span>{item.text}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <p className={styles.heading}>
                                Display Both Inches and Centimeters?
                            </p>
                            <div className={styles.menuItems}>
                                {measureBothItems.map((item: any) => (
                                    <div
                                        data-name="input_723.1"
                                        className={
                                            item.active
                                                ? styles.itemActive
                                                : styles.item
                                        }
                                        key={item.id}
                                        onClick={() =>
                                            toggleMeasureBothItems(item.id, item.value, item.active)
                                        }
                                    >
                                        <span>{item.text}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {
                        showAssignee && (
                            <div className={styles.selectionAssignee}>
                                <p className={styles.heading}>
                                    Product Assignee
                                </p>

                                <div className={styles.selectInput}>
                                    <Select
                                        name="input_883"
                                        inputId="input_883"
                                        value={selectedOptionAssignee}
                                        onChange={handleSelectChangeAssignee}
                                        options={optionsAssignee}
                                        styles={customStylesAssignee}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        (editId && !afterCreate) ? <>
                            <p className={styles.heading}>
                                Description Preview
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <p>
                                                    Preview to show what your description will
                                                    look like once built. This window is not
                                                    editable, please make your changes in the
                                                    fields above and click save.
                                                </p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <div className={styles.textareaContainer}>
                                <Button modifier={ModifierType.Secondary} handleClick={handleCopyClick}>
                                    <CopyIcon/>
                                    <span>Copy Description</span>
                                </Button>
                            </div>
                            <TextareaWithCount
                                textareaRef={textareaRef}
                                placeholder=""
                                name="input_854"
                                value={
                                    formInfo?.copyDesc?.changed ? bdecode(formatText(formInfo.copyDesc.text)) :
                                        (unescapedRowMetadata[854] && formatText(unescapedRowMetadata[854]) !== 'undefined' ) ?
                                        bdecode(formatText(unescapedRowMetadata[854])) : ''
                                }
                                limit={500}
                                height={150}
                                handleChange={handleStandartInputChange}
                                verticallyResized={true}
                            /> </> : <></>
                    }
                </div>
            </div>
        </div>
    )
}