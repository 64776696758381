import styles from './ExpressHeader.module.scss';

import {ListingSvg, NewListingLister, Print, ProgressSaved, SavingIcon} from "@assets/icons";

import React from "react";
import {useNavigate} from "react-router";
import TimeAgo from 'react-timeago';
import Tippy from "@tippyjs/react";
import Button, {ModifierType} from "../../../UI/Button";

const ExpressHeader = ({openPdfModal, isRoleAllowed, hasPro, createdListingId, createActive, navigation, handleAddNewListing, expressListerSaved,expressListerCreated, setServerTime, serverTime,  setAddNewChanger, hasBetaRole}:any) => {
    const navigate = useNavigate();

    const leftMenuExpanded = Boolean(localStorage.getItem('leftMenuExpanded'));


    return (
        <div className={styles.expressHeader}
             style={navigation && leftMenuExpanded ? {width: 'calc(100% - 210px)'} : {width: 'calc(100% - 80px)'}}
        >
            <div className={styles.headerLeftContainer}>
                <div className={styles.editSubmitButtons}>

                    <Button
                     modifier={ModifierType.Primary}
                     handleClick={() => {
                        if (createActive) {
                            setAddNewChanger((prevState: boolean) => !prevState);
                            setServerTime(null);
                            handleAddNewListing();
                        }
                    }}
                     disabled={!createActive}
                    >
                        <NewListingLister/>
                        New Listing
                    </Button>
                    <Button modifier={ModifierType.Primary} handleClick={() => {
                        if (createActive) {
                            navigate('/listings')
                        }
                    }}
                            disabled={!createActive}
                    >
                        <ListingSvg style={{color:"#ffffffb3"}}/>
                        My Listings
                    </Button>
                    {
                        (hasPro && isRoleAllowed) ?
                           <>
                               {
                                   hasBetaRole ?
                                       <Button
                                           modifier={ModifierType.Primary}
                                           disabled={!createdListingId || !createActive}
                                           handleClick={() => {
                                               if (createdListingId && createActive) {
                                                   openPdfModal();
                                               }
                                           }}
                                       >
                                           <Print/>
                                           Inventory Label
                                       </Button> :
                                       <Button
                                           modifier={ModifierType.Primary}
                                           disabled={!createdListingId || !createActive}
                                           handleClick={() => {
                                               if (createdListingId && createActive) {
                                                   window.open(`${origin}/print-qr-page/${createdListingId}`);
                                               }
                                           }}
                                       >
                                           <Print/>
                                           Inventory Label
                                       </Button>
                               }
                           </>

                         :
                         <Tippy
                          interactive={true}
                          arrow={false}
                          zIndex={9999}
                          trigger="click"
                          content={
                              <div className={`popover ${styles.popoverContent}`}>
                                  <p>
                                      Available for Pro Plus users. Upgrade to access
                                      this feature and many more.
                                      <a target="_blank"
                                         href={'https://listperfectly.com/pricing'}> Upgrade
                                          Now </a>
                                  </p>

                              </div>
                          }
                         >
                             <div>
                                 <Button modifier={ModifierType.Primary} disabled={true}>
                                     <Print/>
                                     Inventory Label
                                 </Button>
                             </div>
                         </Tippy>

                    }
                    {
                     (serverTime && !expressListerSaved) &&
                     <>
                         <div className={styles.progressSavedContainer}>
                             <ProgressSaved/>
                             <div style={{color: '#767676', fontWeight: '600'}}>Progress Saved</div>
                             <TimeAgo className={styles.timeAgo} date={serverTime}/>
                         </div>
                     </>
                    }
                    {expressListerCreated &&
                     <div style={{color: '#767676', fontWeight: '600'}}><SavingIcon/> Creating listing...</div>}
                    {expressListerSaved &&
                     <div style={{color: '#767676', fontWeight: '600'}}><SavingIcon/> Saving...</div>}
                </div>
            </div>

        </div>
    );
};

export default ExpressHeader;