import _ from "lodash";

// Import config
import config from "../config";

// Import API client
import LP_API_Client from './LP_API_Client';


class UserConfigs {

    get(type: string, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/user-configs/get`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            _.set(params, 'data.type', type);

            // Return result
            LP_API_Client(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    website_update(filter_by: any, payload: any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'PUT',
                url: `${config.base_url}/v1/user-configs/website/update`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(payload) _.set(params, 'data.data', payload);


            // Return result
            LP_API_Client(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    extension_update(filter_by: any, payload: any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'PUT',
                url: `${config.base_url}/v1/user-configs/extension/update`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(payload) _.set(params, 'data.data', payload);


            // Return result
            LP_API_Client(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    sub_accounts_list(filter_by:any={}, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/users/sub-accounts/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            if (filter_by) _.set(params, 'data.filter_by', filter_by);

            // Return result
            LP_API_Client(params)
            .then((res : any) => {
                const data : any = _.get(res, 'data');
                return resolve(data);
            })
            .catch((err : any) => {
                return reject(err);
            });

        });

    }

    sub_accounts_delete( token : string, filter_by:any, options:any){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'DELETE',
                url: `${config.base_url}/v1/users/sub-accounts/delete`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            // _.set(params, 'data.type', type);
            if (filter_by) _.set(params, 'data.filter_by', filter_by);
            if (options) _.set(params, 'data.options', options);
            // Return result
            LP_API_Client(params)
            .then((res : any) => {
                const data : any = _.get(res, 'data');
                return resolve(data);
            })
            .catch((err : any) => {
                return reject(err);
            });

        });

    }

    sub_accounts_update(token : string, filter_by:any, payload:any){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'PUT',
                url: `${config.base_url}/v1/users/sub-accounts/settings/update`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            // _.set(params, 'data.type', type);
            if (filter_by) _.set(params, 'data.filter_by', filter_by);
            if (payload) _.set(params, 'data.payload', payload);
            // Return result
            LP_API_Client(params)
            .then((res : any) => {
                const data : any = _.get(res, 'data');
                return resolve(data);
            })
            .catch((err : any) => {
                return reject(err);
            });

        });

    }

    sub_accounts_add(token : string,payload:any, options:any){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v1/users/sub-accounts/add`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            // _.set(params, 'data.type', type);
            if (payload) _.set(params, 'data.payload', payload);
            if (options) _.set(params, 'data.options', options);
            // Return result
            LP_API_Client(params)
            .then((res : any) => {
                const data : any = _.get(res, 'data');
                return resolve(data);
            })
            .catch((err : any) => {
                return reject(err);
            });

        });

    }


}

export default new UserConfigs();
