import React, {useState} from "react";
import useBasicFuncs from "@hooks/useBasisFuncs";
import GlobalFooters from "./GlobalFooters";
import styles from "./ItemDetails.module.scss";
import Button, {ModifierType} from "../../../Button";
import {CopyIcon} from "@assets/icons";

const ItemDetails = ({hasSimple,productInfo,spanKeywords, hasBusiness, hasPro, listViewId, globalFooters}:any) =>{
    const { bdecode } = useBasicFuncs();
    const [copied, setCopied] = useState(0);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const descriptionKey = hasPro && productInfo?.metadata[854] ? 854 : 505;
    const metadataFields = [
        { key: 206, label: "Brand"},
        { key: 213, label: "Size" },
        { key: 209, label: "Color" },
        { key: 82, label: "Material" },
        { key: 4, label: "Intended For" },
        { key: 205, label: "Style" },
    ];
    const priceFields = [
        { key: 847, label: "COGS", format: true },
        { key: 104, label: "MSRP", format: true },
        { key: 704, label: "UPC" }
    ]

    const handleCopyContent = (input:any,  val:any) => {
        try {
            let textToCopy = '';

            if (Array.isArray(input)) {
                // If input is an array, join it with commas
                textToCopy = input.join(', ');
            } else if (/<[a-z][\s\S]*>/i.test(input)) {
                // If input contains HTML, extract plain text
                const tempElement = document.createElement('div');
                tempElement.innerHTML = input;
                textToCopy = tempElement.textContent || tempElement.innerText || '';
            } else {
                // Otherwise, treat input as plain text
                textToCopy = input;
            }

            // Copy the processed text to the clipboard
            if (textToCopy) {
                navigator.clipboard.writeText(textToCopy);
                if(val === 'keywords'){
                    setCopied(1);
                }else{
                    setCopied(2)
                }
                setTimeout(() => {
                    setCopied(0);
                }, 2000);
            }
        } catch (error) {
            console.error('Error copying content:', error);
        }
    };

    function hasText(html:any) {
      try{
          // Strip HTML tags and trim to check for meaningful text
          const stripped = html.replace(/<\/?[^>]+(>|$)/g, '').trim();
          return stripped.length > 0;
      }catch(e){
          console.log(e, 'error')
      }
    }


    return(
        <div className={styles.detailsContainerMain}>
            {
                !hasSimple && <div className={styles.itemDetails}>
                    <div className={styles.fieldTitle}>Item Details</div>
                    <div className={styles.itemCondition}>
                        <div className={styles.itemDetailsInfo}>
                            {metadataFields.map(({key, label}) => {
                                const value = productInfo?.metadata[key];
                                if (!value) return null;

                                return (
                                    <div key={key} className={styles.objectNameValueDiv}>
                                        <div className={styles.itemDetailsObjectKeyName}>{label}</div>
                                        <div className={styles.itemDetailsObjectValue}>
                                            {bdecode(value)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className={styles.itemDetailsInfo}>
                            {priceFields.map(({key, label, format}) => {
                                const value = productInfo?.metadata[key];
                                if (!value) return null;

                                return (
                                    <div key={key} className={styles.objectNameValueDiv}>
                                        <div className={styles.itemDetailsObjectKeyName}>{label}</div>
                                        <div className={styles.itemDetailsObjectValue}>
                                            {format ? formatter.format(value) : value}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div className={`${styles.conditionsContainer}`}>

                            {productInfo?.metadata[15] ? (
                                <div className={styles.objectNameValueDiv}>
                                    <div className={styles.itemDetailsObjectKeyName}>
                                        Condition
                                    </div>
                                    <div className={styles.itemDetailsObjectValue}>
                                        {bdecode(productInfo?.metadata[15])}
                                    </div>
                                </div>
                            ) : ''
                            }
                            {productInfo?.metadata[17] ? (
                                <div>
                                    <div className={styles.itemDetailsObjectKeyName}>
                                        Condition Notes
                                    </div>
                                    <div className={styles.itemDetailsObjectValue}>
                                        {bdecode(productInfo?.metadata[17])}
                                    </div>
                                </div>
                            ) : ''}


                        </div>
                        <div className={`${styles.itemDetailsInfo} ${styles.keywordsContainer}`}>
                            {productInfo?.metadata[453] ? (
                                <div>
                                    <div className={`${styles.itemDetailsObjectKeyName} ${styles.itemDetailsKeywords}`}>
                                        <p>Keywords/Tags</p>
                                        {
                                            spanKeywords?.length ? <>
                                                <Button modifier={ModifierType.Secondary} handleClick={()=> handleCopyContent(spanKeywords, 'keywords')}>
                                                    <CopyIcon/>
                                                    <span>Copy</span>
                                                </Button>
                                                {
                                                    copied === 1 && <p className={styles.copiedText}>
                                                        Copied!
                                                    </p>
                                                }
                                            </> : <></>
                                        }
                                    </div>
                                    <div className={styles.keywordDiv}>
                                        <ul className={styles.keywordsWrapper}>
                                            {spanKeywords?.map((item: any, index: number) => (
                                                <li key={index}>
                                                    <span>{item ? bdecode(item).trim() : ''}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                    </div>

                </div>
            }
            <div className={styles.shipDescription}>
                {
                    (!hasSimple && !hasBusiness) && <>
                        <div className={styles.fieldTitle}>Shipping</div>
                        <div className={styles.shipping}>

                            {
                                productInfo?.metadata[837] ? (
                                    <div className={styles.objectNameValueDiv}>
                                        <div className={styles.shippingObjectKeyName}>
                                            Shipping Zip Code
                                        </div>
                                        <div className={styles.shippingObjectValue}>
                                            {bdecode(productInfo?.metadata[837])}
                                        </div>
                                    </div>
                                ) : ''
                            }
                            {
                                productInfo?.metadata[2] || productInfo?.metadata[3] ? (
                                    <div className={styles.objectNameValueDiv}>
                                        <div className={styles.shippingObjectKeyName}>
                                            Package Weight
                                        </div>
                                        <div className={styles.packageProperties}>
                                            {productInfo?.metadata[2] ? (
                                                <div className={styles.packageObjectValue}>
                                                    {productInfo?.metadata[2]}{' '}
                                                    <span className={styles.packageMeausure}>lbs</span>
                                                </div>
                                            ) : ''}
                                            {productInfo?.metadata[3] ? (
                                                <div className={styles.packageObjectValue}>
                                                    {productInfo?.metadata[3]}{' '}
                                                    <span className={styles.packageMeausure}>oz</span>
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                ) : ''
                            }

                            {
                                productInfo?.metadata[531] || productInfo?.metadata[532] || productInfo?.metadata[533] ?
                                    (
                                        <div className={styles.objectNameValueDiv}>
                                            <div className={styles.shippingObjectKeyName}>
                                                Package Dimensions
                                            </div>
                                            <div className={styles.packageProperties}>
                                                {productInfo?.metadata[531] ? (
                                                    <div className={styles.packageObjectValue}>
                                                        {productInfo?.metadata[531]}
                                                        <span className={styles.packageMeausure}> L</span>
                                                    </div>
                                                ) : ''}
                                                {productInfo?.metadata[532] ? (
                                                    <div className={styles.packageObjectValue}>
                                                        {productInfo?.metadata[532]}
                                                        <span className={styles.packageMeausure}> W</span>
                                                    </div>
                                                ) : ''}

                                                {productInfo?.metadata[533] ? (
                                                    <div className={styles.packageObjectValue}>
                                                        {productInfo?.metadata[533]}
                                                        <span className={styles.packageMeausure}> H</span>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    ) : ''
                            }

                        </div>
                    </>
                }
                <div className={styles.description}>
                    <div className={`${styles.fieldTitle} ${styles.fieldTitleDescription}`}>
                        <p>Description</p>
                        {
                            hasText(productInfo?.metadata[descriptionKey]) ?
                                <>
                                    <Button modifier={ModifierType.Secondary} handleClick={()=> handleCopyContent(bdecode(productInfo?.metadata[descriptionKey]), 'description')}>
                                        <CopyIcon/>
                                        <span>Copy</span>
                                    </Button>
                                    {
                                        copied === 2 && <p className={styles.copiedText}>
                                            Copied!
                                        </p>
                                    }
                                    </> : <></>
                        }
                    </div>
                    {productInfo?.metadata[descriptionKey] ? (
                        <div
                            className={styles.descriptionText}
                            style={{whiteSpace: 'pre-line'}}
                            dangerouslySetInnerHTML={{__html: bdecode(productInfo?.metadata[descriptionKey])}}
                        />
                    ) : ''}
                </div>
            </div>
            <div className={styles.line}></div>
            <GlobalFooters listViewId={listViewId} hasSimple={hasSimple} hasBusiness={hasBusiness} globalFooters={globalFooters} />
        </div>
    )
}

export default ItemDetails;