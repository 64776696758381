import React, { useState, ChangeEvent } from 'react';
import styles from '../PlatformsModal.module.scss';
import { getPlatformLink } from '../../../../../../../utils/getPlatformLink';

import {EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,SoldLogo,KidizenLogo,FacebookLogo,ListingPartyLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons"

interface IplatformModalRowProps {
  item: {
    id: number;
    platform: {
      title: string;
      isChecked: boolean;
      platformId: string;
    };
    notOnPlatform: {
      title: string;
      isChecked: boolean;
    };
  };
  handleClick?: (platformName: string) => void;
  main?: boolean;
  unescapedRowMetadata: any;
  tableData?: any;
  setTableData?: (tableData: any) => void;

  index?: number;
  setChangedPatforms?: (value: any) => void;
}

interface IPlatformInputs {
  [key: string]: string;
}

const defaultPlatformIconUrl: string =
    '	https://cdn.listperfectly.com/apptest.listperfectly.com/globals/icons/shop_light.svg';

function passPlatformLogo(platformName: string) {
  const words = platformName.split(' ');

  const path = `	https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/marketplaces/${words.join(
      '_',
  )}.svg`;

  return path;
}

function PlatformModalRow(props: IplatformModalRowProps) {
  const {
    item,
    handleClick,
    main,
    unescapedRowMetadata,
    tableData,
    index,
    setTableData,
    setChangedPatforms,
  } = props;

  let initialPlatformInputs = main
      ? {}
      : {
        [`newPlatformIdBy${item.platform.title}`]:
            unescapedRowMetadata[item.platform?.platformId],
      };

  const [platformInputs, setPlatformInputs] = useState<IPlatformInputs>(
      initialPlatformInputs,
  );

  const handleChangePlatformId = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPlatformInputs({
      ...platformInputs,
      [name]: value,
    });

    if (tableData && index !== undefined) {
      const updatedItems = [...tableData];
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
        changed: true,
      };

      if (setTableData) {
        setTableData(updatedItems);
      }
    }
    if (setChangedPatforms) {
      setChangedPatforms({ ...platformInputs });
    }
  };

  const idValue = platformInputs[`newPlatformIdBy${item.platform.title}`];

  const imgSrc =
      item.platform.title === 'Craigslist' ||
      item.platform.title === 'Vinted' ||
      item.platform.title === 'GoltlRockstart'
          ? defaultPlatformIconUrl
          : passPlatformLogo(item.platform.title.toLowerCase());
  const PlatformLink: any = getPlatformLink(item.platform.title, idValue);

  return (
   <div className={styles.platformsModal__row}>
     <div className={styles.platformsModal__logo}>
       {item.platform.title !== 'SOLD' && !main ? (
        <a target="_blank" href={PlatformLink}>
          {item.platform.title === 'eBay' ? <EbayLogo/> : null}
          {item.platform.title === 'Amazon' ? <AmazonLogo/> : null}
          {item.platform.title === 'Grailed' ? <GrailedLogo/> : null}
          {item.platform.title === 'Shopify' ? <ShopifyLogo/> : null}
          {item.platform.title === 'Etsy' ? <EtsyLogo/> : null}
          {item.platform.title === 'Depop' ? <DepopLogo/> : null}
          {item.platform.title === 'Poshmark' ? <PoshmarkLogo/> : null}
          {item.platform.title === 'SOLD' ? <SoldLogo/> : null}
          {item.platform.title === 'Kidizen' ? <KidizenLogo/> : null}
          {item.platform.title === 'Facebook' ? <FacebookLogo/> : null}
          {item.platform.title === 'Listing Party' ? (
           <ListingPartyLogo/>
          ) : null}
          {item.platform.title === 'Vestiaire' ? <VestiaireLogo/> : null}
          {item.platform.title === 'Instagram' ? <InstagramLogo/> : null}
          {item.platform.title === 'Mercari' ? <MercaryLogo/> : null}
        </a>
       ) : (
        <>
          {item.platform.title === 'eBay' ? <EbayLogo/> : null}
          {item.platform.title === 'Amazon' ? <AmazonLogo/> : null}
          {item.platform.title === 'Grailed' ? <GrailedLogo/> : null}
          {item.platform.title === 'Shopify' ? <ShopifyLogo/> : null}
          {item.platform.title === 'Etsy' ? <EtsyLogo/> : null}
          {item.platform.title === 'Depop' ? <DepopLogo/> : null}
          {item.platform.title === 'Poshmark' ? <PoshmarkLogo/> : null}
          {item.platform.title === 'SOLD' ? <SoldLogo/> : null}
          {item.platform.title === 'Kidizen' ? <KidizenLogo/> : null}
          {item.platform.title === 'Facebook' ? <FacebookLogo/> : null}
          {item.platform.title === 'Listing Party' ? (
           <ListingPartyLogo/>
          ) : null}
          {item.platform.title === 'Vestiaire' ? <VestiaireLogo/> : null}
          {item.platform.title === 'Instagram' ? <InstagramLogo/> : null}
          {item.platform.title === 'Mercari' ? <MercaryLogo/> : null}
        </>
       )}
     </div>
     <div
      className={
        item.platform.isChecked
         ? `${styles.platform__modal__button} ${styles.activeClassGreen}`
         : styles.platformModal__button__green
      }
      onClick={() => {
        if (handleClick) {
          handleClick(item.platform.title);
        }
      }}
     >
       {item.platform.title}
     </div>
     {main ? (
      <div
       className={
         item.notOnPlatform.isChecked
          ? `${styles.platform__modal__button} ${styles.activeClassRed}`
          : styles.platformModal__button__red
       }
       onClick={() => {
         if (handleClick) {
           handleClick(item.notOnPlatform.title);
         }
       }}
      >
        {item.notOnPlatform.title}
      </div>
     ) : (
      item.platform.title !== 'SOLD' &&
      item.platform.title !== 'Listing Party' &&
      item.platform.title !== 'Heroine' && (
       <input
        type="text"
        className="myInput"
        name={
          item.platform.title === 'Listing Party'
           ? `newPlatformIdBy${'ListingParty'}`
           : `newPlatformIdBy${item.platform.title}`
        }
        value={platformInputs[`newPlatformIdBy${item.platform.title}`]}
        onChange={handleChangePlatformId}
       />
      )
     )}
   </div>

  );
}

export default PlatformModalRow;