import _ from 'lodash';
import axios from 'axios';

// Setup Axios client instance
const client = axios.create({ withCredentials: true });

// Add a response interceptor
client.interceptors.response.use((response) => response, (error) => {

    // Init
    const status_code = _.get(error, 'response.status');

    // If Auth error received redirect to log in page
    if([401, 403].includes(status_code)){
        window.location.href = 'https://app.listperfectly.com'; // TODO add UTM source later, consider redirecting to the subscription management page based on roles.
    }

    // TODO implement 3 retries with exponential backoff on all requests @Konstantin.

    // Return error
    return Promise.reject(error);

});

// Export client instance
export default client;
