import React, {useEffect, useRef} from 'react';
import Tippy from '@tippyjs/react';
import styles from '../../Table.module.scss';

import {EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,SoldLogo,KidizenLogo,FacebookLogo,ListingPartyLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons"

import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";
import Auth from "@services/Auth";
import useRoles from "@hooks/useRoles";

interface IplatformBlockItem {
  platformName: string;
  sync?: any;
  href: string;
  platformIcon?: any;
  isLink?: boolean;
  fromPlatform?: boolean;
  handleTooltipClickPlatforms?: any;
  tooltipsPlatformActive?: any;
  visible?: any;
  IsSold?: any;
  type?: string;
  syncIssue?:any;
  syncIssueClick?:any;
  hasTitle?:any;
  removed?:any;
  issueExist?: any;
}

const PlatformBlockItem: React.FC<IplatformBlockItem> = (props) => {
  const {
    platformName,
    sync,
    href,
    platformIcon,
    isLink,
    fromPlatform,
    handleTooltipClickPlatforms,
    tooltipsPlatformActive,
    visible,
    IsSold,
    type,
    syncIssue,
    syncIssueClick,
    hasTitle,
    removed,
    issueExist
  } = props;

  const { is_role_allowed, hasPro } = useRoles();

  useEffect(() => {
    const handleScroll = () => {
      if (handleTooltipClickPlatforms) {
        handleTooltipClickPlatforms(null); // скрывает Tippy при скролле
      }
    };

    const scrollTable:any = document.getElementById('catalogPageTable')

    scrollTable?.addEventListener('scroll', handleScroll);

    return () => {
      scrollTable?.removeEventListener('scroll', handleScroll);
    };
  }, [handleTooltipClickPlatforms]);

  const isSmallScreenLaptop = UseIsSmallLaptop();

  return (
    <div className={styles.platformItemBlock}>
      <div style={{ position: 'relative', margin: hasTitle ? '3px 0' : '0'}}>
        {isLink && !syncIssue ? (
          <div>
            <a target="_blank" href={href} style={ hasTitle ? { userSelect: 'none', display:'flex', alignItems:'center', gap:'10px' } : { userSelect: 'none' }}>
              <div
                className="socialIcon"
                style={ hasTitle ? { height: isSmallScreenLaptop ? '16px' : '20px', width: isSmallScreenLaptop ? '16px' : '20px' , minWidth:isSmallScreenLaptop ? '16px' : '20px' } : { height:isSmallScreenLaptop ? "16px" : "20px" , width: isSmallScreenLaptop ? "16px" : "20px" }}
              >
                {platformName === 'eBay' ? <EbayLogo /> : null}
                {platformName === 'Amazon' ? <AmazonLogo /> : null}
                {platformName === 'Grailed' ? <GrailedLogo /> : null}
                {platformName === 'Shopify' ? <ShopifyLogo /> : null}
                {platformName === 'Etsy' ? <EtsyLogo /> : null}
                {platformName === 'Depop' ? <DepopLogo /> : null}
                {platformName === 'Poshmark' ? <PoshmarkLogo /> : null}
                {platformName === 'SOLD' ? <SoldLogo /> : null}
                {platformName === 'Kidizen' ? <KidizenLogo /> : null}
                {platformName === 'Facebook' ? <FacebookLogo /> : null}
                {platformName === 'listing_party' ? <ListingPartyLogo /> : null}
                {platformName === 'Vestiaire' ? <VestiaireLogo /> : null}
                {platformName === 'Instagram' ? <InstagramLogo /> : null}
                {platformName === 'Mercari' ? <MercaryLogo /> : null}
              </div>
              {
                  hasTitle && <div className={styles.platformTitleWithLink}> {platformName === 'listing_party' ? 'Listing Party' : platformName}</div>
              }

            </a>
          </div>
        ) : isLink && syncIssue ? (
         <div>
           <a target="_blank" href={href} style={hasTitle ? {userSelect: 'none', position: 'relative', display:"flex", alignItems:'center', gap: '10px'}  : {userSelect: 'none', position: 'relative'}}>
             <div className={`syncIssueDot ${hasTitle ? 'syncIssueDotSmall' : ''}`} onClick={(e)=>{
               syncIssueClick();
               e.stopPropagation();
               e.preventDefault();
             }}></div>
             <div
                 className="socialIcon"
                 style={hasTitle ? {height: isSmallScreenLaptop ? "16px" : '20px', width: isSmallScreenLaptop ? "16px" : "20px", minWidth:isSmallScreenLaptop ? "16px" : "20px"} : {height: isSmallScreenLaptop ? "32px" : "40px", width: isSmallScreenLaptop ? "32px" : "40px"}}
             >
               {platformName === 'eBay' ? <EbayLogo/> : null}
               {platformName === 'Amazon' ? <AmazonLogo/> : null}
               {platformName === 'Grailed' ? <GrailedLogo/> : null}
               {platformName === 'Shopify' ? <ShopifyLogo/> : null}
               {platformName === 'Etsy' ? <EtsyLogo/> : null}
               {platformName === 'Depop' ? <DepopLogo/> : null}
               {platformName === 'Poshmark' ? <PoshmarkLogo/> : null}
               {platformName === 'SOLD' ? <SoldLogo/> : null}
               {platformName === 'Kidizen' ? <KidizenLogo/> : null}
               {platformName === 'Facebook' ? <FacebookLogo/> : null}
               {platformName === 'listing_party' ? <ListingPartyLogo/> : null}
               {platformName === 'Vestiaire' ? <VestiaireLogo/> : null}
               {platformName === 'Instagram' ? <InstagramLogo/> : null}
               {platformName === 'Mercari' ? <MercaryLogo/> : null}
             </div>
             {
                 hasTitle && <div className={styles.platformTitleWithLink}> {platformName === 'listing_party' ? 'Listing Party' : platformName}</div>
             }
           </a>
         </div>
        ) : hasPro && is_role_allowed ? <div
            style={ hasTitle ? {position: 'relative', display: "flex", alignItems: 'center', gap: '10px'} : {position: 'relative'}}>
          {type !== 'sales' &&
              platformName !== 'SOLD' &&
              platformName !== 'Tradesy' &&
              fromPlatform &&
              IsSold !== 'SOLD' && (
                  (!removed &&  issueExist) && <div
                        className={`issueDot ${hasTitle ? 'issueDotSmall' : ''}`}
                        onClick={syncIssueClick}
                    ></div>
              )}
          <div
              style={{ userSelect: 'none', height: isSmallScreenLaptop ? "16px" : "20px", width: isSmallScreenLaptop ? "16px" : "20px", minWidth:isSmallScreenLaptop ? "16px" : "20px" } }
              className="socialIcon"
          >
            {platformName === 'eBay' ? <EbayLogo/> : null}
            {platformName === 'Amazon' ? <AmazonLogo/> : null}
            {platformName === 'Grailed' ? <GrailedLogo/> : null}
            {platformName === 'Shopify' ? <ShopifyLogo/> : null}
            {platformName === 'Etsy' ? <EtsyLogo/> : null}
            {platformName === 'Depop' ? <DepopLogo/> : null}
            {platformName === 'Poshmark' ? <PoshmarkLogo/> : null}
            {platformName === 'SOLD' ? <SoldLogo/> : null}
            {platformName === 'Kidizen' ? <KidizenLogo/> : null}
            {platformName === 'Facebook' ? <FacebookLogo/> : null}
            {platformName === 'listing_party' ? <ListingPartyLogo/> : null}
            {platformName === 'Vestiaire' ? <VestiaireLogo/> : null}
            {platformName === 'Instagram' ? <InstagramLogo/> : null}
            {platformName === 'Mercari' ? <MercaryLogo/> : null}
          </div>
          {
              hasTitle && <div
                  className={styles.platformTitleWithNoLink}> {platformName === 'listing_party' ? 'Listing Party' : platformName}</div>
          }
         </div>
            : (
            <div style={hasTitle ? {
              position: 'relative',
              display: "flex",
              alignItems: 'center',
              gap: '10px'
            } : {position: 'relative'}}>
              {type !== 'sales' &&
                  platformName !== 'SOLD' &&
                  platformName !== 'Tradesy' &&
                  fromPlatform &&
                  IsSold !== 'SOLD' && (
                      <Tippy
                          interactive={true}
                          zIndex={9999}
                          arrow={false}
                          placement="top"
                          trigger="click"
                          appendTo={document.body}
                          onClickOutside={() => {
                            if (handleTooltipClickPlatforms) {
                              handleTooltipClickPlatforms(platformName);
                            }
                          }}
                          visible={visible}
                          content={
                            <span>
                      A platform status is selected, but no platform ID is saved
                      to the corresponding item number field in "inventory and
                      sales"
                    </span>
                          }
                      >
                        <div
                            className={`issueDot ${hasTitle ? 'issueDotSmall' : ''}`}
                            onClick={() => {
                              if (handleTooltipClickPlatforms) {
                                handleTooltipClickPlatforms(platformName);
                              }
                            }}
                        ></div>
                      </Tippy>
                  )}
              <div
                  style={{
                    userSelect: 'none',
                    height: isSmallScreenLaptop ? "16px" : "20px",
                    width: isSmallScreenLaptop ? "16px" : "20px",
                    minWidth: isSmallScreenLaptop ? "16px" : "20px"
                  }}
                  className="socialIcon"
              >
                {platformName === 'eBay' ? <EbayLogo/> : null}
                {platformName === 'Amazon' ? <AmazonLogo/> : null}
                {platformName === 'Grailed' ? <GrailedLogo/> : null}
                {platformName === 'Shopify' ? <ShopifyLogo/> : null}
                {platformName === 'Etsy' ? <EtsyLogo/> : null}
                {platformName === 'Depop' ? <DepopLogo/> : null}
                {platformName === 'Poshmark' ? <PoshmarkLogo/> : null}
                {platformName === 'SOLD' ? <SoldLogo/> : null}
                {platformName === 'Kidizen' ? <KidizenLogo/> : null}
                {platformName === 'Facebook' ? <FacebookLogo/> : null}
                {platformName === 'listing_party' ? <ListingPartyLogo/> : null}
                {platformName === 'Vestiaire' ? <VestiaireLogo/> : null}
                {platformName === 'Instagram' ? <InstagramLogo/> : null}
                {platformName === 'Mercari' ? <MercaryLogo/> : null}
              </div>
              {
                  hasTitle && <div
                      className={styles.platformTitleWithNoLink}> {platformName === 'listing_party' ? 'Listing Party' : platformName}</div>
              }

            </div>
        )}
      </div>

      {/* {isOpenToolTip && (
        <MyToolTip handleToggleTollTip={handleToggleTollTip} sync={sync} />
      )}
      * vremenno skrito */}
    </div>
  );
};

export default PlatformBlockItem;
