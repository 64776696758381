import  {useEffect, useRef, useState} from 'react' ;
import getToken from "../../../../../../utils/getToken";
import {useFormik} from "formik";
import * as Yup from "yup";
import UserConfigs from "@services/UserConfigs";
import cryptoRandomString from "crypto-random-string";
import {toast} from "react-toastify";

interface AddSubAccountModalLogicProps {
	handleToggleAddSubAccountModal : () => void;
	setSubAccounts:(subAccounts:any) => void ;
}

export const AddSubAccountModalLogic = (props:AddSubAccountModalLogicProps) => {
	const { handleToggleAddSubAccountModal,setSubAccounts } = props ;

	const token:any = getToken();

	const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true) ;
	const [generatedPassword,setGeneratedPassword] = useState("")

	const addSubAccountModalRef = useRef<HTMLDivElement | null>(null) ;

	const formik = useFormik({
		initialValues: {
			first_name: "",
			last_name: "",
			email: "",
		},
		validationSchema: Yup.object({
			first_name: Yup.string()
			.required("First name is required."),
			last_name: Yup.string()
			.required("Last name is required."),
			email: Yup.string()
			.email("Please enter a valid email address.")
			.required("Email is required."),
		}),
		onSubmit: async (values) => {
			try {
				const options = { send_email: sendWelcomeEmail };
				const response:any = await UserConfigs.sub_accounts_add(token, values, options);
				// console.log(response,'response')
				if(response?.data) {
					const { first_name, last_name, email, id, metadata } = response.data;
					const { username } = metadata || {};

					setSubAccounts((prevSubAccounts:any) => {
						return [...prevSubAccounts, {first_name, last_name,id, metadata:{email, lp_subaccount_settings: null, username}}]
					})
					handleToggleAddSubAccountModal()

				}
			} catch (error: any) {
				toast.error(`This email ${values?.email} is already in use in the system. Please try a different one.`, {
					autoClose: 3000, // Set the duration for auto close
					transitionDuration: 500, // Set the transition duration for this specific notification
					position: 'bottom-right'
				} as any);
				formik.setFieldError('email',"");

				console.error(error, 'error');
			}
		},
	});

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (
			 addSubAccountModalRef.current &&
			 !addSubAccountModalRef.current?.contains(event.target as Node)) {
				handleToggleAddSubAccountModal();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [addSubAccountModalRef, handleToggleAddSubAccountModal]);

	const handleGeneratePassword =() => {
		const generatedPassword = cryptoRandomString({length: 8});
		setGeneratedPassword(generatedPassword)
	}

	const isButtonDisabled = !formik.isValid || !formik.dirty;




	return {
		isButtonDisabled,
		handleGeneratePassword,
		addSubAccountModalRef,
		formik,
		generatedPassword,
		sendWelcomeEmail,
		setSendWelcomeEmail
	}
}