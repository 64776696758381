
import {EbayLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,KidizenLogo,FacebookLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons" ;

import styles from "./ExtensionTabs.module.scss";
const ExtensionTabs = ({ tabsData, setTabsData }: any) => {
	const handleTabClick = (index: any) => {
		const updatedTabsData = tabsData.map((tab:any, i:number) => ({
			...tab,
			tabChosen: i === index,
		}));
		setTabsData(updatedTabsData);
	};
	return <div className={styles.extensionTabs}>
		{
			tabsData.map((tab: any, index: number) => {
				return(
				 <div key={index} >
					 <div key={index}>
						 {tab?.name === 'General' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}>General</div>}
						 {tab?.name === 'Poshmark' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><PoshmarkLogo width={30} height={30} /></div>}
						 {tab?.name === 'Shopify' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><ShopifyLogo width={30} height={30} /></div>}
						 {tab?.name === 'Instagram' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><InstagramLogo width={30} height={30} /></div>}
						 {tab?.name === 'Etsy' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><EtsyLogo width={30} height={30} /></div>}
						 {tab?.name === 'eBay' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><EbayLogo width={30} height={30} /></div>}
						 {tab?.name === 'Mercari' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><MercaryLogo width={30} height={30} /></div>}
						 {tab?.name === 'Facebook' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><FacebookLogo width={30} height={30} /></div>}
						 {tab?.name === 'Grailed' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><GrailedLogo width={30} height={30} /></div>}
						 {tab?.name === 'Depop' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><DepopLogo width={30} height={30} /></div>}
						 {tab?.name === 'Kidizen' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><KidizenLogo width={30} height={30} /></div>}
						 {tab?.name === 'Vestiaire' && <div className={`${styles.tabItem} ${tab?.tabChosen ? styles.activeTab : null}`} onClick={() => handleTabClick(index)}><VestiaireLogo width={30} height={30} /></div>}
					 </div>
				 </div>
				)
			})
		}
	</div>;
};

export default ExtensionTabs ;