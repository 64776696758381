import React from "react";
import _ from "lodash";
import styles from "../ItemDetails.module.scss";

const GlobalFooters = ({listViewId, hasSimple, hasBusiness, globalFooters}:any) =>{

    const renderFooterItem = (property: string, title: string) => {
        if (_.has(globalFooters, property)) {
            const description = globalFooters[property];
            const sanitizedDescription = description.replace(
                /(\r\n|\r|\n)+/g,
                '<br>',
            );

            return (
                <>
                    {
                        description.length ? <div className={styles.footerItemContainer}>
                            <p className={styles.globalFootersItemTitle}>{title}</p>
                            <p
                                className={styles.globalFootersItemSubTitle}
                                dangerouslySetInnerHTML={{__html: sanitizedDescription}}
                            />
                        </div> : ''
                    }
                </>

            );
        }
        return null;
    };
    return (
        <div className={`${styles.ordersFooters}`}>
            <div className={styles.globalFooters}>
                {
                    (!hasSimple && !hasBusiness) &&
                    <>
                        <div className={styles.fieldTitle}>Global Footers</div>
                        {renderFooterItem('mepr_mercari_description', 'Mercari Footer')}
                        {renderFooterItem('mepr_poshmark_description', 'Poshmark Footer')}
                        {renderFooterItem('mepr_ebay_description', 'eBay Footer')}
                        {renderFooterItem('mepr_etsy_description', 'Etsy Footer')}
                        {renderFooterItem('mepr_depop_description', 'Depop Footer')}
                        {renderFooterItem(
                            'mepr_facebookmarketplace_description',
                            'Facebook Footer',
                        )}
                        {renderFooterItem(
                            'mepr_instagram_description',
                            'Instagram Footer',
                        )}
                        {renderFooterItem('mepr_grailed_description', 'Grailed Footer')}
                        {renderFooterItem('mepr_kidizen_description', 'Kidizen Footer')}
                        {renderFooterItem('mepr_shopify_description', 'Shopify Footer')}
                        {renderFooterItem(
                            'mepr_vestiaire_description',
                            'Vestiaire Footer',
                        )}</>
                }
                <div className={styles.lpIdContainer}>
                    <p className={styles.lpHeading}>LP ID</p>
                    <p className={styles.lpId}>{listViewId}</p>
                </div>
            </div>
        </div>
    )
}

export default GlobalFooters;