import styles from './SelectionIDsModal.module.scss';
import {CloseSvg} from "@assets/icons";
import React, {useEffect} from "react";
import Button, {ModifierType} from "../../../../components/UI/Button";

const SelectionIDsModal = ({showSelectionIDsModal, setShowSelectionIDsModal, disableSelectionIDsModal, setDisableSelectionIDsModal, setSelectedListings, setSelectedListingsData, tableData, setTableData}:any) =>{

    useEffect(() => {
        const storedValue = localStorage.getItem('resetSelectionsCheckbox');
        setDisableSelectionIDsModal(storedValue === 'true'); // Set true if 'true' is in localStorage
    }, []);


    useEffect(() => {
        if (showSelectionIDsModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [showSelectionIDsModal]);

    const HandleResetSelections = () =>{
        setSelectedListings([]);
        setSelectedListingsData([]);
        let tempData = tableData.map((tbData:any) => {
            return { ...tbData, isChecked: false };
        });
        setTableData(tempData);
        setShowSelectionIDsModal(false);
    }

    return(
        <div className={styles.selectionIDsMainContainer}>
            <div className={styles.selectionIDsModal}>
               <div className={styles.closeIcon} onClick={() => setShowSelectionIDsModal(false)}> <CloseSvg /></div>
                <div className={styles.selectionIDsModalInner}>
                    <p className={styles.title}>Would you like to clear your selections?</p>
                    <div className={styles.buttonsContainer}>
                        <Button modifier={ModifierType.Primary2} handleClick={HandleResetSelections}>Yes</Button>
                        <Button modifier={ModifierType.Primary2} handleClick={() => setShowSelectionIDsModal(false)}>No</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default  SelectionIDsModal;