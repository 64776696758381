import { configureStore } from '@reduxjs/toolkit';
import navigationReducer from '../slices/NavigationSlice/index';
import roleReducer from '../slices/RoleSlice';
import catalogReducer from '../slices/CatalogSlice';
import salesReducer from '../slices/SalesSlice';
import  sharedWorkerReducer from '../slices/SharedWorker';
import customMarketplacesReducer from '../slices/customMarketplacesSlice';
import settingsReducer from '../slices/SettingsSlice';
import userReducer from '../slices/UserSlice';

const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    roles: roleReducer,
    catalog: catalogReducer,
    sales: salesReducer,
    sharedWorker: sharedWorkerReducer,
    customMarketplaces:customMarketplacesReducer,
    settings: settingsReducer,
    user:userReducer
  },
});

export default store;