import AccordionHeaderComponent from "./AccordionHeaderComponent";
import {useState} from "react";
import ProDescriptionsComponent from "../ProDescriptionsComponent";
import {KeywordsComponent} from "../KeywordsComponent";
import {ShippingAndVariationsComponent} from "../ShippingAndVariationsComponent";
import {CustomizationsComponent} from "../CustomizationsComponent";
import styles from "../../../EditAddListingModal/UI/DropdownComponent/DropdownComponent.module.scss";
import Button, {ModifierType} from "../../../UI/Button";

const DropdownComponent = ({onlyPro, hasBusiness, hasSimple, optionsAssignee,setOptionsAssignee, selectedOptionAssignee, setSelectedOptionAssignee, subAccounts,setSubAccounts, assigneeId, addMoreInputs, setAddMoreInputs,moreMeasurementsPart1, setMoreMeasurementsPart1,moreMeasurementsPart2, setMoreMeasurementsPart2, setFormInfo, variations, setVariations, bulletItems, setBulletItems, measureItems, setMeasureItems, measureBothItems, setMeasureBothItems, handleTagifiesChange, handleStandartInputChange, editIndex, formInfo, unescapedRowMetadata, listInfo, itemData, setItemData }:any) => {
	const [activeIndices, setActiveIndices] = useState<number[]>(hasSimple || hasBusiness ? [2] : [2,3]);

	const openAllDropdown = ()=>{
		if(hasSimple || hasBusiness){
			setActiveIndices([2])
		}else{
			setActiveIndices([1,2,3,5])
		}
	}

	const closeAllDropdown = () =>{
		setActiveIndices([])
	}

	const maxIndices = hasSimple || hasBusiness ? 1 : 4 ;

	return(
		<div>
			<div className={styles.openCloseAllBtn}>
				{
					(activeIndices.length === maxIndices) ?
					 <Button modifier={ModifierType.Secondary} handleClick={closeAllDropdown}>Close All</Button>
						: <Button modifier={ModifierType.Secondary} handleClick={openAllDropdown}>Open All</Button>

				}
			</div>
			{
				(!hasSimple && !hasBusiness) &&
				(
					<>
						<AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices}
												  accIndex={1} accTitle="Pro Description (Optional)"/>
						{activeIndices.includes(1) &&
							<ProDescriptionsComponent itemData={itemData} editIndex={editIndex}
													  setItemData={setItemData} setFormInfo={setFormInfo}
													  addMoreInputs={addMoreInputs} setAddMoreInputs={setAddMoreInputs}
													  moreMeasurementsPart1={moreMeasurementsPart1}
													  setMoreMeasurementsPart1={setMoreMeasurementsPart1}
													  moreMeasurementsPart2={moreMeasurementsPart2}
													  setMoreMeasurementsPart2={setMoreMeasurementsPart2}
													  handleStandartInputChange={handleStandartInputChange}
													  formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata}/>}
					</>
				)
			}

			<AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices} accIndex={2}
									  accTitle="Keywords, Pricing, Quantity, SKU, UPC, COGS"/>
			{activeIndices.includes(2) &&
				<KeywordsComponent hasSimple={hasSimple} formInfo={formInfo} unescapedRowMetadata={unescapedRowMetadata}
								   handleTagifiesChange={handleTagifiesChange}
								   handleStandartInputChange={handleStandartInputChange}/>}
			{
				(!hasSimple && !hasBusiness) &&
				(
					<>
						<AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices}
												  accIndex={3} accTitle="Shipping and Variations"/>
						{activeIndices.includes(3) &&
							<ShippingAndVariationsComponent variations={variations} setVariations={setVariations}
															listInfo={listInfo}
															handleStandartInputChange={handleStandartInputChange}
															itemData={itemData} setItemData={setItemData}
															editIndex={editIndex} formInfo={formInfo}
															unescapedRowMetadata={unescapedRowMetadata}/>}
						<AccordionHeaderComponent activeIndices={activeIndices} setActiveIndices={setActiveIndices}
												  accIndex={5} accTitle="Customizations"/>
						{activeIndices.includes(5) &&
							<CustomizationsComponent onlyPro={onlyPro} assigneeId={assigneeId} setFormInfo={setFormInfo}
													 formInfo={formInfo} subAccounts={subAccounts}
													 handleStandartInputChange={handleStandartInputChange}
													 itemData={itemData} setItemData={setItemData} editIndex={editIndex}
													 listInfo={listInfo} unescapedRowMetadata={unescapedRowMetadata}
													 bulletItems={bulletItems} setBulletItems={setBulletItems}
													 measureItems={measureItems} setMeasureItems={setMeasureItems}
													 measureBothItems={measureBothItems}
													 setMeasureBothItems={setMeasureBothItems}
													 optionsAssignee={optionsAssignee}
													 setOptionsAssignee={setOptionsAssignee}
													 selectedOptionAssignee={selectedOptionAssignee}
													 setSelectedOptionAssignee={setSelectedOptionAssignee}/>}
					</>
				)
			}
		</div>
	)
}

export default DropdownComponent;