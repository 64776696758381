import React from 'react';

import {RemoveAccountAccessModalLogic} from "./RemoveAccountAccessModalLogic";
import RemoveAccountAccessModalUI from "./RemoveAccountAccessModalUI";

interface IRemoveAccountAccessModalProps {
	handleToggleRemoveAccountAccessModal : () => void;
	handleRemoveSubAccount:(action:'detach' | 'delete') => void;
}

const RemoveAccountAccessModal = (props:IRemoveAccountAccessModalProps) => {
	const {handleToggleRemoveAccountAccessModal,handleRemoveSubAccount} = props ;

	const {removeAccountAccessModalRef,removeSubAccountAccessModalItems,selectedAction,handleRadioChange} = RemoveAccountAccessModalLogic({handleToggleRemoveAccountAccessModal})

	return (<RemoveAccountAccessModalUI removeAccountAccessModalRef={removeAccountAccessModalRef} removeSubAccountAccessModalItems={removeSubAccountAccessModalItems} selectedAction={selectedAction} handleRadioChange={handleRadioChange} handleRemoveSubAccount={handleRemoveSubAccount} handleToggleRemoveAccountAccessModal={handleToggleRemoveAccountAccessModal}/>)
}

export default RemoveAccountAccessModal ;