import { useState, useEffect } from 'react';

function UseIsSmallLaptop() {
	const [isSmallScreenLaptop, setIsSmallScreenLaptop] = useState<boolean>(
	 window.innerWidth >= 1024 && window.innerWidth <= 1440
	);

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			setIsSmallScreenLaptop(width >= 1024 && width <= 1440);
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isSmallScreenLaptop;
}

export default UseIsSmallLaptop;
