import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAppPrefix from '../../../hooks/UseAppPrefix';
import useRoles from "@hooks/useRoles";

interface LayoutProps {
  children: JSX.Element[] | JSX.Element;
}

interface NavigationState {
  active: boolean;
  showIframe: boolean;
  isMobile: boolean;
}

interface RootState {
  navigation: NavigationState;
}

function Layout({ children }: LayoutProps) {
  const navigation = useSelector((state: RootState) => state.navigation.active);
  const showIframe = useSelector(
    (state: RootState) => state.navigation.showIframe
  );
  const {is_role_allowed} = useRoles();
  const linkPrefix = useAppPrefix();
  const isMobile = useSelector((state: any) => state.navigation.isMobile);

  useEffect(() => {
    if (!is_role_allowed) {
      window.location.href = `https://${linkPrefix?.prefix}.listperfectly.com/mainmenu/`;
    }
  }, []);

  return <div className="page-container">{children}</div>;
}

export default Layout;
