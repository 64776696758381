import React, {useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { MoonLoader } from 'react-spinners';
import { instanceAxios } from '@services/index';
import Auth from "@services/Auth";
import useRoles from "@hooks/useRoles";
import Listings from "@services/Listings";
import HeaderComponent from "./components/Header";
import MainInfo from "./components/MainInfo";
import ImagesSlider from "./components/ImagesSlider";
import ListedMarkets from "./components/ListedMarkets";
import ItemDetails from "./components/ItemDetails";
import _ from 'lodash';
import {
    setListingViewId, setPdfModalProductId,
    setShowListingViewModal, setShowPdfModal,
} from "../../../redux/slices/NavigationSlice";
import 'tippy.js/dist/tippy.css';
import './SingleProductView.css';
import styles from './SingleProductView.module.scss';
import ShippingLabelModal from "../ShippingLabelModal";

interface ProductInfo {
    created_at: string;
    id?: number;
    images: [];
    metadata: any;
    status: string;
    thumbnail: string;
    type: string;
    updated_at: string;
    user_id: number;
}
const SingleProductView = () => {
    const navigate = useNavigate()
    const token: any = Auth.get_token();
    const location = window.location ;
    const navigation = useSelector((state: any) => state.navigation.active);
    const searchParams = new URLSearchParams(location.search);
    const listViewId = useSelector((state: any) => state.navigation.listingViewId);
    const [productInfo, setProductInfo] = useState<ProductInfo | null>(null);
    const [listingViewModalID, setListingViewModalID] = useState<any>(null)
    const listingViewModalShow = useSelector((state: any) => state.navigation.listingViewModalShow);
    const [visibleCrossPost, setVisibleCrossPost] = useState(false);
    const [shopifyDynamicLink, setShopifyDynamicLink] = useState(null);
    const [globalFooters, setGlobalFooters] = useState<any>({});
    const [productInfoJSON, setProductInfoJSON] = useState<any>(null);
    const [allowSendListingJSON, setAllowSendListingJSON] = useState(false);
    const [id, setId] = useState<any>(null);
    const popoverContainerRef = useRef(null)
    const dispatch = useDispatch();
    const {is_role_allowed, hasSimple, hasBusiness, hasPro, hasBetaRole } = useRoles();
    const showPdfModal = useSelector((state: any) => state.navigation.showPdfModal);

    useEffect(() => {
        const url = window.location.href;
        const id:any = url.split('/').pop();
        setId(+id);
    }, []);

    useEffect(() => {
       try{
           const params = new URLSearchParams(window.location.search);
           const modalListingId:any = params.get('modal-listing-id');

           // Check if modalListingId is a valid number
           if (!isNaN(modalListingId) && modalListingId.trim() !== '') {
               setListingViewModalID(Number(modalListingId));
           } else {
               dispatch(setListingViewId(null));
               dispatch(setShowListingViewModal(false));
           }
       }
       catch(e){
           console.log(e, 'error')
       }
    }, []);


    const spanKeywords = productInfo?.metadata[453]?.split(', ');

    const [isLoading, setIsLoading] = useState(true);


    async function fetchData(): Promise<void> {
        try {
            const metadata = { offset: 0, limit: 1 };
            const options = {
                include_thumbnail: true,
                include_images: true,
            }
            const response:any = await Listings.list({ id: id ? id : listingViewModalID }, metadata,options, null, token);
            if (response?.data?.length) {
                setProductInfo(response?.data[0]);
            }else{
                dispatch(setListingViewId(null));
                dispatch(setShowListingViewModal(false));
            }
        } catch (error) {
            dispatch(setListingViewId(null));
            dispatch(setShowListingViewModal(false));
            console.error('Error fetching data:', error);
        }
    }

    async function fetchGlobalFooters(): Promise<void> {
        try {
                const response = await instanceAxios.post('/users/legacy/metadata/list', {
                    metadata: { offset: 0, limit: 10 },
                    filter_by: {},
                });
                if (response.status === 200) {
                    const subdomain = _.get(
                        response.data.data,
                        'mepr_shopify_id_subdomain',
                    );
                    if (subdomain !== undefined) {
                        setShopifyDynamicLink(subdomain);
                    }
                   if(!hasSimple && !hasBusiness) {
                       setGlobalFooters(response.data.data);
                    }
                }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const openPdfModal = () => {
        dispatch(setShowPdfModal(true));
        dispatch(setPdfModalProductId(listViewId));
    };

    const closePdfModal = () => {
        dispatch(setShowPdfModal(false));
        dispatch(setPdfModalProductId(null));
    };

    const isRequestSentRef = useRef<any>(false); // Track if the request has been sent
    useEffect(() => {
        const fetchDataAndFooters = async () => {
            if (isRequestSentRef.current) return; // Block if request already sent
            if(id || listingViewModalID) {
                isRequestSentRef.current = true; // Mark as sent to prevent further requests
            }
            setIsLoading(true);
            try {
                if (id || listingViewModalID) {
                    if(id) {
                        dispatch(setListingViewId(id))
                    }
                    else {
                        dispatch(setListingViewId(listingViewModalID))
                    }
                    await Promise.all([fetchData(), fetchGlobalFooters()]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setAllowSendListingJSON(true);
                setIsLoading(false);
            }
        };

        fetchDataAndFooters();
    }, [id, listingViewModalID]);

    useEffect(() => {
        return () => {
            dispatch(setListingViewId(null));
            dispatch(setShowListingViewModal(false));
        }
    }, [dispatch]);


    useEffect(() => {
        if(allowSendListingJSON) {
            try {
                const jsonStringProductInfo = JSON.stringify(productInfo);
                const jsonStringFooters = JSON.stringify(globalFooters);
                const productInfoObj = JSON.parse(jsonStringProductInfo);
                const footersObj = JSON.parse(jsonStringFooters);

                const combinedObj = {
                    ...productInfoObj,
                    ...footersObj
                };

                const jsonStringCombined = JSON.stringify(combinedObj);
                if(productInfo) {
                    setProductInfoJSON(jsonStringCombined)
                }
            }
            catch (e) {
                console.log(e, 'error')
            }
        }

    }, [allowSendListingJSON, productInfo, globalFooters]);

    const crossPostRef:any = useRef(null);

    const checkCrossPostClass = () => {
        if (crossPostRef.current && crossPostRef.current.classList.contains('lp_custom_crosspost_icon_disabled')) {
            setVisibleCrossPost(true);
        } else {
            setVisibleCrossPost(false);
        }
    };

    useEffect(() => {
        try {
            const crossPostElement = crossPostRef.current;
            // if (!crossPostElement) return;
            // Create an observer instance linked to the callback function
            const observer = new MutationObserver(() => {
                checkCrossPostClass();
            });
            // Start observing the target node for configured mutations
            observer.observe(crossPostElement, {
                attributes: true,
                attributeFilter: ['class'],
            });
            // Initial check
            checkCrossPostClass();
            // Cleanup function to disconnect the observer
            return () => {
                observer.disconnect();
            };
        }
        catch (e) {
            console.log(e, 'error')
        }
    }, [crossPostRef.current, productInfo]);


    const handleCloseListingModal = () =>{
        dispatch(setShowListingViewModal(false))
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [listingViewModalShow]);

    useEffect(() => {
        return () => {
            dispatch(setShowListingViewModal(false));
        }
    }, [dispatch]);

    useEffect(() => {
        return () => {
            searchParams.delete('modal');
            searchParams.delete('modal-listing-id');
            navigate(`?${searchParams.toString()}`);
        }
    }, []);

    const leftMenuExpanded = Boolean(localStorage.getItem('leftMenuExpanded'));

    return (
        <div className={`${styles.singleProductViewContainer} ${listingViewModalShow ? styles.singleProductAsModal : ''} `} id={"singleProductView"}>
            <div ref={popoverContainerRef} className="popoverContainer"></div>
            <div className={`${styles.SingleProductView} ${listingViewModalShow ? styles.singleProductViewModal : ''}`}>
                {
                    isLoading ? (
                        <div className={styles.loading}>
                            <MoonLoader />
                        </div>
                    ) :  productInfo && (
                        <div className={styles.SingleProductViewContent} id={`${listingViewModalShow ? 'listingViewModalContent' : 'listingViewNavigateContent'}`}>
                            {
                                showPdfModal &&  <ShippingLabelModal
                                    isOpen={showPdfModal}
                                    onRequestClose={closePdfModal}
                                    title={productInfo?.metadata[133]}
                                    sku={productInfo?.metadata[1]}
                                />
                            }
                            <HeaderComponent
                                crossPostRef={crossPostRef}
                                visibleCrossPost={visibleCrossPost}
                                listingViewModalShow={listingViewModalShow}
                                listViewId={listViewId}
                                hasPro={hasPro}
                                is_role_allowed={is_role_allowed}
                                handleCloseListingModal={handleCloseListingModal}
                                popoverContainerRef={popoverContainerRef}
                                hasBetaRole={hasBetaRole}
                                openPdfModal={openPdfModal}
                            />
                            <div className={`${styles.singleViewBody} ${listingViewModalShow ? styles.singleViewBodyModal : ''}`}
                                 style={(navigation && !listingViewModalShow && leftMenuExpanded) ? {marginRight: '30px'} : {} }>
                                <div className={styles.listingViewTitle}>
                                    Listing Preview
                                </div>
                                {productInfoJSON && <div className="lp_custom_chrome_extension_listing_data">{productInfoJSON}</div>}
                                <MainInfo listingViewModalShow={listingViewModalShow} handleCloseListingModal={handleCloseListingModal} productInfo={productInfo} />
                                <div className={`${styles.images}`}>
                                    <ImagesSlider
                                     imageUrls={productInfo?.images}
                                     imagesLength={productInfo?.images?.length}
                                    />
                                </div>
                                <div className={`${styles.mainDetailsContainer} `}>
                                   <ListedMarkets productInfo={productInfo} shopifyDynamicLink={shopifyDynamicLink} hasSimple={hasSimple} hasBusiness={hasBusiness} />
                                   <ItemDetails hasSimple={hasSimple} productInfo={productInfo} spanKeywords={spanKeywords} hasBusiness={hasBusiness} hasPro={hasPro} listViewId={listViewId} globalFooters={globalFooters} />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default SingleProductView;