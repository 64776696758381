import { createSlice } from '@reduxjs/toolkit';

interface IUserSliceState {
	user:any;
}

const initialState: IUserSliceState = {
	user:{}
};

const userSlice = createSlice({
	name: 'sales',
	initialState,
	reducers: {
		setUser:(state, action) => {
			state.user = action.payload ;
		}
	},
});

export const {setUser} = userSlice.actions;
export default userSlice.reducer;