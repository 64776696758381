import styles from "./FooterComponent.module.scss";
import Select, {components, SingleValueProps} from "react-select";
import React, {useState} from "react";
import {toast} from "react-toastify";
import Button, {ModifierType} from "../../../UI/Button";
import UseIsSmallLaptop from "@hooks/UseIsSmallLaptop";

export const FooterComponent = ({SKUloader, savingProgress, showEditAddModal, disableSaveButtons, editId, handleAddListing, forTabletSize, enabledButtons, handleSaveForm, setSaveAndClose, setSaveAndPreview, setSaveAndNew} :any)=>{

    const isSmallScreenLaptop = UseIsSmallLaptop();

    const FooterButtonsOptions =  editId ? [
        { value: 'save_progress', label: 'Save Progress' },
        { value: 'save_and_preview', label: 'Save & Preview' },
        { value: 'save_and_close', label: 'Save & Close' }
    ] :  [
        { value: 'save_progress', label: 'Save Progress' },
        { value: 'save_and_new', label: 'Save & New' },
        { value: 'save_and_preview', label: 'Save & Preview' },
        { value: 'save_and_close', label: 'Save & Close' }
    ]
   let selectedFromStorage:any = null;
   try{
       selectedFromStorage =  localStorage.getItem('selectedOptionSave');
       if(editId) {
           if(selectedFromStorage){
               if(JSON.parse(selectedFromStorage).value === 'save_and_new') {
                   const saveProgress =  { value: 'save_and_close', label: 'Save & Close' };
                   selectedFromStorage = JSON.stringify(saveProgress);
               }
           }
       }
   }
   catch (e) {
        console.log(e, 'error')
   }
    const [selectedOptionFooter, setSelectedOptionFooter] = useState<any>(selectedFromStorage ? JSON.parse(selectedFromStorage) : editId ?  FooterButtonsOptions[2] : FooterButtonsOptions[3] );

    const customStylesFooter = {
        control: (provided:any, state:any) => ({
            ...provided,
            height: isSmallScreenLaptop ? 32 : 39,
            minHeight: isSmallScreenLaptop ? 32 : 39,
            borderRadius: isSmallScreenLaptop ? "0 6px 6px 0" : '0 8px 8px 0',
            cursor:'pointer',
            maxWidth: 100,
            borderColor: "#ccc",
            boxShadow: "none",
            "&:hover": {
                border: "1px solid #ccc",
            },
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: isSmallScreenLaptop ? 32 : 39,
            padding: isSmallScreenLaptop ? '0 5px' : '0 6px'
        }),
        container: (provided: any, state: any) => ({
            ...provided,
            fontSize: isSmallScreenLaptop ? 11 : 14
        }),
        indicatorSeparator: (state:any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: isSmallScreenLaptop ? 32 : 39,
        }),
        menu: (provided: any) => ({
            ...provided,
            maxWidth: isSmallScreenLaptop ? 112 : 140,
            minWidth: isSmallScreenLaptop ? 112 : 140,
            fontSize:  isSmallScreenLaptop ? 11 : 14,
            right: 0,
            left: 'auto',
            margin: 0,
        }),
        singleValue: (provided: any) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            whiteSpace: 'nowrap',
            overflow: 'visible'
        }),
    };

    const handleSelectChange = (selectedOptionFooter:any) => {
        setSelectedOptionFooter(selectedOptionFooter);
        localStorage.setItem('selectedOptionSave', JSON.stringify(selectedOptionFooter));
    };

    const handleFooterSaveButtons = (value:any)=>{
        switch (value){
            case 'save_progress':
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_new':
                setSaveAndNew(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_preview':
                setSaveAndPreview(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            case 'save_and_close':
                setSaveAndClose(true);
                editId ? handleSaveForm() : handleAddListing()
                break;
            default:
        }
    }
    // Custom component to hide the selected value
    const CustomSingleValue = (props: SingleValueProps<any>) => (
        <components.SingleValue {...props}>
            <span style={{ color: 'hsl(0, 0%, 50%)', whiteSpace: 'nowrap' }}>Options</span>
        </components.SingleValue>
    );

    return (
        <div className={`${styles.footerButtonsMobile} ${forTabletSize ? styles.footerButtonsTablet : styles.fixedFooterMobile} ${editId && showEditAddModal ? styles.footerButtonsMobileOnModal : ''}`}>
            <Select
                options={FooterButtonsOptions}
                onChange={handleSelectChange}
                placeholder={'Options'}
                menuPlacement={forTabletSize ? "bottom" : 'top'}
                styles={customStylesFooter}
                isSearchable={false}
                components={{ SingleValue: CustomSingleValue }}
            />
            {selectedOptionFooter && (
                    <Button
                     style={{
                         borderTopRightRadius:0, borderBottomRightRadius:0}}
                     disabled={!enabledButtons || disableSaveButtons || savingProgress || SKUloader}
                     modifier={ModifierType.Primary}
                     handleClick={() => {
                                 if(enabledButtons && !disableSaveButtons){
                                     handleFooterSaveButtons(selectedOptionFooter.value)
                                 }else{
                                     console.log('happening')
                                     toast.warning(`Oops! It looks like no changes were made to your listing. Please edit something for the 'Save' button to become active.`, {
                                         position: 'bottom-right', // You can customize the notification position
                                     });
                                 }
                             }}
                    >
                        {selectedOptionFooter.label}
                    </Button>
            )}
        </div>
    )
}