import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import PlatformsTitles from "./PlatformsTitles";
import PlatformBlockItem from "../../../../../pages/CatalogPage/components/Table/components/PlatformBlockItem";
import styles from "./ListedMarkets.module.scss";
import {CheckedIcon} from "@assets/icons";
import Vector from "@assets/icons/svg/Vector.svg";

const ListedMarkets = ({productInfo, shopifyDynamicLink, hasSimple, hasBusiness}:any) => {
    const [tooltipsPlatformActive, setTooltipsPlatformActive] = useState<any>(
        {
            ebay: false,
            etsy: false,
            poshmark: false,
            mercari: false,
            amazon: false,
            grailed: false,
            facebook: false,
            depop: false,
            kidizen: false,
            shopify: false,
            instagram: false,
            vestiaire: false,
        },
    );
    const initialPlatforms = {
        eBay: { isCopied: false },
        Etsy: { isCopied: false },
        Poshmark: { isCopied: false },
        Mercari: { isCopied: false },
        Amazon: { isCopied: false },
        Grailed: { isCopied: false },
        Facebook: { isCopied: false },
        Depop: { isCopied: false },
        Kidizen: { isCopied: false },
        Shopify: { isCopied: false },
        Instagram: { isCopied: false },
        listing_party: { isCopied: false },
        Vestiaire: { isCopied: false },
    };

    const fullPlatformsList = [
        // Static Platforms
        { metadataKey: 539.2, idKey: 618, name: 'eBay', urlPrefix: 'https://ebay.com/itm/', dynamic: false },
        { metadataKey: 539.3, idKey: 619, name: 'Etsy', urlPrefix: 'https://www.etsy.com/listing/', dynamic: false },
        { metadataKey: 539.4, idKey: 620, name: 'Poshmark', urlPrefix: 'https://poshmark.com/listing/', dynamic: false },
        { metadataKey: 539.5, idKey: 621, name: 'Mercari', urlPrefix: 'https://www.mercari.com/us/item/', dynamic: false },
        { metadataKey: 539.7, idKey: 836, name: 'Amazon', urlPrefix: 'https://www.amazon.com/', dynamic: false },
        { metadataKey: 539.8, idKey: 839, name: 'Grailed', urlPrefix: 'https://www.grailed.com/listings/', dynamic: false },
        { metadataKey: 539.9, idKey: 840, name: 'Facebook', urlPrefix: 'https://www.facebook.com/marketplace/category/', dynamic: false },
        { metadataKey: 539.11, idKey: 841, name: 'Depop', urlPrefix: 'https://www.depop.com/products/', dynamic: false },
        { metadataKey: 539.12, idKey: 853, name: 'Kidizen', urlPrefix: 'https://www.kidizen.com/items/', dynamic: false },
        { metadataKey: 539.13, idKey: 855, name: 'Shopify', urlPrefix: 'https://accounts.shopify.com/lookup', dynamic: false },
        { metadataKey: 539.14, idKey: 856, name: 'Instagram', urlPrefix: 'https://www.instagram.com/p/', dynamic: false },
        { metadataKey: 539.16, idKey: 621, name: 'listing_party', urlPrefix: 'https://listingparty.com/listings?id', dynamic: false },
        { metadataKey: 539.17, idKey: 885, name: 'Vestiaire', urlPrefix: 'https://vestiairecollective.com/', dynamic: false },
        // Dynamic Platforms
        { metadataKey: 891.1, idKey: 892, dynamic: true, condition: 'hasSimpleOnly' },
        { metadataKey: 891.2, idKey: 893, dynamic: true, condition: 'hasSimpleOnly' },
        { metadataKey: 891.3, idKey: 894, dynamic: true, condition: 'hasSimpleOnly'},
        { metadataKey: 891.4, idKey: 895, dynamic: true, condition: 'hasSimpleOnly' },
        { metadataKey: 891.5, idKey: 896, dynamic: true, condition: 'hasSimpleOnly' },
        { metadataKey: 891.6, idKey: 897, dynamic: true, condition: 'hasSimpleAndBusiness' },
        { metadataKey: 891.7, idKey: 898, dynamic: true, condition: 'hasSimpleAndBusiness' },
        { metadataKey: 891.8, idKey: 899, dynamic: true, condition: 'hasSimpleAndBusiness' },
        { metadataKey: 891.9, idKey: 900, dynamic: true, condition: 'hasSimpleAndBusiness' },
        { metadataKey: 891.10, idKey: 901, dynamic: true, condition: 'hasSimpleAndBusiness' },
    ];

    const shouldHidePlatform = (condition:any) => {
        if (condition === 'hasSimpleOnly' && hasSimple) return true;
        return !!(condition === 'hasSimpleAndBusiness' && (hasSimple || hasBusiness));

    };

    const customMarketsLocal = useSelector((state: any) => state.customMarketplaces.customMarketplaces);
    const [platforms, setPlatforms] = useState<any>(initialPlatforms);
    const handleUpdateState = (platformName: any) => {
        try {
            setPlatforms((prevPlatforms:any) => ({
                ...prevPlatforms,
                [platformName]: { isCopied: true },
            }));
            setTimeout(() => {
                setPlatforms((prevPlatforms:any) => ({
                    ...prevPlatforms,
                    [platformName]: { isCopied: false },
                }));
            }, 1000);
        }
        catch (e) {
            console.log('error', e)
        }
    };

    const shopifyLinkLocal = shopifyDynamicLink
        ? `https://admin.shopify.com/store/${shopifyDynamicLink}/products/${productInfo?.metadata[855]}`
        : `https://.myshopify.com/admin/products/${productInfo?.metadata[855]}`;

    useEffect(() => {
        // Array of codes to iterate over
        const codesToCheck = ['891.1', '891.2', '891.3', '891.4', '891.5', '891.6', '891.7', '891.8', '891.9', '891.10'];

        // Iterate over codes and update state
        codesToCheck.forEach(code => {
            const title = findAndReturnTitleByKey(code, customMarketsLocal);

            if (title) {
                setPlatforms((prevPlatforms:any) => ({
                    ...prevPlatforms,
                    [title]: { isCopied: false },
                }));
            }
        });
    }, [customMarketsLocal]);

    function findAndReturnTitleByKey(codeToFind:any, customMarkets:any) {
        for (const market of customMarkets) {
            if (market?.platform && market?.platform?.code && market?.platform?.code?.includes(codeToFind)) {
                return market?.platform?.title;
            }
        }
        // If code is not found, you can return null or any other default value
        return null;
    }

    const handleTooltipClickPlatforms = (tooltipName: any) => {
        setTooltipsPlatformActive((prevState:any) => ({
            ebay: tooltipName === 'eBay' ? !prevState.ebay : false,
            etsy: tooltipName === 'Etsy' ? !prevState.etsy : false,
            poshmark: tooltipName === 'Poshmark' ? !prevState.poshmark : false,
            mercari: tooltipName === 'Mercari' ? !prevState.mercari : false,
            amazon: tooltipName === 'Amazon' ? !prevState.amazon : false,
            grailed: tooltipName === 'Grailed' ? !prevState.grailed : false,
            facebook: tooltipName === 'Facebook' ? !prevState.facebook : false,
            instagram: tooltipName === 'Instagram' ? !prevState.instagram : false,
            depop: tooltipName === 'Depop' ? !prevState.depop : false,
            kidizen: tooltipName === 'Kidizen' ? !prevState.kidizen : false,
            shopify: tooltipName === 'Shopify' ? !prevState.shopify : false,
            vestiaire: tooltipName === 'Vestiaire' ? !prevState.vestiaire : false,
        }));
    };

    const renderedPlatforms = new Set();

    return (
        <div className={styles.mainLeftContainer}>
            <div className={styles.listedOn}>
                <div className={`${styles.fieldTitle} singlePageForTippy`}>
                    Listed on
                </div>
                <div className={styles.platformsContainer}>
                    {fullPlatformsList.map((platform: any) => {
                        const metadataValue = productInfo?.metadata[platform.metadataKey];
                        const platformIdValue = productInfo?.metadata[platform.idKey];
                        const platformName = platform.dynamic
                            ? findAndReturnTitleByKey(platform.metadataKey.toString(), customMarketsLocal)
                            : platform.name;
                        if (!metadataValue || shouldHidePlatform(platform.condition)) return null;
                        // Skip if already rendered
                        if (platformName && renderedPlatforms.has(platformName)) return null;
                        // Add to rendered list
                        renderedPlatforms.add(platformName);

                        return (
                            <div className={styles.platformInfo} key={platform.metadataKey}>
                                <div className={styles.iconBlock}>
                                    {platform.dynamic ? (
                                        <img
                                            style={{width: '35px'}}
                                            src="https://cdn.listperfectly.com/app.listperfectly.com/globals/icons/shop_light.svg"
                                            alt={platformName}
                                        />
                                    ) : (
                                        <PlatformBlockItem
                                            platformName={platformName}
                                            href={
                                                platformName === 'Shopify' && shopifyLinkLocal
                                                    ? shopifyLinkLocal
                                                    : `${platform.urlPrefix}${platformIdValue}`
                                            }
                                            isLink={!!platformIdValue && metadataValue === platformName}
                                            fromPlatform={true}
                                            visible={tooltipsPlatformActive[platformName?.toLowerCase()]}
                                            handleTooltipClickPlatforms={handleTooltipClickPlatforms}
                                            IsSold={productInfo?.metadata[539.1]}
                                        />
                                    )}
                                </div>
                                <div>
                                    <div className={styles.platformName}>
                                        {platformName === 'listing_party' ? 'Listing Party' : platformName}
                                    </div>
                                    <div className={styles.platformIdContainer}>
                                        {platformName !== 'listing_party' && <span className={styles.platformIdTitle}>ID</span>}
                                        {platformIdValue ? (
                                            <a
                                                className={styles.aListedOnId}
                                                href={
                                                    platformName === 'Shopify' && shopifyLinkLocal
                                                        ? shopifyLinkLocal
                                                        : platform.dynamic
                                                            ? platformIdValue
                                                            : `${platform.urlPrefix}${platformIdValue}`
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {platformName !== 'listing_party' && platformIdValue}
                                            </a>
                                        ) : (
                                            <div className={styles.noMarketListed}>{platformName !== 'listing_party' ? "-": ''}</div>
                                        )}
                                        {(platformIdValue && platformName !== 'listing_party') && (
                                            <>
                                                {platforms[platformName]?.isCopied ? (
                                                    <div className={styles.copiedContent}>
                                                        <CheckedIcon/>
                                                        <span>Copied</span>
                                                    </div>
                                                ) : (
                                                    <img
                                                        style={{cursor: 'pointer'}}
                                                        src={Vector}
                                                        alt="copyIcon"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(platformIdValue);
                                                            handleUpdateState(platformName);
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <PlatformsTitles hasSimple={hasSimple} productInfo={productInfo}/>
        </div>
    )
}

export default ListedMarkets;