import React from "react";
import useBasicFuncs from "@hooks/useBasisFuncs";
import styles from "./MainInfo.module.scss";

const MainInfo = ({productInfo} :any) => {
    const { bdecode } = useBasicFuncs();

    const platformsCode: string[] = [
        '539.3',
        '539.2',
        '539.3',
        '539.4',
        '539.5',
        '539.6',
        '539.7',
        '539.8',
        '539.9',
        '539.11',
        '539.12',
        '539.13',
        '539.14',
        '539.15',
        '539.16',
        '539.17',
        '891.1',
        '891.2',
        '891.3',
        '891.4',
        '891.5',
        '891.6',
        '891.7',
        '891.8',
        '891.9',
        '891.10'
    ];

    const containKeys = platformsCode.some(
        (key) => typeof productInfo?.metadata[key] === 'string'
    );

    const statuses = [
        {
            condition: containKeys && !productInfo?.metadata[859.1] &&
                !productInfo?.metadata[539.1] && productInfo?.metadata[851] !== '0',
            label: 'Active',
            style: styles.active
        },
        {
            condition: productInfo?.metadata[851] === '0' &&
                !productInfo?.metadata[859.1] && !productInfo?.metadata[539.1],
            label: 'Out of Stock',
            style: styles.outOfStock
        },
        {
            condition: productInfo?.metadata[539.1] && !productInfo?.metadata[859.1],
            label: 'Sold',
            style: styles.sold
        },
        {
            condition: productInfo?.metadata[859.1],
            label: 'Draft',
            style: styles.draft
        },
        {
            condition: !containKeys && !productInfo?.metadata[859.1] &&
                !productInfo?.metadata[539.1],
            label: 'Unlisted',
            style: styles.unlisted
        }
    ];

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    return (
        <div className={styles.mainInfoContainer}>
            <div className={styles.titleLeft}>
                {productInfo?.metadata[133] ? (
                    <div className={`${styles.titleText}`}>
                        {bdecode(productInfo?.metadata[133])}
                    </div>
                ) : (
                    <div className={styles.titleText}>--</div>
                )}
                {productInfo?.metadata[22] && (
                    <div className={styles.price}>
                        {formatter.format(productInfo?.metadata[22])}
                    </div>
                )}
            </div>
            <div className={styles.titleRight}>
                <div className={styles.listingStatusesContainer}>
                    {statuses.map((status, index) => (
                        status.condition && (
                            <div
                                key={index}
                                className={`${styles.listingStatusSinglView} ${status.style}`}>
                                {status.label}
                            </div>
                        )
                    ))}
                </div>
                <div className={styles.skuContainer}>
                    <div className={styles.skuLeftContainer}>
                        {productInfo?.metadata[1] ? (
                            <div className={styles.printViewSku}>
                                <div className={styles.titleObjectKeyNameSKU}>SKU</div>
                                <div className={styles.titleObjectValueSKU}>
                                    {bdecode(productInfo?.metadata[1])}
                                </div>
                            </div>
                        ) : ''}
                        {productInfo?.metadata[851] ? (
                            <div className={styles.printViewQuantity}>
                                <div className={styles.titleObjectKeyNameSKU}>Quantity</div>
                                <div className={styles.titleObjectValueSKU}>
                                    {bdecode(productInfo?.metadata[851])}
                                </div>
                            </div>
                        ) : ''}
                    </div>
                    {productInfo?.metadata[726] ? (
                        <div className={styles.sellerNotesContainer}>
                            <div className={styles.titleObjectKeyNameSKU}>Seller Notes</div>
                            <div className={styles.titleObjectValueSKU}>
                                {bdecode(productInfo?.metadata[726])}
                            </div>
                        </div>
                    ) : ''}
                </div>
            </div>
        </div>
    )
}

export default MainInfo;