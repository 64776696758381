import {useRef, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";

import styles from './Iframe.module.scss';
import closeIcon from '../../../assets/icons/png/close.png';
import {
  iframeLoaderChanger,
  setIframeLink,
  setRemoveShowIframe,
  setRemoveActionFromURL, setShowIframe
} from '../../../redux/slices/NavigationSlice';
import useAppPrefix from '../../../hooks/UseAppPrefix';
import Tippy from '@tippyjs/react';
import { CircleQuestionGraySVG } from '../../../assets/icons';
import useRoles from "@hooks/useRoles";

interface NavigationState {
  active: boolean;
  editModalShow: boolean;
  showAddlistingIframe: boolean;
  showAddTemplateIframe: boolean;
  showAddlistingIframeQuickLink: boolean;
  zIndexGenerator: number[];
  isBulkEdit: boolean;
  saved: boolean;
  zIndex: number;
  showIframe: boolean;
  iframeLink: string;
  iframeLoad: boolean;
  showModalQuestion: boolean;
  candidatIframeLink: string;
  showNotificationModal: boolean;
  showRedirectModal: boolean;
  isMobile: boolean;
  iframeTitle: string;
}

interface RolesState {
  roles: string[];
}

interface RootState {
  navigation: NavigationState;
  roles: RolesState;
}

const Iframe = () => {
  const navigate = useNavigate()
  const location = window.location ;
  const searchParams = new URLSearchParams(location.search);
  const linkPrefix = useAppPrefix();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [saveAndCloseActive, setSaveAndCloseActive] = useState(false);
  const showIframe = useSelector(
      (state: RootState) => state.navigation.showIframe
  );
  const iframeTitle = useSelector(
      (state: RootState) => state.navigation.iframeTitle
  );
  const iframeLink = useSelector(
      (state: RootState) => state.navigation.iframeLink
  );
  const iframeLoad = useSelector(
      (state: RootState) => state.navigation.iframeLoad
  );
  const {hasSimple, hasBusiness, hasPro, is_role_allowed } = useRoles();

  const dispatch = useDispatch();

  const sendMessageToIframe = () => {
    if (!hasSimple && !hasBusiness) {
      const iframe: any = document.getElementById('productIframe');

      iframe.contentWindow.postMessage(
          { target: 'listing-assistant', data: 'show' },
          '*'
      );
    }
  };

  const sendMessage = () => {
    if (iframeRef.current) {
      if (iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
            { target: 'change-save-and-close', data: 'true' },
            '*'
        );
      }
    }
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (
          event.origin === 'https://apptest.listperfectly.com' ||
          event.origin === 'https://app.listperfectly.com'
      ) {
        const { data: { target = '', data = '' } = {} } = event || {};
        if (target === 'get-save-and-close') {
         setSaveAndCloseActive(data)
        }
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('message', handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if(iframeTitle === 'edit listing') {
      try {
        let intervalId : any;
        let timeoutId: any;
        const sendMessageInterval = () => {
          sendMessage();
        };

        if (showIframe && !iframeLoad && iframeRef.current) {
          if (!saveAndCloseActive) {
            sendMessageInterval()
            intervalId = setInterval(sendMessageInterval, 10);
          } else {
            clearInterval(intervalId);
          }
          timeoutId = setTimeout(() => {
            clearInterval(intervalId);
          }, 2000);
        }

        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
      }
      catch (e) {
        console.log(e)
      }
    }
  }, [showIframe, iframeTitle, iframeLoad, saveAndCloseActive, iframeRef.current]);

  const handleIframeLoad = () => {
    dispatch(iframeLoaderChanger(false));
  };

  useEffect(() => {
    return () => {
      dispatch(setRemoveShowIframe());
      dispatch(iframeLoaderChanger(false));
      dispatch(setIframeLink(''));
    }
  }, []);



  return showIframe ? (
      <div className={styles.iframeContainer}>
        {iframeLoad && (
            <div className={styles.loaderIframe}>
              <div className={styles.loaderAnimation}></div>
            </div>
        )}
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
        >
          <div className={styles.closeBackground}>
            {(iframeTitle === 'add listing' ||
                iframeTitle === 'edit listing') && (
                <div className={styles.listingAssistantBtnBlock}>
                  <button
                      style={{
                        background:
                            (hasPro || hasSimple || hasBusiness) && !is_role_allowed
                                ? '#808080'
                                : '#234165',
                        opacity:
                            (hasPro || hasSimple || hasBusiness) && !is_role_allowed
                                ? 0.75
                                : 1,
                        cursor:
                            (hasPro || hasSimple || hasBusiness) && !is_role_allowed
                                ? 'not-allowed !important'
                                : 'pointer',
                      }}
                      className={styles.assistantButton}
                      onClick={sendMessageToIframe}
                  >
                    Listing assistant
                  </button>

                  {(hasPro || hasBusiness || hasSimple) && !is_role_allowed && (
                      <Tippy
                          interactive={true}
                          zIndex={5}
                          arrow={false}
                          trigger="focus click"
                          content={
                            <div style={{ textAlign: 'center' }}>
                              <p>
                                Listing Assistant (Pro Plus) – Use AI to generate descriptions from a title or scan a barcode to auto-fill listing details.
                                <br/>
                                <br/>
                                Listing Assistant Express Lister – AI-generated listing details directly from your images. Now available on ALL plans.
                                <a style={{color: "#2185d0", fontSize: 17}} target="_blank" href="https://my.listperfectly.com/listings/express"> Create now !</a>
                              </p>

                            </div>
                          }
                      >
                        <div style={{ cursor: 'pointer' }}>
                          <CircleQuestionGraySVG />
                        </div>
                      </Tippy>
                  )}
                </div>
            )}

            <img
                className={styles.closeIcon}
                src={closeIcon}
                onClick={() => {
                  if(iframeTitle === 'add listing') {
                    dispatch(setRemoveActionFromURL(true));
                  }
                  if(iframeTitle === 'title' && searchParams?.has('modal')) {
                    searchParams.delete('modal');
                    navigate(`?${searchParams.toString()}`);
                  }
                  dispatch(setRemoveShowIframe());
                  dispatch(iframeLoaderChanger(false));
                  dispatch(setIframeLink(''));
                }}
                alt=""
            />
          </div>

          <iframe
              id="productIframe"
              ref={iframeRef}
              src={`https://${linkPrefix.prefix}.${iframeLink}`}
              className="iframeStyle"
              onLoad={handleIframeLoad}
              title="editIframe"
              allow="autoplay; camera; microphone"
          />
        </div>
      </div>
  ) : null;
};

export default Iframe;