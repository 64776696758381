import styles from './SellerNotesDraft.module.scss';
import Tippy from "@tippyjs/react";
import {PopoverIcon} from "../../../../assets/icons";
import {TextareaWithCount} from "../../TextAreaCount";
import React, { useState} from "react";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import getNameGenerator from "../../../../hooks/useNameGenerator";
export const SellerNotesDraft = ({hasBusiness, hasSimple, isCheckBoxChecked,  setIsCheckBoxChecked, itemData, editIndex, setItemData, setFormInfo, handleStandartInputChange, formInfo, unescapedRowMetadata, allowDraftsPageAccess }:any) => {

	const handleDraftChanged = (
	 e:
	  | React.ChangeEvent<HTMLInputElement>
	  | React.ChangeEvent<HTMLTextAreaElement>
	  | React.ChangeEvent<HTMLSelectElement>,

	)=>{

		if (
		 e.target.type === 'checkbox' &&
		 e.target instanceof HTMLInputElement
		) {
			const checked = e.target.checked;
			let updatedItems: any = [...itemData];
			const generatedName = getNameGenerator(e.target.name);
			updatedItems[editIndex] = {
				...updatedItems[editIndex],
				[generatedName]: checked ? 'Draft' : null,
				changed: true,
			};
			setItemData(updatedItems);
			setFormInfo({
				...formInfo,
				[generatedName]: { checked: checked, changed: true },
			});
		}
	}

	const toggleCheckbox = (e: any) => {
		handleDraftChanged(e);
		setIsCheckBoxChecked(!isCheckBoxChecked);
	};


	const { bdecode } = useBasisFuncs();


	return(
	 <div>
		 <div className={styles.sellerDesc}>
			 <p className={styles.title}>
				 Seller Notes (will not display in listing)
			 </p>
			 <TextareaWithCount
			  placeholder=""
			  name="input_726"
			  value={
				  formInfo.newNotes.changed
				   ? bdecode(formInfo.newNotes.text)
				   : unescapedRowMetadata[726]
					? bdecode(unescapedRowMetadata[726])
					: ''
			  }
			  limit={30000}
			  showCount={false}
			  handleChange={handleStandartInputChange}
			  height={150}
			 />
		 </div>
		 {
		  (!hasSimple && !hasBusiness) && <div className={styles.markListing}>
			  <p className={styles.title}>
				  Mark listing as as draft if your listing needs review or is
				  still in progress
				  <Tippy
				   interactive={true}
				   zIndex={9991}
				   trigger="click"
				   arrow={false}
				   content={
					   <div className={`popover ${styles.popoverContent}`}>
						   <ul>
							   <li>
								   Listings marked as Draft will be shown on the{' '}
								   <a
									href="https://app.listperfectly.com/docs/topics/list-perfectly-products/#draft"
									target="_blank"
								   >
									   Drafts page
								   </a>
							   </li>
							   <li>
								   <a
									href="https://app.listperfectly.com/docs/topics/inventory-management/draft-listings/"
									target="_blank"
								   >
									   Draft listings guide
								   </a>
							   </li>
						   </ul>
					   </div>
				   }
				  >
					  <PopoverIcon
					   className={styles.popoverIcon}
					   color={'#fff'}
					  ></PopoverIcon>
				  </Tippy>
			  </p>
				 <>
					 {allowDraftsPageAccess === false ? <Tippy
						 interactive={true}
						 zIndex={9999}
						 trigger="click"
						 arrow={false}
						 content={
							 <div className={`popover ${styles.popoverContent}`}>
								 <p>Request access from primary account</p>
							 </div>
						 }
					 >
						 <div
							 className={`${styles.draftCheckbox}`}
							 style={{border: '1px solid #B6B6B6', cursor: 'not-allowed'}}
						 >
							 <label className={styles.checkBoxContainer}
									style={{cursor: 'not-allowed'}}>
								 <input
									 type="checkbox"
									 disabled={true}
								 />
								 <span
									 className={`${styles.checkMark}`}
									 style={{backgroundColor: '#B6B6B6', borderColor: '#B6B6B6'}}
								 ></span>
							 </label>
							 <span>Draft</span>
						 </div>
					 </Tippy> : <div
						 className={`${styles.draftCheckbox} ${
							 isCheckBoxChecked ? styles.addBorder : ''
						 }`}
					 >
						 <label className={styles.checkBoxContainer}>
							 <input
								 type="checkbox"
								 name="input_859.1"
								 checked={isCheckBoxChecked}
								 onChange={(e) => toggleCheckbox(e)}
							 />
							 <span
								 className={`${
									 isCheckBoxChecked
										 ? styles.checkMark
										 : styles.checkboxChecked
								 }`}
							 ></span>
						 </label>
						 <span>Draft</span>
					 </div>}

				 </>

			 </div>
		 }
	 </div>
	)
}