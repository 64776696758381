import React, {useEffect, useMemo, useState} from "react";
import {
    Drafted,
    FaRotateLeft,
    SVGNewPencil,
    SVGNewSquares,
    SVGNewTrash
} from "../../../../assets/icons";
import styles from './TableRow.module.scss';
import {TextareaWithCharacterCount} from "../../../../components/UI/TextArea";
import _ from "lodash";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {instanceAxios} from "../../../../services";
import {toast} from "react-toastify";
import {IConfirmModalShow} from "../../../../interfaces";
import ConfirmModal from "../../../CatalogPage/components/Table/components/ConfirmModal";

import {
    iframeLoaderChanger, setCandidantLinkPure, setEditTemplateId,
    setIframeLink,
    setIframeName, setIsDuplicateTemplate, setShowEditTemplateModal,
    setShowIframe, setShowQuestionModal
} from "../../../../redux/slices/NavigationSlice";

import useAppPrefix from "../../../../hooks/UseAppPrefix";
import {useNavigate} from "react-router-dom";
import Checkbox, {CheckboxModifier} from "../../../../components/UI/Checkbox";
import useRoles from "@hooks/useRoles";
interface NavigationState {
    iframeLink: string;
}

interface RootState {
    navigation: NavigationState;
}
const TableRow = ({ defaultTemplateId, setDefaultTemplateId, setFormChanged, row, isBulkEdit, handleChangeCheckbox, index, handleBulkRestore, setSaveActive, columns, selectedListings, handleRowSelected, handleBulkDelete, listingsForDelete, tableData, setTableData, stickyColumnsListTemplates, getStickyStyle, activeIndex, mouseDown}:any) => {
    const {hasSimple} = useRoles();
    const navigate = useNavigate();
    const [newTableInfo, setNewTableInfo] = useState<any>({
        newTitle: {
            text: '',
            changed: false,
        },
        newActive: {
            checked: false,
            changed: false,
        },
        newDefaultTemplate: {
            checked: false,
            changed: false,
        },
    });
    const {bdecode} = useBasisFuncs();
    const [checker, setChecker] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState<IConfirmModalShow>({
        status: false,
        role: '',
        listingCount: 0,
    });
    const dispatch = useDispatch();
    const linkPrefix = useAppPrefix();
    const iframeLink = useSelector(
     (state: RootState) => state.navigation.iframeLink
    );
    const unescapedRowMetadata = useMemo(() => {
        const result: any = {};
        for (const key in row?.metadata) {
            if (row.metadata.hasOwnProperty(key)) {
                result[key] = _.get(row.metadata, key);
            }
        }
        return result;
    }, [row]);

    const handleChangeStatusToListing = async () => {
        const previousTableData = [...tableData];


        function checkIfExists(listingsForDelete:any, idToCheck:any) {
            return listingsForDelete.some((id:any) => id === idToCheck);
        }

        const exists = checkIfExists(listingsForDelete, row?.id);

        const remainingSelectedListings = selectedListings.filter((id:any) => !listingsForDelete.some((item:any) => item === id));

        const foundItem:any = tableData.find((item:any) => item.id === row?.id);
        const isChecked = foundItem?.isChecked;


        if (selectedListings?.length > 0 && !exists && isChecked) {
            setConfirmModalShow({
                ...confirmModalShow,
                status: true,
                role: 'delete',
                listingCount: remainingSelectedListings?.length,
            });
        } else if (selectedListings?.length > 0 && exists && isChecked) {
            setConfirmModalShow({
                ...confirmModalShow,
                status: true,
                role: 'restore',
                listingCount: listingsForDelete?.length,
            });
        } else {
            try {
                const newStatus = row.status === 'active' ? 'trash' : 'active';
                const tempTableData = tableData.map((item:any) => {
                    if (item.id === row.id) {
                        return {...item, status: newStatus};
                    } else {
                        return item;
                    }
                });
                setTableData(tempTableData);

                const response = await instanceAxios.put('/templates/update', {
                    filter_by: {id: row.id},
                    payload: {status: newStatus},
                });

            } catch (error) {

                setTableData(previousTableData)
                toast.error(`Oops! We encountered an error while attempting to ${row.status === 'active' ? 'delete' : 'restore'} your listing. Please try again later.`, {
                    position: 'bottom-right',
                });
                console.error(error);
            }
        }
    };

    const handleToggleConfirmModalShow = () => [
        setConfirmModalShow({
            ...confirmModalShow,
            status: !confirmModalShow.status,
        }),
    ];

    const handleFuncDeleteOrRestore = (role: string) => {
        if (role === 'delete') {
            handleBulkDelete();
        } else if (role === 'restore') {
            handleBulkRestore();
        }
        handleToggleConfirmModalShow();
    };

    const handleChangetemplateEdit = (
     e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const {name, value} = e.target;
        const updatedItems = [...tableData];
        if (
         e.target instanceof HTMLInputElement &&
         (e.target.type === 'checkbox' || e.target.type === 'radio')
        ) {
            const checked = e.target.checked;

            updatedItems[index] = {
                ...updatedItems[index],
                [name]: checked ? '1' : null,
                changed: true,
            };
            setNewTableInfo({
                ...newTableInfo,
                [name]: {checked: checked, changed: true},
            });
        } else {
            updatedItems[index] = {
                ...updatedItems[index],
                [name]: value,
                changed: true,
            };
            setNewTableInfo({
                ...newTableInfo,
                [name]: {text: value, changed: true},
            });
        }
        setSaveActive(true);
        setTableData(updatedItems);
    };

    useEffect(() => {
        const allChecked = tableData.some((item :any ) => item.isChecked === true);
        if (allChecked) {
            setChecker(true);
        } else {
            setChecker(false);
        }
    }, [isBulkEdit]);

    const activeRowClass = row?.isChecked && !isBulkEdit ? styles.activeRow : '';
    const rowNotActiveClass =
     !row?.isChecked && isBulkEdit && checker ? styles.rowNotActive : '';
    const combinedClassName = `${activeRowClass} ${rowNotActiveClass}`;
    useEffect(() => {
        if(row?.metadata?.default_template){
            setDefaultTemplateId(row?.id)
        }
    }, []);

    const handleChangeDefaultTemplate = (e:any, rowId: any) => {
        const isChecked = rowId !== defaultTemplateId;
        if (isChecked) {
            setDefaultTemplateId(rowId);
        }

        const { name } = e.target;
        const updatedItems = [...tableData];

        const checked = e.target.checked;

        const updatedItemsNew = updatedItems.map((item, idx) => ({
            ...item,
            newDefaultTemplate: false,
        }));

        updatedItemsNew[index] = {
         ...updatedItemsNew[index],
         [name]: checked ? true : false,
            changed: true,
         };
         setNewTableInfo({
            ...newTableInfo,
            [name]: {checked: checked, changed: true},
         });
        setSaveActive(true);
        setTableData(updatedItemsNew);
    };

    useEffect(() => {
        if(newTableInfo.newDefaultTemplate.changed || newTableInfo.newActive.changed) {
            setFormChanged(true);
            setNewTableInfo((prevState: any) => ({
                ...prevState,
                newActive: {
                    ...prevState.newActive,
                    changed: false,
                },
                newDefaultTemplate: {
                    ...prevState.newDefaultTemplate,
                    changed: false,
                },
            }));
        }
    }, [newTableInfo.newActive, newTableInfo.newDefaultTemplate]);

    return (
        <>
            {confirmModalShow.status && (
             <ConfirmModal
              handleToggleConfirmModalShow={handleToggleConfirmModalShow}
              confirmModalShow={confirmModalShow}
              selectedListings={selectedListings}
              handleFuncDeleteOrRestore={handleFuncDeleteOrRestore}
              type={'Templates'}
             />
            )}
            <tr className={combinedClassName} id="templateRowId">
                <td onClick={(e: any) => {
                    if (!hasSimple) {
                        handleRowSelected(e, row?.id, row?.isChecked);
                    }
                    }}
                    className='stickyCheckboxColumn'
                >
                    <Checkbox
                        name={row?.id}
                        checked={row?.isChecked && !isBulkEdit ? row.isChecked : undefined}
                        onChange={handleChangeCheckbox}
                        modifier={CheckboxModifier.Primary}
                        disabled={hasSimple ? true : isBulkEdit}
                        visibility={!hasSimple}
                    />
                </td>

                {columns.map((columnItem: any, i: any) =>
                 columnItem.value === 'title' ?
                  (<td
                      className={`
				    ${stickyColumnsListTemplates.some(
                          (obj: any) =>
                              obj.value?.toLowerCase() === 'title' &&
                              obj.stickyColumn
                      ) ? 'stickyColumnActive' : ''}
				   `}
                      style={{
                          ...getStickyStyle(columnItem.value), // Dynamic styles
                      }}
                  >
                      {
                          !isBulkEdit ?
                           <p>{row?.newTitle !== undefined ? bdecode(row!.newTitle) : bdecode(unescapedRowMetadata[133])}</p>
                           : <TextareaWithCharacterCount
                            name="newTitle"
                            value={
                                row?.newTitle !== undefined
                                 ? bdecode(row!.newTitle)
                                 : bdecode(unescapedRowMetadata[133])
                            }
                            onChange={handleChangetemplateEdit}
                           />

                      }
                      {
                          stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === 'title' && obj.activeColumn) &&
                          <div
                              style={{
                                  height: '100%',
                                  position: 'absolute',
                                  zIndex: 534534,
                              }}
                              onMouseDown={(e) => {
                                  e.preventDefault(); // Prevent any default browser behavior
                                  if (typeof mouseDown === "function") {
                                      mouseDown(i); // Pass the index of the column you want to resize
                                  }
                                  // Add global mouseup fallback to ensure cleanup
                                  const stopResize = () => {
                                      const mouseUpEvent = new MouseEvent("mouseup");
                                      window.dispatchEvent(mouseUpEvent);
                                      window.removeEventListener("mouseup", stopResize); // Clean up
                                  };
                                  window.addEventListener("mouseup", stopResize);
                              }}
                              className={`resize-handle ${
                                  activeIndex === i ? 'active' : 'idle'
                              } 
						 
						  `}
                          />
                      }
                  </td>) :
                  columnItem.value === 'default_template' ?
                   (<td
                        className={` ${styles.centeredTd}
				    ${stickyColumnsListTemplates.some(
                            (obj: any) =>
                                obj.value?.toLowerCase() === 'default_template' &&
                                obj.stickyColumn
                        ) ? 'stickyColumnActive' : ''}
				   `}
                        style={{
                            ...getStickyStyle(columnItem.value), // Dynamic styles
                        }}
                   >
                       {
                            <input
                             className={styles.defaultTemplateCheckbox}
                             type="checkbox"
                             name="newDefaultTemplate"
                             style={{
                                 cursor: 'pointer',
                                 width: '30px',
                             }}
                             checked={ row?.newDefaultTemplate !== undefined ? row?.newDefaultTemplate ? true : false :  row?.id === defaultTemplateId ? true : false }
                             onChange={(event) => handleChangeDefaultTemplate(event, row?.id)}
                            />
                       }
                       {
                           stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === 'default_template' && obj.activeColumn) &&
                           <div
                               style={{
                                   height: '100%',
                                   position: 'absolute',
                                   zIndex: 534534,
                               }}
                               onMouseDown={(e) => {
                                   e.preventDefault(); // Prevent any default browser behavior
                                   if (typeof mouseDown === "function") {
                                       mouseDown(i); // Pass the index of the column you want to resize
                                   }
                                   // Add global mouseup fallback to ensure cleanup
                                   const stopResize = () => {
                                       const mouseUpEvent = new MouseEvent("mouseup");
                                       window.dispatchEvent(mouseUpEvent);
                                       window.removeEventListener("mouseup", stopResize); // Clean up
                                   };
                                   window.addEventListener("mouseup", stopResize);
                               }}
                               className={`resize-handle ${
                                   activeIndex === i ? 'active' : 'idle'
                               } 
						 
						  `}
                           />
                       }
                   </td>) : columnItem.value === 'active' ? (
                    <td
                        className={` ${styles.centeredTd}
				    ${stickyColumnsListTemplates.some(
                            (obj: any) =>
                                obj.value?.toLowerCase() === 'active' &&
                                obj.stickyColumn
                        ) ? 'stickyColumnActive' : ''}
				   `}
                        style={{
                            ...getStickyStyle(columnItem.value), // Dynamic styles
                        }}
                    >
                        {
                           <input
                              className={styles.templateActiveCheckbox}
                              type="checkbox"
                              name="newActive"
                              style={{
                                  cursor: 'pointer',
                                  width: '30px',
                              }}
                              checked={
                                  row?.newActive !== undefined ? row?.newActive ? true : false : unescapedRowMetadata[122.1] ? true : false
                              }
                              onChange={handleChangetemplateEdit}
                             />
                        }
                        {
                            stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === 'active' && obj.activeColumn) &&
                            <div
                                style={{
                                    height: '100%',
                                    position: 'absolute',
                                    zIndex: 534534,
                                }}
                                onMouseDown={(e) => {
                                    e.preventDefault(); // Prevent any default browser behavior
                                    if (typeof mouseDown === "function") {
                                        mouseDown(i); // Pass the index of the column you want to resize
                                    }
                                    // Add global mouseup fallback to ensure cleanup
                                    const stopResize = () => {
                                        const mouseUpEvent = new MouseEvent("mouseup");
                                        window.dispatchEvent(mouseUpEvent);
                                        window.removeEventListener("mouseup", stopResize); // Clean up
                                    };
                                    window.addEventListener("mouseup", stopResize);
                                }}
                                className={`resize-handle ${
                                    activeIndex === i ? 'active' : 'idle'
                                } 
						 
						  `}
                            />
                        }
                    </td>
                   ) : columnItem.value === 'updated' ? (
                    <td
                        className={` ${styles.centeredTd}
				    ${stickyColumnsListTemplates.some(
                            (obj: any) =>
                                obj.value?.toLowerCase() === 'updated' &&
                                obj.stickyColumn
                        ) ? 'stickyColumnActive' : ''}
				   `}
                        style={{
                            ...getStickyStyle(columnItem.value), // Dynamic styles
                        }}
                    >
                        {moment(row?.updated_at).format('MM/DD/YYYY')}
                        {
                            stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === 'updated' && obj.activeColumn) &&
                            <div
                                style={{
                                    height: '100%',
                                    position: 'absolute',
                                    zIndex: 534534,
                                }}
                                onMouseDown={(e) => {
                                    e.preventDefault(); // Prevent any default browser behavior
                                    if (typeof mouseDown === "function") {
                                        mouseDown(i); // Pass the index of the column you want to resize
                                    }
                                    // Add global mouseup fallback to ensure cleanup
                                    const stopResize = () => {
                                        const mouseUpEvent = new MouseEvent("mouseup");
                                        window.dispatchEvent(mouseUpEvent);
                                        window.removeEventListener("mouseup", stopResize); // Clean up
                                    };
                                    window.addEventListener("mouseup", stopResize);
                                }}
                                className={`resize-handle ${
                                    activeIndex === i ? 'active' : 'idle'
                                } 
						 
						  `}
                            />
                        }
                    </td>
                   ) : columnItem.value === 'assignee' ? (
                     <td className={` ${styles.centeredTd}
				    ${stickyColumnsListTemplates.some(
                         (obj: any) =>
                             obj.value?.toLowerCase() === 'assignee' &&
                             obj.stickyColumn
                     ) ? 'stickyColumnActive' : ''}
				   `}
                         style={{
                             ...getStickyStyle(columnItem.value), // Dynamic styles
                         }}
                     >{row?.assignee ? row.assignee : '-'}
                         {
                             stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === 'assignee' && obj.activeColumn) &&
                             <div
                                 style={{
                                     height: '100%',
                                     position: 'absolute',
                                     zIndex: 534534,
                                 }}
                                 onMouseDown={(e) => {
                                     e.preventDefault(); // Prevent any default browser behavior
                                     if (typeof mouseDown === "function") {
                                         mouseDown(i); // Pass the index of the column you want to resize
                                     }
                                     // Add global mouseup fallback to ensure cleanup
                                     const stopResize = () => {
                                         const mouseUpEvent = new MouseEvent("mouseup");
                                         window.dispatchEvent(mouseUpEvent);
                                         window.removeEventListener("mouseup", stopResize); // Clean up
                                     };
                                     window.addEventListener("mouseup", stopResize);
                                 }}
                                 className={`resize-handle ${
                                     activeIndex === i ? 'active' : 'idle'
                                 } 
						 
						  `}
                             />
                         }
                     </td>
                   ) : columnItem.value === 'created' ?
                   (<td className={` ${styles.centeredTd}
				    ${stickyColumnsListTemplates.some(
                       (obj: any) =>
                           obj.value?.toLowerCase() === 'created' &&
                           obj.stickyColumn
                   ) ? 'stickyColumnActive' : ''}
				   `}
                        style={{
                            ...getStickyStyle(columnItem.value), // Dynamic styles
                        }}>{moment(row?.created_at).format('MM/DD/YYYY')}
                       {
                           stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === 'created' && obj.activeColumn) &&
                           <div
                               style={{
                                   height: '100%',
                                   position: 'absolute',
                                   zIndex: 534534,
                               }}
                               onMouseDown={(e) => {
                                   e.preventDefault(); // Prevent any default browser behavior
                                   if (typeof mouseDown === "function") {
                                       mouseDown(i); // Pass the index of the column you want to resize
                                   }
                                   // Add global mouseup fallback to ensure cleanup
                                   const stopResize = () => {
                                       const mouseUpEvent = new MouseEvent("mouseup");
                                       window.dispatchEvent(mouseUpEvent);
                                       window.removeEventListener("mouseup", stopResize); // Clean up
                                   };
                                   window.addEventListener("mouseup", stopResize);
                               }}
                               className={`resize-handle ${
                                   activeIndex === i ? 'active' : 'idle'
                               } 
						 
						  `}
                           />
                       }
                   </td>) :
                       columnItem.value === 'actions' ? (
                        <td className={` ${styles.centeredTd}
				           ${stickyColumnsListTemplates.some(
                            (obj: any) =>
                                obj.value?.toLowerCase() === 'actions' &&
                                obj.stickyColumn
                           ) ? 'stickyColumnActive' : ''}
				         `}
                            style={{
                                ...getStickyStyle(columnItem.value), // Dynamic styles
                            }}>
                            <div className={styles.actions}>
                                <a
                                 className={styles.editSpan}
                                 title="Edit"
                                 onClick={(e:any) => {
                                     e.preventDefault();
                                     // navigate(`/create-templates/${row.id}`)
                                     dispatch(setShowEditTemplateModal(true))
                                     dispatch(setEditTemplateId(row.id))
                                 }}
                                 style={{borderColor: '#4db89e'}}
                                 target="_blank"
                                >
                                    <SVGNewPencil/>
                                </a>
                                <a
                                 className={styles.pageSpan}
                                 target="_blank"
                                 onClick={(e:any) => {
                                     e.preventDefault();
                                     dispatch(setIsDuplicateTemplate(true))
                                     dispatch(setShowEditTemplateModal(true))
                                     dispatch(setEditTemplateId(row.id))
                                 }}
                                 rel="noreferrer"
                                 title="Duplicate"
                                 style={{borderColor: '#1E3166'}}
                                >
                                    <SVGNewSquares/>
                                </a>
                                {row.status === 'active' ? (
                                 <a
                                  className={styles.trashSpan}
                                  onClick={handleChangeStatusToListing}
                                  title="Delete"
                                  style={{borderColor: '#CA1010'}}
                                 >
                                     <SVGNewTrash/>
                                 </a>
                                ) : (
                                 <a
                                  className={styles.recoverySpan}
                                  onClick={handleChangeStatusToListing}
                                  title="Restore"
                                 >
                                     <FaRotateLeft/>
                                 </a>
                                )}
                            </div>
                            {
                                stickyColumnsListTemplates.some((obj: any) => obj.value?.toLowerCase() === 'actions' && obj.activeColumn) &&
                                <div
                                    style={{
                                        height: '100%',
                                        position: 'absolute',
                                        zIndex: 534534,
                                    }}
                                    onMouseDown={(e) => {
                                        e.preventDefault(); // Prevent any default browser behavior
                                        if (typeof mouseDown === "function") {
                                            mouseDown(i); // Pass the index of the column you want to resize
                                        }
                                        // Add global mouseup fallback to ensure cleanup
                                        const stopResize = () => {
                                            const mouseUpEvent = new MouseEvent("mouseup");
                                            window.dispatchEvent(mouseUpEvent);
                                            window.removeEventListener("mouseup", stopResize); // Clean up
                                        };
                                        window.addEventListener("mouseup", stopResize);
                                    }}
                                    className={`resize-handle ${
                                        activeIndex === i ? 'active' : 'idle'
                                    } 
						 
						  `}
                                />
                            }
                        </td>
                       ) : null
                )}
            </tr>
        </>
    )
}

export default TableRow;
