import React from 'react' ;
import styles from './RemoveAccountAccessModal.module.scss';

import {TrashCanDelete} from "@assets/icons";
import Button, {ModifierType} from "../../../../../../components/UI/Button";

interface IRemoveAccountAccessModalUIProps {
	removeAccountAccessModalRef:any;
	removeSubAccountAccessModalItems:any;
	selectedAction:any;
	handleRadioChange:(action: 'detach' | 'delete') => void;
	handleRemoveSubAccount:(action:'detach' | 'delete') => void;
	handleToggleRemoveAccountAccessModal:() => void;

}

const RemoveAccountAccessModalUI = (props:IRemoveAccountAccessModalUIProps) => {
	const {removeAccountAccessModalRef,removeSubAccountAccessModalItems,selectedAction,handleRadioChange,handleRemoveSubAccount,handleToggleRemoveAccountAccessModal} = props ;
	return (
	 <div className={styles['modal__overlay']}>
		 <div ref={removeAccountAccessModalRef} className={styles['modal']}>
			 <div className={styles['modal__header']}>
				 <TrashCanDelete/>
				 <div>Remove Sub Account Access</div>
			 </div>
			 <div className={styles['modal__content']}>
				 {removeSubAccountAccessModalItems.map((subAccount: any, index: any) => (
				  <div
				   key={subAccount.id}
				   className={styles['modal__content-option']}
				  >
					  <input
					   id={`radioBtn${index}`}
					   type={"radio"}
					   name={"radioBtn"}
					   className={styles['modal__content-radio']}
					   checked={selectedAction === subAccount.action}
					   onChange={() => handleRadioChange(subAccount.action)}

					  />
					  <label
					   htmlFor={`radioBtn${index}`}
					   className={`${selectedAction === subAccount.action ? styles['modal__content-radio--active'] : ''}`}
					  >
						  <span className={styles['modal__custom-radio']}></span>
						  {subAccount.label}</label>
				  </div>
				 ))}
			 </div>
			 <div className={styles['modal__footer']}>
				 <Button modifier={ModifierType.Transparent} style={{color: '#767676', background: '#EFEFEF'}}
						 handleClick={handleToggleRemoveAccountAccessModal}>
					 Cancel
				 </Button>
				 <Button modifier={ModifierType.Primary} handleClick={() => handleRemoveSubAccount(selectedAction)}>
					 Confirm Removal
				 </Button>
			 </div>
		 </div>
	 </div>

	)
}

export default RemoveAccountAccessModalUI;