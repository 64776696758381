import _ from 'lodash';

// Import config
import config from "../config";

// Import API client
import LP_API_Client from './LP_API_Client';


class Notifications {

    list(filter_by : any, metadata : any, options: any, sort_by: any, token : string){

        return new Promise(async (resolve, reject) => {

            // Init root params
            const params : any = {
                method: 'POST',
                url: `${config.base_url}/v2/notifications/list`,
                data: {},
                headers: { Authorization: `Bearer ${token}` }
            };

            // Attach data params
            if(filter_by) _.set(params, 'data.filter_by', filter_by);
            if(metadata) _.set(params, 'data.metadata', metadata);
            if(options) _.set(params, 'data.options', options);
            if(sort_by) _.set(params, 'data.sort_by', sort_by);

            // Return result
            LP_API_Client(params)
                .then((res : any) => {
                    const data : any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err : any) => {
                    return reject(err);
                });

        });

    }

    update(payload:any, token: any) {
        return new Promise(async (resolve, reject) => {
            // Init root params
            const params: any = {
                method: 'POST',
                url: `${config.base_url}/v1/notifications/dismiss`,
                data: {
                    ...payload,
                },
                headers: {Authorization: `Bearer ${token}`}
            };

            // Return result
            LP_API_Client(params)
                .then((res: any) => {
                    const data: any = _.get(res, 'data');
                    return resolve(data);
                })
                .catch((err: any) => {
                    return reject(err);
                });
        });
    }
}

export default new Notifications();
