import React, {useEffect} from 'react'
import styles from './MainPage.module.scss'
import {useDispatch} from "react-redux";

import QuickLinks from "./components/QuickLinks";
import Iframe from "../../components/UI/Iframe";
import {setRoles} from "../../redux/slices/RoleSlice";
import {Helmet} from "react-helmet";
import useRoles from "@hooks/useRoles";


interface IMainPageProps {
}

const MainPage = (props: IMainPageProps) => {
	const {roles} = useRoles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setRoles(roles));
	}, [dispatch]);

	return (
	 <>
		 <Helmet>
			 <title>
				 Main Menu | List Perfectly
			 </title>
		 </Helmet>
		 <div
		  className={styles.mainPage}
		 ><QuickLinks/>
			 <Iframe/>
		 </div>
	 </>

	)
}

export default MainPage