import styles from './ProDescriptionsComponent.module.scss';
import Tippy from "@tippyjs/react";
import {AddButton, PopoverIcon} from "../../../../assets/icons";
import {InputCount} from "../../InputCount";
import useBasisFuncs from "../../../../hooks/useBasisFuncs";
import {TextareaWithCount} from "../../TextAreaCount";
import {TextareaWithGrowHeight} from "../../TextareaGrowHeight";
import Button, {ModifierType} from "../../../UI/Button";

const ProDescriptionsComponent = ({itemData,editIndex, setItemData, setFormInfo,  addMoreInputs, setAddMoreInputs,moreMeasurementsPart1, setMoreMeasurementsPart1,moreMeasurementsPart2, setMoreMeasurementsPart2, formInfo, unescapedRowMetadata, handleStandartInputChange }:any) => {

    const handleShowMoreMeasurementsPart1 = () =>{
        setMoreMeasurementsPart1(!moreMeasurementsPart1);
        if(!moreMeasurementsPart1){
            setMoreMeasurementsPart2(false);
        }
        if(moreMeasurementsPart1){
            const updatedItem = [...itemData];
            const updatedFormInfo = { ...formInfo };
            const itemToUpdate = { ...updatedItem[editIndex] };

            const propertiesToRemove = [
                'measurementLabel4',
                'measurementLabel5',
                'measurementLabel6',
                'measurementLabel7',
                'measurementLabel8',
                'measurementLabel9',
                'measurementLabel10',
                'measurementLabel11',
                'measurementLabel12',
                'measurementLabel13',
                'measurementLabel14',
                'measurementLabel15',
                'measurementLabel16',
                'measurementLabel17',
                'measurementLabel18',
                'measurementLabel19',
                'measurementLabel20',
                'measurementLabelInCm4',
                'measurementLabelInCm5',
                'measurementLabelInCm6',
                'measurementLabelInCm7',
                'measurementLabelInCm8',
                'measurementLabelInCm9',
                'measurementLabelInCm10',
                'measurementLabelInCm11',
                'measurementLabelInCm12',
                'measurementLabelInCm13',
                'measurementLabelInCm14',
                'measurementLabelInCm15',
                'measurementLabelInCm16',
                'measurementLabelInCm17',
                'measurementLabelInCm18',
                'measurementLabelInCm19',
                'measurementLabelInCm20',

            ];
            propertiesToRemove.forEach((prop) => {
                if (prop in itemToUpdate) {
                    delete itemToUpdate[prop];
                }
                if (prop in updatedFormInfo) {
                    updatedFormInfo[prop].text = '';
                }
            });
            updatedFormInfo['moreMeasurementCheckbox1'] = { text: null, changed: true };

            itemToUpdate.moreMeasurementCheckbox1 = null;
            itemToUpdate.changed = true;

            updatedItem[editIndex] = itemToUpdate;

            setItemData(updatedItem);

            setFormInfo(updatedFormInfo);

        }else{
            const updatedItem = [...itemData];
            updatedItem[editIndex] = {
                ...updatedItem[editIndex],
                ['moreMeasurementCheckbox1']: "Enter more measurements?",
                changed: true,
            };

            setItemData(updatedItem);
            setFormInfo({
                ...formInfo,
                ['moreMeasurementCheckbox1']: { text: "Enter more measurements?", changed: true },
            });
        }
    }
    const handleShowMoreMeasurementsPart2 = () =>{
        setMoreMeasurementsPart2(!moreMeasurementsPart2);
        if(moreMeasurementsPart2){
            const updatedItem = [...itemData];
            const updatedFormInfo = { ...formInfo };
            const itemToUpdate = { ...updatedItem[editIndex] };

            const propertiesToRemove = [
                'measurementLabel11',
                'measurementLabel12',
                'measurementLabel13',
                'measurementLabel14',
                'measurementLabel15',
                'measurementLabel16',
                'measurementLabel17',
                'measurementLabel18',
                'measurementLabel19',
                'measurementLabel20',
                'measurementLabelInCm11',
                'measurementLabelInCm12',
                'measurementLabelInCm13',
                'measurementLabelInCm14',
                'measurementLabelInCm15',
                'measurementLabelInCm16',
                'measurementLabelInCm17',
                'measurementLabelInCm18',
                'measurementLabelInCm19',
                'measurementLabelInCm20',

            ];
            propertiesToRemove.forEach((prop) => {
                if (prop in itemToUpdate) {
                    delete itemToUpdate[prop];
                }
                if (prop in updatedFormInfo) {
                    updatedFormInfo[prop].text = '';
                }
            });
            updatedFormInfo['moreMeasurementCheckbox2'] = { text: null, changed: true };

            itemToUpdate.moreMeasurementCheckbox2 = null;
            itemToUpdate.changed = true;

            updatedItem[editIndex] = itemToUpdate;

            setItemData(updatedItem);

            setFormInfo(updatedFormInfo);

        }else{
            const updatedItem = [...itemData];
            updatedItem[editIndex] = {
                ...updatedItem[editIndex],
                ['moreMeasurementCheckbox2']: "Enter more measurements?",
                changed: true,
            };
            setItemData(updatedItem);
            setFormInfo({
                ...formInfo,
                ['moreMeasurementCheckbox2']: { text: "Enter more measurements?", changed: true },
            });
        }
    }

    const handleToggleAddMoreInputs = () => {
        setAddMoreInputs(!addMoreInputs);
        if(addMoreInputs){
            const updatedItem = [...itemData];
            const updatedFormInfo = { ...formInfo };
            const itemToUpdate = { ...updatedItem[editIndex] };

            const propertiesToRemove = [
                'lineDetail11',
                'lineDetail12',
                'lineDetail13',
                'lineDetail14',
                'lineDetail15'
            ];
            propertiesToRemove.forEach((prop) => {
                if (prop in itemToUpdate) {
                    delete itemToUpdate[prop];
                }
                if (prop in updatedFormInfo) {
                    updatedFormInfo[prop].text = '';
                }
            });
            updatedFormInfo['addMoreLinesCheckbox'] = { text: null, changed: true };

            itemToUpdate.addMoreLinesCheckbox = null;
            itemToUpdate.changed = true;

            updatedItem[editIndex] = itemToUpdate;

            setItemData(updatedItem);

            setFormInfo(updatedFormInfo);

        }else{
            const updatedItem = [...itemData];
            updatedItem[editIndex] = {
                ...updatedItem[editIndex],
                ['addMoreLinesCheckbox']: "add-lines",
                changed: true,
            };
            setItemData(updatedItem);
            setFormInfo({
                ...formInfo,
                ['addMoreLinesCheckbox']: { text: "add-lines", changed: true },
            });
        }
    };
    const { bdecode } = useBasisFuncs();




    return(
        <div>
            <div className={styles.accordionContent}>
                <div className={styles.accordionContent1}>
                    <div className={styles.upperContainer}>
                        <div className={styles.leftContainer}>
                            <p className={styles.title}>
                                Bullet or Line Details
                                <Tippy
                                    interactive={true}
                                    zIndex={9991}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    Details added here will display under
                                                    Short Description as part of the Pro
                                                    description builder.
                                                </li>
                                                <li>
                                                    You can customize your bullet points to
                                                    an icon or emoji in the Customize
                                                    section at the bottom of this listing
                                                    form.
                                                </li>

                                                <div className={styles.line}></div>

                                                <p>
                                                    ♦ Pro{' '}
                                                    <a
                                                        href="https://help.listperfectly.com/en/articles/8908753-list-perfectly-s-description-builder-pro-pro-plus"
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        Description Builder
                                                    </a>{' '}
                                                    section
                                                </p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <div className={styles.defaultInputs}>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_340"
                                        value={
                                            formInfo.lineDetail1.changed
                                                ? bdecode(formInfo.lineDetail1.text)
                                                : unescapedRowMetadata[340]
                                                    ? bdecode(unescapedRowMetadata[340])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 1</p>
                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_341"
                                        value={
                                            formInfo.lineDetail2.changed
                                                ? bdecode(formInfo.lineDetail2.text)
                                                : unescapedRowMetadata[341]
                                                    ? bdecode(unescapedRowMetadata[341])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 2</p>
                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_357"
                                        value={
                                            formInfo.lineDetail3.changed
                                                ? bdecode(formInfo.lineDetail3.text)
                                                : unescapedRowMetadata[357]
                                                    ? bdecode(unescapedRowMetadata[357])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 3</p>
                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_629"
                                        value={
                                            formInfo.lineDetail4.changed
                                                ? bdecode(formInfo.lineDetail4.text)
                                                : unescapedRowMetadata[629]
                                                    ? bdecode(unescapedRowMetadata[629])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 4</p>
                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_630"
                                        value={
                                            formInfo.lineDetail5.changed
                                                ? bdecode(formInfo.lineDetail5.text)
                                                : unescapedRowMetadata[630]
                                                    ? bdecode(unescapedRowMetadata[630])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 5</p>

                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_188"
                                        value={
                                            formInfo.lineDetail6.changed
                                                ? bdecode(formInfo.lineDetail6.text)
                                                : unescapedRowMetadata[188]
                                                    ? bdecode(unescapedRowMetadata[188])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 6</p>

                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_266"
                                        value={
                                            formInfo.lineDetail7.changed
                                                ? bdecode(formInfo.lineDetail7.text)
                                                : unescapedRowMetadata[266]
                                                    ? bdecode(unescapedRowMetadata[266])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 7</p>

                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_397"
                                        value={
                                            formInfo.lineDetail8.changed
                                                ? bdecode(formInfo.lineDetail8.text)
                                                : unescapedRowMetadata[397]
                                                    ? bdecode(unescapedRowMetadata[397])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 8</p>

                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_175"
                                        value={
                                            formInfo.lineDetail9.changed
                                                ? bdecode(formInfo.lineDetail9.text)
                                                : unescapedRowMetadata[175]
                                                    ? bdecode(unescapedRowMetadata[175])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 9</p>

                                </div>
                                <div className={styles.lineContainer}>
                                    <InputCount
                                        placeholder=""
                                        name="input_628"
                                        value={
                                            formInfo.lineDetail10.changed
                                                ? bdecode(formInfo.lineDetail10.text)
                                                : unescapedRowMetadata[628]
                                                    ? bdecode(unescapedRowMetadata[628])
                                                    : ''
                                        }
                                        handleChange={handleStandartInputChange}
                                        limit={250}
                                        height={40}
                                        hideCount={true}
                                    />
                                    <p>Line 10</p>

                                </div>
                            </div>

                            {addMoreInputs && (
                                <div className={styles.addMoreInputs}>
                                    <div className={styles.lineContainer}>
                                        <InputCount
                                            placeholder=""
                                            name="input_874"
                                            value={
                                                formInfo.lineDetail11.changed
                                                    ? bdecode(formInfo.lineDetail11.text)
                                                    : unescapedRowMetadata[874]
                                                        ? bdecode(unescapedRowMetadata[874])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <p>Line 11</p>

                                    </div>
                                    <div className={styles.lineContainer}>
                                        <InputCount
                                            placeholder=""
                                            name="input_875"
                                            value={
                                                formInfo.lineDetail12.changed
                                                    ? bdecode(formInfo.lineDetail12.text)
                                                    : unescapedRowMetadata[875]
                                                        ? bdecode(unescapedRowMetadata[875])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <p>Line 12</p>

                                    </div>
                                    <div className={styles.lineContainer}>
                                        <InputCount
                                            placeholder=""
                                            name="input_876"
                                            value={
                                                formInfo.lineDetail13.changed
                                                    ? bdecode(formInfo.lineDetail13.text)
                                                    : unescapedRowMetadata[876]
                                                        ? bdecode(unescapedRowMetadata[876])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <p>Line 13</p>

                                    </div>
                                    <div className={styles.lineContainer}>
                                        <InputCount
                                            placeholder=""
                                            name="input_877"
                                            value={
                                                formInfo.lineDetail14.changed
                                                    ? bdecode(formInfo.lineDetail14.text)
                                                    : unescapedRowMetadata[877]
                                                        ? bdecode(unescapedRowMetadata[877])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <p>Line 14</p>

                                    </div>
                                    <div className={styles.lineContainer}>
                                        <InputCount
                                            placeholder=""
                                            name="input_878"
                                            value={
                                                formInfo.lineDetail15.changed
                                                    ? bdecode(formInfo.lineDetail15.text)
                                                    : unescapedRowMetadata[878]
                                                        ? bdecode(unescapedRowMetadata[878])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <p>Line 15</p>
                                    </div>
                                </div>
                            )}
                            <Button
                             style={{border:!addMoreInputs ? "1px solid #1e3166" : "",  color:!addMoreInputs  ? "#1e3166" : ""}}
                             modifier={addMoreInputs ? ModifierType.Secondary : ModifierType.Transparent} handleClick={() => handleToggleAddMoreInputs()}>
                                <AddButton className={styles.addMoreIcon}/>
                                <span>Add More Lines</span>
                            </Button>
                        </div>
                        <div className={styles.rightContainer}>
                            <p className={styles.title}>
                                Optional Additional Specifics
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    Details added here will display under
                                                    Short Description as part of the Pro
                                                    description builder.
                                                </li>
                                                <li>
                                                    You can customize your bullet points to
                                                    an icon or emoji in the Customize
                                                    section at the bottom of this listing
                                                    form.
                                                </li>
                                                <li>
                                                    Where applicable will be added to item
                                                    specifics on marketplaces.
                                                </li>
                                                <div className={styles.line}></div>
                                                <p>
                                                    ♦ Pro{' '}
                                                    <a
                                                        href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/"
                                                        target="_blank"
                                                    >
                                                        {' '}
                                                        Description Builder
                                                    </a>{' '}
                                                    section
                                                </p>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <p className={styles.heading}>
                                Pattern or Texture
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    ex. If your item is fabric
                                                    <ul>
                                                        <li>What is the weave?</li>
                                                        <li>If furniture, is it carved?</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_210"
                                value={
                                    formInfo.newPatternOrTexture.changed
                                        ? bdecode(
                                            formInfo.newPatternOrTexture.text
                                        )
                                        : unescapedRowMetadata[210]
                                            ? bdecode(unescapedRowMetadata[210])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                hasLimit={false}
                            />
                            <p className={styles.heading}>
                                Season or Weather
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    ex. Is this item good for summer? Or for
                                                    snow?
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_36"
                                value={
                                    formInfo.newSeasonOrWeather.changed
                                        ? bdecode(
                                            formInfo.newSeasonOrWeather.text
                                        )
                                        : unescapedRowMetadata[36]
                                            ? bdecode(unescapedRowMetadata[36])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                hasLimit={false}
                            />
                            <p className={styles.heading}>
                                Care
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    This includes washing instructions,
                                                    polishing, maintenance, or anything else
                                                    your buyer should do to take care of
                                                    this item.
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_43"
                                value={
                                    formInfo.newCare.changed
                                        ? bdecode(formInfo.newCare.text)
                                        : unescapedRowMetadata[43]
                                            ? bdecode(unescapedRowMetadata[43])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                hasLimit={false}
                            />
                            <p className={styles.heading}>
                                Made in
                                <Tippy
                                    interactive={true}
                                    zIndex={5}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    What country, or if known, region, state
                                                    or city?
                                                </li>
                                                <li>
                                                    This is great information for your buyer
                                                    and especially for rare and unique items
                                                    is part of your item's story.
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </p>
                            <TextareaWithGrowHeight
                                placeholder=""
                                name="input_211"
                                value={
                                    formInfo.newMadein.changed
                                        ? bdecode(formInfo.newMadein.text)
                                        : unescapedRowMetadata[211]
                                            ? bdecode(unescapedRowMetadata[211])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                hasLimit={false}
                            />
                        </div>
                    </div>
                    <div className={styles.line}></div>
                    <div>
                        <p className={styles.title}>
                            Measurements Heading
                            <Tippy
                                interactive={true}
                                zIndex={5}
                                trigger="click"
                                arrow={false}
                                content={
                                    <div
                                        className={`popover ${styles.popoverContent}`}
                                    >
                                        <ul>
                                            <li>
                                                Measurements heading can be customized to show a consistent custom header
                                                in your <a
                                                href="https://app.listperfectly.com/account/?action=descriptions">Description
                                                Settings.</a>
                                            </li>
                                            <li>
                                                If you have unique items, you can override the description settings here
                                                and change to anything you'd like, such as 'Measurements taken flat'
                                            </li>
                                            <li>Measurements can be <a
                                                href="https://app.listperfectly.com/docs/topics/lp-catalog-templates/">templated</a> for
                                                repeated use.
                                            </li>

                                            <div className={styles.line}></div>

                                            <p>
                                                ♦ Pro{' '}
                                                <a
                                                    href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/"
                                                    target="_blank"
                                                >
                                                    {' '}
                                                    Description Builder
                                                </a>{' '}
                                                section
                                            </p>
                                        </ul>
                                    </div>
                                }
                            >
                                <PopoverIcon
                                    className={styles.popoverIcon}
                                    color={'#fff'}
                                ></PopoverIcon>
                            </Tippy>
                        </p>
                        <InputCount
                            placeholder=""
                            name="input_750"
                            value={
                                formInfo.newMeasuerements?.changed
                                    ? bdecode(formInfo.newMeasuerements?.text)
                                    : unescapedRowMetadata[750]
                                        ? bdecode(unescapedRowMetadata[750])
                                        : ''
                            }
                            handleChange={handleStandartInputChange}
                            limit={250}
                            height={40}
                            hideCount={true}
                        />
                    </div>
                    <div className={styles.measurementLabelContainer}>
                        <p className={styles.title}>Measurement Label / Measurement</p>
                        <div className={styles.measureItem}>
                            <div className={styles.popoverContainer}>
                                <Tippy
                                    interactive={true}
                                    zIndex={9991}
                                    trigger="click"
                                    arrow={false}
                                    placement="right"
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    Add your own measurement labels in the left column, and enter the
                                                    actual measurements of your listing in the right column.<br/>

                                                    Your measurements will be automatically calculated and displayed in
                                                    both inches and centimeters for international buyers. You can change
                                                    your settings in the Custom Settings area below. <br/>

                                                    All measurement labels can be templated for later use. Examples of
                                                    measurement labels:
                                                    Table height:
                                                    Sleeve Length:
                                                    Diameter:
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                            <div className={styles.popoverContainer}>
                                <Tippy
                                    interactive={true}
                                    zIndex={9991}
                                    trigger="click"
                                    arrow={false}
                                    content={
                                        <div
                                            className={`popover ${styles.popoverContent}`}
                                        >
                                            <ul>
                                                <li>
                                                    Add measurements without any symbols in number or decimal only (no
                                                    fractions). These will be automatically converted from Inches to CM (or
                                                    cm
                                                    to in depending on your setting) and added to your description. <br/>

                                                    If you need to add measurements as a fraction or other, you can ignore
                                                    these fields and add your labels and measurements together in the first
                                                    column fields only. <br/>

                                                    *Note that your measurements will only be calculated and converted for
                                                    international buyers if they are added to these fields. <br/>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <PopoverIcon
                                        className={styles.popoverIcon}
                                        color={'#fff'}
                                    ></PopoverIcon>
                                </Tippy>
                            </div>
                        </div>
                        <div className={styles.measureItem}>
                            <InputCount
                                placeholder="ex.Length"
                                name="input_48"
                                value={
                                    formInfo.newLength?.changed
                                        ? bdecode(formInfo.newLength?.text)
                                        : unescapedRowMetadata[48]
                                            ? bdecode(unescapedRowMetadata[48])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                height={40}
                                hideCount={true}
                            />
                            <InputCount
                                placeholder="in/cm"
                                name="input_380"
                                type={'number'}
                                value={
                                    formInfo.newLengthMeasure?.changed
                                        ? bdecode(formInfo.newLengthMeasure?.text)
                                        : unescapedRowMetadata[380]
                                            ? bdecode(unescapedRowMetadata[380])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                height={40}
                                hideCount={true}
                            />
                        </div>
                        <div className={styles.measureItem}>
                            <InputCount
                                placeholder="ex.Width"
                                name="input_46"
                                value={
                                    formInfo.newWidth?.changed
                                        ? bdecode(formInfo.newWidth?.text)
                                        : unescapedRowMetadata[46]
                                            ? bdecode(unescapedRowMetadata[46])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                height={40}
                                hideCount={true}
                            />
                            <InputCount
                                placeholder="in/cm"
                                name="input_386"
                                type={'number'}
                                value={
                                    formInfo.newWidthMeasure?.changed
                                        ? bdecode(formInfo.newWidthMeasure?.text)
                                        : unescapedRowMetadata[386]
                                            ? bdecode(unescapedRowMetadata[386])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                height={40}
                                hideCount={true}
                            />
                        </div>
                        <div className={`${styles.measureItem} ${styles.includesButton}`}>
                            <InputCount
                                placeholder="ex.Height"
                                name="input_47"
                                value={
                                    formInfo.newHeight?.changed
                                        ? bdecode(formInfo.newHeight?.text)
                                        : unescapedRowMetadata[47]
                                            ? bdecode(unescapedRowMetadata[47])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                height={40}
                                hideCount={true}
                            />
                            <InputCount
                                placeholder="in/cm"
                                name="input_391"
                                type={'number'}
                                value={
                                    formInfo.newHeigthMeasure?.changed
                                        ? bdecode(formInfo.newHeigthMeasure?.text)
                                        : unescapedRowMetadata[391]
                                            ? bdecode(unescapedRowMetadata[391])
                                            : ''
                                }
                                handleChange={handleStandartInputChange}
                                limit={250}
                                height={40}
                                hideCount={true}
                            />
                            <Button
                             style={{borderRadius:"4px",border:!moreMeasurementsPart1 ? "1px solid #cecece" : "", background:!moreMeasurementsPart1 ? "#fff" : "" }}
                             modifier={moreMeasurementsPart1 ? ModifierType.Secondary : ModifierType.Transparent} handleClick={handleShowMoreMeasurementsPart1}>Enter More Measurements?</Button>
                        </div>
                        {
                            moreMeasurementsPart1 && (
                                <>
                                    <div className={styles.measureItem}>
                                        <InputCount
                                            placeholder=""
                                            name="input_378"
                                            value={
                                                formInfo.measurementLabel4?.changed
                                                    ? bdecode(formInfo.measurementLabel4?.text)
                                                    : unescapedRowMetadata[378]
                                                        ? bdecode(unescapedRowMetadata[378])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <InputCount
                                            placeholder=""
                                            name="input_88"
                                            type={'number'}
                                            value={
                                                formInfo.measurementLabelInCm4?.changed
                                                    ? bdecode(formInfo.measurementLabelInCm4?.text)
                                                    : unescapedRowMetadata[88]
                                                        ? bdecode(unescapedRowMetadata[88])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    <div className={styles.measureItem}>
                                        <InputCount
                                            placeholder=""
                                            name="input_384"
                                            value={
                                                formInfo.measurementLabel5?.changed
                                                    ? bdecode(formInfo.measurementLabel5?.text)
                                                    : unescapedRowMetadata[384]
                                                        ? bdecode(unescapedRowMetadata[384])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <InputCount
                                            placeholder=""
                                            name="input_89"
                                            type={'number'}
                                            value={
                                                formInfo.measurementLabelInCm5?.changed
                                                    ? bdecode(formInfo.measurementLabelInCm5?.text)
                                                    : unescapedRowMetadata[89]
                                                        ? bdecode(unescapedRowMetadata[89])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    <div className={styles.measureItem}>
                                        <InputCount
                                            placeholder=''
                                            name="input_389"
                                            value={
                                                formInfo.measurementLabel6?.changed
                                                    ? bdecode(formInfo.measurementLabel6?.text)
                                                    : unescapedRowMetadata[389]
                                                        ? bdecode(unescapedRowMetadata[389])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <InputCount
                                            placeholder=""
                                            name="input_90"
                                            type={'number'}
                                            value={
                                                formInfo.measurementLabelInCm6?.changed
                                                    ? bdecode(formInfo.measurementLabelInCm6?.text)
                                                    : unescapedRowMetadata[90]
                                                        ? bdecode(unescapedRowMetadata[90])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    <div className={styles.measureItem}>
                                        <InputCount
                                            placeholder=''
                                            name="input_70"
                                            value={
                                                formInfo.measurementLabel7?.changed
                                                    ? bdecode(formInfo.measurementLabel7?.text)
                                                    : unescapedRowMetadata[70]
                                                        ? bdecode(unescapedRowMetadata[70])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <InputCount
                                            placeholder=""
                                            name="input_91"
                                            type={'number'}
                                            value={
                                                formInfo.measurementLabelInCm7?.changed
                                                    ? bdecode(formInfo.measurementLabelInCm7?.text)
                                                    : unescapedRowMetadata[91]
                                                        ? bdecode(unescapedRowMetadata[91])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    <div className={styles.measureItem}>
                                        <InputCount
                                            placeholder=""
                                            name="input_641"
                                            value={
                                                formInfo.measurementLabel8?.changed
                                                    ? bdecode(formInfo.measurementLabel8?.text)
                                                    : unescapedRowMetadata[641]
                                                        ? bdecode(unescapedRowMetadata[641])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <InputCount
                                            placeholder=""
                                            name="input_617"
                                            type={'number'}
                                            value={
                                                formInfo.measurementLabelInCm8?.changed
                                                    ? bdecode(formInfo.measurementLabelInCm8?.text)
                                                    : unescapedRowMetadata[617]
                                                        ? bdecode(unescapedRowMetadata[617])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    <div className={styles.measureItem}>
                                        <InputCount
                                            placeholder=""
                                            name="input_642"
                                            value={
                                                formInfo.measurementLabel9?.changed
                                                    ? bdecode(formInfo.measurementLabel9?.text)
                                                    : unescapedRowMetadata[642]
                                                        ? bdecode(unescapedRowMetadata[642])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <InputCount
                                            placeholder=""
                                            name="input_637"
                                            type={'number'}
                                            value={
                                                formInfo.measurementLabelInCm9?.changed
                                                    ? bdecode(formInfo.measurementLabelInCm9?.text)
                                                    : unescapedRowMetadata[637]
                                                        ? bdecode(unescapedRowMetadata[637])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                    </div>
                                    <div className={`${styles.measureItem} ${styles.includesButton}`}>
                                        <InputCount
                                            placeholder=""
                                            name="input_643"
                                            value={
                                                formInfo.measurementLabel10?.changed
                                                    ? bdecode(formInfo.measurementLabel10?.text)
                                                    : unescapedRowMetadata[643]
                                                        ? bdecode(unescapedRowMetadata[643])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <InputCount
                                            placeholder=""
                                            name="input_639"
                                            type={'number'}
                                            value={
                                                formInfo.measurementLabelInCm10?.changed
                                                    ? bdecode(formInfo.measurementLabelInCm10?.text)
                                                    : unescapedRowMetadata[639]
                                                        ? bdecode(unescapedRowMetadata[639])
                                                        : ''
                                            }
                                            handleChange={handleStandartInputChange}
                                            limit={250}
                                            height={40}
                                            hideCount={true}
                                        />
                                        <Button
                                         style={{borderRadius:"4px",border:!moreMeasurementsPart1 ? "1px solid #cecece" : "", background:!moreMeasurementsPart2 ? "#fff" : "" }}
                                         modifier={moreMeasurementsPart2 ? ModifierType.Secondary : ModifierType.Transparent} handleClick={handleShowMoreMeasurementsPart2}>Enter More Measurements?</Button>
                                    </div>
                                    {
                                        moreMeasurementsPart2 && (
                                            <>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_185"
                                                        value={
                                                            formInfo.measurementLabel11?.changed
                                                                ? bdecode(formInfo.measurementLabel11?.text)
                                                                : unescapedRowMetadata[185]
                                                                    ? bdecode(unescapedRowMetadata[185])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_28"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm11?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm11?.text)
                                                                : unescapedRowMetadata[28]
                                                                    ? bdecode(unescapedRowMetadata[28])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_186"
                                                        value={
                                                            formInfo.measurementLabel12?.changed
                                                                ? bdecode(formInfo.measurementLabel12?.text)
                                                                : unescapedRowMetadata[186]
                                                                    ? bdecode(unescapedRowMetadata[186])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_30"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm12?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm12?.text)
                                                                : unescapedRowMetadata[30]
                                                                    ? bdecode(unescapedRowMetadata[30])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_187"
                                                        value={
                                                            formInfo.measurementLabel13?.changed
                                                                ? bdecode(formInfo.measurementLabel13?.text)
                                                                : unescapedRowMetadata[187]
                                                                    ? bdecode(unescapedRowMetadata[187])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_59"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm13?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm13?.text)
                                                                : unescapedRowMetadata[59]
                                                                    ? bdecode(unescapedRowMetadata[59])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_192"
                                                        value={
                                                            formInfo.measurementLabel14?.changed
                                                                ? bdecode(formInfo.measurementLabel14?.text)
                                                                : unescapedRowMetadata[192]
                                                                    ? bdecode(unescapedRowMetadata[192])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_84"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm14?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm14?.text)
                                                                : unescapedRowMetadata[84]
                                                                    ? bdecode(unescapedRowMetadata[84])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_194"
                                                        value={
                                                            formInfo.measurementLabel15?.changed
                                                                ? bdecode(formInfo.measurementLabel15?.text)
                                                                : unescapedRowMetadata[194]
                                                                    ? bdecode(unescapedRowMetadata[194])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_85"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm15?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm15?.text)
                                                                : unescapedRowMetadata[85]
                                                                    ? bdecode(unescapedRowMetadata[85])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_331"
                                                        value={
                                                            formInfo.measurementLabel16?.changed
                                                                ? bdecode(formInfo.measurementLabel16?.text)
                                                                : unescapedRowMetadata[331]
                                                                    ? bdecode(unescapedRowMetadata[331])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_86"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm16?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm16?.text)
                                                                : unescapedRowMetadata[86]
                                                                    ? bdecode(unescapedRowMetadata[86])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_405"
                                                        value={
                                                            formInfo.measurementLabel17?.changed
                                                                ? bdecode(formInfo.measurementLabel17?.text)
                                                                : unescapedRowMetadata[405]
                                                                    ? bdecode(unescapedRowMetadata[405])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_87"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm17?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm17?.text)
                                                                : unescapedRowMetadata[87]
                                                                    ? bdecode(unescapedRowMetadata[87])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_407"
                                                        value={
                                                            formInfo.measurementLabel18?.changed
                                                                ? bdecode(formInfo.measurementLabel18?.text)
                                                                : unescapedRowMetadata[407]
                                                                    ? bdecode(unescapedRowMetadata[407])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_92"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm18?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm18?.text)
                                                                : unescapedRowMetadata[92]
                                                                    ? bdecode(unescapedRowMetadata[92])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_411"
                                                        value={
                                                            formInfo.measurementLabel19?.changed
                                                                ? bdecode(formInfo.measurementLabel19?.text)
                                                                : unescapedRowMetadata[411]
                                                                    ? bdecode(unescapedRowMetadata[411])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_93"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm19?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm19?.text)
                                                                : unescapedRowMetadata[93]
                                                                    ? bdecode(unescapedRowMetadata[93])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                                <div className={styles.measureItem}>
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_602"
                                                        value={
                                                            formInfo.measurementLabel20?.changed
                                                                ? bdecode(formInfo.measurementLabel20?.text)
                                                                : unescapedRowMetadata[602]
                                                                    ? bdecode(unescapedRowMetadata[602])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                    <InputCount
                                                        placeholder=""
                                                        name="input_94"
                                                        type={'number'}
                                                        value={
                                                            formInfo.measurementLabelInCm20?.changed
                                                                ? bdecode(formInfo.measurementLabelInCm20?.text)
                                                                : unescapedRowMetadata[94]
                                                                    ? bdecode(unescapedRowMetadata[94])
                                                                    : ''
                                                        }
                                                        handleChange={handleStandartInputChange}
                                                        limit={250}
                                                        height={40}
                                                        hideCount={true}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>

                    <div className={styles.bottomOfDescriptionContainer}>
                        <p className={styles.title}>
                            Bottom Of Description
                            <Tippy
                                interactive={true}
                                zIndex={5}
                                trigger="click"
                                arrow={false}
                                content={
                                    <div
                                        className={`popover ${styles.popoverContent}`}
                                    >
                                        <ul>
                                            <li>
                                                Great for shop policies, or anything else you'd like your buyer to know.
                                            </li>
                                            <div className={styles.line}></div>

                                            <p>
                                                ♦ Pro{' '}
                                                <a
                                                    href="https://app.listperfectly.com/docs/topics/pro-plan/description-builder/"
                                                    target="_blank"
                                                >
                                                    {' '}
                                                    Description Builder
                                                </a>{' '}
                                                section
                                            </p>
                                        </ul>
                                    </div>
                                }
                            >
                                <PopoverIcon
                                    className={styles.popoverIcon}
                                    color={'#fff'}
                                ></PopoverIcon>
                            </Tippy>
                        </p>
                        <TextareaWithCount
                            placeholder=""
                            name="input_722"
                            value={
                                formInfo.newBottomOfDescription?.changed
                                    ? bdecode(formInfo.newBottomOfDescription?.text)
                                    : unescapedRowMetadata[722]
                                        ? bdecode(unescapedRowMetadata[722])
                                        : ''
                            }
                            handleChange={handleStandartInputChange}
                            height={150}
                            limit={30000}
                            showCount={false}
                            hideCount={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProDescriptionsComponent;