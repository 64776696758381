import styles from './ConnectionPlatformsItem.module.scss';
import Tippy from '@tippyjs/react';
import { PopoverIconMarkets } from '../../../../assets/icons';
import React from 'react';

import {EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,KidizenLogo,FacebookLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons" ;

const ConnectionPlatformsItem = (props: any) => {
	const { image, marketName, selected, onChildClick, hasSimple ,isShopifySupported} = props;
	const isShopifyOrInstagram = (!isShopifySupported && marketName.toLowerCase() === 'shopify') || marketName.toLowerCase() === 'instagram';
	return (
	 <Tippy
	  interactive={true}
	  zIndex={5}
	  arrow={false}
	  trigger="click"
	  content={
		  <div className={styles.popoverContentDisabledActions}>
			  {
				  marketName.toLowerCase() === 'instagram' ? (
				   <div>
					   {
						   hasSimple ? <p>Available on the Business, Pro & Pro Plus plan.</p> :
							<div>Instagram does not currently need to be connected through the Connection Wizard. Only
								crossposting to Instagram is supported and you only need to be logged in on Instagram to
								start crossposting.</div>
					   }
				   </div>

				  ) : (
				   <div>
					   {hasSimple ? <p>Available on the Business, Pro & Pro Plus plan.</p> : <div>
						   Connecting <span style={{textTransform: 'capitalize'}}>{marketName}</span> accounts for
						   crossposting and inventory management is done by following these
						   <a target="_blank" href="https://help.listperfectly.com/en/articles/8928099-connecting-shopify-to-your-list-perfectly-catalog"> steps.</a>
					   </div>}
				   </div>

				  )}

		  </div>
	  }
	  disabled={!isShopifyOrInstagram}
	 >
		 <div
		  className={`${styles.listItemContainer} lp_custom_connection_wizard_modal_platform`}
		  style={{backgroundColor: selected && ((!isShopifySupported && marketName.toLowerCase() !== 'shopify') || marketName.toLowerCase() !== 'instagram') ? '#D9E2FC' : ((!isShopifySupported && marketName.toLowerCase() === 'shopify') || marketName.toLowerCase() === 'instagram' ) ? '#eeeeee' : '#fff' }}
		  data-platform-name={marketName}
		  data-platform-selected={selected}
		  onClick={onChildClick}
		 >
			 <div className={styles.image}>
				 {marketName.toLowerCase() === 'ebay' ? (
				  <EbayLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'etsy' ? (
				  <EtsyLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'poshmark' ? (
				  <PoshmarkLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'mercari' ? (
				  <MercaryLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'grailed' ? (
				  <GrailedLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'facebook' ? (
				  <FacebookLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'depop' ? (
				  <DepopLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'kidizen' ? (
				  <KidizenLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'shopify' ? (
				  <ShopifyLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'instagram' ? (
				  <InstagramLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'vestiaire' ? (
				  <VestiaireLogo height={60} width={60} />
				 ) : marketName.toLowerCase() === 'amazon' ? (
				  <AmazonLogo height={60} width={60} />
				 ) : null}
			 </div>
			 <div className={styles.marketName}>
				 <span style={{textTransform: marketName.toLowerCase() !== 'ebay' ? 'capitalize' : 'initial'}}>{marketName.toLowerCase() === 'ebay' ? 'eBay' : marketName}</span>
				 {
				  isShopifyOrInstagram &&  <PopoverIconMarkets className={styles.popovericon} />
				 }
			 </div>
		 </div>
		 {/*<span>*/}
		 {/* <PopoverIconMarkets className={styles.popovericon} />*/}
		 {/*</span>*/}
	 </Tippy>

	);
};

export default ConnectionPlatformsItem;