import styles from "./CsvDownloadProgressBar.module.scss" ;
import ProgressBar from 'react-progressbar';

interface ICsvDownloadProgressBarProps {
	progress:number;
	label:string;
}

const CsvDownloadProgressBar = (props:ICsvDownloadProgressBarProps) => {
	const {progress,label} = props;
	return (
	 <div className={`${styles['csv-progressbar']}  ${label === 'listings' ? styles['csv-progressbar--paddingForListings'] : label === 'sales' ? `${styles['csv-progressbar--paddingForSales']}` : null }`}>
		 <div className={styles['csv-progressbar__header']}>
			 <div className={styles['csv-progressbar__title']}>CSV Download in progress</div>
			 <div className={`${styles['csv-progressbar__title']} ${styles['csv-progressbar__title--complete']}`}>{`${progress.toFixed(0)}% complete`}</div>
		 </div>
		 <ProgressBar completed={progress} className={styles['csv-progressbar__line']}/>
	 </div>
	)
}

export default CsvDownloadProgressBar;