import React from 'react';

import {SettingsAccountModalLogic} from "./SettingsAccountModalLogic";
import SettingsAccountModalUI from "./SettingsAccountModalUI";

interface ISettingsAccountModalProps {
	handleToggleSettingsAccountModal : () => void;
	subAccount:any;
}


const SettingsAccountModal = (props:ISettingsAccountModalProps) => {
	const {handleToggleSettingsAccountModal,subAccount} = props ;

	const {settingsAccountModalRef, settingsAccountModalCheckers, isLoading, checkedState,handleCheckboxChange, handleSettingsSubAccount} = SettingsAccountModalLogic({handleToggleSettingsAccountModal,subAccount})

	return (
		<SettingsAccountModalUI settingsAccountModalRef={settingsAccountModalRef} isLoading={isLoading} settingsAccountModalCheckers={settingsAccountModalCheckers} checkedState={checkedState} handleCheckboxChange={handleCheckboxChange} handleSettingsSubAccount={handleSettingsSubAccount} handleToggleSettingsAccountModal={handleToggleSettingsAccountModal}/>
	)
}

export default SettingsAccountModal;