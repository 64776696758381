import styles from './AvalaiblePlatformItem.module.scss';

import {EbayLogo,AmazonLogo,GrailedLogo,ShopifyLogo,EtsyLogo,DepopLogo,PoshmarkLogo,KidizenLogo,FacebookLogo,VestiaireLogo,InstagramLogo,MercaryLogo} from "@assets/icons" ;

import React from 'react';
const AvalaiblePlatformItem = (props: any) => {
	const { marketName } = props;
	return (
	 <div className={styles.listItemContainer}>
		 <div className={styles.image}>
			 {marketName.toLowerCase() === 'ebay' ? (
			  <EbayLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'etsy' ? (
			  <EtsyLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'poshmark' ? (
			  <PoshmarkLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'mercari' ? (
			  <MercaryLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'grailed' ? (
			  <GrailedLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'facebook' ? (
			  <FacebookLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'depop' ? (
			  <DepopLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'kidizen' ? (
			  <KidizenLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'shopify' ? (
			  <ShopifyLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'instagram' ? (
			  <InstagramLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'vestiaire' ? (
			  <VestiaireLogo height={50} width={50} />
			 ) : marketName.toLowerCase() === 'amazon' ? (
			  <AmazonLogo height={50} width={50} />
			 ) : null}
		 </div>
	 </div>
	);
};

export default AvalaiblePlatformItem;