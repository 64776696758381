import TemplatesTable from "./TemplatesTable";
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import './templates.css'
import {TemplatesHeader} from "./TemplatesHeader";
import {IFilterBy, IMetadata, ISortBy, ITableData} from "../../interfaces";
import {instanceAxios} from "../../services";
import useDebounce from "../../hooks/UseDebounce";
import _, {debounce} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";
import formatString from "../../utils/formatString";
import TableEditColumn from "./TemplatesTable/TabdleEditColumn";
import {toast} from "react-toastify";
import Iframe from "../../components/UI/Iframe";
import {SearchResultContainer} from "./SearchResultContainer";
import CreateTemplate from "../../components/CreateTemplate";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
// import styles from "./TemplatesHeader/TemplatesHeader.module.scss";
import styles from './Template.module.scss';
import useRoles from "@hooks/useRoles";
import Templates from "@services/Templates";
import Auth from "@services/Auth";

const TemplatesPage = () => {
	const token: any = Auth.get_token();
    const titleRef = useRef();
    const actionsRef = useRef();
    const defaultTemplateRef = useRef();
    const activeRef = useRef();
    const updatedRef = useRef();
    const createRef = useRef();
    const assigneeRef = useRef();
	const {hasSimple, hasSubRole} = useRoles();
    const [loader, setLoader] = useState(true);
    const [isBulkEdit, setIsBulkEdit] = useState(false);
    const [metadata, setMetadata] = useState<IMetadata>({
        offset: 0,
        limit: Number(localStorage.getItem('TemplatesPerPage')) || 10,
    });
	const tippyElementStickyColumnsRefTemplates = useRef<any>(null);
	const [stickyColumnsListTemplates, setStickyColumnsListTemplates] = useState<any>([]);
	const [templateColumnsSizes, setTemplateColumnsSizes] =  useState<any>([])
	const navigate = useNavigate();
	const showEditTemplateModal = useSelector((state: any) => state.navigation.showEditTemplateModal);
    const [pagiInputValue, setPagiInputValue] = useState<any>('1');
	const [isDefaultTemplate, setIsDefaultTemplate] = useState(localStorage.getItem('defaultTemplate') === 'false' ? true : false)
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const filterByParam = queryParams.get('filter_by');
	let decodedFilterBy;
	if (filterByParam) {
		decodedFilterBy = JSON.parse(decodeURIComponent(filterByParam));
		if (isDefaultTemplate) {
			decodedFilterBy.default_template = true;
		} else {
			delete decodedFilterBy.default_template;
		}
	}
	const filterSort = queryParams.get('sort_by');
	let decodedSort;
	if (filterSort) {
		decodedSort = JSON.parse(decodeURIComponent(filterSort));
	} else {
		decodedSort = {};
	}
	const [sortBy, setSortBy] = useState<ISortBy>(decodedSort);
	const [saveActive, setSaveActive] = useState(false);
	const [filterBy, setFilterBy] = useState<IFilterBy>(
	 decodedFilterBy ? decodedFilterBy : {}
	);
	const [autoSaveLoader, setAutoSaveLoader] = useState(false);
	const [directions, setDirections] = useState<{
		[columnId: string]: 'top' | 'bottom' | 'none';
	}>({});
	const [clearAllActive, setClearAllActive] = useState(false);
	const [formChanged, setFormChanged] = useState(false);
	const [retryActive, setRetryActive] = useState(false);
	const [isCanceled, setIsCanceled] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [cachedTableData, setCachedTableData] = useState<any>(null);
	const [tableModal, setTableModal] = useState(false);
	const [listingsForDelete, setListingsForDelete] = useState<any>([])
	const [selectedListings, setSelectedListings] = useState<any>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [tableData, setTableData] = useState<any>([]);
	const innerRef = useRef();
	const [searchResult, setSearchResult] = useState<any>([]);
	const [defaultTemplateId, setDefaultTemplateId] = useState(null);
	const [cachedDefaultTemplateId, setCachedDefaultTemplateId] = useState(null);
	const [defautlChanged, setDefaultChanged] = useState(false);
	const [longRerquestActivate, setLongRequestActivate] = useState(false);

	const [columns, setColumns] = useState<any>([
                {
                    heading: 'Title',
                    value: 'title',
                    width: 110,
                    order: 1,
                    ref: titleRef,
                },
		       {
			        heading: 'Default Template',
			        value: 'default_template',
			        width: 140,
			        order: 2,
			        ref: defaultTemplateRef,
		        },
                {
                    heading: 'Active',
                    value: 'active',
                    width: 140,
                    order: 3,
                    ref: activeRef,
                },
                {
                    heading: 'Updated',
                    value: 'updated',
                    width: 140,
                    order: 4,
                    ref: updatedRef,
                },
                {
                    heading: 'Created',
                    value: 'created',
                    width: 140,
                    order: 5,
                    ref: createRef,
                },
                {
                    heading: 'Actions',
                    value: 'actions',
                    width: 140,
                    order: 6,
                    ref: actionsRef,
                },
            ]
    );

    const sectionsCheker = [
        {
            value: 'Title',
            allowed: true,
        },
		{
			value: 'default_template',
			allowed: true,
		},
        {
            value: 'Active',
            allowed: true,
        },
        {
            value: 'Updated',
            allowed: true,
        },
        {
            value: 'Assignee',
            allowed: hasSubRole ? false : true,
        },
        {
            value: 'Created',
            allowed: true,
        },
        {
            value: 'Actions',
            allowed: true,
        },
    ];

	const fetchPaginateDatas = useCallback(
		async (currentPage: string) => {
			setLoader(true);
			try {
				let localFilterBy: any = filterBy;
				if (isDefaultTemplate) {
					localFilterBy['default_template'] = true;
				}
				else{
					delete localFilterBy.default_template
				}
				const answer = getSortBy();
				const sort = {...sortBy};
				const hasNoneValue = Object.values(sort).some(value => value === "none");
				let localSort : any = {};
				if (!hasNoneValue) {
					localSort = !answer ? {133: "asc"} : {...sortBy};
				}
				else {
					localSort = null;
				}
				const res:any = await Templates.list(localFilterBy, { offset: +currentPage * (metadata.limit || 10),
					limit: metadata.limit || 10}, {include_assignee: true}, localSort, token)

				if (res) {
					setLoader(false);
					setMetadata({...metadata, offset: res.metadata.offset});
				}
				return res.data;
			} catch (error) {
				console.error(error);
			}
		},
		[
			filterBy,
			metadata,
			sortBy
		]
	);

    const handleChangeByInput = async (num: string) => {
        let currentPage = +num - 1;

        if (pagiInputValue !== '0' && pagiInputValue !== '') {
            const dataFromChangeInput = await fetchPaginateDatas(
             currentPage.toString()
            );
            setTableData(dataFromChangeInput);
        }
    };

    const debounceSearchItem = useDebounce(pagiInputValue, 1000);

    useEffect(() => {
        if (debounceSearchItem !== '1') {
            handleChangeByInput(pagiInputValue);
        }
    }, [debounceSearchItem]);

    const handleGetColumnSearchDebounced = useCallback(
	 debounce(async (col: string, value: any) => {
		 let key: string = '';

		 switch (col) {
			 case 'SKU':
				 key = '1';
				 break;
			 case 'condition':
				 key = '15';
				 break;
			 case 'price':
				 key = '22';
				 break;
			 case 'material':
				 key = '82';
				 break;
			 case 'title':
				 key = '133';
				 break;
			 case 'brand':
				 key = '206';
				 break;
			 case 'color':
				 key = '209';
				 break;
			 case 'size':
				 key = '213';
				 break;
			 case 'keywords':
				 key = '453';
				 break;
			 case 'description':
				 key = '505';
				 break;
			 case 'upc':
				 key = '704';
				 break;
			 case 'notes':
				 key = '726';
				 break;
			 case 'sold_price':
				 key = '842';
				 break;
			 case 'cogs':
				 key = '847';
				 break;
			 case 'drafts':
				 key = '859.1';
				 break;
			 case 'quantity':
				 key = '851';
				 break;
			 default:
				 break;
		 }
         const searchPayload = {
             ...filterBy,
             [key]: (col === 'price' || col === 'sold_price' || col === 'cogs' || col === 'quantity') ? +value : value
         };
         function removeEmptyProperties(obj:any) {
             for (let key in obj) {
                 if (obj.hasOwnProperty(key)) {
                     if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
                         delete obj[key];
                     }
                 }
             }
             return obj;
         }
         const cleanedPayload = removeEmptyProperties(searchPayload);
		 setFilterBy(cleanedPayload)
         setLoader(true);
		 try {
			 let localFilterBy: any = cleanedPayload;
			 if (isDefaultTemplate) {
				 localFilterBy['default_template'] = true;
			 }
			 else{
				 delete localFilterBy.default_template
			 }
			 const answer = getSortBy();
			 const sort = {...sortBy};
			 const hasNoneValue = Object.values(sort).some(value => value === "none");
			 const payload:any = {
				 metadata: {offset: 0, limit: metadata.limit || 10},
				 filter_by: localFilterBy,
				 options:{include_assignee: true}
			 }
			 if (!hasNoneValue) {
				 payload.sort_by = !answer ? {133: "asc"} : {...sortBy};
			 }
             const response:any = await instanceAxios.post('templates/list', payload);
             if(response) {
				 setPageCount(Math.ceil(response.data.metadata.total / (metadata.limit || 10)));
                 setTableData(response.data.data);
                 setLoader(false);
             }
		 }
         catch (e) {
             console.log(e, 'error')
         }

	 }, 1000),
	 [filterBy, metadata]
	);

	const handleGetDataPerPage = async(num:any)=>{
		try {
			setLoader(true);
			const answer = getSortBy();
			const sort = {...sortBy};
			const hasNoneValue = Object.values(sort).some(value => value === "none");
			const payload:any = {
				metadata: {offset: 0, limit: num},
				options:{include_assignee: true},
				filter_by: {...filterBy},
			}
			if (!hasNoneValue) {
				payload.sort_by = !answer ? {133: "asc"} : {...sortBy};
			}
			const response = await instanceAxios.post('/templates/list', payload);

			if (response.data) {
				const {limit} = response.data.metadata;
				const offset = 0;
				setPagiInputValue('1');
				setPageCount(Math.ceil(response.data.metadata.total / limit));
				setMetadata({offset, limit});
				setLoader(false);

				setTableData(response.data.data);
			}
		} catch (error) {
			console.error(error);
		}
	}


	let decodedSortBy: any;
	const getSortBy = (): any => {
		const SortByParam = queryParams.get('sort_by');
		if (SortByParam) {
			decodedSortBy = JSON.parse(decodeURIComponent(SortByParam));
		}
		if (_.get(decodedSortBy, '133')) {
			return {
				title:
				 _.get(decodedSortBy, '133') === 'asc'
				  ? 'top'
				  : _.get(decodedSortBy, '133') === 'desc'
				   ? 'bottom'
				   : 'none',
			};
		} else if (_.get(decodedSortBy, '1')) {
			return {
				SKU:
				 _.get(decodedSortBy, '1') === 'asc'
				  ? 'top'
				  : _.get(decodedSortBy, '1') === 'desc'
				   ? 'bottom'
				   : 'none',
			};
		} else if (_.get(decodedSortBy, '22')) {
			return {
				price:
				 _.get(decodedSortBy, '22') === 'asc'
				  ? 'top'
				  : _.get(decodedSortBy, '22') === 'desc'
				   ? 'bottom'
				   : 'none',
			};
		} else if (_.get(decodedSortBy, '851')) {
			return {
				quantity:
				 _.get(decodedSortBy, '851') === 'asc'
				  ? 'top'
				  : _.get(decodedSortBy, '851') === 'desc'
				   ? 'bottom'
				   : 'none',
			};
		} else if (_.get(decodedSortBy, 'updated_at')) {
			return {
				updated:
				 _.get(decodedSortBy, 'updated_at') === 'asc'
				  ? 'top'
				  : _.get(decodedSortBy, 'updated_at') === 'desc'
				   ? 'bottom'
				   : 'none',
			};
		} else if (_.get(decodedSortBy, 'created_at')) {
			return {
				created:
				 _.get(decodedSortBy, 'created_at') === 'asc'
				  ? 'top'
				  : _.get(decodedSortBy, 'created_at') === 'desc'
				   ? 'bottom'
				   : 'none',
			};
		}
	};




	const handleSearch = (col:string, value:any) => {
	   setSearchResult((prevState: any) => {
		   // Check if the item with the specific name already exists
		   const itemIndex = prevState.findIndex((item: any) => item?.name.toLowerCase() === col?.toLowerCase());
		   if (itemIndex !== -1) {
			   // Update the value of the existing item
			   return prevState.map((item: any, index: number) =>
				index === itemIndex ? { ...item, value } : item
			   );
		   } else {
			   // Add a new item
			   return [...prevState, { name: col.toLowerCase(), value }];
		   }
	   });

	   if (value.trim().length >= 2 || value.length === 0) {
           handleGetColumnSearchDebounced(col, value);
       }
   }


    useLayoutEffect(() => {
        try {
            const tempColumnsString = localStorage.getItem('colsTemplates');
            const tempColumns = tempColumnsString
                ? JSON.parse(tempColumnsString)
                : null;
            const filteredData = tempColumns?.filter((item:any)=> item?.heading !== undefined);
            if (filteredData) {
                const filteredStorage = filteredData?.filter((storageColumn: any) => {
                    const matchingSection = sectionsCheker.find(
                        (section) =>
                            section.value.toLowerCase() === storageColumn.value.toLowerCase()
                    );
                    // If the corresponding section is found and allowed is true, keep the column
                    return matchingSection && matchingSection.allowed;
                });

                localStorage.setItem('colsTemplates', JSON.stringify(filteredStorage));
            }
        } catch (e) {
            console.log(e, 'error');
        }
    }, []);

	const handleChangeCheckbox = useCallback(
	 (e: React.ChangeEvent<HTMLInputElement>) => {
		 const {name, checked} = e.target;
		 if (name === 'allSelect') {
			 let tempDatas = tableData.map((tbData:any) => {
				 return {...tbData, isChecked: checked};
			 });
			 setTableData(tempDatas);
			 if (checked) {
				 const activeIds = tempDatas
				 .filter((tbData:any) => tbData.status === 'active')
				 .map((tbData:any) => tbData.id);

				 setSelectedListings((prevSelected:any) => {
					 // Create a Set to ensure unique IDs
					 const updatedSet = new Set([...prevSelected, ...activeIds]);
					 return Array.from(updatedSet);
				 });
			 } else {
				 const idsToRemove:any = tempDatas.map((tbData:any) => tbData.id);

				 // setSelectedListings((prevSelected:any) => {
					//  // Filter out the IDs that match idsToRemove
					//  return prevSelected.filter((id:any) => !idsToRemove.includes(id));
				 // });
				 setSelectedListings([]);
			 }
		 } else {
			 let tempDatas = tableData.map((tbData:any) =>
			  tbData.id == name ? {...tbData, isChecked: checked} : tbData
			 );
			 setTableData(tempDatas);
		 }
	 },
	 [tableData, setTableData]
	);

	const handleRowSelected = useCallback(
	 (e: any, id: string, checked:any): void => {
		 let tempDatas = tableData.map((tbData: ITableData) =>
		  tbData.id === +id ? {...tbData, isChecked: !tbData.isChecked} : tbData
		 );
		 const localIsChecked = Boolean(!checked);

		 setSelectedListings((prevSelectedListings:any) => {
			 if (localIsChecked) {
				 if (!prevSelectedListings.includes(+id)) {
					 return [...prevSelectedListings, +id];
				 }
			 } else {
				 return prevSelectedListings.filter((selectedId:any) => +selectedId !== +id);
			 }
			 return prevSelectedListings;
		 });


		 // Update the tableData state
		 setTableData(tempDatas);
	 },
	 [tableData, setTableData]
	);

	const handleBulkDelete = async () => {
		const requests: Promise<any>[] = [];
		let updatedTableData = [...tableData];
		let deletedListings:any = []; // State to store deleted listings

		const filteredSelectedListings = selectedListings.filter((id: any) => !listingsForDelete.includes(id));

		// Filter out the selected listings from tableData and create update requests
		filteredSelectedListings.forEach((id:any) => {

			const requestData = {
				filter_by: {id: id},
				payload: {status: 'trash'},
			};
			const request = instanceAxios.put('/templates/update', requestData);
			requests.push(request);

			deletedListings.push(id);

		})
		try {
			await Promise.all(requests);
			// Update the local state and reset selectedListings
			setTableData(updatedTableData);
			// Update deleted listings state
			setListingsForDelete((prevDeletedListings: any) => {
				const updatedDeletedListings = [...prevDeletedListings];

				// Add new items from selectedListings that are not already in prevDeletedListings
				selectedListings.forEach((id: number) => {
					if (!prevDeletedListings.includes(id)) {
						updatedDeletedListings.push(id);
					}
				});
				deletedListings = [];

				return updatedDeletedListings;
			});

		} catch (error) {
			console.error(error);
		}
	};

	const handleChangeModal = () => {
		setTableModal(!tableModal);
	};

	const handleGetColumns = () => {
		const tempColumnsString = localStorage.getItem('colsTemplates');
		const tempColumns = tempColumnsString
		 ? JSON.parse(tempColumnsString)
		 : null;

		setColumns(
		 tempColumns?.length === 0 || tempColumns === null ? columns : tempColumns
		);
	};

	const handleEditColumns = async (cols: string[]) => {
		const myNewCols = cols.map((item) => {
			return {
				heading:
				 item === 'cogs'
				  ? 'COGS'
				  : item === 'sku'
				   ? 'SKU'
				   : formatString(item),

				value: item,
				ref: innerRef,
			};
		});

		localStorage.setItem('colsTemplates', JSON.stringify(myNewCols));
		handleGetColumns();
		setTableModal(!tableModal);
	};

	useEffect(() => {
		if (filterBy) {
			const results = Object.keys(filterBy).map(key => ({
				name: key === '133' ? 'title' : key,
				value: filterBy[key]
			}));
			const filteredResults = results.filter(result => result.name !== 'default_template');
			setSearchResult(filteredResults);
		}
	}, [filterBy]);

	useEffect(() => {
		try{
			const savedName = localStorage.getItem("activeColumnNameTemplates");

			const firstThreeColumn = columns.slice(0, 3).map((column:any, index:any) => ({
				...column,
				activeColumn: false,
				stickyColumn: false, // Default value
				position: index + 1,
			}));

			if (savedName) {
				// Find the index of the active column
				const activeIndex = firstThreeColumn.findIndex(
					(item:any) => item.value?.toLowerCase() === savedName.toLowerCase()
				);

				const updatedColumns = firstThreeColumn.map((item:any, index:any) => ({
					...item,
					activeColumn: index === activeIndex,
					stickyColumn: index <= activeIndex, // Make all columns up to and including the active one sticky
				}));

				setStickyColumnsListTemplates(updatedColumns);
			} else {
				setStickyColumnsListTemplates(firstThreeColumn);
			}
		}catch(e){
			console.log(e, 'error')
		}
	}, [columns]);

	const handleCheckboxChange = (name?:any) => {
           if(defautlChanged || name === 'longRequestActive') {
			   setLongRequestActivate(true);
		   }
		   else{
			   setLongRequestActivate(false);
		   }
			if (!isDefaultTemplate) {
				setFilterBy({...filterBy, default_template: true});
			} else {
				const updatedFilterBy = {...filterBy};
				delete updatedFilterBy.default_template;
				setFilterBy(updatedFilterBy);
			}
			localStorage.setItem('defaultTemplate', String(isDefaultTemplate));
			setIsDefaultTemplate(!isDefaultTemplate);
	}


	useEffect(() => {
		handleGetColumns();
	}, []);

  useEffect(() => {
    const answer = getSortBy();
    if(!answer) {
      setDirections({ ...answer, title: 'top' });
    }
    else {
      setDirections({ ...answer });
    }
  }, []);

	const handleGetData = async(name?:any) => {
		if(!name){
			setLoader(true);
		}
		try {
			const answer = getSortBy();
			const sort = {...sortBy};
			let localFilterBy: any = filterBy;
			if (isDefaultTemplate) {
				localFilterBy['default_template'] = true;
			}
			else{
				delete localFilterBy.default_template
			}
			const hasNoneValue = Object.values(sort).some(value => value === "none");
			const tempMetadata = {offset: 0, limit: metadata.limit};
			let localSort : any = {};
			if (!hasNoneValue) {
				localSort = !answer ? {133: "asc"} : {...sortBy};
			}
			else {
				localSort = null;
			}
            const response:any = await Templates.list(localFilterBy, tempMetadata, {include_assignee: true}, localSort, token)

			if(response) {
				setPagiInputValue('1');
				setPageCount(Math.ceil(response.metadata.total / (metadata.limit || 10)));
				setTableData(response.data);
				setLoader(false);
			}
		}
		catch (e){
			console.log(e, 'error')
		}
		finally {
			setDefaultChanged(false);
		}
	}


	useEffect(() => {
		if(clearAllActive) {
			setClearAllActive(false);
		}
		if(longRerquestActivate) {
			setLoader(true);
			setTimeout(() => {
				handleGetData('loader');
			}, 3500)
		}
		else {
			handleGetData();
		}
	}, [clearAllActive, filterBy.default_template, longRerquestActivate]);


	const handleBulkRestore = async () => {
		const requests: Promise<any>[] = [];
		let updatedTableData = [...tableData];
		let restoredListings:any = []; // State to store restored listings

		listingsForDelete?.forEach((id:any)=>{
			const requestData = {
				filter_by: { id: id },
				payload: { status: 'active' },
			};
			const request = instanceAxios.put('/templates/update', requestData);
			requests.push(request);
			// Track restored items
			restoredListings.push(id);
		})

		try {
			const responses = await Promise.all(requests);

			responses?.forEach((response) => {
				if (response.data) {
					const filterId = response.data.filter_by.id;
					updatedTableData = updatedTableData.map((item) => {
						if (item.id === filterId) {
							return { ...item, status: 'active', isChecked: false };
						} else {
							return item;
						}
					});

					setTableData(updatedTableData);
				}
			});

			// Remove restored items from deletedListings state
			setListingsForDelete((prevDeletedListings: number[]) => {
				const updatedDeletedListings = prevDeletedListings.filter((prevItemId: any) => {
					return !restoredListings.includes(prevItemId);
				});
				return updatedDeletedListings;
			});

		} catch (error) {
			console.error(error);
		}
	};

	const handleSaveListings = async (autosave?:boolean) => {
		try {
			setFormChanged(false);
			if(autosave){
				setAutoSaveLoader(true);
			}
			if (!saveActive && !autosave) {
				toast.warning(`Oops! It looks like no changes were made to your listing. Please edit something for the 'Save' button to become active.`, {
					position: 'bottom-right', // You can customize the notification position
				});
			}
			const listings: any = [...tableData];
			const groupedUpdates: any = {};

            let hasTitle = false;
			let isDefaultTemplateChecked: any = null;

			// Iterate through the listings array to group updates by listing ID
			listings.forEach((listing: any) => {
				if (listing.changed) {
					groupedUpdates[listing.id] = {};
					if (listing.newTitle) {
						hasTitle = true;
					}
					if(autosave) {
						if (listing.newActive !== undefined) {
							groupedUpdates[listing.id]['122.1'] = listing.newActive;
						}
						if(listing.id === defaultTemplateId){
							groupedUpdates[listing.id]['default_template'] = listing.newDefaultTemplate;
							setDefaultChanged(true);
							if(!listing.newDefaultTemplate){
								isDefaultTemplateChecked = 0;
								setDefaultTemplateId(null);
							}
						}
					}
					else {
						if (listing.newTitle) {
							groupedUpdates[listing.id]['133'] = listing.newTitle;
						}
					}
				}
			});

			// Create an array of promises for all update requests
			const updatePromises = Object.keys(groupedUpdates).map(async (id) => {
				const requestData = {
					filter_by: { id: +id },
					payload: groupedUpdates[id],
				};
				const isEmptyObject = (obj:any) => {
					return Object.keys(obj).length === 0 && obj.constructor === Object;
				}

				if (!requestData.payload || isEmptyObject(requestData.payload)) {
					return;
				}
				try {
					const response = await instanceAxios.put('/templates/metadata/update', requestData);
					setRetryActive(false);
					if(isDefaultTemplateChecked == 0 && isDefaultTemplate){
						isDefaultTemplateChecked = null;
						 handleCheckboxChange('longRequestActive');
					}
					return { id, data: response.data, success: true };
				} catch (error) {
					setRetryActive(true);
					toast.error(`Oops! We encountered an error while attempting to save your changes. Please click the 'Retry' button to try again.`, {
						position: 'bottom-right', // You can customize the notification position
					});
					return { id, success: false };
				}
			});
			// Wait for all update requests to complete
			const results = await Promise.all(updatePromises);
			const filteredArray = results.filter(item => item?.id !== undefined);
			// Update the tableData state with the new data
			const updatedTableData = tableData.map((listing: any) => {
				const result = filteredArray?.find((r :any) => r?.id == listing?.id);
				if (result && result?.success && !hasTitle) {
					hasTitle = false;
					return { ...listing, changed: false, ...groupedUpdates[listing?.id] };
				}
				return listing;
			});
			if(!autosave) {
				setIsBulkEdit(!isBulkEdit);
			}
			setIsSaved(true);
			setTableData(updatedTableData);
		}
		catch (error) {
			console.log(error, 'error')
		}

		finally {
			setAutoSaveLoader(false);
		}

	};

	const handleSortByClick = useCallback(
	 async (thValue: string, sortValue: 'asc' | 'desc' | 'none') => {
		 if (
		  thValue === 'platforms' ||
		  thValue === 'description' ||
		  thValue === 'sold price' ||
		  thValue === 'cogs' ||
		  thValue === 'assignee' ||
		  thValue === 'upc' ||
		  thValue === 'brand' ||
		  thValue === 'color' ||
		  thValue === 'size' ||
		  thValue === 'material' ||
		  thValue === 'condition'
		 ) {
			 return;
		 }

		 let key: string = '';
		 if (thValue === 'SKU') {
			 key = '1';
		 } else if (thValue === 'price') {
			 key = '22';
		 } else if (thValue === 'image') {
			 key = '727';
		 } else if (thValue === 'quantity') {
			 key = '851';
		 } else if (thValue === 'updated') {
			 key = 'updated_at';
		 } else if (thValue === 'created') {
			 key = 'created_at';
		 } else if (thValue === 'title') {
			 key = '133';
		 } else if (thValue === 'notes') {
			 key = '726';
		 }

		 const sortPayload = {
			 ...(thValue !== '' && {[key]: sortValue}),
		 };

		 try {
			 let localFilterBy: any = filterBy;
			 if (isDefaultTemplate) {
				 localFilterBy['default_template'] = true;
			 }
			 else{
				 delete localFilterBy.default_template
			 }
			 setLoader(true);
			 const payload:any = {
				 filter_by: localFilterBy,
				 ...(sortValue !== 'none' && {sort_by: sortPayload}),
				 metadata: {offset: 0, limit: metadata.limit || 10},
				 options: {include_assignee: true},
			 }

			 const response = await instanceAxios.post('/templates/list', payload);

			 if (response.status === 200) {
				 setLoader(false);
				 setTableData(response.data.data);
				 setSortBy(sortPayload);
			 }
		 } catch (error) {
			 console.error(error);
		 }
	 },
	 [filterBy, metadata]
	);

	const handleChangeDirection = (
	 columnId: string,
	 direction: 'top' | 'bottom' | 'none'
	) => {
		setDirections({ [columnId]: direction });

		handleSortByClick(
		 columnId,
		 direction === 'top' ? 'asc' : direction === 'none' ? 'none' : 'desc'
		);
		setPagiInputValue('1');
	};

	const updateUrl = (newParams : any, name?: string) => {
		const urlParams = new URLSearchParams(window.location.search);

		// Update or add new parameters
		Object.keys(newParams).forEach(key => {
			urlParams.set(key, newParams[key]);
		});

		// Preserve checklist if present
		const checklistParam = urlParams.get('checklist');
		if (checklistParam !== null) {
			urlParams.set('checklist', checklistParam);
		}
		if(name === 'action') {
			urlParams.delete('action');
		}
		const newUrl = `/templates?${urlParams.toString()}`;
		navigate(newUrl);
	};

	useEffect(() => {
		updateUrl({ filter_by: JSON.stringify(filterBy) });
	}, [filterBy]);

	useEffect(() => {
		updateUrl({ sort_by: JSON.stringify(sortBy) });
	}, [sortBy]);

	const [AvoidInfiniteLoop, setAvoidInfiniteLoop] = useState(false);

	useEffect(() => {
		if(tableData && !AvoidInfiniteLoop) {
			const updatedTableData:any = tableData.map((item:any) => {

				if (selectedListings.includes(item?.id)) {
					let newItem = { ...item, isChecked: true };

					if (listingsForDelete.includes(item?.id)) {
						newItem = {...newItem, status: 'trash', isChecked: true};
					}
					return newItem;
				}
				else {
					return item;
				}
			});
			setAvoidInfiniteLoop(true);
			setTableData(updatedTableData);
		}
	}, [tableData]);

	useEffect(() => {
		 if(formChanged) {
			 handleSaveListings(true);
		 }
	}, [formChanged]);

	useEffect(() => {
		if(AvoidInfiniteLoop && tableData) {
			setAvoidInfiniteLoop(false);
		}
	}, [tableData, pagiInputValue, pageCount, filterBy]);

	useEffect(() => {
		if ((tableData?.length && !cachedTableData) || isSaved) {
			setCachedTableData(tableData);
			setIsSaved(false);
		}
	}, [tableData, cachedTableData, isSaved]);
	useEffect(() => {
		if ((isCanceled && cachedTableData)) {
			setTableData(cachedTableData);
			setCachedTableData(null);
		}
	}, [isCanceled]);


	// useEffect(() => {
	// 	if((defaultTemplateId && !cachedDefaultTemplateId) || isSaved){
	// 		setCachedDefaultTemplateId(defaultTemplateId);
	// 	}
	// }, [defaultTemplateId, cachedDefaultTemplateId, isSaved]);
	//
	// useEffect(() => {
	// 	if(isCanceled && cachedDefaultTemplateId){
	// 		setDefaultTemplateId(cachedDefaultTemplateId);
	// 		setCachedDefaultTemplateId(null);
	// 	}
	// }, [isCanceled]);


    return(
	 	<>
			<Helmet>
				<title>
					Templates | List Perfectly
				</title>
			</Helmet>
			<div className="template-main-container"
				 style={{
					 display: 'flex',
					 flexDirection: 'column',
					 height: '100vh',
					 justifyContent: 'flex-start'
				 }}
			>
				{
					showEditTemplateModal && <CreateTemplate setTableData={setTableData}/>
				}
				{tableModal && (
					<TableEditColumn
						handleChangeModal={handleChangeModal}
						columns={columns}
						handleEditColumns={handleEditColumns}
					/>
				)}
				<Iframe/>
				<div className='hiddenElementForTippyStickyColumnsTemplates' ref={tippyElementStickyColumnsRefTemplates}></div>
				{/*<div className="templates-title">Templates Page</div>*/}
				<TemplatesHeader
					handleGetDataPerPage={handleGetDataPerPage}
					retryActive={retryActive}
					setRetryActive={setRetryActive}
					handleSaveListings={handleSaveListings}
					setIsCanceled={setIsCanceled}
					saveActive={saveActive}
					setSaveActive={setSaveActive}
					handleChangeModal={handleChangeModal}
					pagiInputValue={pagiInputValue}
					pageCount={pageCount}
					filterBy={filterBy}
					setTableData={setTableData}
					setMetadata={setMetadata}
					setPageCount={setPageCount}
					setLoader={setLoader}
					metadata={metadata}
					setPagiInputValue={setPagiInputValue}
					isBulkEdit={isBulkEdit}
					setIsBulkEdit={setIsBulkEdit}
					handleCheckboxChange={handleCheckboxChange}
					isDefaultTemplate={isDefaultTemplate}
					hasSimple={hasSimple}
				/>
				<div className={styles.defaultTemplateCheckboxMobile}>
					<label>
						<p>Default Template</p>
						<input
							className="selectInputDraftSold"
							type="checkbox"
							checked={isDefaultTemplate}
							onChange={handleCheckboxChange}
						/>
					</label>
				</div>
				<div className={"tableAboveBlock"}>
					{selectedListings.length ? <div style={{
						padding: '5px 0',
						color: '#1E3166',
						fontWeight: 600
					}}>{selectedListings.length} Selected</div> : null}
					{
						searchResult?.length > 0 &&
						<SearchResultContainer setClearAllActive={setClearAllActive} searchResult={searchResult}
											   setSearchResult={setSearchResult} setFilterBy={setFilterBy}/>
					}
				</div>


				<TemplatesTable
					handleChangeDirection={handleChangeDirection}
					autoSaveLoader={autoSaveLoader}
					setFormChanged={setFormChanged}
					directions={directions}
					setSaveActive={setSaveActive}
					searchResult={searchResult}
					handleBulkRestore={handleBulkRestore}
					handleBulkDelete={handleBulkDelete}
					setTableData={setTableData}
					listingsForDelete={listingsForDelete}
					isBulkEdit={isBulkEdit}
					selectedListings={selectedListings}
					handleRowSelected={handleRowSelected}
					columns={columns}
					loader={loader}
					tableData={tableData}
					handleSearch={handleSearch}
					handleChangeCheckbox={handleChangeCheckbox}
					defaultTemplateId={defaultTemplateId}
					setDefaultTemplateId={setDefaultTemplateId}
					tippyElementStickyColumnsRefTemplates={tippyElementStickyColumnsRefTemplates}
					stickyColumnsListTemplates={stickyColumnsListTemplates}
					templateColumnsSizes={templateColumnsSizes}
					setTemplateColumnsSizes={setTemplateColumnsSizes}
					setStickyColumnsListTemplates={setStickyColumnsListTemplates}
				/>
			</div>
		</>
	)
}

export default TemplatesPage;