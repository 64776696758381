import {ExpressListerMain} from "./components/ExpressListermain";
import React, {useEffect, useState} from "react";
import {ExpressListerCustomize} from "./components/ExpressListerCustomize";
import './express.css'
import {toast} from "react-toastify";
import _ from "lodash";
import _get from "lodash/get";
import {useSelector} from "react-redux";
import useRoles from "@hooks/useRoles";
import UserConfigs from "@services/UserConfigs";
import Auth from "@services/Auth";

export const ExpressLister = () => {
    const allowDraftsPageAccess =  useSelector(
        (state: any) => state.navigation.allowDraftsPageAccess
    );
    const token: any = Auth.get_token();
    const [preventFirstRender, setPreventFirstRender] = useState(false);
    const [customizeActivated, setCustomizeActivated] = useState(false);
    const [SKUloader, setSKUloader] = useState(false);
    const [activeItemSKU, setActiveItemSKU] = useState(null);
    const [activeItemSKUValue, setActiveItemSKUValue] = useState(null);
    const [SKUInputValue, setSKUInputValue] = useState('');
    const [SKUprefix, setSKUprefix] = useState("");
    const [SKUformat, setSKUformat] = useState([]);
    const [config, setConfig] = useState<any>({});
    const [configID, setConfigID] = useState(null);
    const [allFieldsForListingAssistant, setAllFieldsForListingAssistant] = useState<any>([]);
    const [skuGeneratorUniqueIdFormat, setSkuGeneratorUniqueIdFormat] = useState(2);
    const {is_role_allowed,  hasBusiness, hasSimple, hasPro} = useRoles();
    const [mainListDataLister, setMainListDataLister] = useState<any>([
        {
            id:1,
            title:'Listing Title',
            checked: true,
            alwaysVisible: true,
            keyCode: '133',
            allowed: true
        },
        {
            id:2,
            title:'SKU',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode: '1',
            allowed:hasSimple ? false : true,
        },
        {
            id:3,
            title:'Draft',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: true,
            keyCode:'859.1',
            allowed:hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },

        {
            id:4,
            title:'Color',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode:'209',
            allowed: hasSimple ? false : true,
        },
        {
            id:5,
            title:'Material',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode: '82',
            allowed: hasSimple ? false : true,
        },
        {
            id:6,
            title:'Size',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode:'213',
            allowed: hasSimple ? false : true,
        },
        {
            id:7,
            title:'Price',
            checked: true,
            alwaysVisible: false,
            keyCode:'22',
            allowed: true
        },
        {
            id:8,
            title:'Brand',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode: '206',
            allowed: hasSimple ? false : true,
        },
        {
            id:9,
            title:'Short Description',
            checked: true,
            alwaysVisible: false,
            keyCode: "505",
            allowed: true
        },

        {
            id:10,
            title:'UPC',
            checked:  hasSimple  ? false : true,
            alwaysVisible: false,
            keyCode: '704',
            allowed: hasSimple ? false : true,
        },

        {
            id:11,
            title:'Keywords',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode: '453',
            allowed: hasSimple ? false : true,
        },

        {
            id:12,
            title:'Condition',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: 'condition',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:13,
            title:'Weight',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: 'weight',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:14,
            title:'Style',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: '205',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:15,
            title:'Intended For',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: '4',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:16,
            title:'Description Builder Settings',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: '166',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:17,
            title:'Made In',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: '211',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:18,
            title:'COGS',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode: '847',
            allowed: hasSimple ? false : true,
        },
        {
            id:19,
            title:'MSRP',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode: '104',
            allowed: hasSimple ? false : true,
        },
        {
            id:20,
            title:'Quantity',
            checked: hasSimple ? false : true,
            alwaysVisible: false,
            keyCode: '851',
            allowed: hasSimple ? false : true,
        },
        {
            id:21,
            title:'Seller Notes',
            checked: true,
            alwaysVisible: false,
            keyCode: '726',
            allowed: true
        },
        {
            id:22,
            title:'Shipping ZIP Or City Code',
            checked: hasPro || (hasPro && is_role_allowed) ? true : false,
            alwaysVisible: false,
            keyCode: '837',
            allowed: hasPro || (hasPro && is_role_allowed) ? true : false,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:23,
            title:'Shipping Dimensions',
            checked: hasPro || (hasPro && is_role_allowed) ? true : false,
            alwaysVisible: false,
            keyCode: 'shipping_dimensions',
            allowed: hasPro || (hasPro && is_role_allowed) ? true : false,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:24,
            title:'Care',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: '43',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:25,
            title:'Pattern or texture',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: '210',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id:26,
            title:'Season or weather',
            checked: hasSimple || hasBusiness ? false : true,
            alwaysVisible: false,
            keyCode: '36',
            allowed: hasSimple || hasBusiness ? false : true,
            disabledForBusiness: !(hasPro || (hasPro && is_role_allowed)),
        },
        {
            id: 27,
            title: 'SKU Generator',
            checked: (hasPro && is_role_allowed) ? true : false,
            alwaysVisible: false,
            keyCode: 'sku_generator',
            showGenerator: true,
            allowed: (hasPro && is_role_allowed) ? true : false,
        },

    ])

    const nestedFields = [
        {
            id: 28,
            title: 'newCondition',
            checked: true,
            alwaysVisible: false,
            keyCode: '15'
        },
        {
            id: 29,
            title: 'newNewCondition',
            checked: true,
            alwaysVisible: false,
            keyCode: '795'
        },
        {
            id: 30,
            title: 'newPreOwnedCondition',
            checked: true,
            alwaysVisible: false,
            keyCode: '796'
        },
        {
            id: 31,
            title: 'newConditionNotes',
            checked: true,
            alwaysVisible: false,
            keyCode: '17'
        },
        {
            id: 32,
            title: 'newShippingWeightlbkg',
            checked: true,
            alwaysVisible: false,
            keyCode: '2'
        },
        {
            id: 33,
            title: 'newShippingWeightozg',
            checked: true,
            alwaysVisible: false,
            keyCode: '3'
        },
        {
            id: 34,
            title: 'newShippingPackageLength',
            checked: true,
            alwaysVisible: false,
            keyCode: '531'
        },
        {
            id: 35,
            title: 'newShippingPackageWidth',
            checked: true,
            alwaysVisible: false,
            keyCode: '532'
        },
        {
            id: 36,
            title: 'newShippingPackageHeight',
            checked: true,
            alwaysVisible: false,
            keyCode: '533'
        },
    ];
    const skuChecked = mainListDataLister?.find((item:any) => item.keyCode === '1')?.checked;

    const skuKeys = [
        'sku_generator_location_1',
        'sku_generator_location_2',
        'sku_generator_location_3',
        'sku_generator_location_4',
        'sku_generator_location_5'
    ];

    const [listFromMainLister, setListFromMainLister] = useState(mainListDataLister);
    const [expressListerFields, setExpressListerFields] = useState(mainListDataLister);
    const [selectedFields, setSelectedFields] = useState([]);
    const [backendDataFields, setBackendDataFields] = useState<any>(null);
    const [expressLoader, setExpressLoader] = useState(true);
    const [listingAssistantTone, setListingAssistantTone] = useState("")
    const [listingAssistantContext, setListingAssistantContext] = useState('')
    const [zipCode, setZipCode] = useState(null);

    useEffect(() => {
        // Function to combine expressListerFields and new items
        const updateAllFields = () => {
            const conditionItem = expressListerFields.find((item:any) => item.keyCode === 'condition');
            const weightItem = expressListerFields.find((item:any) => item.keyCode === 'weight');
            const shippingDimensionsItem = expressListerFields.find((item:any) => item.keyCode === 'shipping_dimensions');

            const updatedNewItems = nestedFields.map((item:any) => {
                if (item.keyCode === '15' || item.keyCode === '795' || item.keyCode === '796' || item.keyCode === '17') {
                    return { ...item, checked: conditionItem ? conditionItem.checked : item.checked };
                }
                if (item.keyCode === '2' || item.keyCode === '3') {
                    return { ...item, checked: weightItem ? weightItem.checked : item.checked };
                }
                if (item.keyCode === '531' || item.keyCode === '532' || item.keyCode === '533' ) {
                    return { ...item, checked: shippingDimensionsItem ? shippingDimensionsItem.checked : item.checked };
                }
                return item;
            });

            const combinedItems = [...expressListerFields, ...updatedNewItems];
            setAllFieldsForListingAssistant(combinedItems);
        };

        updateAllFields();
    }, [expressListerFields]);


    const getTypeWebsiteInfo = async() => {
        setExpressLoader(true)
        try {
            const response:any = await UserConfigs.get('website', token);
            if(response) {
                const { config, id } = response;
                const hasExpressListerLayout = _.has(config, 'express_lister_layout');
                const hasListingAssistantTone = _.has(config, 'listing_assistant_tone');
                const hasDefaultZip = _.has(config, 'default_shipping_zip');
                if (hasExpressListerLayout) {
                    const expressListerLayoutData = _.get(config, 'express_lister_layout');
                    setBackendDataFields(expressListerLayoutData);
                }
                if(hasDefaultZip){
                    const defalutZip = _.get(config, 'default_shipping_zip');
                    setZipCode(defalutZip)
                }
                if(hasListingAssistantTone) {
                    const listingAssistantTone = _.get(config, 'listing_assistant_tone');
                    setListingAssistantTone(listingAssistantTone)
                }
                setConfig(config);
                setConfigID(id);
                const skuPrefix = _get(config, 'sku_generator_prefix', '');
                if (skuPrefix) {
                    setSKUprefix(skuPrefix);
                }
                const skuFormat = _get(config, 'sku_generator_format', []);
                if (skuFormat.length > 0) {
                    setSKUformat(skuFormat);
                }

                const skuUniqueId = _.has(config, 'sku_generator_unique_id_format');
                if(skuUniqueId){
                    const skuID = _.get(config, 'sku_generator_unique_id_format');
                    setSkuGeneratorUniqueIdFormat(skuID)
                }
                const firstAvailableKey:any = skuKeys.find(key => _.has(config, key));
                if (firstAvailableKey) {
                    setActiveItemSKU(firstAvailableKey);
                    setActiveItemSKUValue(config[firstAvailableKey]);
                }
            }
        }
        catch (e) {
            console.log('error', e)
        }
        finally {
            setExpressLoader(false)
        }
    }

    useEffect(() => {
        setPreventFirstRender(true);
        if(preventFirstRender){
            const updatedMainListData = mainListDataLister.map((item:any) => {
                if (item.keyCode === 'sku_generator') {
                    const skuItem = mainListDataLister.find((sku:any) => sku.keyCode === '1');
                    return { ...item, showGenerator: skuItem ? skuItem.checked : false };
                }
                return item;
            });
            setMainListDataLister(updatedMainListData);
        }

    }, [skuChecked]);

    useEffect(() => {
        getTypeWebsiteInfo();
        const savedSKUValue:any = localStorage.getItem('SKUInputValue');
        if (savedSKUValue) {
            setSKUInputValue(savedSKUValue);
        }
    }, []);

    const handleSKUitemClick = (key:any) => {
        setActiveItemSKU(key);
        setActiveItemSKUValue(config[key])
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = event.charCode;
        const inputValue = event.currentTarget.value;

        // Check if the pressed key is a dot
        if (charCode === 46) {
            // Check if there is already a dot in the input value
            if (inputValue.includes('.')) {
                event.preventDefault();
            }
        } else if ((charCode < 48 || charCode > 57)) {
            // Allow only digits
            event.preventDefault();
        }
    };

    const handleInputChangeSKU = (e:any) => {
        const value = e.target.value;
        if(value?.length > 10) return
        localStorage.setItem('SKUInputValue', value);
        setSKUInputValue(value);
    };

    const handleUpdateSelectedFields = async(selectedFields:any) => {
        try {
            const payload = {
                    config: {
                        ['express_lister_layout'] : selectedFields
                    },
            };
            const response = await UserConfigs.website_update({ id: configID }, payload, token)
            if(response) {
                setCustomizeActivated(false);
                toast.success('Selected fields saved', {
                    autoClose: 2000, // Set the duration for auto close
                    transitionDuration: 500, // Set the transition duration for this specific notification
                    position: 'bottom-right'
                } as any); // Type cast the options object
            }
        }
        catch (e){
            toast.error('Something went wrong while save your selected fields please try again', {
                autoClose: 3000, // Set the duration for auto close
                transitionDuration: 500, // Set the transition duration for this specific notification
                position: 'bottom-right'
            } as any); // Type cast the options object
        }
    }


    return (
     <div>
         {customizeActivated && (
          <ExpressListerCustomize
           setExpressLoader={setExpressLoader}
           handleUpdateSelectedFields={handleUpdateSelectedFields}
           setSelectedFields={setSelectedFields}
           selectedFields={selectedFields}
           listFromMainLister={listFromMainLister}
           setListFromMainLister={setListFromMainLister}
           mainListDataLister={mainListDataLister}
           setMainListDataLister={setMainListDataLister}
           setCustomizeActivated={setCustomizeActivated}
           setExpressListerFields={setExpressListerFields} />
         )
         }
         <ExpressListerMain
          configID={configID}
          expressLoader={expressLoader}
          setExpressLoader={setExpressLoader}
          backendDataFields={backendDataFields}
          setExpressListerFields={setExpressListerFields}
          customizeActivated={customizeActivated}
          setCustomizeActivated={setCustomizeActivated}
          expressListerFields={expressListerFields}
          setListFromMainLister={setListFromMainLister}
          setMainListDataLister={setMainListDataLister}
          listingAssistantTone={listingAssistantTone}
          setListingAssistantTone={setListingAssistantTone}
          listingAssistantContext={listingAssistantContext}
          setListingAssistantContext={setListingAssistantContext}
          zipCode={zipCode}
          allFieldsForListingAssistant={allFieldsForListingAssistant}
          skuKeys={skuKeys}
          config={config}
          handleSKUitemClick={handleSKUitemClick}
          activeItemSKU={activeItemSKU}
          handleKeyPress={handleKeyPress}
          SKUInputValue={SKUInputValue}
          handleInputChangeSKU={handleInputChangeSKU}
          SKUloader={SKUloader}
          setSKUloader={setSKUloader}
          activeItemSKUValue={activeItemSKUValue}
          SKUprefix={SKUprefix}
          SKUformat={SKUformat}
          skuGeneratorUniqueIdFormat={skuGeneratorUniqueIdFormat}
          allowDraftsPageAccess={allowDraftsPageAccess}
         />
     </div>
    )
}