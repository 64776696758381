export const  removeSubAccountAccessModalItems = [
	{
		id:1,
		label:'Remove & transfer listings to sub account',
		action:'detach'
	},
	{
		id:2,
		label:'Remove & keep all listings in your account',
		action:'delete'
	},
]