import styles from './PlansPage.module.scss';
import {ErrorYellowIcon} from "@assets/icons";
import PlansBoxes from "./components/PlansBoxes";
import {Helmet} from "react-helmet";
import React from "react";

const PlansPage = ()=>{
    const plansList = [
        {
            type:'Simple',
            price: '29',
            featuresList: [
                {
                    label: 'Unlimited Listings & Crossposting',
                    value: null
                },
                {
                    label: 'PhotoRoom Background Removals',
                    value: '25 / mo',
                },
                {
                    label: 'Listing Assistant - AI Generated Listings',
                    value: '25 / mo',
                },
                {
                    label: 'Barcode Scans',
                    value: '25 / mo',
                },
            ],
            planSource: 'https://app.listperfectly.com/register/simple/',
            viewFeatureLink: 'https://listperfectly.com/pricing/#table',
        },
        {
            type:'Business',
            price: '49',
            featuresList: [
                {
                    label: 'Unlimited Listings & Crossposting',
                    value: null
                },
                {
                    label: 'PhotoRoom Background Removals',
                    value: '50 / mo',
                },
                {
                    label: 'Listing Assistant - AI Generated Listings',
                    value: '50 / mo',
                },
                {
                    label: 'Barcode Scans',
                    value: '50 / mo',
                },
            ],
            planSource: 'https://app.listperfectly.com/register/business/',
            viewFeatureLink: 'https://listperfectly.com/pricing/#table'
        },
        {
            type:'Pro',
            price: '69',
            featuresList: [
                {
                    label: 'Unlimited Listings & Crossposting',
                    value: null
                },
                {
                    label: 'PhotoRoom Background Removals',
                    value: '1500 / mo',
                },
                {
                    label: 'Listing Assistant - AI Generated Listings',
                    value: '100 / mo',
                },
                {
                    label: 'Barcode Scans',
                    value: '100 / mo',
                },
            ],
            planSource: 'https://app.listperfectly.com/register/pro/',
            viewFeatureLink: 'https://listperfectly.com/pricing/#table'
        },
        {
            type:'Pro Plus - Tier 1',
            price: '99',
            featuresList: [
                {
                    label: 'Unlimited Listings & Crossposting',
                    value: null
                },
                {
                    label: 'PhotoRoom Background Removals',
                    value: '3000 / mo',
                },
                {
                    label: 'Listing Assistant - AI Generated Listings',
                    value: '1000 / mo',
                },
                {
                    label: 'Barcode Scans',
                    value: '1000 / mo',
                },
                {
                    label: `1 Sub Account`,
                    value: null,
                },
            ],
            planSource: 'https://app.listperfectly.com/register/pro-plus/',
            viewFeatureLink: 'https://listperfectly.com/pricing/#table'
        },
        {
            type:'Pro Plus - Tier 2',
            price: '149',
            featuresList: [
                {
                    label: 'Unlimited Listings & Crossposting',
                    value: null
                },
                {
                    label: 'PhotoRoom Background Removals',
                    value: '7500 / mo',
                },
                {
                    label: 'Listing Assistant - AI Generated Listings',
                    value: '2500 / mo',
                },
                {
                    label: 'Barcode Scans',
                    value: '2500 / mo',
                },
                {
                    label: `4 Sub Accounts`,
                    value: null,
                },
            ],
            planSource: 'https://app.listperfectly.com/register/pro-plus-5/',
            viewFeatureLink: 'https://listperfectly.com/pricing/#table'
        },
        {
            type:'Pro Plus - Tier 3',
            price: '249',
            featuresList: [
                {
                    label: 'Unlimited Listings & Crossposting',
                    value: null
                },
                {
                    label: 'PhotoRoom Background Removals',
                    value: '15000 / mo',
                },
                {
                    label: 'Listing Assistant - AI Generated Listings',
                    value: '5000 / mo',
                },
                {
                    label: 'Barcode Scans',
                    value: '5000 / mo',
                },
                {
                    label: `9 Sub Accounts`,
                    value: null,
                },
            ],
            planSource: 'https://app.listperfectly.com/register/pro-plus-10/',
            viewFeatureLink: 'https://listperfectly.com/pricing/#table'
        }
    ]
    return(
        <div className={styles.plansPageMain}>
            <Helmet>
                <title>
                    Change Plan | List Perfectly
                </title>
            </Helmet>
            <div className={styles.plansPageContainer}>
                <div className={styles.plansPageTitle}>Change your Plan</div>
                <div className={`${styles.plansPageDetails} ${styles.plansPageDetailsImportantTitle}`}>
                    <h2 className={styles.plansPageDetailsTitle}><ErrorYellowIcon/> IMPORTANT</h2>
                    <p className={styles.plansPageDetailsDesc}>
                        <span>Downgrading  your plan will result in immediate processing, and any unused subscription time on your current plan will be forfeited. To avoid losing the remaining time on your subscription, we recommend downgrading at the end of your billing cycle.</span>
                    </p>
                </div>
                <div className={styles.plansPageDetails}>
                    <h2 className={styles.plansPageDetailsTitle}>Make Changes Anytime
                    </h2>
                    <ul className={`${styles.plansPageDetailsDesc} ${styles.plansPageDetailsDescList}`}>
                        <li>Payment: When you select “Change Plan,” you will need to reenter your payment information. List Perfectly ensures prorated adjustments, so you never pay extra.
                        </li>
                        <li>Prorated Upgrades: For upgrades, you’ll be charged the balance for the remaining days in your current billing cycle.
                        </li>
                        <li>Discount Codes: Discount codes apply only during initial signup for full subscription discounts. If you upgrade or downgrade later, the remaining balance of your previous subscription is adjusted, and codes will only apply to that remaining balance. Once a code is applied, it cannot be changed.
                        </li>
                    </ul>
                </div>
                <PlansBoxes plansList={plansList}/>
            </div>
        </div>
    )
}

export default PlansPage;